<template>
  <div>
    <v-container class="my-5">
      <v-data-table
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :items-per-page="10"
          :sort-by="'name'"
          :sort-desc="false"
          :headers="headersPrice"
          :items="prices"
          item-key="name"
          class="elevation-1"
          :loading="pricesLoading"

      >
        <template v-slot:top>
          <v-toolbar flat color="primary">
            <v-toolbar-title class="title"> Credits Price </v-toolbar-title>
          </v-toolbar>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Edit</v-card-title>
              <v-card-text>
                <p>{{ name }}</p>
                <v-spacer></v-spacer>
                <v-text-field
                    type="number"
                    v-model="creditsAmount"
                    label="Credits amount"
                    required
                ></v-text-field>
                <v-text-field
                    v-model="usdAmount"
                    label="USD amount"
                    type="number"
                    required
                ></v-text-field>
                <v-text-field
                    v-if="discount !== null"
                    v-model="discount"
                    label="USD amount after Discount"
                    type="number"
                    required
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-card-text>
              <v-card-actions>

                <v-btn color="blue darken-1" text @click="editItemConfirmCredits"
                >
                  {{ translations.GLOBAL_VALUE.save }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
              small
              @click="editItemPrices(item)"
          >
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import {
  changeCommissionPrices,
  changeCommissionTax, changeCreditsRate,
  getCommissionPrices,
  getCommissionTax
} from '@/api/commissionRequest';
import BigNumber from "bignumber.js";
import {mapActions, mapGetters, mapState} from "vuex";
import {api} from "@/providers/api";

export default {
  name: 'CreditsPage',
  data: () => ({
    headers: [
      {
        text: 'ID',
        value: 'id'
      },
      {
        text: 'Credits mount',
        value: 'creditsAmount'
      },
      {
        text: 'USD Amount',
        value: 'usdAmount'
      },
      {
        text: 'Discount (%)',
        value: 'discounts'
      }
    ],
    idDiscount: null,
    id: -1,
    prices: [],
    creditsRateLoading: false,
    dialog: false,
    creditsAmount: '',
    usdAmount: '',
    discount: null,
    pricesLoading: false,
    taxLoading: false,
  }),
  computed: {
    ...mapState(['creditsRate']),
    ...mapGetters(['getActiveCurrencyRate', 'getActiveCreditRate']),
    translations() {
      return this.$t('translation')
    },
    translationsCommission() {
      return this.translations.COMMISSION_PRICE
    },
    activeCreditsRate() {
      return this.getActiveCurrencyRate;
    }
  },
  created() {
    this.headersPrice = [
      {
        text: 'ID',
        value: 'id'
      },
      {
        text: 'Credits Amount',
        value: 'creditsAmount'
      },
      {
        text: 'Amount ($)',
        value: 'usdAmount'
      },
      {
        text: 'Amount after Discount ($)',
        value: 'usdDiscount'
      },
      { text: 'Actions', value: 'actions', sortable: false },
    ]
    this.fetchPrices();
  },

  methods: {
    ...mapActions(['fetchCurrentRate']),
    async fetchPrices() {
      this.pricesLoading = true;
      const prices = await api.get('/payments/bill-prices');
      this.prices = prices.map(item => {
        return {
          ...item,
          usdDiscount: item.discounts.length > 0 ? item.discounts[0].discount : undefined,
          idDiscount: item.discounts.length > 0 ? item.discounts[0].id : undefined,
        }
      })
      this.pricesLoading = false;
      return this.prices;
    },
    editItemPrices(item) {
      this.dialog = true;
      this.id = item.id
      this.idDiscount = item.idDiscount
      this.usdAmount = item.usdAmount;
      this.creditsAmount = item.creditsAmount;
      if(item.usdDiscount) {
        this.discount = item.usdDiscount
      } else {
        this.discount = null
      }
    },
    async editItemConfirmCredits() {
      const creditsAmount = Number(this.creditsAmount);
      const usdAmount = Number(this.usdAmount);
      await api.patch(`/admin/payments-price/bill-prices/${this.id}`, {
        creditsAmount: creditsAmount,
        usdAmount: usdAmount
      }).catch(e => e)
      if(this.discount !== null) {
        const discount = Number(this.discount);
        await api.patch(`/admin-payment-bill-price-discount/${this.idDiscount}`, {
          discount: discount,
          disable: true
        }).catch(e => e)
      }
      this.dialog = false;
      await this.fetchPrices();
    },
  }
};
</script>

<style scoped lang="scss">
.actions-container {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
}
.v-data-table {
  margin-bottom: 30px;
}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.user-img {
  display: flex;
  align-items: center;
}

.title {
  color: #f4f6f9;
}

.photo-img {
  margin-top: 3px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    object-fit: cover;
  }

}
</style>
