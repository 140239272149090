import { socket } from '@/providers/socket';

export const userOnlineCheck = {
  computed: {
    getterUserOnline() {
      return (user) => {
        const s = this.$store.state.User.usersOnline.find(s => s.id === user.id);
        if (s) { return s.value; } else {
          this.$store.commit('User/ADD_USER_ONLINE', user);
          // socket.on(`user:online:${user.id}`, (online) => this.$store.commit('User/SET_USER_ONLINE', {id: user.id, online}))
          socket.emit('user-online:subscribe', { userId: user.id });
          return user.isOnline;
        }
      };
    }
  }
};
