<template>
  <div>
    <v-container class="my-5">
      <v-tabs background-color="#f4f6f9" color="primary">
        <v-tab to="photo-log">{{ translations.photo }}</v-tab>
        <v-tab to="text-log">{{ translations.text }}</v-tab>
        <v-tab to="passport-log">{{ translations.passport }}</v-tab>
        <v-tab to="video-log">Video log</v-tab>
        <v-tab to="attachments-log">Attachments log</v-tab>
        <v-tab to="closing-account-log">Closing Account Request</v-tab>
      </v-tabs>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'ModerationUsersLog',
  computed: {
    translations() {
      return this.$t('translation.USERS.MODERATION_USERS_LOG.tabs_name')
    }
  }

};
</script>

<style scoped lang="scss">


.row, .row-text {
  width: 200px;
  display: flex;
  flex-wrap: nowrap;
  margin: 5px;

  .user-img {
    margin-right: 30px;
  }

  .v-btn {
    margin-right: 5px;
    margin-bottom: 15px;
  }
}

.row-text {
  width: unset;
}

.title {
  color: #f4f6f9;
}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.text-container {
}

.user-img {
  display: flex;
  align-items: center;
}
</style>
