var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"my-5"},[_c('v-data-table',{staticClass:"elevation-1 mb-6",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.categoryHeaders,"items":_vm.category,"item-key":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.translationsBlogLog.table_blog_category.title))]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.translationsBlogLog.table_blog_category.btn_new_category)+" ")])]}}]),model:{value:(_vm.dialogCategory),callback:function ($$v) {_vm.dialogCategory=$$v},expression:"dialogCategory"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":_vm.translationsBlogLog.table_blog_category.modal_category_title_label},model:{value:(_vm.editedCategory.title),callback:function ($$v) {_vm.$set(_vm.editedCategory, "title", $$v)},expression:"editedCategory.title"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeCategory}},[_vm._v(" "+_vm._s(_vm.translations.GLOBAL_VALUE.cancel)+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveCategory}},[_vm._v(" "+_vm._s(_vm.translations.GLOBAL_VALUE.save)+" ")])],1)],1)],1),_c('DeleteModal',{attrs:{"dialog":_vm.dialogDeleteCategory},on:{"close-options":_vm.closeDeleteCategory,"save-options":_vm.deleteCategoryConfirm}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editCategory(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteCategory(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.blogHeaders,"items":_vm.blogs,"item-key":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.translationsBlogLog.table_blog_pages.title))]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.addBlog}},[_vm._v(" "+_vm._s(_vm.translationsBlogLog.table_blog_pages.btn_new_page)+" ")]),_c('DeleteModal',{attrs:{"dialog":_vm.dialogDeletePage},on:{"close-options":_vm.closeDeletePage,"save-options":_vm.deleteConfirmPage}})],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [(item.createdAt !== null )?_c('div',[_vm._v(" "+_vm._s(_vm._f("date")(new Date(item.createdAt),'Pp'))+" ")]):_c('div',[_vm._v(" "+_vm._s(item = '')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editPage(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deletePage(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }