<template>
  <div class="create-woman">

    <v-alert
        :value="copyPass"
        border="top"
        color="gray"
        dark
        transition="fade-transition"
        class="alert"
    >
      {{ translationsAddWoman.alert_password_copy }}
    </v-alert>
    <v-form @submit.prevent ref="form">
      <v-row>
        <v-col
            md="8"
            lg="8"
            offset-md="2"
        >
          <h1 class="pb-10">{{ translationsAddWoman.title }}</h1>
          <v-row class="delete-all mb-10" @click="back">
            <v-icon color="primary">mdi-arrow-left-thin</v-icon>
            <h4 class="text-overline ">{{ translationsAddWoman.back_to_agency }}</h4>
          </v-row>
          <v-card color="#f4f6f9" elevation="1" class="pa-5">
            <v-card-title>{{ translationsAddWoman.registration_block.title }}</v-card-title>

            <v-row>
              <v-col md="5" lg="4">
                <div class="upload-button">
                  <AppImageAvatar
                      class="edit-user-avatar"
                      v-model="womanAvatar"
                      :remove-url="womanAvatar"
                  />
                </div>
              </v-col>


              <v-col lg="8" md="7">

                <v-row>
                  <v-col cols="6">
                    <v-text-field
                        :rules="rulesRegistered"
                        v-model="forms.signUp.name"
                        :label="translationsAddWoman.registration_block.name_label"
                        outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        :rules="rulesEmail"
                        v-model="forms.signUp.email"
                        :label="translationsAddWoman.registration_block.email_label"
                        outlined>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">


                    <v-text-field
                        :rules="rulesPassword"
                        v-model="forms.signUp.password"
                        :label="translationsAddWoman.registration_block.password_label"
                        outlined
                        type="password"
                        id="passwordInput"
                        :append-icon="showPass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                        append-outer-icon="mdi-dice-5-outline"
                        prepend-inner-icon="mdi-content-copy"
                        @click:append-outer="randomPassword"
                        @click:append="showPassword"
                        @click:prepend-inner="copyPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete :rules="rulesRegistered "
                                    v-model="forms.signUp.country"
                                    outlined
                                    :items="$getCountries()"
                                    item-text="name"
                                    item-value="code"
                                    :label="translationsAddWoman.registration_block.country_code_label"
                                    persistent-hint
                                    single-line
                                    clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="checkbox">
                    <v-checkbox :label="translationsAddWoman.registration_block.checkbox" v-model="forms.signUp.sendPasswordToEmail">

                    </v-checkbox>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
          </v-card>

          <v-card color="#f4f6f9" elevation="1" class="pa-5 mt-5">
            <v-card-title>{{ translationsAddWoman.profile_info_block.title }}</v-card-title>
            <v-row>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="profileForm.birthday"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        outlined
                        v-model="profileForm.birthday"
                        :label="labelBirthday"
                        placeholder="YYYY-MM-DD"
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="profileForm.birthday"
                      no-title
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                      {{ translations.GLOBAL_VALUE.cancel }}
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(profileForm.birthday)"
                    >
                      {{ translations.GLOBAL_VALUE.save }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    outlined
                    v-model="profileForm.city"
                    :label="translationsAddWoman.profile_info_block.city_label"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-select
                    outlined
                    v-model="profileForm.religion"
                    :label="translationsAddWoman.profile_info_block.religion_label"
                    :items="['Christianity', 'Catholic', 'Orthodoxy', 'Muslim', 'Buddhism', 'Judaism', 'Atheism', 'Other']"
                    :rules="rulesReligion"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-select
                    outlined
                    v-model="profileForm.marital"
                    :label="translationsAddWoman.profile_info_block.marital_label"
                    :items="['never married', 'divorced', 'widowed', ]"
                    :rules="rulesMarital"
                ></v-select>
                <!--              <v-text-field-->
                <!--                  outlined-->
                <!--                  v-model="profileForm.marital"-->
                <!--                  :rules="rulesMarital"-->
                <!--                  label="Marital"-->
                <!--              ></v-text-field>-->
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    type="number"
                    outlined
                    v-model.number="profileForm.children"
                    :label="translationsAddWoman.profile_info_block.children_label"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    outlined
                    v-model="profileForm.occupation"
                    :label="translationsAddWoman.profile_info_block.occupation_label"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-select
                    outlined
                    v-model="profileForm.education"
                    :label="translationsAddWoman.profile_info_block.education_label"
                    :items="['below high school', 'high school', 'some college', 'college', 'university degree']"
                    :rules="rulesEducation"
                ></v-select>

              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <vue-phone-mask-input
                    ref="phoneMask"
                    :placeholder="translationsAddWoman.profile_info_block.phone_label"
                    v-model="profileForm.phone"
                />

                <!--                <vue-phone-mask-input placeholder="Phone" class="input-phone" v-model="profileForm.phone"/>-->
                <!--                <v-text-field-->
                <!--                    outlined-->
                <!--                    v-model="profileForm.phone"-->
                <!--                    label="Phone"-->
                <!--                ></v-text-field>-->
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    outlined
                    v-model="profileForm.eyeColor"
                    :label="translationsAddWoman.profile_info_block.eye_color_label"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    outlined
                    v-model="profileForm.hairColor"
                    :label="translationsAddWoman.profile_info_block.hair_color_label"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    type="number"
                    outlined
                    v-model="profileForm.height"
                    :rules="rulesRegistered"
                    :label="translationsAddWoman.profile_info_block.height_label"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    type="number"
                    outlined
                    v-model="profileForm.weight"
                    :rules="rulesRegistered"
                    :label="translationsAddWoman.profile_info_block.weight_label"
                ></v-text-field>
              </v-col>


            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-textarea
                    outlined
                    v-model="profileForm.bio"
                    :label="translationsAddWoman.profile_info_block.bio_label"
                ></v-textarea>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-textarea
                    outlined
                    v-model="profileForm.ideal"
                    :label="translationsAddWoman.profile_info_block.ideal_label"
                ></v-textarea>
              </v-col>
            </v-row>

          </v-card>

          <v-card color="#f4f6f9" elevation="1" class="pa-5 mt-5">
            <v-card-title>{{ translationsAddWoman.album_photo_label }}</v-card-title>
            <v-row>
              <v-col md="12" lg="12">
                <input type="file" accept="image/*" hidden multiple ref="files" @change="listFiles">
                <v-select
                    v-model="photoList"
                    :items="photoList"
                    item-text="file.name"
                    chips
                    readonly
                    prepend-icon="mdi-paperclip"
                    multiple
                    @click.native="$refs.files.click()"
                    @click:prepend="$refs.files.click()"
                    @click:append="clearClick"
                    label="Photo input"
                    append-icon="mdi-close"
                >
                  <template v-slot:selection="{item}">
                    <v-chip
                        color="primary"
                        label
                        small
                        close
                        @click:close="deletePhoto(item.src)"
                    >
                      {{ item.file.name }}
                    </v-chip>

                  </template>
                </v-select>
              </v-col>
              <v-col lg="12">
                <div v-if="photoList.length !== 0">
                  <!--                  <p @click="clearClick" class="delete-all">Delete All</p>-->
                  <v-btn outlined @click="clearClick" dark class="delete-all" color="red">{{ translations.GLOBAL_VALUE.remove_all }}</v-btn>
                  <div class="photo-list">
                    <div class="container-photo" v-for="photo in photoList" :key="photo.src">
                      <img :src="photo.src" alt=""/>
                      <v-icon class="icon-delete" color="red" @click="deletePhoto(photo.src)">mdi-close-box</v-icon>
                    </div>

                  </div>
                </div>
              </v-col>
            </v-row>

          </v-card>

          <v-card color="#f4f6f9" elevation="1" class="pa-5 mt-5">
            <v-card-title>{{ translationsAddWoman.passport_label }}</v-card-title>

            <v-row>
              <v-col md="12" lg="12">
                <v-row>

                  <v-col cols="4">
                    <p class="ml-8">{{ translationsAddWoman.passport_types.front }}</p>
                    <v-file-input
                        class="passport-input pass-front"
                        :class="{'active': passportPhotoOne.length > 0}"
                        accept="image/*"
                        outlined
                        v-model="passport1"
                        @change="passportOneChange"
                        :clearable="false"
                        ref="passportOneInput"
                    >
                      <template v-slot:selection>
                        <div class="passport-photo">
                          <div class="container-photo" v-for="photo in passportPhotoOne" :key="photo.src">
                            <img :src="photo.src" alt=""/>
                            <v-icon class="icon-delete" color="red" @click.stop="clearClickPassportOne()">mdi-close-box
                            </v-icon>
                          </div>

                        </div>

                      </template>

                    </v-file-input>

                  </v-col>
                  <v-col cols="4">
                    <p class="ml-8">{{ translationsAddWoman.passport_types.back }}</p>
                    <v-file-input
                        :class="{'active': passportPhotoTwo.length > 0}"
                        class="passport-input pass-back"
                        outlined
                        accept="image/*"
                        v-model="passport2"
                        @change="passportTwoChange"
                        :clearable="false"
                    >

                      <template v-slot:selection>
                        <div class="passport-photo">
                          <div class="container-photo" v-for="photo in passportPhotoTwo" :key="photo.src">
                            <img :src="photo.src" alt=""/>
                            <v-icon class="icon-delete" color="red" @click.stop="clearClickPassportTwo()">mdi-close-box
                            </v-icon>
                          </div>

                        </div>

                      </template>

                    </v-file-input>
                  </v-col>
                  <v-col cols="4">
                    <p class="ml-8">{{ translationsAddWoman.passport_types.selfie }}</p>
                    <v-file-input
                        :class="{'active': passportPhotoThree.length > 0}"
                        class="passport-input pass-self"
                        outlined
                        accept="image/*"
                        v-model="passport3"
                        @change="passportThreeChange"
                        :clearable="false"
                    >

                      <template v-slot:selection>
                        <div class="passport-photo">
                          <div class="container-photo" v-for="photo in passportPhotoThree" :key="photo.src">
                            <img :src="photo.src" alt=""/>
                            <v-icon class="icon-delete" color="red" @click.stop="clearClickPassportThree()">
                              mdi-close-box
                            </v-icon>
                          </div>

                        </div>

                      </template>

                    </v-file-input>
                  </v-col>

                </v-row>
              </v-col>
            </v-row>


            <v-row>
              <v-col>
                <v-btn
                    color="success"
                    class="mr-4"
                    @click="createProfile"
                    v-if="!progress"
                >
                  {{ translationsAddWoman.btn_create_profile }}
                </v-btn>
                <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                ></v-progress-circular>
                <v-alert
                    class="mt-3"
                    :value="errorAlert"
                    type="error"
                    border="top"
                    transition="scale-transition"
                >
                  {{ translationsAddWoman.alert_err_message }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-checkbox
                  v-model="multipleAcc"
                  :label="translationsAddWoman.checkbox"
                  color="warning"
                  hide-details
              ></v-checkbox>
            </v-row>


          </v-card>


        </v-col>

      </v-row>
    </v-form>

  </div>
</template>

<script>
import AppImageAvatar from "@/components/AppImageAvatar.vue";
import {postAuthRegister} from "@/api/authRequest";
import {postSaveAvatar, postSaveUser, postSaveUserProfile} from "@/api/editUsers";
import {addUsersAgency} from "@/api/agencyRequest";
import {api} from "@/providers/api";
import {uniqBy} from "lodash";

export default {
  name: "AddWoman",
  components: {AppImageAvatar},
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    errorAlert: false,
    passportPhotoOne: [],
    passportPhotoTwo: [],
    passportPhotoThree: [],
    passport1: [],
    passport2: [],
    passport3: [],
    files: [],
    passports: [],
    passportPhoto: [],
    multipleAcc: false,
    progress: false,
    showPass: false,
    copyPass: false,
    rulesRegistered: [
      v => v && v.length >= 1 || 'Must be filled'
    ],
    rulesPassword: [
      v => v && v.length >= 4 || 'Must be filled'
    ],
    rulesEmail: [
      v => !!v || 'Must be filled',
      v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
    ],
    rulesMarital: [
      v => !!v || 'Marital must be one of the following values: never married, divorced, widowed',
    ],
    rulesEducation: [
      v => !!v || 'Education must be one of the following values: below high school, high school, some college, college, university degree',
    ],
    rulesReligion: [
      v => !!v || 'Religion must be one of the following values: Christianity, Catholic, Orthodoxy, Muslim, Buddhism, Judaism, Atheism, Other',
    ],
    selectedMarital: '',
    womanAvatar: undefined,
    forms: {
      signUp: {
        name: null,
        email: null,
        password: null,
        country: 'us',
        isBoy: false,
        sendPasswordToEmail: true,
        agency: 0
      }
    },
    profileForm: {
      name: null,
      surname: null,
      birthday: null,
      balance: null,
      status: null,
      countryCode: 'us',
      city: null,
      state: null,
      marital: null,
      children: 0,
      occupation: null,
      education: null,
      phone: null,
      eyeColor: null,
      hairColor: null,
      religion: null,
      height: null,
      weight: null,
      smoking: false,
      seekingAgeMin: 18,
      seekingAgeMax: 99,
      bio: null,
      ideal: null,
      rating: null,
      membership: {
        membershipTariff: {}
      }
    },
    statusItems: ['REVIEW', 'ACTIVE', 'BLOCKED'],
    valuesRole: [],
    eyeColors: ['brown', 'blue', 'green', 'grey', 'hazel', 'grey-blue'],
    hairColors: ['black', 'brown', 'light-brown', 'blond', 'red', 'grey'],
    menu: false,
    photoList: [],
    photoListName: [],
    test: [],
  }),

  computed: {
    ageComputed() {
      if(this.profileForm.birthday !== null) {
        const currentDate = new Date();
        const currentBirthday =  new Date(this.profileForm.birthday);
        let yearsPassed = currentDate.getFullYear() - currentBirthday.getFullYear();
        const isBirthdayPassed = (currentDate.getMonth() > currentBirthday.getMonth()) ||
            (currentDate.getMonth() === currentBirthday.getMonth() && currentDate.getDate() >= currentBirthday.getDate());
        if (!isBirthdayPassed) {
          yearsPassed--;
        }
        return yearsPassed;
      } else {
        return undefined
      }
    },
    labelBirthday() {
      let staticLabel = this.translationsAddWoman.profile_info_block.birth_label;
      return this.ageComputed && this.ageComputed > 0 ? staticLabel + ". Age — " + this.ageComputed : staticLabel
    },
    translations() {
      return this.$t('translation')
    },
    translationsAddWoman() {
      return this.translations.AGENCY.AGENCY_LOG.create_user_page
    },
  },
  methods: {

    back() {
      this.$router.push({name: `agency-log`, params: {id: this.id}})
    },
    listFiles() {
      for (let i = 0; i < this.$refs.files.files.length; i++)
        this.photoList.push({src: URL.createObjectURL(this.$refs.files.files[i]), file: this.$refs.files.files[i]})
    },
    passportTwoChange() {
      this.passportPhotoTwo.push({
        src: URL.createObjectURL(this.passport2),
        file: this.passport2
      })


    },
    passportOneChange() {
      this.passportPhotoOne.push({
        src: URL.createObjectURL(this.passport1),
        file: this.passport1
      })

    },

    passportThreeChange() {
      this.passportPhotoThree.push({
        src: URL.createObjectURL(this.passport3),
        file: this.passport3
      })

    },

    clearClickPassportOne() {
      this.passport1 = null;
      this.passportPhotoOne.forEach(photo => URL.revokeObjectURL(photo.src));
      this.passportPhotoOne = [];
      // this.$refs.passportOneInput.reset();
    },
    clearClickPassportTwo() {
      this.passport2 = null;
      this.passportPhotoTwo.forEach(photo => URL.revokeObjectURL(photo.src));
      this.passportPhotoTwo = [];
    },
    clearClickPassportThree() {
      this.passport3 = null;
      this.passportPhotoThree.forEach(photo => URL.revokeObjectURL(photo.src));
      this.passportPhotoThree = [];
    },


    deletePhoto(src) {
      console.log(src)
      let index = this.photoList.findIndex(el => el.src === src)
      this.photoList.splice(index, 1);
    },

    clearClick() {
      this.photoList = []
      this.files = []
    },


    copyPassword() {
      console.log(this.forms.signUp.password)
      if (this.forms.signUp.password !== null) {
        this.copyPass = true
        let input = document.getElementById('passwordInput')
        navigator.clipboard.writeText(input.value);
        setTimeout(() => {
          this.copyPass = false
        }, 2000)
      }

    },
    showPassword() {
      this.showPass = !this.showPass
      let input = document.getElementById('passwordInput')
      this.showPass ? input.type = 'text' : input.type = 'password'
    },
    randomPassword() {
      let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
      let charLength = chars.length;
      let result = '';
      for (let i = 0; i < 12; i++) {
        result += chars.charAt(Math.floor(Math.random() * charLength));
      }
      this.forms.signUp.password = result
    },
    async createProfile() {
      console.log(this.profileForm.marital)
      if (this.$refs.form.validate()) {

        try {
          this.progress = true
          this.forms.signUp.agency = Number(this.id)
          const {user, access_token} = await postAuthRegister({...this.forms.signUp});
          // await addUsersAgency(user.id, this.id, '');
          if (this.womanAvatar) {
            const formData = new FormData();
            formData.append('avatar', this.womanAvatar.blob, this.womanAvatar.file.name);
            await api.post('/account/avatar', formData, {
              headers: {
                'Authorization': `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data'
              }
            })
            // await postSaveAvatar(user.id, formData)
          }
          const womanInfo = {
            phone: this.profileForm.phone === '' ? null : this.profileForm.phone,
            patronymic: '',
          }
          await postSaveUser(user.id, womanInfo);

          const womanProfile = {
            birthday: this.profileForm.birthday,
            city: this.profileForm.city,
            state: this.profileForm.state,
            marital: this.profileForm.marital,
            children: this.profileForm.children,
            occupation: this.profileForm.occupation,
            education: this.profileForm.education,
            eyeColor: this.profileForm.eyeColor,
            religion: this.profileForm.religion,
            height: +this.profileForm.height,
            weight: +this.profileForm.weight,
            hairColor: this.profileForm.hairColor,
            smoking: false,
            bio: this.profileForm.bio,
            ideal: this.profileForm.ideal,
            hobby: '',
            seekingAgeMin: this.profileForm.seekingAgeMin,
            seekingAgeMax: this.profileForm.seekingAgeMax,
            rating: 0
          }
          await postSaveUserProfile(user.id, womanProfile);


          if (this.passportPhotoOne.length > 0) {
            const formDataPass1 = new FormData();
            formDataPass1.append('file', this.passportPhotoOne[0].file, this.passportPhotoOne[0].file.name);
            await api.post('/admin/users/passport', formDataPass1, {
              params: {
                userId: user.id,
                type: 'PASSPORT1'
              },
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
          }

          if (this.passportPhotoTwo.length > 0) {
            const formDataPass2 = new FormData();
            formDataPass2.append('file', this.passportPhotoTwo[0].file, this.passportPhotoTwo[0].file.name);
            await api.post('/admin/users/passport', formDataPass2, {
              params: {
                userId: user.id,
                type: 'PASSPORT2'
              },
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
          }
          if (this.passportPhotoThree.length > 0) {
            const formDataPass3 = new FormData();
            formDataPass3.append('file', this.passportPhotoThree[0].file, this.passportPhotoThree[0].file.name);
            await api.post('/admin/users/passport', formDataPass3, {
              params: {
                userId: user.id,
                type: 'PASSPORT_SELFIE'
              },
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
          }


          for (let i = 0; i < this.photoList.length; i++) {
            const formData = new FormData();
            formData.append('avatar', this.photoList[i].file, this.photoList[i].file.name);
            await api.post('/account/album-photo', formData, {
              headers: {
                'Authorization': `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data'
              }
            });
          }
          console.log(user.id, this.id)
          console.log('end')

        } catch (e) {
          this.errorAlert = true

        } finally {
          this.progress = false
          if (!this.errorAlert) {
            this.forms = {
              signUp: {
                name: null,
                email: null,
                password: null,
                country: 'us',
                isBoy: false,
                agency: Number(this.id)
              }
            }
            this.womanAvatar = undefined
            this.$refs.phoneMask.innerValue = null
            this.profileForm = {
              name: null,
              surname: null,
              birthday: null,
              balance: null,
              status: null,
              countryCode: 'us',
              city: null,
              state: null,
              marital: null,
              children: 0,
              occupation: null,
              education: null,
              phone: null,
              eyeColor: null,
              hairColor: null,
              religion: null,
              height: null,
              weight: null,
              smoking: false,
              seekingAgeMin: 18,
              seekingAgeMax: 99,
              bio: null,
              ideal: null,
              rating: null,
              membership: {
                membershipTariff: {}
              }
            }
            this.clearClick()
            this.clearClickPassportOne()
            this.clearClickPassportTwo()
            this.clearClickPassportThree()
            this.$refs.form.resetValidation()
          }

          if (!this.multipleAcc && !this.errorAlert) {
            this.$router.push({name: `agency-log`, params: {id: this.id}})
          }
          setTimeout(() => {
            this.errorAlert = false
          }, 3000)

        }


      }

    }
  }

}
</script>

<style scoped lang="scss">
.upload-button {
  margin-left: 25px;
}

.alert {
  position: absolute;
  right: 10px;
  top: 10px;
  width: max-content;
}

.checkbox {
  margin-top: -35px;
}

.delete-all {
  cursor: pointer;
  width: max-content;

  h4 {
    color: #2f55b4
  }
}

.input-phone {
  padding: 0 12px;
  border: 1px solid black;
}

.photo-list, .passport-photo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .container-photo {
    display: flex;
    align-items: flex-start;
  }

  img {
    width: 175px;
    height: 200px;
    object-fit: cover;
    margin: 10px;
  }
}

.passport-photo {
  @media (max-width: 1440px) {
    img {
      margin: 0px;
      height: 170px;
      width: 130px;
    }

    .icon-delete {
      margin-top: 0px;
      margin-left: -23px;
    }
  }
}


.icon-delete {
  cursor: pointer;
  margin-top: 7px;
  margin-left: -30px;
  background: #f4f6f9;
}

::v-deep {
  .phone-input-lib {
    margin-top: 15px;
    height: unset !important;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    box-shadow: none;
    font-size: 16px;
    padding: 14.5px 14px;
    transition: border-color 0.2s ease-in-out;
    width: 100%;
  }

  .phone-input-lib:focus {
    border-color: #2f55b4;
    outline: none;
  }


  .passport-input {

    &.active {
      .v-file-input__text {
        color: #2f55b4;
        justify-content: center;

        &:after {
          display: none;

        }
      }
    }


    .v-input__slot {
      height: 230px;
      position: relative;


      .v-file-input__text {
        &:after {
          width: 100%;
          background-position: center;
          height: 100%;
          content: "";
          left: 0;
          right: 0;
          background-size: 130px 80px;
          margin: 0 auto;
          background-image: url('@/assets/images/icons/passport-front.svg');

        }
      }
    }

    &.pass-back {
      .v-file-input__text {
        &:after {
          background-image: url('@/assets/images/icons/passport-back.svg');
        }
      }
    }

    &.pass-self {
      .v-file-input__text {
        &:after {
          background-image: url('@/assets/images/icons/passport-self.svg');
        }
      }
    }
  }

}


</style>
