<template>
  <v-container class="my-5">
    <v-data-table
        :footer-props="{'items-per-page-options': [10, 50, 100]}"
        :items-per-page="10"
        :headers="headers"
        :items="withdrawal"
        item-key="id"
        show-expand
        class="elevation-1"
        :expanded.sync="expanded"
        :single-expand="singleExpand"
    >
      <template v-slot:top>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title class="title">Agency Admin Log Withdrawal</v-toolbar-title>
        </v-toolbar>

      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="row">
            <v-col>


              <p class="text-h6">{{ item.fullName }}</p>
              <p class="text-h6">Card: {{ item.bankCard }}</p>

              <v-btn dark class="processing" color="blue"
                     v-if="item.status !== 1 && item.status !== 3 && item.status !== 2" @click="processingStatus(item)">
                Processing
              </v-btn>
              <v-btn dark color="success" v-if="item.status === 1" @click="successStatus(item)">Performed</v-btn>
              <v-btn dark color="error" v-if="item.status === 1" @click="errorStatus(item)">Denied</v-btn>


            </v-col>
          </div>

        </td>
      </template>


      <template v-slot:[`item.status`]="{ item }">
        <div>
          <v-chip
              :color="getColor(item.status)"
              dark
          >
            <span v-if="item.status === 0">Waiting</span>
            <span v-if="item.status === 1">Processing</span>
            <span v-if="item.status === 2">Completed</span>
            <span v-if="item.status === 3">Canceled</span>
            <span v-if="item.status === 4">Failed</span>
          </v-chip>
        </div>


      </template>

      <!--      <template v-slot:[`item.createdAt`]="{ item }">-->
      <!--        <div v-if="item.createdAt !== null ">-->
      <!--          {{ new Date(item.createdAt) | date('Pp') }}-->
      <!--        </div>-->
      <!--        <div v-else>-->
      <!--          {{ item = 'null' }}-->
      <!--        </div>-->
      <!--      </template>-->

      <!--      <template v-slot:[`item.completedAt`]="{ item }">-->
      <!--        <div v-if="item.completedAt !== null ">-->
      <!--          {{ new Date(item.completedAt) | date('Pp') }}-->
      <!--        </div>-->
      <!--        <div v-else>-->
      <!--          {{ item = 'Processing' }}-->
      <!--        </div>-->
      <!--      </template>-->


    </v-data-table>
  </v-container>
</template>

<script>
import { getWithdrawals } from '@/api/transactionsRequest';

export default {
  name: 'AgencyAdminTableWithdrawel',
  data: () => ({
    showBtn: false,
    expanded: [],
    singleExpand: true,
    withdrawal: [],
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',

      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Bank Name',
        value: 'bankName',
      },

      {
        text: 'Bank Card',
        value: 'bankCard',
      },

      {
        text: 'Agency Name',
        value: 'fullName',
      },

      {
        text: 'Created',
        value: 'createdAt',
      },

      {
        text: 'Completed',
        value: 'completedAt',
      },

      {
        text: 'Amount',
        value: 'amount',
      },

      {
        text: 'Status',
        value: 'status',
      },
    ],

  }),
  created() {
    this.fetchAgencyWithdrawal();
  },
  methods: {
    async fetchAgencyWithdrawal() {
      let response = await getWithdrawals();
      console.log(response);
      this.withdrawal = response.items;
      return this.withdrawal;

    },
    getColor(status) {
      if (status === 0) {
        return 'purple';
      } else if (status === 1) return 'orange';
      else if (status === 2) return 'green';
      else return 'red';
    },
  }
};
</script>

<style scoped lang="scss">
.row {
  max-width: 350px;
  display: flex;
  flex-wrap: nowrap;
  margin: 5px;

  .user-img {
    margin-right: 30px;
  }

  .v-btn {
    margin-right: 5px;
    margin-bottom: 15px;
  }
}
</style>
