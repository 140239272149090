import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import router from '@/routes/router';

import VueRouterMiddleware from 'vue-router-middleware';
import { initAuth } from '@/providers/auth';
import { routerMiddlewares } from '@/routes/middlewares';
import { AuthMixin } from '@/mixins/auth.mixin';
import { UserAgeMixin } from '@/mixins/user-age.mixin';
import VModal from 'vue-js-modal';

import { ImageResolverMixin } from '@/mixins/image-resolver.mixin';
import { LocationsMixin } from '@/mixins/locations.mixin';
import { userOnlineCheck } from "@/mixins/user-online.mixin";
import VuePhoneMaskInput from 'vue-phone-mask-input';

import '@/providers/socket';
import '@/providers/events';
import VueDateFns from 'vue-date-fns';

import lodash from 'lodash';
import Clipboard from 'v-clipboard';
import hljs from 'highlight.js'
import 'highlight.js/styles/monokai-sublime.css'
import VueQuillEditor from "vue-quill-editor";
import store from '@/store';
// import "quill-paste-smart";
import "quill/dist/quill.snow.css";

import { BalanceMixin } from '@/mixins/balance.mixin';
import VueI18n from 'vue-i18n';

import locales from './locales';
import { EmojiPickerPlugin } from 'vue-emoji-picker'


Vue.use(EmojiPickerPlugin)
Vue.use(VueI18n);


const i18n = new VueI18n({
  locale: store.state.translation.code,
  messages: {}
});

Vue.use(VueQuillEditor);
Vue.use(hljs);
Vue.use(VueRouterMiddleware, { router, middlewares: routerMiddlewares });
Vue.mixin(ImageResolverMixin);
Vue.mixin(LocationsMixin);
Vue.mixin(userOnlineCheck);
Vue.mixin(AuthMixin);
Vue.mixin(UserAgeMixin);
Vue.mixin(BalanceMixin);
Vue.use(VueRouter);
Vue.use(VModal);
Vue.use(lodash);
Vue.component('vue-phone-mask-input', VuePhoneMaskInput);

// Vue.use(VuePhoneMaskInput);

Vue.use(Clipboard);
Vue.use(VueDateFns);
Vue.config.productionTip = false;
initAuth();

export const app = new Vue({
  i18n,
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app');
