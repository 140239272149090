<template>
  <v-container class="my-5">
    <div v-for="(item, i) in data" :key="i">
      <h3 class="mb-5 mt-5">{{ item.title }}</h3>
      <v-row justify="center">
        <v-expansion-panels inset>
          <v-expansion-panel
              v-for="(item,i) in item.components"
              :key="i"
          >
            <v-expansion-panel-header>{{ item.title }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider class="mb-2"/>

              <p class="mb-1" v-for="(item, i) in item.description" :key="i">{{ item }}</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </div>

  </v-container>
</template>

<script>
export default {
  name: "faq-page",
  data: () => ({
    data: [
      {
        title: 'Profile', components: [
          {
            title: 'What does the status in the female profile mean?',
            description: ['Active - profile is active.',
              'Review - profile is under review by the administrator.',
              'Blocked - profile is blocked by the administrator.']
          },
          {
            title: 'How long does it take to activate a profile?',
            description: ['The activation period usually takes 1-2 days and up to 1 week, depending on the number of new profiles received.']
          },
          {
            title: 'Do I need to attach a copy of a passport to the lady\'s profile?',
            description: ['Yes, a copy of the passport as well as a photo of the lady with a passport are necessary in order to publish a lady\'s profile.']
          },
          {
            title: 'What are the requirements to include copies of the passport?',
            description: ['Only a color copy is accepted, with a size of 600 pixels wide. The passport number should be clearly visible. The administrator has the right to reject the copy of the passport in case of a discrepancy in the data. The profile will not be activated until the requirements are met.']
          },
          {
            title: 'Where can I download a copy of a passport? Is it visible to men?',
            description: ['A copy of a passport is required when creating a new profile. It can be attached in the lady\'s profile. Passports are not visible to male members.']
          },
          {
            title: 'We made some changes to the profile. Do we notify the administration?',
            description: ['Yes, notify the administration about the changes. The administrator will review all changes.']
          },
          {
            title: 'How do we activate a lady’s profile if she has previously been registered on your site with another agency?',
            description: ['In order to activate the lady\'s profile on the admin panel of your agency, the previous agency must delete the lady’s profile on their admin panel.']
          },
          {
            title: 'The lady was registered on your site with another agency. Now she wants to switch to our agency. Her profile has been blocked. How do we do this?',
            description: ['In order to transfer a profile from another agency, you must have at least 20 female profiles on your admin panel. Please notify the administration of the transfer. Once it is approved, the lady\'s profile will be transferred to your agency admin panel.']
          },
          {
            title: 'How do we change photos in a lady\'s profile?',
            description: [
              'Go to your admin panel and find below the list of your ladies’ profiles. Click on the lady’s profile you would like to change the photo of. Next, click on the button "Edit”. Click on the previous photo in the Main Info area. Now you can attach a new photo and click Save Profile.',
              'If you would like to attach more album photos, on the same edit page, find below “PHOTO INFO”, where you may click on “Photo Input” to attach new album photos.',
              'Please note that these photos would be sent to moderation before they could be seen on the lady’s profile.'
            ]
          },
          {
            title: 'Lady\'s profile has been uploaded but still remains in review status. Should we contact the administration?',
            description: [
              'Contact the administrator if it\'s been more than 5 working days.'
            ]
          },
          {
            title: 'Where can I find the username and password for the lady’s profile?',
            description: [
              'You set the login and password when you register a female profile. By checking Send Password to email - login data will be sent to the email address which you enter in her profile.',
              'In case the girl forgot her password or it was lost, please contact the administration of the site to get the login data.'
            ]
          },
          {
            title: 'Does every lady need her own email address, or can we assign an agency’s email address?',
            description: [
              'Yes, every lady needs to have her own email address; unless it\'s not available, then use the agency\'s email, which has not yet been used on the site.'
            ]
          },
          {
            title: 'What will the status of the profile be if we add a copy of the passport?',
            description: [
              'The profile will be in review until the administration confirms the lady\'s profile.'
            ]
          },
          {
            title: 'How can the profile be deactivated?',
            description: [
              'Contact the administration to deactivate the profile.'
            ]
          },
          {
            title: 'The lady is not 18 years old; is it possible to publish her profile?',
            description: [
              'Profiles of ladies under 18 years old are not accepted.'
            ]
          },
          {
            title: 'Can a lady provide a copy of her driver\'s license instead of a passport?',
            description: [
              'Yes, we accept a copy of the driver\'s license instead of a passport.'
            ]
          },
          {
            title: 'Can we register a lady from another city?',
            description: [
              'Yes, make sure that the lady\'s identity has been confirmed and that communication with her is available by phone or email.'
            ]
          },
        ]
      },
      {
        title: 'Bonuses', components: [
          {
            title: 'Is there a bonus for adding profiles?',
            description: ['Once your agency adds 20 women profiles with good-quality photos and these profiles get moderated and registered on the site, you get a bonus of $200. Only if the profile has a copy of her passport and the profiles have good quality photos. If, within 30 days, you deactivate these profiles, these $200 and earned bonuses for these profiles will automatically be removed.']
          },

        ]
      },
      {
        title: 'Chat', components: [
          {
            title: 'Can you give us advice on how to chat? Many men are somehow refusing an invitation to chat with our ladies. Maybe we are doing something wrong. What could be the reason?',
            description: [
              'To attract a man\'s attention a girl can use the following features of the site:',
              '1. Sending chat messages;',
              '2. Sending flirts;',
              '3. Sending a template letter to a selected list of male contacts;',
              '4. Sending a chat date invitation;',
              '5. Liking photos in men\'s profiles.',
            ]
          },
          {
            title: 'The video camera does not work. We click on the button (which is green in color), and everything looks correct, but nothing on the screen appears on the screen.',
            description: ['Please check if you allowed the Flash Player to work. You should receive a window with a request: "Allow this program to use my camera". If everything seems to work correctly and the issue is still not resolved, please contact the site administrators.']
          },
        ]
      },
      {
        title: 'General Questions', components: [
          {
            title: 'How do we contact the site administrator?',
            description: ['If you have any questions and need assistance from the administration of the site, please send a message through the page Customer Support.']
          },
          {
            title: 'Is it possible to give access to the site to other staff of our agency?',
            description: [
              'Yes, this is possible. For each account, you can give access to different roles. How to add a new role to an account:',
              '1. On the main Info page of your admin panel, click on Create User to add a new employee for your agency. If the employee is already a user, please skip this step.',
              '2. To give a role to your registered employee, please click on the Add New User button and select the first row, the name or email of your registered employee. Next, in the second row, you can choose the role of the employee to assign.',
              '3. When you are finished, click on Save.',
              'You can also delete an account if it is no longer needed by pressing Delete.'
            ]
          },
          {
            title: 'How can we change the Admin of the agency?',
            description: ['As with assigning employees roles, you may assign the role of the Admin to another user from your agency. Please see the previous question for the steps on how to assign a role.\n' +
            'If any problem with the assignment occurs, please contact the site administrators.\n']
          },
        ]
      },
      {
        title: 'Favorites', components: [
          {
            title: 'What does a star on the men\'s profile mean?',
            description: ['By adding an account to Favorites, both men and ladies can save an interesting account with whom they would like to chat. After adding an account to favorites, it could be found in the Favorites page and stay there until a man or lady takes the favorite off the profile.']
          },
        ]
      },
      {
        title: 'Chat Date', components: [
          {
            title: 'How do I use the admin of "Chat Date" and what does it mean?',
            description: [
              'By using the Chat Date, users can schedule a chat date ahead of time. In order to send an invitation, click on the round clock sign (Send chat invitation) in the male profile, then enter the date and time of when they want to make the appointment. When a man sends an invitation to a girl, you will be able to see all the information on the page "Chat Date" in the female account. You can also see the status of the appointment:',
              'Pending - New invitation',
              'Accepted - Invitation accepted',
              'Declined - The invitation has been declined',
              'Reschedule - Invitation reassigned to another date',
              'If the status of invitations is pending, the girl must either accept the invitation (accept) or deny it (decline). It can also be an invitation to appoint a different date (reschedule). If the invitation was missed (past due), it can also be rescheduled.',
            ]
          },
        ]
      },
      {
        title: 'Auto-message template', components: [
          {
            title: 'What is an auto-message, and why do I need to use it?',
            description: ['Auto-message is a function on our site that automatically sends chat messages to men online. With this function, you can write your personal templates that will be automatically sent from your ladies’ to men\'s online.',
              'Please note: if you do not add templates for your agency, the base templates set by the site will be used.'
            ]
          },
          {
            title: 'Where can I find templates for auto-messages?',
            description: ['Auto-message templates can be found in your admin panel, in the Settings section. By clicking on New Item, you can add your templates. Below in the list you can also edit and delete existing templates.']
          },
          {
            title: 'How many templates can I input?',
            description: ['Right now, you can input 10 messages to auto-messages.']
          },
          {
            title: 'What examples of an auto-message should I input?',
            description: ['The auto-message is a first impression of a man about your lady within chat. Keep the template interesting enough to get the men’s attention. It is also advised to make some templates with light questions so that the first contact occurs and the man starts to chat.']
          },
          {
            title: 'Can I choose to whom the auto-messages are sent?',
            description: ['No, you cannot. The auto-messaging system operates randomly.']
          },
          {
            title: 'I’ve made my own templates for the auto-message, but it doesn’t work. What should I do?',
            description: ['First, save all the templates you’ve created and try to reload the page. If the templates are still present and the system doesn’t work, please contact the site administrators.']
          },
        ]
      },
      {
        title: 'Introduction letter', components: [
          {
            title: 'What is an introduction letter, and how do you use it?',
            description: ['An introduction letter is the first letter a lady sends to a man to let him get to know her better before chatting. It is advised to use the introduction letter for the first contact. By clicking on the male profile, you may access the “Email Me” button. This will take you to the page where you can compose a letter and add a photo of your liking to get the men’s attention.']
          },
          {
            title: 'Where can I find the letters that I sent or received?',
            description: ['All the letters that you have sent and received can be found in the “Mailbox” section.']
          },
          {
            title: 'Can I send letters to several men at the same time?',
            description: ['Yes, if you go to your mailbox and click on “New Email”, it will take you to the page for the letter. Below it, you can find “Select Recipients”, and by that, choose several men to send the letter to.']
          },
          {
            title: 'How do I respond to a letter in which the man gives his address, phone number, etc.?',
            description: ['Lady needs to send a polite letter to the man that explains the rules of the site, and direct exchange of contact information is prohibited. Male members know that they must request the lady’s contact information. If a man writes that it is very expensive or he does not know how to do it, please advise him to contact the site administrators.']
          },
          {
            title: 'Can a lady invite a man to visit her in a letter?',
            description: ['Of course, it is not only permitted but is also welcome on our website. When a man agrees, she should send him a message asking him to request her contact information.']
          },
          {
            title: 'We cannot respond to a letter from a man; the Reply button is not working.',
            description: ['Please check if you are not using plugins like AdBlock. Try to refresh the page and send the letter again. If the reply button is still not working, please contact the site administrators.']
          },
        ]
      },
      {
        title: 'Recommendations', components: [
          {
            title: 'What is the best way for a girl to start a conversation with a man?',
            description: [
              'To attract a man\'s attention a girl can use the following features of the site:',
              '1. Sending chat messages;',
              '2. Sending flirts;',
              '3. Sending a template letter to a selected list of male contacts;',
              '4. Sending a chat date invitation;',
              '5. Liking photos in men\'s profiles.',
            ]
          },
          {
            title: 'What should the girl write to a man if he offers the girl to join the other free sites, share personal information, and so on?',
            description: ['The girl should write that it is against the rules of the site and that she does not want to break them. Also, she can write that if a man is serious about it, then it can request your contact information (if the girl can talk on the phone and/or she prefers to communicate with a man on a personal email).']
          },
          {
            title: 'How can we increase revenue and the popularity of girls?',
            description: [
              '1. Write an introductory letter from each woman\'s account and distribute to a list of men.',
              '2. Do not write in the letter the city where she was born or any other personal information.',
              '3. Try to help the girl write an informative letter so that the men can find out more about her and know that she is serious about her intentions to find a husband.',
              '4. All letters (including the First Letter) sent by the girl to a man must be in English, literate, and have meaning.',
              '5. We found that different girls from different agencies sent identical letters using exactly the same phrases and sentences. The reason for this is that the girls or their translators copy letter templates or whole sentences from the Internet and send them to men. The result of this is that one man gets the same letter from a few girls who belong to different agencies, which affects both your and our earnings.',
              '6. Make a new photo shoot for the girls who have outdated or low-quality photos. Photos may be amateur, but well made, and the girl must be clearly visible.',
              '7. Chat and also do video chat.',
              '8. Make sure the questionnaire the girls fill out has columns about me and my hobbies; make sure it all makes sense and is written in literate English.',
            ]
          },
        ]
      },
      {
        title: 'Video Chat', components: [
          {
            title: 'What is video chat?',
            description: ['You could access videochat once the original chat (Start Chat Now) has been made with a man. Through either the chat page with a man or his profile, you can click on the camera icon, which would lead to video chat. Both men and women need to accept the invitation to video chat for it to work.']
          },
          {
            title: 'Can a lady communicate simultaneously with several men in a video chat?',
            description: ['No, she can only talk to one man. If a girl is in video chat with a man, another man cannot call her until she finishes talking.']
          },
          {
            title: 'What equipment do I need to use for the video chat?',
            description: ['To use the service, the girl must have headphones with a microphone, or headphones and microphone separately. If the girl is planning to communicate through an interpreter, you can buy a web camera with a built-in microphone, but you still need to have headphones for hearing. To talk to an interpreter, it is necessary to buy two-way headphones so the girl and the translator can hear the man.']
          },
          {
            title: 'Many girls can communicate only through an interpreter. Is that okay?',
            description: ['Please make sure that the profile has the correct level of English for the girl. After all, if it says on the profile that the lady’s English competency is excellent or good, the man would think that she knows English and is able to communicate on her own, without the aid of an interpreter. However, to avoid confusion, the girl can tell a man what level of English she speaks before agreeing to speak on live video chat.']
          },
          {
            title: 'Are there required minutes in video chat?',
            description: ['No, there are no minimum minutes in video chat, a girl can choose how much time she wants to spend in video chat.']
          },
        ]
      },
      {
        title: 'Photo', components: [
          {
            title: 'The girl doesn\'t like her main photo. Can I replace it?',
            description: ['Yes, you can replace the photo. Please go to edit the lady’s profile and click on the first picture for her profile photo. After choosing the new photo, click on Save in the same row as the photo.']
          },
          {
            title: 'What photos are suitable for a portrait?',
            description: ['A portrait is a photo where the girl looks straight and her face is clearly visible. If the image shows only the girl\'s head, this picture is not suitable as a portrait. A picture where the girl is in her underwear or a bathing suit cannot be used as a portrait picture. Portraits can be cut from a photo depicting well-dressed girls.']
          },
          {
            title: 'Can a girl save photos to her account?',
            description: ['Only the profile photo could be changed by the lady. Other photos that are intended to be in the album could only be added by the admin of the agency through the admin page.']
          },

        ]
      },
      {
        title: 'Bank and Payments', components: [
          {
            title: 'What is the currency of the transactions on the site?',
            description: ['A bank transfer is always made in US dollars ($USD).']
          },
          {
            title: 'Is it possible that we receive commissions for a few individuals\' accounts?',
            description: ['No, unfortunately, this is not possible.']
          },
          {
            title: 'When are commission payments made?',
            description: ['After the inquiry for the payment is sent through the admin page, the payment is processed and executed within 24 hours. Please note that the payment could be delayed if the information that you sent is incorrect and/or if the volume of inquiries for the commission payment is high at the moment.']
          },
          {
            title: 'Can I change my bank account?',
            description: ['Yes, banking information can be changed. Please also note that the payment would not come through to the new bank account if you sent the inquiry before changing it.']
          },
          {
            title: 'What if the transfer had not come?',
            description: ['If the transaction did not come within 24 hours from the date of payment in the first place, check that it was filled with information on the bank. If information has been filled in correctly, contact your bank and ask your bank for the reason for the lack of money in your account. If your bank is unable to help you, please contact us using this page, Customer Support, and we will help you resolve this issue.']
          },
        ]
      },
    ]
  })
}
</script>

<style scoped lang="scss">
::v-deep{
  .v-expansion-panel-header{
    font-size: 16px;
    color: #2f55b4;
  }
}
</style>