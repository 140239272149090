export const AuthMixin = {
  computed: {
    $auth() {
      return {
        user: this.$store.state.User.me,
        loggedIn: !!this.$store.state.User.me,
        isAdmin: this.$store.state.User.me.roles.map(r => r.name).includes('admin'),
        isModerator: this.$store.state.User.me.roles.map(r => r.name).includes('moderator')
      };
    },
  },
};
