import {api} from "@/providers/api";

export async function getContact(data) {
  return api.get('/admin/feedbacks', data)
}
export async function sendAnswer(data) {
  return api.post('/admin/feedbacks/response', data)
}
export async function deleteContact(id) {
  return api.delete(`/admin/feedbacks/${id}`)
}

