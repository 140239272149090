<template>
  <div>
    <v-container class="my-5">
      <v-data-table
          :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 50, 100]
      }"
          :items-per-page.sync="itemsPerPage"
          :headers="headers"
          :items="users"
          item-key="name"
          class="elevation-1"
          :server-items-length="totalLikesItems"
          :options.sync="likesOptions"
          :loading="likesLoading"
          :page.sync="page"


      >
        <template v-slot:top>
          <v-toolbar flat color="primary">
            <v-toolbar-title class="title">{{ translationsLikeLog.title }}</v-toolbar-title>
            <v-spacer/>
            <v-btn dark icon @click="toggleSearchFields">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-toolbar>
          <TableSearchFields
              v-if="showSearchFields"
              :searchFields="searchFields"
              @add-field="addSearchField"
              @remove-field="removeSearchField"
              @update-fields="updateSearchCriteria"
              @clear-fields="clearFields"
          />
          <DeleteModal :dialog="dialogDelete" @close-options="closeDelete" @save-options="deleteItemConfirm"/>


        </template>
        <template v-slot:[`item.from`]="{ item }">
          <div class="user-img" @click="userOpen(item.from)">
            <img :src="$getAvatarImage(item.from.profile.avatar)">
            {{ $getUserName(item.from) }}

            <v-icon
                v-if="item.from.id !== me.id && !$auth.isAdmin"
                class="ml-3"
                color="primary"
                @click.stop="$switchProfileUser(item.from.id)"
            >
              mdi-account-arrow-left
            </v-icon>
          </div>
        </template>

        <template v-slot:[`item.to`]="{ item }">
          <div class="user-img" @click="userOpen(item.to)">
            <img :src="$getAvatarImage(item.to.profile.avatar)">
            {{ $getUserName(item.to) }}
            <v-icon
                v-if="item.to.id !== me.id && !$auth.isAdmin"
                class="ml-3"
                color="primary"
                @click.stop="$switchProfileUser(item.to.id)"
            >
              mdi-account-arrow-left
            </v-icon>
          </div>

        </template>

        <template v-slot:[`item.file`]="{ item }">

          <v-img :src="item.file.url" max-height="150" max-width="150"></v-img>

        </template>

        <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:footer>
          <div class="input-page">
            <v-row align="center" justify="start" align-content="center" class="mx-2">
              <v-col cols="12" md="3">
                <v-text-field
                    single-line
                    hide-details
                    label="Go to page"
                    v-model="pageInput"
                    @change="updatePage"
                    type="number"
                    min="1"
                    :max="pageCount"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  Pages: {{ page }} / {{ pageCount }}
                </span>
              </v-col>
            </v-row>
          </div>

        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>

import {getLikes, deleteLike} from "@/api/likeRequest";
import {getUserFlirt, getUserLike} from "@/api/usersRequest";
import router from "@/routes/router";
import {mapGetters} from 'vuex';
import DeleteModal from "@/components/DeleteModal";
import TableSearchFields from "@/components/TableSearchFields.vue";

export default {
  name: 'LikeLog',
  components: {TableSearchFields, DeleteModal},
  data() {
    return {
      likesOptions: {},
      totalLikesItems: 0,
      likesLoading: true,
      dialogDelete: false,
      deleteId: '',
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',

        },

        {
          text: 'FROM',
          value: 'from',
          sortable: false,
        },
        {
          text: 'TO',
          value: 'to',
          sortable: false,
        },
        {
          text: 'PHOTO LIKE',
          value: 'file',
          sortable: false,
        },
        {
          text: 'CREATED',
          value: 'createdAt',
        },
        {text: 'Actions', value: 'actions', sortable: false},

      ],
      users: [],
      page: 1,
      pageInput: 1,
      itemsPerPage: 10,

      showSearchFields: false,
      searchFields: {
        id: [{value: '', multiple: true, name: 'id'}],
        from: [{value: '', multiple: true, name: 'from'}],
        to: [{value: '', multiple: true, name: 'to'}],
        created: [{value: '', multiple: false, name: 'created'}]
      },
      searchCriteria: []
    };
  },

  created() {
    this.headers = [
      {
        text: 'ID',
        align: 'start',
        sortable: true,
        value: 'id',

      },

      {
        text: this.translationsLikeLog.headers.from.toUpperCase(),
        value: 'from',
        sortable: true,
      },
      {
        text: this.translationsLikeLog.headers.to.toUpperCase(),
        value: 'to',
        sortable: true,
      },
      {
        text: 'PHOTO',
        value: 'file',
        sortable: false,
      },
      {
        text: 'CREATED',
        value: 'createdAt',
      },
      {text: this.translationsLikeLog.headers.actions.toUpperCase(), value: 'actions', sortable: false},

    ]
    this.fetchList();
  },
  watch: {
    likesOptions: {
      handler(change) {
        console.log('change payments', change)
        this.syncLikesFromApi()
      },
      deep: true
    },
    page(newVal) {
      this.pageInput = newVal;
    },
  },
  computed: {
    ...mapGetters('User', ['me']),
    translations() {
      return this.$t('translation')
    },
    translationsLikeLog() {
      return this.translations.LIKE_LOG.table
    },
    pageCount() {
      return Math.ceil(this.totalLikesItems / this.itemsPerPage);
    },
  },

  methods: {
    clearFields() {
      this.searchFields = {
        id: [{value: '', multiple: true, name: 'id'}],
        from: [{value: '', multiple: true, name: 'from'}],
        to: [{value: '', multiple: true, name: 'to'}],
        created: [{value: '', multiple: false, name: 'created'}]
      }
    },
    toggleSearchFields() {
      this.showSearchFields = !this.showSearchFields;
    },
    addSearchField(data) {
      const {searchFields, category} = data
      this.searchFields = searchFields
      this.searchFields[category].push({value: ''});
    },
    removeSearchField(data) {
      const {category, index} = data
      this.searchFields[category].splice(index, 1);
    },
    async updateSearchCriteria(data) {
      const {searchFields, searchCriteria} = data
      this.searchFields = searchFields
      this.searchCriteria = searchCriteria
      if (this.page !== 1) {
        this.page = 1
      } else {
        await this.syncLikesFromApi();
      }
    },

    updatePage() {
      let page = parseInt(this.pageInput, 10);
      if (page > 0 && page <= this.totalLikesItems) {
        this.page = page;
      } else {
        this.pageInput = this.page;
      }
    },
    async userOpen(user) {
      const dataLike = await getUserLike(user.id);
      const dataFlirt = await getUserFlirt(user.id);
      const obj = {
        like: {
          dataLike
        },
        flirt: {
          dataFlirt
        }
      };
      console.log(user)
      this.$router.push({name: 'user-log', params: {id: user.id, targetUser: user, obj: obj}})
    },

    async syncLikesFromApi() {
      this.likesLoading = true
      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.likesOptions

        const response = await getLikes({
          params: {
            ...sortBy.length ? {
              sortOrder: sortBy.map((s, i) => (sortDesc[i] ? 'DESC' : 'ASC'))[0],
              sortBy: sortBy.map((s, i) => ((s === 'from' ? 'from_profile.name' : s === 'to' ? 'to_profile.name' : s)))
            } : '',
            limit: limit === -1 ? 100 : limit,
            page,
            ...this.searchCriteria
          },

        })
        console.log(response)

        const {items, meta} = response

        this.users = items;
        this.totalLikesItems = meta.totalItems
      } catch (e) {
        console.log('likes error', e)
      } finally {
        this.likesLoading = false
      }
    },

    deleteItem(item) {
      this.deleteId = item.id
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.users = this.users.filter(e => e.id !== this.deleteId)
      console.log(this.deleteId)
      await deleteLike(this.deleteId)
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    async fetchList() {
      this.users = await getLikes().then(r => r.data);
      return this.users
    }
  }
};
</script>

<style scoped lang="scss">
img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.user-img {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.title {
  color: #f4f6f9;
}

.photo-img {
  margin-top: 3px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    object-fit: cover;
  }

}
</style>
