var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"my-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headers,"items":this.roles,"sort-by":"calories","loading":_vm.rolesLoading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.translation.ROLE_MANAGER.table_roles.title))]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"white","outlined":"","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.translation.ROLE_MANAGER.table_roles.btn_new_role)+" ")])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.translation.ROLE_MANAGER.table_roles.modal_title))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.translation.ROLE_MANAGER.table_roles.modal_label},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.translation.GLOBAL_VALUE.cancel)+" ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.translation.GLOBAL_VALUE.save)+" ")])],1)],1)],1),_c('DeleteModal',{attrs:{"dialog":_vm.dialogDelete},on:{"close-options":_vm.closeDelete,"save-options":_vm.deleteItemConfirm}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.name !== 'admin')?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"select-container"},[_c('v-select',{staticStyle:{"max-width":"400px"},attrs:{"filled":"","items":this.roles,"item-text":"name","item-value":"name","label":_vm.translation.ROLE_MANAGER.select_role_label,"persistent-hint":"","return-object":"","single-line":""},on:{"change":_vm.sortRoles},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-data-table',{staticClass:"table elevation-1",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headersUsers,"items":this.sortList,"item-key":"name","loading":_vm.sortListLoading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.translation.ROLE_MANAGER.table_users_roles.title))])],1)]},proxy:true},{key:"item.profile.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"user-img"},[_c('img',{attrs:{"src":_vm.$getAvatarImage(item.profile.avatar)}}),_vm._v(" "+_vm._s(item.profile.name)+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.createdAt),'Pp')))]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$getBalance(item.balance)))]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }