<template>
  <v-data-table
      :footer-props="{'items-per-page-options': [10, 50, 100]}"
      :items-per-page="10"
      :headers="headers"
      :items="passports"
      item-key="id"
      show-expand
      class="elevation-1"
      :expanded.sync="expanded"
      :single-expand="singleExpand"
      :options.sync="optionsPassport"
      :server-items-length="passportsTotalItems"
      :loading="passportsLoading"
  >
    <template v-slot:top>
      <v-toolbar flat color="primary">
        <v-toolbar-title class="title">{{ translations.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-select
              :label="translations.status_label"
              hide-details
              dark
              dense
              outlined
              :items="passportsStatus"
              class="mt-3"
              v-model="status"
              @change="changeStatusPassport"/>
        </v-toolbar-items>
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-row class="ma-0">
          <v-col cols="4">
            <v-img class="user-img" max-height="200" max-width="800" :src="$getAvatarImage(item.file)"
                   contain></v-img>
          </v-col>

          <v-col>
            <v-btn width="100" class="mb-5 d-block" v-if="item.documentStatus === 'PENDING' || item.documentStatus === 'DECLINED'" color="success" @click="accept(item)">Accept</v-btn>
            <v-btn width="100" class="mb-10 d-block" v-if="item.documentStatus === 'PENDING'" color="error" @click="reject(item)">Reject</v-btn>
          </v-col>
        </v-row>

      </td>
    </template>

    <template v-slot:[`item.user.profile.name`]="{ item }">
      <div class="user-img" @click.prevent="userOpen(item)">
        <img :src="$getAvatarImage(item.user.profile.avatar)">
        {{ item.user.profile.name }}

        <v-icon
            v-if="item.user.id !== me.id && !$auth.isAdmin"
            class="ml-3"
            color="primary"
            @click.stop="$switchProfileUser(item.user.id)"
        >
          mdi-account-arrow-left
        </v-icon>
      </div>
    </template>
    <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>
    <template v-slot:[`item.status`]="{ item }">
      <v-chip
          :color="getColor(item.documentStatus)"
          dark
      >
        <span v-if="item.documentStatus === 'PENDING'">Waiting</span>
        <span v-if="item.documentStatus === 'ACCEPTED'">Accepted</span>
        <span v-if="item.documentStatus === 'DECLINED'">Rejected</span>
      </v-chip>
    </template>

  </v-data-table>
</template>

<script>
import {
  approveChangeImageRequest, changeDocumentStatus,
  getChangeImageRequests,
  getPassportsRequests,
  getUserFlirt,
  getUserLike
} from "@/api/usersRequest";
import router from "@/routes/router";
import {mapGetters} from "vuex";
import {api} from "@/providers/api";

export default {
  name: "UsersPassportTable",

  data: () => ({
    passportsTotalItems: 0,
    optionsPassport: {},
    passportsLoading: true,
    expanded: [],
    singleExpand: true,
    passportsStatus: ['Waiting', 'Accepted', 'Rejected'],
    status: 'Waiting',
    selectedStatus: null,
    headers: [

    ],
    passports: [],
  }),
  watch: {

    optionsPassport: {
      handler(change) {
        console.log('change passport', change);
        this.syncPassportFromApi();
      },
      deep: true
    },
    selectedStatus() {
      this.syncPassportFromApi();
    }
  },
  computed: {
    ...mapGetters('User', ['me']),
    translations() {
      return this.$t('translation.USERS.MODERATION_USERS_LOG.tabs_items.passport_table')
    },
    changeStatus() {
      if (this.status === 'Waiting' ){
        return 'PENDING'
      }
      else if(this.status === 'Accepted' ) {
        return 'ACCEPTED'
      }
      else {
        return 'DECLINED'
      }
    },
  },
  created() {
    this.headers = [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
        sortable: false
      },

      {
        text: this.translations.headers.name,
        value: 'user.profile.name',
        sortable: false

      },
      {
        text: this.translations.headers.created_at,
        value: 'createdAt',
        sortable: false

      },
      {
        text: this.translations.headers.status,
        value: 'status',
        sortable: true

      },
      {
        text: this.translations.headers.document_type,
        value: 'documentType',
        sortable: false
      },
    ]
    // this.fetchPassports()
  },
  methods: {
    changeStatusPassport() {
      // this.fetchPassports();
      this.syncPassportFromApi()
    },
    async syncPassportFromApi() {
      this.passportsLoading = true;

      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.optionsPassport;
        const response = await getPassportsRequests({
          params: {
            status: this.changeStatus,
            ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : [],
            limit: limit === -1 ? 100 : limit,
            page
          }
        });

        const {items, meta} = response;
        console.log('totalItems', response)
        this.passports = items;
        this.passportsTotalItems = meta.totalItems;
      } catch (e) {
        console.log('error contact', e);
      } finally {
        this.passportsLoading = false;
      }


    },

    // async fetchPassports() {
    //   this.passports = await getPassportsRequests({
    //     params: {
    //       status: this.status
    //     }
    //   })
    // },

    async accept(item) {
      const response = await changeDocumentStatus(item.id, 'ACCEPTED');
      console.log(response.success)
      if (response.success)
        item.documentStatus = 'ACCEPTED';
      this.syncPassportFromApi();
    },

    async reject(item) {
      const response = await changeDocumentStatus(item.id, 'DECLINED');
      if (response.success)
        item.documentStatus = 'DECLINED';
      this.syncPassportFromApi();
    },

    getColor(status) {
      if (status === 'ACCEPTED')
        return 'green';
      else if (status === 'DECLINED')
        return 'red';
      else return 'orange';
    },

    async userOpen(user) {
      const dataLike = await getUserLike(user.user.id);
      const dataFlirt = await getUserFlirt(user.user.id);
      const obj = {
        like: {
          dataLike
        },
        flirt: {
          dataFlirt
        }
      };
      console.log(user);
      router.push({name: 'user-log', params: {id: user.user.id, obj: obj}});
    },

  },

}
</script>

<style scoped lang="scss">




.row-text {
  width: unset;
}

.title {
  color: #f4f6f9;
}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.text-container {
}

.user-img {
  cursor: pointer;
  display: flex;
  align-items: center;
}
</style>
