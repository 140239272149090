var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"my-5"},[_c('v-alert',{staticClass:"alert",attrs:{"type":_vm.alertSetting.type,"max-width":"max-content"},model:{value:(_vm.alertSetting.show),callback:function ($$v) {_vm.$set(_vm.alertSetting, "show", $$v)},expression:"alertSetting.show"}},[_vm._v(" "+_vm._s(_vm.alertSetting.text)+" ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"sort-by":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.translationsEmailManager.title))]),_c('v-spacer')],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0 mb-2"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.frequency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.frequency(item.frequency))+" ")]}},{key:"item.sentResult",fn:function(){return [_vm._v(" success ")]},proxy:true},{key:"item.isExecuting",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.isExecuteFormat(item.lastStartTime, item.lastEndTime))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"ma-0 align-center"},[_c('v-switch',{staticClass:"mt-0 mr-0",attrs:{"hide-details":"","color":"green"},on:{"change":function($event){return _vm.changeStatus(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}}),_c('v-chip',{attrs:{"small":"","dark":"","color":_vm.getColorStatus(item.active)}},[_vm._v(" "+_vm._s(item.active ? 'active' : 'inactive')+" ")])],1)]}},{key:"item.lastStartTime",fn:function(ref){
var item = ref.item;
return [(item.lastStartTime === null)?_c('span',[_vm._v(" "+_vm._s('')+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(new Date(item.lastStartTime),'Pp'))+" ")])]}},{key:"item.lastEndTime",fn:function(ref){
var item = ref.item;
return [(item.lastEndTime === null)?_c('span',[_vm._v(" "+_vm._s('')+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(new Date(item.lastEndTime),'Pp'))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-3",on:{"click":function($event){return _vm.openEmail(item)}}},[_vm._v(" mdi-bell-cog ")]),_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.executeById(item)}}},[_vm._v(" mdi-play ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }