import { api } from "@/providers/api";
import store from "@/store";
import router from "@/routes/router";
import { setSocketAuth, socket } from "@/providers/socket";
// import { initChats } from "@/providers/chats";
import  { initPeer } from "@/providers/peer";
import {getAuthMe} from "@/api/authRequest";

function saveAccessToken(accessToken) {
  localStorage.setItem("access_token", accessToken);
}

function getAccessToken() {
  return localStorage.getItem("access_token");
}

function removeAccessToken() {
  localStorage.removeItem("access_token");
}

export function setApiAuth(accessToken) {
  api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  saveAccessToken(accessToken);
  // initChats();
}

export function getApiAuth() {
  return !!getAccessToken();
}

export function getApiAuthToken() {
  return getAccessToken();
}

export function removeApiAuth() {
  api.defaults.headers.common["Authorization"] = null;
  removeAccessToken();
  setSocketAuth(false);
}

export function setUserAuth(accessToken, user) {
  setApiAuth(accessToken);
  store.commit("User/SET_USER_ME", user);
  setSocketAuth(accessToken);
}

export function removeUserAuth() {
  removeApiAuth();
  router.push("/login");
  store.commit("User/SET_USER_ME", null);
}

export function initAuth() {
  const token = getAccessToken();
  if (token) {
    setApiAuth(token);
    socket.on("connect", () => {
      console.log("socket connected, connect to peerjs");
      initPeer();
    });
    store.dispatch("onAuthInit");
  }
}

export async function syncAuthUser() {
  const user = await getAuthMe();
  store.commit("User/SET_USER_ME", user);
  return user;
}
