<template>
  <LineChartGenerator :chart-data="chartData" :chart-options="chartOptions" :data-line="dataLine" width="500" height="500"/>
</template>

<script>
  import {Line as LineChartGenerator} from 'vue-chartjs/legacy'

  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
  } from 'chart.js'

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
  )

  export default {
    name: 'LineChart',
    components: {LineChartGenerator},
    props: {
      dataLine: {
        type: Object,
        required: true
      },
      // chartData: {
      //   type: Object,
      //   required: true
      // },
      // chartOptions: {
      //   type: Object,
      //   default: () => {
      //
      //   }
      // }
    },
    data() {
      return {
        chartData: {
          labels: this.dataLine.labels,
          datasets: [{
            label: this.dataLine.label,
            data: this.dataLine.data,
            fill: false,
            borderColor: '#2f55b4',
            tension: 0.1,
            backgroundColor:
              '#fff'

          }]
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
  }
</script>