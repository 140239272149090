<template>
  <div>
    AgencyApplications
  </div>
</template>

<script>
  export default {
    name: "AgencyApplications"
  }
</script>

<style scoped lang="scss">

</style>