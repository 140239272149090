var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headers,"items":_vm.usersText,"item-key":"id","show-expand":"","expanded":_vm.expanded,"single-expand":_vm.singleExpand,"options":_vm.optionsText,"server-items-length":_vm.textTotalItems,"loading":_vm.textLoading},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.optionsText=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.translations.title))])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"row-text"},[_c('div',{staticClass:"text-container"},[_c('h3',[_vm._v("Hobby")]),_c('p',[_vm._v(" text ")]),_c('h3',[_vm._v("About")]),_c('p',[_vm._v("text")]),_c('h3',[_vm._v("Ideal")]),_c('p',[_vm._v("text")])]),_c('v-col',[(item.status === 0)?_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.accept(item)}}},[_vm._v("Accept")]):_vm._e(),(item.status === 0)?_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.reject(item)}}},[_vm._v("Reject")]):_vm._e()],1)],1)])]}},{key:"item.user.profile.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"user-img",attrs:{"to":{name: 'user-log', params: {id: item.user.id}}}},[_c('img',{attrs:{"src":_vm.$getAvatarImage(item.user.profile.avatar)}}),_vm._v(" "+_vm._s(item.user.profile.name)+" "),(item.user.id !== _vm.me.id && !_vm.$auth.isAdmin)?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$switchProfileUser(item.user.id)}}},[_vm._v(" mdi-account-arrow-left ")]):_vm._e()],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.createdAt),'Pp')))]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[(item.status === 0)?_c('span',[_vm._v("Waiting")]):_vm._e(),(item.status === 1)?_c('span',[_vm._v("Accepted")]):_vm._e(),(item.status === 2)?_c('span',[_vm._v("Rejected")]):_vm._e()])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }