import { Peer } from "peerjs";
import { socket } from "@/providers/socket";
// import { getUserFullMediaStream } from "@/components/app-video-call/helpers";
// import { app } from "@/main";
// import IncomingCallDynamicModal from "@/components/IncomingCallDynamicModal";

export let peerCall = null;
export let peerCallUser = null;
export let peerCallStream = null;
export let peerCallRemoteStream = null;
export let peer = null;

export function setCallStream(stream) {
  peerCallStream = stream;
}

export function setCall(call) {
  peerCall = call;
}

export function setCallRemoteStream(stream) {
  peerCallRemoteStream = stream;
}

export function initPeer() {
  console.log("peerjs init start");
  peer = new Peer();

  peer.on("open", (peerId) => {
    console.log("My peer id", peerId);
    socket.emit("rtc/peer", { peerId });

    peer.on("call", (call) => {
      console.log("call start");
      peerCall = call;
      socket.emit(
        "rtc/peer-user",
        { peerId: call.peer },
        // async ({ user, chatId }) => {
        //   console.log("on Call");
        //   peerCallUser = user;
        //   // const stream = await getUserFullMediaStream();
        //   peerCallStream = stream;
        //
        //   app.$modal.show(
        //     // IncomingCallDynamicModal,
        //     {
        //       user,
        //       chatId,
        //       onAccept: () => {
        //         call.answer(stream); // Answer the call with an A/V stream.
        //
        //         call.on("stream", (remoteStream) => {
        //           console.log("on stream");
        //           console.log(remoteStream);
        //           peerCallRemoteStream = remoteStream;
        //           app.$modal.hide("IncomingCallDynamicModal");
        //           app.$router.push({
        //             name: "chat",
        //             query: {
        //               chat_id: chatId,
        //               is_video_chat: "true",
        //               is_self_call: "false",
        //             },
        //           });
        //           // Show stream in some video/canvas element.
        //         });
        //         call.on("close", () => console.log("call was closed!"));
        //         call.on("error", () => console.log("call was error!"));
        //       },
        //       onReject: () => {
        //         socket.emit("rtc/call-stop", { userId: user.id });
        //       },
        //     },
        //     {
        //       height: 220,
        //       width: 860,
        //       name: "IncomingCallDynamicModal",
        //       classes: "incoming-call-modal",
        //     }
        //   );
        // }
      );
    });
  });
}

// export default {
//   peer,
//   peerCall,
//   peerCallUser,
//   peerCallStream,
//   peerCallRemoteStream,
//   initPeer,
// };
