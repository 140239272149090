<template>
  <div>
    <v-row>
      <v-col
          md="8"
          lg="8"
          offset-md="2"
      >
        <v-card color="#f4f6f9" elevation="1" class="mt-5 pa-5">
          <v-file-input outlined v-model="fileSelected" label="Input file .csv"></v-file-input>
          <v-btn class="mt-5" color="success" @click="saveFile">Save</v-btn>
          <v-alert class="mt-5" max-width="fit-content" v-model="alertShow" :type="alertType">
            {{ alertMessage }}
          </v-alert>
        </v-card>
<!--        <v-card color="#f4f6f9" elevation="1" class="mt-5 pa-5">-->
<!--          <h1>Edit Key</h1>-->
<!--          <v-row class="ma-0">-->
<!--            <v-autocomplete-->
<!--                v-model="searchKey"-->
<!--                :items="allObjectKeys"-->
<!--                label="Search"-->
<!--                outlined-->
<!--                hide-details-->
<!--                @input="onKeySelected"-->
<!--            />-->
<!--          </v-row>-->
<!--          <v-row class="ma-0">-->
<!--            <v-col class="pa-0 pr-3" cols="4">-->
<!--              <v-textarea-->
<!--                  placeholder="New Key"-->
<!--                  outlined-->
<!--                  color="primary"-->
<!--                  height="500"-->
<!--              ></v-textarea>-->
<!--              <v-btn block color="primary">Add new key</v-btn>-->
<!--            </v-col>-->
<!--            <v-col class="pa-0" cols="8">-->
<!--              <div class="d-flex flex-column fill-height justify-space-between">-->
<!--                <div>-->
<!--                  <v-col class="pa-0 mb-3">-->
<!--                    <v-text-field color="primary" prepend-inner-icon="mdi-square-edit-outline" outlined hide-details-->
<!--                                  placeholder="Edit Key"></v-text-field>-->
<!--                  </v-col>-->
<!--                  <v-col class="pa-0">-->
<!--                    <v-text-field color="primary" prepend-inner-icon="mdi-text-box-outline" outlined hide-details-->
<!--                                  placeholder="New Value"></v-text-field>-->
<!--                  </v-col>-->
<!--                  <v-btn color="success" class="mt-5 mr-5">Save</v-btn>-->
<!--                  <v-btn color="error" class="mt-5">Delete</v-btn>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <div-->
<!--                      class="list-language mx-auto"-->

<!--                  >-->
<!--                    <v-list color="#f4f6f9">-->
<!--                      <v-subheader>Languages</v-subheader>-->
<!--                      <v-list-item-group-->
<!--                          v-model="selectedItem"-->

<!--                      >-->
<!--                        <v-list-item-->
<!--                            color="primary"-->
<!--                            v-for="(item, i) in languages"-->
<!--                            :key="i"-->
<!--                            :value="item.value"-->
<!--                        >-->
<!--                          <v-list-item-content>-->
<!--                            <v-list-item-title v-text="item.text"></v-list-item-title>-->
<!--                          </v-list-item-content>-->
<!--                        </v-list-item>-->
<!--                      </v-list-item-group>-->
<!--                    </v-list>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->


<!--            </v-col>-->


<!--          </v-row>-->
<!--        </v-card>-->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {api} from "@/providers/api";

export default {
  name: "KeyEdit",
  data: () => ({
    fileSelected: '',
    alertMessage: '',
    alertShow: false,
    alertType: 'success',
    searchKey: '',
    selectedKeyEdit: '',
    selectedValueEdit: '',
    selectedItem: 1,
    items: [],
  }),
  computed: {
    languages() {
      let arr = []
      switch (this.$store.state.translation.code) {
        case 'en':
          arr = [
            {text: 'English', value: 'en'},
            {text: 'Ukrainian', value: 'ua'},
            {text: 'Russian', value: 'ru'},
          ]
          break
        case 'ua':
          arr = [
            {text: 'Англійська', value: 'en'},
            {text: 'Українська', value: 'ua'},
            {text: 'Російська', value: 'ru'},
          ]
          break
        case 'ru':
          arr = [
            {text: 'Английский', value: 'en'},
            {text: 'Украинский', value: 'ua'},
            {text: 'Русский', value: 'ru'},
          ]
          break
      }
      return arr
    },
    getTranslation() {
      return this.$store.state.translation
    },
    allObjectKeys() {
      return this.flattenKeys(this.getTranslation);
    },


  },
  created() {
    this.selectedItem = this.getTranslation.code
    console.log(this.selectedItem)
  },
  methods: {
    async saveFile() {
      try {
        let formData = new FormData();
        formData.append("file", this.fileSelected, this.fileSelected.name);
        await api.post('/admin/language-translates/upload-translation', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        await this.$store.dispatch('fetchTranslation')
        await this.$store.dispatch('fetchTranslations')
        this.alertMessage = 'Success'
        this.alertShow = true
        this.alertType = 'success'
      } catch (e) {
        console.log("EROOR", e)
        this.alertMessage = e.data.message
        this.alertShow = true
        this.alertType = 'error'
      }
      finally {
        setTimeout(() => {
          this.alertShow = false
        }, 3000)
      }
    },
    flattenKeys(object, parentKey = '') {
      let keys = [];
      for (const key in object) {
        const value = object[key];
        const nestedKey = parentKey ? `${parentKey}.${key}` : key;
        keys.push(nestedKey);
        if (typeof value === 'object') {
          keys = keys.concat(this.flattenKeys(value, nestedKey));
        }
      }
      return keys;
    },

    onKeySelected(selectedKey) {
      this.selectedKeyEdit = selectedKey;
      this.selectedValueEdit = this.getValueByKey(this.getTranslation, selectedKey);
    },
    getValueByKey(object, key) {
      console.log('1', object, key)
      const keys = key.split('.');
      let value = object;
      for (const k of keys) {
        value = value[k];
      }
      if (typeof value !== 'object') {
        return value;
      }
      else {
        return 'OBJECT'
      }
    },

  }
}
</script>

<style scoped lang="scss">
.list-language {
  background: #f4f6f9;
}
</style>