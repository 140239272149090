<template>
  <v-dialog v-model="_dialog" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">{{ transition.title }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDelete">{{ transition.cancel_btn }}</v-btn>
        <v-btn color="blue darken-1" text @click="saveClick">{{ transition.ok_btn }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "DeleteModal",
    props: {
      dialog: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      transition() {
        return this.$t('translation.GLOBAL_VALUE.delete_modal')
      },
      _dialog: {
        get: function () {
          return this.dialog;
        },
        set: function () {
          this.$emit('close-options');
        }
      }
    },
    methods: {
      closeDelete() {
        this.$emit('close-options')
      },
      saveClick() {
        this.$emit('save-options')
      }
    }
  }
</script>

<style scoped>

</style>