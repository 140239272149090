<template>
  <v-container class="my-5">
    <div class="header">


      <v-row>
        <v-col
            cols="12"
            lg="5"
            md="5"
        >
          <v-select
              v-model="select"
              :items="category"
              item-text="title"
              item-value="id"
              :label="translationsBlogLogNewPage.select_category_label"
              persistent-hint
              return-object
              single-line
          >
          </v-select>
        </v-col>
        <v-col
            cols="12"
            lg="5"
            md="5"
        >
          <v-file-input
              show-size
              :label="translationsBlogLogNewPage.img_input_label"
              v-model="formImg"
          ></v-file-input>
        </v-col>
        <v-col
            cols="12"
            lg="2"
            md="2"
        >
          <v-btn class="primary mt-3" @click="pushPost">{{ translationsBlogLogNewPage.btn_add_page }}</v-btn>
        </v-col>

      </v-row>

      <v-text-field
          v-model="formTitle"
          :label="translationsBlogLogNewPage.title_page_label"
      >

      </v-text-field>


    </div>
    <div class="container">
      <quill-editor class="editor"
                    ref="myTextEditor"
                    :value="content"
                    v-model="content"
                    :options="editorOption"
      />
    </div>


    <!--    <div class="output code">-->
    <!--      <code class="hljs" v-html="contentCode"></code>-->
    <!--    </div>-->
  </v-container>
</template>

<script>

import hljs from 'highlight.js';
import 'highlight.js/styles/monokai-sublime.css';
import dedent from 'dedent';
import {getBlogsCategory, postBlogs, uploadFile} from '@/api/blogs';
import ImageUploader from 'quill-image-uploader';
import { Quill } from 'vue-quill-editor/src';

Quill.register('modules/imageUploader', ImageUploader);
export default {
  name: 'NewBlog',
  data() {
    return {
      formImg: null,
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
            ['link', 'image', 'video']
          ],
          syntax: {
            highlight: text => hljs.highlightAuto(text).value
          },
          imageUploader: {
            async upload(file) {
              const form = new FormData();
              form.append('image', file);
              const uploadedFile = await uploadFile(form);
              return uploadedFile.url;
            }
          }
        }
      },
      content: dedent``,
      quillConfig: {
        theme: 'snow',
        placeholder: '',
        modules: {
          toolbar: ['bold', 'italic', 'underline', 'link', 'clean']
        },
      },
      select: '',
      formTitle: '',
      category: []
    };
  },

  created() {
    this.fetchCategory();

  },
  methods: {
    async fetchCategory() {
      this.category = await getBlogsCategory();
      return this.category;
    },
    async pushPost() {
      console.log('categoryID ', this.select.id);

      let formData = new FormData();
      formData.append('image', this.formImg);
      formData.append('title', this.formTitle);
      formData.append('text', this.content);
      await postBlogs(this.select.id, formData);
      this.formImg = null;
      this.formTitle = '';
      this.content = '';
      this.select = null;
    },

  },
  computed: {
    translations() {
      return this.$t('translation')
    },
    translationsBlogLogNewPage() {
      return this.translations.BLOG_LOG.new_blog_page
    },
  },
  mounted() {
  }
};
</script>

<style>
html {
  height: 100%;
  background: #f3f3f3;
}

.container {
  width: 100%;
  height: 150px;
}

header {
  text-align: center;
}

header h1 {
  font-weight: 300;
  margin-top: 30px;
  margin-bottom: 0;
}

header p {
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
}

.ql-container.ql-snow {
  background: white;
}

.ql-editor {
  min-height: 160px;
}
</style>
