<template>
  <div>
    <v-container class="my-5">

      <v-data-table
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :items-per-page="10"
          :headers="headers"
          :items="payments"
          item-key="name"
          class="elevation-1"
          :loading="loadingPayments"
          :server-items-length="totalItemsPayments"
          :options.sync="optionsPayments"
      >
        <template v-slot:top>
          <v-toolbar flat color="primary">
            <v-toolbar-title class="title">{{ translationsPaymentLog.title }}</v-toolbar-title>
          </v-toolbar>

          <!--          <v-dialog v-model="dialogDelete" max-width="500px">-->
          <!--            <v-card>-->
          <!--              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>-->
          <!--              <v-card-actions>-->
          <!--                <v-spacer></v-spacer>-->
          <!--                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>-->
          <!--                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>-->
          <!--                <v-spacer></v-spacer>-->
          <!--              </v-card-actions>-->
          <!--            </v-card>-->
          <!--          </v-dialog>-->

        </template>
        <template v-slot:[`item.fromBalance`]="{ item }">

          <div v-if="item.fromBalance">
            <div class="user-img" v-if="item.fromBalance.owner">
              <img :src="$getAvatarImage(item.fromBalance.owner.profile.avatar)">
              {{ item.fromBalance.owner.profile.name }}

              <v-icon
                      v-if="item.fromBalance.owner.id !== me.id && !$auth.isAdmin"
                      class="ml-3"
                      color="primary"
                      @click.stop="$switchProfileUser(item.fromBalance.owner.id)"
              >
                mdi-account-arrow-left
              </v-icon>
            </div>
            <div v-if="item.fromBalance.agency">
              Agency: {{ item.fromBalance.agency.name }}
            </div>
          </div>
          <div v-else> - </div>
        </template>

        <template v-slot:[`item.destinationBalance`]="{ item }" >
          <div v-if="item.destinationBalance" @click="userOpen(item.destinationBalance.owner)">
            <div class="user-img" v-if="item.destinationBalance.owner">
              <img :src="$getAvatarImage(item.destinationBalance.owner.profile.avatar)">
              {{ item.destinationBalance.owner.profile.name }}

              <v-icon
                      v-if="item.destinationBalance.owner.id !== me.id && !$auth.isAdmin"
                      class="ml-3"
                      color="primary"
                      @click.stop="$switchProfileUser(item.destinationBalance.owner.id)"
              >
                mdi-account-arrow-left
              </v-icon>
            </div>
            <div v-if="item.destinationBalance.agency">
              Agency: {{ item.destinationBalance.agency.name }}
            </div>
          </div>
          <div v-else> - </div>
        </template>


        <template v-slot:[`item.completedAt`]="{ item }">
          <div v-if="item.completedAt !== null ">
            <v-chip
                :color="getColor(item.completedAt)"
                dark
            >
              {{ new Date(item.completedAt) | date('Pp') }}
            </v-chip>
          </div>
          <div v-else>
            <v-chip
                :color="getColor(item.completedAt)"
                dark
            >
              {{ item = 'In pending' }}
            </v-chip>
          </div>
          <!--          {{ item.membership !== null ? ( new Date(item.membership.startDate )) : 'null' }}-->
        </template>

        <template v-slot:[`item.membership.endDate`]="{ item }">
          <div v-if="item.membership !== null ">
            {{ new Date(item.membership.endDate) | date('Pp') }}
          </div>
          <div v-else>
            {{ item = 'null' }}
          </div>
        </template>


        <!--        <template v-slot:[`item.actions`]="{ item }">-->
        <!--          <v-icon-->
        <!--                  small-->
        <!--                  @click="deleteItem(item)"-->
        <!--          >-->
        <!--            mdi-delete-->
        <!--          </v-icon>-->
        <!--        </template>-->
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
  import {getNewTransactions, getUserFlirt, getUserLike} from '@/api/usersRequest';
  import router from "@/routes/router";
  import {mapGetters} from "vuex";


export default {
  name: 'PaymentsLog',
  components: {},
  data() {
    return {
      testDia: false,
      loadingPayments: true,
      optionsPayments: {},
      totalItemsPayments: 0,
      dialogDelete: false,
      deleteId: '',
      expanded: [],
      singleExpand: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',

        },
        {
          text: 'FROM',
          value: 'fromBalance',
                    sortable: false,
        },
        {
          text: 'TO',
          value: 'destinationBalance',
                    sortable: false,
        },

        {
          text: 'TIME',
          value: 'completedAt',
        },

        {
          text: 'DIFF',
          value: 'amount',
        },
        {
          text: 'CATEGORY',
          value: 'category',
        },

      ],

      payments: [],


    };
  },
  async created() {
    this.headers = [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',

      },
      {
        text: this.translationsPaymentLog.headers.from,
        value: 'fromBalance',
        sortable: false,
      },
      {
        text: this.translationsPaymentLog.headers.to,
        value: 'destinationBalance',
        sortable: false,
      },

      {
        text: this.translationsPaymentLog.headers.time,
        value: 'completedAt',
      },

      {
        text: this.translationsPaymentLog.headers.diff,
        value: 'amount',
      },
      {
        text: this.translationsPaymentLog.headers.category,
        value: 'category',
      },

    ]
    this.fetchPayments()
  },

  watch: {
    optionsPayments: {
      handler(change) {
        console.log('change payments', change)
        this.syncTransactionsFromApi()
      },
      deep: true
    }
  },
  computed:{
    ...mapGetters('User', ['me']),
    translations() {
      return this.$t('translation')
    },
    translationsPaymentLog() {
      return this.translations.PAYMENT_LOG.table
    }
  },

  methods: {

    async userOpen(user) {
      const  dataLike  = await getUserLike(user.id);
      const  dataFlirt  = await getUserFlirt(user.id);
      const obj = {
        like: {
          dataLike
        },
        flirt: {
          dataFlirt
        }
      };
      console.log(user)
      router.push({name: 'user-log', params: {id: user.id, targetUser: user,  obj: obj}})
    },
    getColor(status) {
      if (status === null)
        return 'orange';
      else return 'green';
    },

    async fetchPayments() {
      return await getNewTransactions();
    },

    async syncTransactionsFromApi() {
      this.loadingPayments = true
      try {
        const { sortBy, sortDesc, page, itemsPerPage: limit} = this.optionsPayments
        const response = await getNewTransactions({
          params: {
            ...sortBy.length ? { sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC'))) } : [],
            limit: limit === -1 ? 100: limit,
            page
          }
        });

        const { data: items, total } = response

        this.payments = items;
        console.log('payments')
        console.log(this.payments);
        this.totalItemsPayments = total
      }
      catch (e) {
        console.log('error' + JSON.stringify(e))
      }
      finally
      {
        this.loadingPayments = false
      }
    },


  },
  async mounted() {

  }
};
</script>

<style scoped lang="scss">
img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.user-img {
  display: flex;
  align-items: center;
}

.title {
  color: #f4f6f9;
}


</style>
