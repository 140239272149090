var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.activityLoading)?_c('PreloaderPage',{staticClass:"preloader-line"}):_vm._e(),_c('v-container',{class:{'disabled' : _vm.activityLoading}},[_c('MyBreadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('v-tabs',{staticClass:"mb-3",attrs:{"background-color":"#f4f6f9","color":"primary"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab',{attrs:{"to":"staff"}},[_vm._v(" Staff ")]),_c('v-tab',{attrs:{"to":"transactions"}},[_vm._v(" "+_vm._s(_vm.translationsAgencyLog.breadcrumbs.transactions)+" ")]),_c('v-tab',{attrs:{"to":"chats"}},[_vm._v(" Chats ")]),_c('v-tab',{attrs:{"to":"activities"}},[_vm._v(" "+_vm._s(_vm.translationsAgencyLog.breadcrumbs.activities)+" ")]),(!this.$auth.isAdmin)?_c('v-tab',{attrs:{"to":"withdrawal"}},[_vm._v(" "+_vm._s(_vm.translationsAgencyLog.breadcrumbs.withdrawal)+" ")]):_vm._e(),_c('v-tab',{attrs:{"to":"stats"}},[_vm._v(" "+_vm._s(_vm.translationsAgencyLog.breadcrumbs.stats)+" ")]),_c('v-tab',{attrs:{"to":"settings"}},[_vm._v(" "+_vm._s(_vm.translationsAgencyLog.breadcrumbs.settings)+" ")]),_c('v-tab',{attrs:{"to":"pay-out"}},[_vm._v(" "+_vm._s(_vm.translationsAgencyLog.breadcrumbs.pay_out)+" ")])],1)],1),_c('v-container',{staticClass:"my-5"},[_c('v-row',[_c('v-col',{attrs:{"lg":"6","md":"6"}},[_c('v-data-table',{staticClass:"elevation-1",class:{ 'disabled-table': _vm.disabledTable },attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headersMessage,"items":_vm.itemsChats,"server-items-length":_vm.totalItemsChats,"options":_vm.optionsChats,"loading":_vm.loadingChats,"item-class":_vm.itemRowSelected},on:{"update:options":function($event){_vm.optionsChats=$event},"click:row":_vm.selectChat},scopedSlots:_vm._u([{key:"item.chat.members",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"pa-0",attrs:{"dense":""}},[((_vm.$auth.isAdmin || _vm.me.isAgency))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.$switchProfileUser(_vm.getUser(item).id)}}},[_vm._v(" mdi-account-arrow-right ")]):_vm._e(),_c('v-list-item-avatar',{staticClass:"mr-3",attrs:{"size":"35"}},[_c('v-list-item-avatar',{staticClass:"mr-0"},[_c('v-img',{attrs:{"src":_vm.$getAvatarImage(_vm.getUser(item).profile.avatar)}})],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.getUser(item).name))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.getUser(item).email))])],1)],1)]}},{key:"item.chat.membersSecond",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"pa-0",attrs:{"dense":""}},[_c('v-list-item-avatar',{staticClass:"mr-3",attrs:{"size":"35"}},[_c('v-list-item-avatar',{staticClass:"mr-0"},[_c('v-img',{attrs:{"src":_vm.$getAvatarImage(_vm.getUser(item, false).profile.avatar)}})],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.getUser(item, false).name))]),(_vm.$auth.isAdmin)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.getUser(item, false).email))]):_vm._e()],1)],1)]}},{key:"item.unread",fn:function(ref){
var item = ref.item;
return [(item.unread !== 0)?_c('v-chip',{attrs:{"dark":"","small":"","color":"green"}},[_vm._v(" "+_vm._s(item.unread)+" ")]):_vm._e()]}}],null,true)})],1),(_vm.selectedChat)?_c('v-col',{attrs:{"lg":"6","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"card-header"},[_c('v-list-item',{staticClass:"pa-0",attrs:{"dense":"","dark":""}},[_c('v-list-item-avatar',{staticClass:"mr-3",attrs:{"size":"50"}},[_c('v-list-item-avatar',{staticClass:"mr-0"},[_c('v-img',{attrs:{"src":_vm.$getAvatarImage(_vm.getUser(_vm.selectedChat, false).profile.avatar)}})],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.getUser(_vm.selectedChat, false).name))])],1)],1)],1),_c('v-card',{staticClass:"chat-container ",attrs:{"tile":"","loading":_vm.messageLoading,"height":"480"}},[_c('v-card-text',[(!_vm.messageLoading)?_c('v-sheet',_vm._l((_vm.groupMessagesTimeList),function(ref){
var lastMessageTime = ref.lastMessageTime;
var group = ref.group;
return _c('div',{key:lastMessageTime},[_c('div',{staticClass:"date"},[_c('span',[_vm._v(_vm._s(_vm.dateFormat(lastMessageTime)))])]),_vm._l((group),function(ref,index){
var messages = ref.messages;
var sender = ref.sender;
return _c('v-row',{key:index},[_c('v-col',{staticClass:"pa-1",class:_vm.getMessageAlignmentClass(sender.id)},_vm._l((messages),function(message){return _c('div',{key:message.id},[(_vm.getUser(_vm.selectedChat).id !== sender.id)?_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":_vm.$getAvatarImage(_vm.getUser(_vm.selectedChat, false).profile.avatar)}})],1):_vm._e(),_c('div',{staticClass:"message-container"},[(message.emoji)?_c('div',[_c('img',{staticClass:"emoji-img-container",attrs:{"src":message.emoji.file.s3Url,"alt":""}})]):(message.photos.length !== 0)?_c('div',{staticClass:"photos-img-container"},[_c('img',{attrs:{"src":message.photos[0].file.s3Url,"alt":""}})]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(message.text)+" ")]),_c('span',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.format(new Date(message.createdAt), "HH:mm"))+" ")])])],1)}),0)],1)})],2)}),0):_vm._e()],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticStyle:{"position":"relative"},attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","label":"Input message"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendMessage.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('emoji-picker',{attrs:{"search":_vm.search},on:{"emoji":_vm.append},scopedSlots:_vm._u([{key:"emoji-invoker",fn:function(ref){
var clickEvent = ref.events.click;
return _c('button',{on:{"click":function($event){$event.stopPropagation();return clickEvent.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-emoticon")])],1)}},{key:"emoji-picker",fn:function(ref){
var emojis = ref.emojis;
var insert = ref.insert;
return _c('div',{},[_c('div',{staticClass:"emoji-picker"},[_c('div',{staticClass:"emoji-picker__search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"},{name:"focus",rawName:"v-focus"}],attrs:{"type":"text"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]),_c('div',_vm._l((emojis),function(emojiGroup,category){return _c('div',{key:category},[_c('h5',[_vm._v(_vm._s(category))]),_c('div',{staticClass:"emojis"},_vm._l((emojiGroup),function(emoji,emojiName){return _c('span',{key:emojiName,attrs:{"title":emojiName},on:{"click":function($event){return insert(emoji)}}},[_vm._v(_vm._s(emoji))])}),0)])}),0)])])}}],null,false,944117950)})]},proxy:true}],null,false,1911032453),model:{value:(_vm.newMessage),callback:function ($$v) {_vm.newMessage=$$v},expression:"newMessage"}})],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }