import {api} from "@/providers/api";

export async function getCommissionPrices() {
  return api.get('/admin/commission-price/prices')
}

export async function getCommissionTax() {
  return api.get('/admin/commission-price/tax')
}

export async function changeCommissionTax(value, type) {
  return api.post('/admin/commission-price/tax', {},{
    params: {
      value: value,
      commission_type: type
    }
  }).catch(e => e)
}
export async function changeCommissionPrices(value, type, percent) {
  return api.post('/admin/commission-price/prices', {},{
    params: {
      percent: percent,
      value: value,
      price_type: type
    }
  }).catch(e => e)
}

export async function changeCreditsRate(id, value) {
  return api.patch(`/admin-currency-exchange-rates/${id}`, {
    rate: value
  }).catch(e => e)
}