var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-alert',{staticClass:"alert",attrs:{"border":"top","type":"error"},model:{value:(_vm.alertErr),callback:function ($$v) {_vm.alertErr=$$v},expression:"alertErr"}},[_vm._v("No original file ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headers,"items":_vm.usersPhoto,"item-key":"id","show-expand":"","expanded":_vm.expanded,"single-expand":_vm.singleExpand,"options":_vm.optionsAttachments,"server-items-length":_vm.avatarTotalItems,"loading":_vm.avatarLoading},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.optionsAttachments=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v("Attachments Log")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-select',{staticClass:"mt-3",attrs:{"label":"Status","hide-details":"","dark":"","dense":"","outlined":"","items":_vm.photoStatus,"clearable":""},on:{"change":_vm.changeStatusPhoto},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"ma-0"},[(item.image)?_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"pending-photo"},[_c('div',{staticClass:"photo"},[_c('img',{attrs:{"src":_vm.$getAvatarImage(item.image.url),"alt":""}}),(item.image.paid)?_c('v-icon',{staticClass:"icon-lock",attrs:{"color":"red"}},[_vm._v(" mdi-lock ")]):_vm._e()],1)])]):_vm._e(),(item.video)?_c('v-col',{attrs:{"cols":"4"}},[_c('video',{attrs:{"src":item.video.converted.s3Url,"width":"250","controls":""}})]):_vm._e(),_c('v-col',{attrs:{"cols":"8"}},[(item.status === 0 || item.status === 2)?_c('v-btn',{staticClass:"mb-5 d-block",attrs:{"width":"100","color":"success"},on:{"click":function($event){return _vm.accept(item)}}},[_vm._v("Accept ")]):_vm._e(),(item.status === 0)?_c('v-btn',{staticClass:"mb-10 d-block",attrs:{"width":"100","color":"error"},on:{"click":function($event){return _vm.reject(item)}}},[_vm._v(" Reject ")]):_vm._e(),(item.image)?_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingBtnDownload,"disabled":_vm.loadingBtnDownload},on:{"click":function($event){return _vm.download(item)}}},[_vm._v("Download ")]):_vm._e()],1)],1)],1)]}},{key:"item.user.profile.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"user-img",attrs:{"to":{name: 'user-log', params: {id: item.user.id}}}},[_c('img',{attrs:{"src":_vm.$getAvatarImage(item.user.profile.avatar)}}),_vm._v(" "+_vm._s(item.user.profile.name)+" "),(item.user.id !== _vm.me.id && !_vm.$auth.isAdmin)?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$switchProfileUser(item.user.id)}}},[_vm._v(" mdi-account-arrow-left ")]):_vm._e()],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.createdAt),'Pp')))]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[(item.status === 0)?_c('span',[_vm._v("Waiting")]):_vm._e(),(item.status === 1)?_c('span',[_vm._v("Accepted")]):_vm._e(),(item.status === 2)?_c('span',[_vm._v("Rejected")]):_vm._e()])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.image ? 'primary' : 'blue',"dark":""}},[_vm._v(" "+_vm._s(item.image ? 'photo' : 'video')+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }