<template>
  <div>
    CharReport
  </div>
</template>

<script>
  export default {
    name: "CharReport"
  }
</script>

<style scoped lang="scss">

</style>