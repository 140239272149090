var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"my-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headers,"items":_vm.payments,"item-key":"name","loading":_vm.loadingPayments,"server-items-length":_vm.totalItemsPayments,"options":_vm.optionsPayments},on:{"update:options":function($event){_vm.optionsPayments=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.translationsPaymentLog.title))])],1)]},proxy:true},{key:"item.fromBalance",fn:function(ref){
var item = ref.item;
return [(item.fromBalance)?_c('div',[(item.fromBalance.owner)?_c('div',{staticClass:"user-img"},[_c('img',{attrs:{"src":_vm.$getAvatarImage(item.fromBalance.owner.profile.avatar)}}),_vm._v(" "+_vm._s(item.fromBalance.owner.profile.name)+" "),(item.fromBalance.owner.id !== _vm.me.id && !_vm.$auth.isAdmin)?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$switchProfileUser(item.fromBalance.owner.id)}}},[_vm._v(" mdi-account-arrow-left ")]):_vm._e()],1):_vm._e(),(item.fromBalance.agency)?_c('div',[_vm._v(" Agency: "+_vm._s(item.fromBalance.agency.name)+" ")]):_vm._e()]):_c('div',[_vm._v(" - ")])]}},{key:"item.destinationBalance",fn:function(ref){
var item = ref.item;
return [(item.destinationBalance)?_c('div',{on:{"click":function($event){return _vm.userOpen(item.destinationBalance.owner)}}},[(item.destinationBalance.owner)?_c('div',{staticClass:"user-img"},[_c('img',{attrs:{"src":_vm.$getAvatarImage(item.destinationBalance.owner.profile.avatar)}}),_vm._v(" "+_vm._s(item.destinationBalance.owner.profile.name)+" "),(item.destinationBalance.owner.id !== _vm.me.id && !_vm.$auth.isAdmin)?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$switchProfileUser(item.destinationBalance.owner.id)}}},[_vm._v(" mdi-account-arrow-left ")]):_vm._e()],1):_vm._e(),(item.destinationBalance.agency)?_c('div',[_vm._v(" Agency: "+_vm._s(item.destinationBalance.agency.name)+" ")]):_vm._e()]):_c('div',[_vm._v(" - ")])]}},{key:"item.completedAt",fn:function(ref){
var item = ref.item;
return [(item.completedAt !== null )?_c('div',[_c('v-chip',{attrs:{"color":_vm.getColor(item.completedAt),"dark":""}},[_vm._v(" "+_vm._s(_vm._f("date")(new Date(item.completedAt),'Pp'))+" ")])],1):_c('div',[_c('v-chip',{attrs:{"color":_vm.getColor(item.completedAt),"dark":""}},[_vm._v(" "+_vm._s(item = 'In pending')+" ")])],1)]}},{key:"item.membership.endDate",fn:function(ref){
var item = ref.item;
return [(item.membership !== null )?_c('div',[_vm._v(" "+_vm._s(_vm._f("date")(new Date(item.membership.endDate),'Pp'))+" ")]):_c('div',[_vm._v(" "+_vm._s(item = 'null')+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }