<template>
  <div class="main-notifications-campaign-log">
    <v-alert class="alert" v-model="alertNotification" type="success" max-width="max-content">
      {{ textAlert }}
    </v-alert>
    <PreloaderPage v-if="isLoading"/>
    <v-container v-else>
      <MyBreadcrumbs v-if="this.$auth.isAdmin" :breadcrumbs="breadcrumbs"/>
      <v-card>
        <v-row
            class="pa-4 flex-column"
            justify="space-between"
        >
          <v-col
              class="pa-4"
              justify="space-between">
            <v-card-text>
              ID: {{ activeCampaign.id }}
            </v-card-text>
            <v-card-title>
              {{ activeCampaign.name }}
            </v-card-title>
          </v-col>
          <v-divider></v-divider>
          <v-col
              class="pa-4"
              justify="space-between">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title> Title </v-list-item-title>
                <v-list-item-subtitle> {{ activeCampaign.title }} </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title> Description </v-list-item-title>
                <v-list-item-action-text> {{ activeCampaign.text }} </v-list-item-action-text>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line v-if="activeCampaign.targetPage !== null">
              <v-list-item-content>
                <v-list-item-title> URL </v-list-item-title>
                <a :href="activeCampaign.targetPage"> {{ activeCampaign.targetPage }} </a>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line v-if="activeCampaign.imageUrl !== null">
              <v-list-item-content class="image">
                <v-list-item-title> Image </v-list-item-title>
                <div class="img-container">
                  <img :src="activeCampaign.imageUrl" alt="img">
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line v-if="targetAudience.length > 0">
              <v-list-item-content class="target">
                <v-list-item-title> Target Audience </v-list-item-title>
                <v-list-item-content class="chips">
                  <v-chip v-for="(item, index) in targetAudience" :key="index"> {{ item }} </v-chip>
                </v-list-item-content>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-divider></v-divider>
          <v-col

              class="pa-4"
              justify="space-between">
            <v-list-item two-line>
              <v-list-item-content class="action">
                <div class="d-flex w-100">
                  <v-switch
                      :disabled="isActiveStatus === 'IN PROCESS'"
                      dense
                      color="green"
                      @change="changeStatus"
                      v-model="activeCampaign.enabled"
                  ></v-switch>
<!--                  <v-icon-->
<!--                      :disabled="isActiveStatus !== 'stall'"-->
<!--                      class="ml-auto mr-auto"-->
<!--                      @click="changeStatus"-->
<!--                      :color="!activeCampaign.enabled ? 'success' : 'error'" >-->
<!--                    {{ !activeCampaign.enabled ? 'mdi-play-circle-outline' : 'mdi-stop-circle-outline' }}-->
<!--                  </v-icon>-->
                </div>
                <v-list-item-title> Campaign Launch </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content class="action">
                <div class="d-flex w-100">
                  <v-icon
                      :disabled="isActiveStatus === 'IN PROCESS'"
                      class="ml-auto mr-auto"
                      @click="changeProcessCampaign"
                      :color="!isProcess ? 'success' : 'error'" >
                    {{ 'mdi-play-circle-outline' }}
                  </v-icon>
                </div>
                <v-list-item-title> Notifications Launch </v-list-item-title>
                <v-chip small dark :color="isColor"> {{ this.isActiveStatus }}</v-chip>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card>
      <notifications-campaign-list-log :active-id="activeId"/>
      <notifications-campaign-log :active-id="activeId"/>
    </v-container>

  </div>
</template>

<script>
import NotificationsCampaignLog from "@/views/Campaigns/NotificationsCampaign/NotificationsCampaignLog.vue";
import NotificationsCampaignListLog from "@/views/Campaigns/NotificationsCampaign/NotificationsCampaignListLog.vue";
import MyBreadcrumbs from "@/components/Breadcrumbs.vue";
import {api} from "@/providers/api";
import PreloaderPage from "@/components/PreloaderPage.vue";

export default {
  name: "MainNotificationsCampaign",
  components: {PreloaderPage, MyBreadcrumbs, NotificationsCampaignListLog, NotificationsCampaignLog},
  data() {
    return {
      agency: undefined,
      tariff: undefined,
      lastNotificationCampaignProcess: undefined,
      isLoading: false,
      activeCampaign: {},
      alertNotification: false,
      timer: null,
      textAlert: 'Notification sent!'
    }
  },
  created() {
    this.isLoading = true;
    this.fetchDate();
  },
  methods: {
    async changeProcessCampaign() {
      await api.patch(`/admin/notifications-campaigns/${this.activeCampaign.id}/run-process`).then(r => {
        this.textAlert = 'Notification sent!'
        this.alertNotification = true
      }).finally(() => {
        if(this.timer) {
          clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => {
          this.alertNotification = false
        },3000)
      }).catch(e => {
        this.textAlert = 'Error!'
        this.alertNotification = true
      })
    },
    async fetchDate() {
      try {
        const item = await api.get(`/admin/notifications-campaigns/${this.activeId}`)
        this.activeCampaign = item.campaign
        this.agency = item.agency
        this.lastNotificationCampaignProcess = item.lastNotificationCampaignProcess
        this.tariff = item.tariff
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false;
      }
    },
    async changeStatus() {
      try {
        if(!this.activeCampaign.enabled) {
          await api.patch(`/admin/notifications-campaigns/${this.activeId}/stop`)
        } else {
          await api.patch(`/admin/notifications-campaigns/${this.activeId}/start`)
        }
        await this.fetchDate();
      } catch (e) {
        console.log(e)
      }
    },
  },
  computed: {
    isActiveStatus() {
      let item = this.activeCampaign;
      let itemLast = this.lastNotificationCampaignProcess?.status
      let isLastTargetTime = 'STALL';
      if(item.targetTime === null) {
        isLastTargetTime = itemLast ? itemLast === 'RUNNING' ? 'IN PROCESS' : 'STALL' : 'STALL'
      } else {
        let isLastNotificationRunning = itemLast === 'RUNNING'
        isLastTargetTime = isLastNotificationRunning ? 'IN PROCESS' : item.lastExecutedAt !== null ? new Date(item.lastExecutedAt).getTime() <= new Date(item.targetTime).getTime() ? 'WAITING' : 'COMPLETED' : 'WAITING'
      }
      return isLastTargetTime
    },
    isColor() {
        switch (this.isActiveStatus) {
          case 'STALL':
            return 'orange'
          case 'WAITING':
            return 'deep-orange'
          case 'COMPLETED':
            return 'green'
          case 'IN PROCESS':
            return 'blue'
        }
        return 'blue'
    },
    targetAudience() {
      let targetAudience = this.activeCampaign.targetAudience
      let objectKey = Object.keys(targetAudience)
      return objectKey.length > 0 ? objectKey.map(item => {
        switch (item) {
          case 'agencyId':
            return this.agency !== undefined  ? `Agency name: ${this.agency.name}` : undefined;
          case 'tariffId':
            return this.tariff !== undefined  ? `Tariff: ${this.tariff.name}` : undefined;
          case 'isAgency':
            return targetAudience.isAgency ? 'Is agency' : undefined;
          case 'balanceMax':
            return `Balance Max: ${ targetAudience.balanceMax } credits`
          case 'balanceMin':
            return `Balance Min: ${ targetAudience.balanceMin } credits`
          case 'ageMax':
            return `Age Max: ${ targetAudience.ageMax }`
          case 'ageMin':
            return `Age Min: ${ targetAudience.ageMin }`
          case 'isBoy':
            return `Gender: ${ targetAudience.isBoy ? 'Boy' : 'Woman' }`
          case 'country':
            return  `Country: ${this.$getCountyByCode(targetAudience.country).name}`
        }
      }).filter(item => item !== undefined) : []
    },
    isProcess() {
      return this.activeCampaign.notificationCampaignProcess.filter(item => item.status === 'RUNNING').length > 0;
    },
    activeId() {
      return Number(this.$route.params.id);
    },
    translations() {
      return this.$t('translation')
    },
    breadcrumbs() {
      return [
        {text: this.translations.NAVBAR.DASHBOARD.title, href: '/'},
        {text: 'Campaigns', href: '/campaigns'},
        {text: this.activeId, disabled: true}
      ];
    },
  }
}
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
  color: black !important;
}
.v-chip {
  width: fit-content;
  max-width: fit-content;
}
  .v-card {
    &__text {
      padding: 2px 16px;
    }
    &__title {
      padding: 2px 16px;
    }
  }
  .v-list-item__content {
    &.target {
      max-width: fit-content;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      align-self: flex-start;
      width: fit-content;
      .v-list-item__title{
        align-self: flex-start;
      }
    }
    &.image {
      max-width: fit-content;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      align-self: flex-start;
      width: fit-content;
      .v-list-item__title{
        align-self: flex-start;
      }
    }
    &.chips {
      max-height: 250px;
      flex-wrap: wrap;
      flex-direction: column;
      row-gap: 4px;
      column-gap: 4px;
      justify-content: flex-start;
      align-self: flex-start;
      width: fit-content;
      max-width: fit-content;
      align-items: flex-start;

      & > * {
        padding: 12px;
      }
    }
    &.action {
      align-items: center;
      flex-direction: row;
      column-gap: 8px;

      & > * {
        margin-left: 3px;
        width: fit-content;
        max-width: fit-content;
      }

      .v-list-item__title{
        margin-bottom: 0;
        text-align: left;
      }
    }
    .v-list-item__title {
      width: fit-content;
      max-width: fit-content;
      margin-bottom: 6px;
    }

    .v-btn {
      width: fit-content;
      max-width: fit-content;
    }
  }


.alert {
  width: fit-content;
  white-space: nowrap;
  position: fixed;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);
  top: 70px;
  z-index: 100;
}

.img-container {
  max-width: 200px;
  max-height: 120px;
  height: 100%;
  width: 100%;

  & img {
    width: 100%;
    height: 100%;
  }
}
</style>