<template>
  <div class="pa-4">
    <v-data-table
        :footer-props="{'items-per-page-options': [10, 50, 100]}"
        :items-per-page="10"
            :headers="agencyHeader"
            :items="propsItemsAgency"
            show-expand
            class="elevation-1"
            :expanded.sync="expanded"
            :single-expand="singleExpand"
    >
      <template v-slot:top>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title class="title">Agency contracts log</v-toolbar-title>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">


          <v-row justify="space-between">
            <v-col cols="9">
              <v-row align="start">

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Email</v-list-item-title>
                      <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Name of Agency</v-list-item-title>
                      <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Phone</v-list-item-title>
                      <v-list-item-subtitle>{{ item.phone }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

              </v-row>

              <v-divider class="my-5"/>

              <v-row align="start">

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Building Number</v-list-item-title>
                      <v-list-item-subtitle>{{ item.buildingNumber }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Street</v-list-item-title>
                      <v-list-item-subtitle>{{ item.street }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Office Number</v-list-item-title>
                      <v-list-item-subtitle>{{ item.officeNumber }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

              </v-row>
              <v-divider class="my-5"/>

              <v-row align="start">

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>City</v-list-item-title>
                      <v-list-item-subtitle>{{ item.city }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Country</v-list-item-title>
                      <v-list-item-subtitle>{{ item.country }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Date of agency registration</v-list-item-title>
                      <v-list-item-subtitle>{{ item.registerDate }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

              </v-row>
              <v-divider class="my-5"/>

              <v-row align="start">

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>First name of agency director</v-list-item-title>
                      <v-list-item-subtitle>{{ item.directorFirstName }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Last name of agency director</v-list-item-title>
                      <v-list-item-subtitle>{{ item.directorLastName }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Number of active female clients</v-list-item-title>
                      <v-list-item-subtitle>{{ item.activeFemaleClientsCount }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

              </v-row>
              <v-divider class="my-5"/>

              <v-row align="start">

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Number of web cameras</v-list-item-title>
                      <v-list-item-subtitle>{{ item.webCamerasCount }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Number of computers</v-list-item-title>
                      <v-list-item-subtitle>{{ item.computersCount }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Number of employees</v-list-item-title>
                      <v-list-item-subtitle>{{ item.employeesCount }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

              </v-row>
              <v-divider class="my-5"/>

              <v-row align="start">

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Links to your girls on partner sites (min 10) (Please list one link per line.)
                      </v-list-item-title>
                      <v-list-item-subtitle>{{ item.girlLinksList }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>List of current partnership programs your agency is participating in
                      </v-list-item-title>
                      <v-list-item-subtitle>{{ item.partnershipProgramsList }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Tell us about your agency</v-list-item-title>
                      <v-list-item-subtitle>{{ item.aboutUs }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

              </v-row>
              <v-divider class="my-5"/>

              <v-row align="start">

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Have you applied for a partnership program on our site before?
                      </v-list-item-title>
                      <v-list-item-subtitle>{{ item.isAppliedOtherPartnershipBefore }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>If yes, has your application ever been rejected?</v-list-item-title>
                      <v-list-item-subtitle>{{ item.isRejectedOtherPartnershipApplicationsBefore }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>If yes, what was the reason your application was rejected?</v-list-item-title>
                      <v-list-item-subtitle>{{ item.rejectedOtherPartnershipBeforeReason }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

              </v-row>
              <v-divider class="my-5"/>

              <v-row align="start">

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Have you ever been our partner and lost partnership?</v-list-item-title>
                      <v-list-item-subtitle>{{ item.isLostOurPartnership }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>If yes, what was the reason your partnership was terminated?
                      </v-list-item-title>
                      <v-list-item-subtitle>{{ item.lostOurPartnershipReason }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Do you have a website?</v-list-item-title>
                      <v-list-item-subtitle>{{ item.isWebsiteExists }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

              </v-row>
              <v-divider class="my-5"/>

              <v-row align="start">

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>If yes, what is your website address?</v-list-item-title>
                      <v-list-item-subtitle>{{ item.websiteUrl }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>The agency that referred you?</v-list-item-title>
                      <v-list-item-subtitle>{{ item.isProvideMaterialCompensation }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Business license number</v-list-item-title>
                      <v-list-item-subtitle>{{ item.businessLicenseNumber }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

              </v-row>
              <v-divider class="my-5"/>


              <v-row align="start">
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Do you provide any kind of material compensation to the ladies for chat/video
                        chat/ letters etc?
                      </v-list-item-title>
                      <v-list-item-subtitle>{{ item.isProvideMaterialCompensation }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-divider class="my-5"/>

              <v-row align="start">

                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Business license</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                </v-col>

                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Director's Passport</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>


                </v-col>

              </v-row>

            </v-col>


            <v-divider class="my-5" vertical/>

            <v-col cols="3">
              <v-row align="start">
                <v-col class="ma-3">
                  <v-text-field
                          label="Username"
                          outlined
                  />
                  <v-text-field
                          label="Password"
                          outlined
                  />
                  <v-text-field
                          label="Agency name"
                          outlined
                  />
                  <v-btn color="green" dark>Accept Contract</v-btn>
                  <v-divider class="mt-5"/>
                  <h3 class="my-3">Reject Contract</h3>
                  <v-textarea v-model="item.rejectMessage" filled placeholder="Reject message"/>
                  <v-btn color="purple" dark>Reject Contract</v-btn>
                  <v-divider class="mt-5"/>
                  <h3 class="my-3">Deny Contract</h3>
                  <v-textarea v-model="item.denyMessage" filled placeholder="Deny message"/>
                  <v-btn color="red" dark>Deny Contract</v-btn>
                  <v-divider class="mt-5"/>

                </v-col>

              </v-row>
            </v-col>

          </v-row>


        </td>
      </template>

      <template v-slot:[`item.status`]="{item}">

        <v-chip
                :color="getColor(item.status)"
                dark
        >
          {{ item.status }}
        </v-chip>

      </template>

      <template v-slot:[`item.license`]="{item}">
        View
        <v-icon @click="item" color="primary">
          mdi-file-find
        </v-icon>
      </template>

      <template v-slot:[`item.contract`]="{item}">
        Download
        <v-icon @click="item" color="primary">
          mdi-file-download
        </v-icon>
      </template>

      <!--      <template v-slot:[`item.creator`]="{ item }">-->
      <!--        <div class="user-img">-->
      <!--          <img :src="$getAvatarImage(item.creator.profile.avatar)">-->
      <!--          {{ item.creator.profile.name }}-->
      <!--        </div>-->
      <!--      </template>-->

    </v-data-table>
  </div>
</template>

<script>
  import {postStatusAgency} from "@/api/agencyRequest";

  export default {
    name: "UsersContractTable.vue",
    props: {
      propsItemsAgency: {
        type: Array,
        required: true
      }
    },
    data: () => ({
      expanded: [],
      singleExpand: true,
      denyMessage: '',
      rejectMessage: '',


      agencyHeader: [
        {text: 'ID', align: 'start', value: 'id'},
        {text: 'Name of Agency', value: 'name'},
        {text: 'Site Address', value: 'websiteUrl'},
        {text: 'Creator', value: 'creator'},
        {text: 'License', value: 'license'},
        {text: 'Contract', value: 'contract'},

        {text: 'Status', value: 'status'},
      ],
    }),
    methods: {
      getColor(color) {
        switch (color) {
          case 'PENDING':
            return 'orange'
          case 'REJECTED':
            return 'purple'
          case 'APPROVED':
            return 'green'
          default:
            return 'red'
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 20px;
  }

  .user-img {
    display: flex;
    align-items: center;
  }

  .select {
    max-width: 300px;
    margin-right: 100px;
  }

  .text-status {
    color: orange;
  }

  .img {
    width: 300px !important;
    cursor: pointer;
  }

  .icon-btn {
    border: 1px solid white;
    position: absolute;
    margin-top: -25px;
    margin-left: 280px;
    cursor: pointer;
  }
</style>