var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{key:"id",staticClass:"elevation-1",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headersFlirt,"items":_vm.items,"server-items-length":_vm.totalItems,"options":_vm.options,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.creator.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"user-img",on:{"click":function($event){return _vm.userPush(item.creator)}}},[_c('img',{attrs:{"src":_vm.$getAvatarImage(item.creator.profile.avatar)}}),_vm._v(" "+_vm._s(item.creator.name)+" "),(item.creator.id !== _vm.me.id && !_vm.$auth.isAdmin)?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$switchProfileUser(item.creator.id)}}},[_vm._v(" mdi-account-arrow-left ")]):_vm._e()],1)]}},{key:"item.receiver.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"user-img",on:{"click":function($event){return _vm.userPush(item.receiver)}}},[_c('img',{attrs:{"src":_vm.$getAvatarImage(item.receiver.profile.avatar)}}),_vm._v(" "+_vm._s(item.receiver.name)+" "),(item.receiver.id !== _vm.me.id && !_vm.$auth.isAdmin)?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$switchProfileUser(item.receiver.id)}}},[_vm._v(" mdi-account-arrow-left ")]):_vm._e()],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.createdAt),'Pp')))]}},{key:"item.gift.file",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":_vm.$getAvatarImage(item.gift.file),"max-height":"50","max-width":"50"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }