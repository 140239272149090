<template>
    <v-progress-linear
        indeterminate
        color="cyan"
    ></v-progress-linear>
</template>

<script>
export default {
  name: "PreloaderPage",

}
</script>

<style scoped>

</style>