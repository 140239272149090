<template>
  <div>
    <v-container class="my-5">
      <v-alert class="alert" v-model="alertSetting.show" :type="alertSetting.type" max-width="max-content">
        {{ alertSetting.text }}
      </v-alert>
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          class="elevation-1"
          sort-by="id"
      >
        <template v-slot:top>
          <v-toolbar
              color="primary"
              dark
          >
            <v-toolbar-title>{{ translationsEmailManager.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
<!--            <v-checkbox hide-details dark label="Show hidden emails">-->

<!--            </v-checkbox>-->
          </v-toolbar>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <p class="ma-0 mb-2">
            {{ item.name }}
          </p>
          <!--          <a href="">View Details</a>-->
          <!--          <div class="d-flex mt-2">-->
          <!--            <a class="mr-2" href="">View HTML</a>-->
          <!--            <a href="">View TEXT</a>-->
          <!--          </div>-->
        </template>
        <template v-slot:[`item.frequency`]="{ item }">
          {{ frequency(item.frequency) }}
        </template>
        <template v-slot:[`item.sentResult`]>
          success
        </template>
        <template v-slot:[`item.isExecuting`]="{ item }">
          {{ isExecuteFormat(item.lastStartTime, item.lastEndTime) }}
        </template>

        <template v-slot:[`item.active`]="{ item }">
          <v-row class="ma-0 align-center">
            <v-switch
                v-model="item.active"
                hide-details
                color="green"
                class="mt-0 mr-0"
                @change="changeStatus(item)"
            ></v-switch>
            <v-chip  small dark :color="getColorStatus(item.active)">
              {{ item.active ? 'active' : 'inactive'}}
            </v-chip>
          </v-row>
        </template>
        <template v-slot:[`item.lastStartTime`]="{ item }">
          <span v-if="item.lastStartTime === null">
            {{ '' }}
          </span>
          <span v-else>
            {{ new Date(item.lastStartTime) | date('Pp') }}
          </span>
        </template>
        <template v-slot:[`item.lastEndTime`]="{ item }">
          <span v-if="item.lastEndTime === null">
            {{ '' }}
          </span>
          <span v-else>
            {{ new Date(item.lastEndTime) | date('Pp') }}
          </span>
        </template>

        <!--        <template v-slot:[`item.result`]="{ item }">-->
        <!--          <v-chip small dark :color="getColorResult(item.result)">-->
        <!--            {{ item.result }}-->
        <!--          </v-chip>-->
        <!--        </template>-->

        <!--        <template v-slot:[`item.lastSent`]="{ item }">-->
        <!--            <p class="pa-0 ma-0">-->
        <!--              {{ item.lastSent[0] }}-->
        <!--            </p>-->
        <!--            <p class="pa-0 ma-0">-->
        <!--              {{ item.lastSent[1] }}-->
        <!--            </p>-->
        <!--        </template>-->
        <!--        <template v-slot:[`item.action`]>-->
        <!--          <p class="pa-0 ma-0 red&#45;&#45;text">-->
        <!--            You need setup email an address to send out test email-->
        <!--          </p>-->
        <!--          <a href="">Target Email List</a>-->
        <!--        </template>-->


                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                      class="mr-3"

                      @click="openEmail(item)"
                  >
                    mdi-bell-cog
                  </v-icon>
                  <v-icon

                      @click.stop="executeById(item)"
                  >
                    mdi-play
                  </v-icon>
                </template>

      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import {api} from "@/providers/api";
import cronstrue from 'cronstrue';
import {isAfter, parseISO} from "date-fns";

export default {
  name: "EmailManager",
  data: () => ({
    items: [],
    loading: false,
    alertSetting: {
      type: 'success',
      text: '',
      show: false
    },
    headers: [
      {text: 'ID', align: 'start', value: 'id', sortable: false},
      {text: 'Name', value: 'name', sortable: false},
      {text: 'Title', value: 'title', sortable: false},
      {text: 'Frequency', value: 'frequency', sortable: false},
      {text: 'Class', value: 'class', sortable: false},
      {text: 'Time Zone', value: 'timezone', sortable: false},
      {text: 'Status', width: 150, value: 'active', sortable: false},
      {text: 'Last Start Time', value: 'lastStartTime', sortable: false},
      {text: 'Last End Time', value: 'lastEndTime', sortable: false},
      {text: 'Sent result', value: 'sentResult', sortable: false},
      {text: 'Is Executing', value: 'isExecuting', sortable: false},
      {text: 'Action', value: 'actions', sortable: false, width: 100},
    ]
  }),
  computed: {
    translations() {
      return this.$t('translation')
    },
    translationsEmailManager() {
      return this.translations.EMAIL_MANAGER.table_email_manager
    },
  },
  async created() {
    this.headers = [
      {text: 'ID', align: 'start', value: 'id', sortable: false},
      {text: this.translationsEmailManager.headers.name, value: 'name', sortable: false},
      {text: this.translationsEmailManager.headers.title, value: 'title', sortable: false},
      {text: this.translationsEmailManager.headers.frequency, value: 'frequency', sortable: false},
      {text: this.translationsEmailManager.headers.class, value: 'class', sortable: false},
      {text: this.translationsEmailManager.headers.timezone, value: 'timezone', sortable: false},
      {text: this.translationsEmailManager.headers.status, width: 150, value: 'active', sortable: false},
      {text: this.translationsEmailManager.headers.last_start_time, value: 'lastStartTime', sortable: false},
      {text: this.translationsEmailManager.headers.last_end_time, value: 'lastEndTime', sortable: false},
      {text: this.translationsEmailManager.headers.sent_result, value: 'sentResult', sortable: false},
      {text: this.translationsEmailManager.headers.is_executing, value: 'isExecuting', sortable: false},
      {text: this.translationsEmailManager.headers.actions, value: 'actions', sortable: false, width: 100},
    ]
    await this.fetchItems()
  },


  methods: {
    async executeById(item) {
      try {
        await api.post(`/admin/email/${item.id}/execute`)
        this.alertSetting.text = `${item.name} - Execute success`
        this.alertSetting.show = true
      }
      catch (e) {
        console.log(e)
      }
      finally {
        setTimeout(() => {
          this.alertSetting.show = false
        },3000)
        await this.fetchItems()

      }
    },
    isExecuteFormat(start, end) {
      const dateStart = parseISO(start);
      const dateEnd = parseISO(end);

      if (isAfter(dateStart, dateEnd)) {
        return 'Performed'
      } else {
        return 'Completed'
      }
    },

    openEmail(item) {
      this.$router.push({path: `/email-manager/${item.id}`})
    },
    frequency(text) {
      return cronstrue.toString(text)
    },
    async fetchItems() {
      this.loading = true

      try {
        this.items = await api.get('/admin/email')

      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }

    },

    async changeStatus(item) {
      if (item.active) {
        await api.get(`/admin/email/${item.id}/enable`)
      } else {
        await api.get(`/admin/email/${item.id}/disable`)
      }
      await this.fetchItems()
    },
    getColorStatus(item) {
      if (item) {
        return 'green'
      }
      return '#b7b7b7'
    },
    getColorResult(item) {
      if (item === 'success') {
        return 'green'
      }
      if (item === 'processing') {
        return 'blue'
      }
      return 'red'
    },
  }
}
</script>

<style scoped>
.alert {
  position: fixed;
  right: 10px;
  top: 70px;
  z-index: 100;
}
</style>