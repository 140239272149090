<template>
  <div>
    AgencyAdjustments
  </div>
</template>

<script>
  export default {
    name: "AgencyAdjustments"
  }
</script>

<style scoped lang="scss">

</style>