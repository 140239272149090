var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"agency"},[(_vm.preloader)?_c('PreloaderPage'):_c('v-container',{staticClass:"my-5"},[(this.$auth.isAdmin)?_c('v-breadcrumbs',{attrs:{"large":"","items":_vm.breadcrumbs}}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"table-header"},[_vm._v(" "+_vm._s(_vm.translationsAgencyAdmin.table.title)+"("+_vm._s(_vm.items.length)+") "),_c('v-spacer'),_c('v-text-field',{staticClass:"search-input",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","color":"white"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"button-add mb-2",attrs:{"elevation":"0","color":"primary","dark":""},on:{"click":_vm.createAgency}},[_vm._v(" "+_vm._s(_vm.translationsAgencyAdmin.table.btn_add_new_agency)+" ")])],1),_c('v-data-table',{attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.allAgencyLoading,"server-items-length":_vm.totalAgencyItems,"options":_vm.agencyOptions},on:{"click:row":_vm.agencyClick,"update:options":function($event){_vm.agencyOptions=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.createdAt),'Pp')))]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"success","small":""}},[(item.balance === null)?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$getBalance(item.balance.amount))+" ")])])]}},{key:"item.owner.name",fn:function(ref){
var item = ref.item;
return [(item.owner)?_c('div',{staticClass:"user-img"},[_c('img',{attrs:{"src":_vm.$getAvatarImage(item.owner.profile.avatar),"alt":"img"}}),_c('span',{staticClass:"user-title",on:{"click":function($event){$event.stopPropagation();return _vm.openUserNewPage(item.owner.id)}}},[_vm._v(_vm._s(item.owner.email))]),(_vm.getterUserOnline(item.owner.id))?_c('div',{staticClass:"online"}):_vm._e()]):_c('div',[_vm._v(" - ")])]}},{key:"item.agencyPercent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((+item.agencyPercent * 100) + '%')+" ")]}},{key:"item.girlPercent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((+item.girlPercent * 100) + '%')+" ")]}},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.translations.GLOBAL_VALUE.delete_modal.title))]),_c('v-card-actions',[_c('v-checkbox',{attrs:{"color":"success","label":"Delete all users at once"},model:{value:(_vm.deleteUsers),callback:function ($$v) {_vm.deleteUsers=$$v},expression:"deleteUsers"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.translations.GLOBAL_VALUE.delete_modal.cancel_btn))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(_vm._s(_vm.translations.GLOBAL_VALUE.delete_modal.ok_btn))]),_c('v-spacer')],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteDialog(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }