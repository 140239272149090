<template>
  <v-container class="my-5">
    <div class="header">

      <v-text-field
              v-model="editItem.title"
              placeholder="Title page"
      >

      </v-text-field>

      <v-row>
        <v-col
                cols="12"
                lg="5"
                md="5"
        >
          <v-select
                  v-model="editItem.category"
                  :items="category"
                  item-text="title"
                  item-value="id"
                  label="Select category"
                  persistent-hint
                  return-object
                  single-line
          >
          </v-select>
        </v-col>
        <v-col
                cols="12"
                lg="5"
                md="5"
        >
          <v-file-input
                  show-size
                  label="Image input"
                  v-model="editItem.image"
          ></v-file-input>
        </v-col>
        <v-col
                cols="12"
                lg="2"
                md="2"
        >
          <v-btn class="primary mt-3" @click="editPost">{{ translationsBlogLogEditPage.btn_edit_page }}</v-btn>
        </v-col>

      </v-row>

    </div>

    <quill-editor class="editor"
                  ref="myTextEditor"
                  :value="editItem.text"
                  v-model="editItem.text"
                  :options="editorOption"
    />
  </v-container>
</template>

<script>

  import hljs from "highlight.js";
  import {editBlog, getBlogId, getBlogsCategory} from "@/api/blogs";
  export default {
    name: "EditBlog",
    props: {
      id: {
        type: Object,
        default: null,
      },
      targetPost: {
        type: Object,
        default: null
      }
    },
    data: () => ({
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{'header': 1}, {'header': 2}],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'script': 'sub'}, {'script': 'super'}],
            [{'indent': '-1'}, {'indent': '+1'}],
            [{'direction': 'rtl'}],
            [{'size': ['small', false, 'large', 'huge']}],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'font': []}],
            [{'color': []}, {'background': []}],
            [{'align': []}],
            ['clean'],
            ['link', 'image', 'video']
          ],
          syntax: {
            highlight: text => hljs.highlightAuto(text).value
          }
        }
      },
      post: [],
      category: [],
      editItem: {
        title: '',
        text: '',
        image: null,
        category: null,
      }
    }),

    computed: {
      translations() {
        return this.$t('translation')
      },
      translationsBlogLogEditPage() {
        return this.translations.BLOG_LOG.edit_blog_page
      },
    },
    async created() {
      this.fetchCategory()

    },

    async mounted() {

      if (this.targetPost) {
        this.editItem = this.targetPost

      } else {
        this.editItem = await getBlogId(this.id)
        console.log(this.editItem)

      }
    },
    methods: {
      async editPost() {
        const editPost = Object.assign({}, this.editItem)

        let formData = new FormData();
        formData.append("image", editPost.image)
        formData.append("title", editPost.title)
        formData.append("text", editPost.text)

        await editBlog(this.id, this.editItem.category.id, formData)
        console.log(editPost)
      },
      async fetchCategory() {
        this.category = await getBlogsCategory()
        return this.category
      },
    }


  }
</script>

<style scoped>

</style>