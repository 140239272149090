<template>
  <div class="users-list">
    <v-container class="my-5">
      <MyBreadcrumbs s class="mb-3" :breadcrumbs="breadcrumbs"/>

      <v-card>
        <v-card-title class="table-header">
          {{ translationsEmailManagerUsersList.title }}
          <v-spacer></v-spacer>
<!--          <v-text-field-->
<!--              v-model="search"-->
<!--              append-icon="mdi-magnify"-->
<!--              label="Search"-->
<!--              single-line-->
<!--              hide-details-->
<!--              dark-->

<!--          ></v-text-field>-->
          <v-spacer/>
        </v-card-title>
        <v-data-table
            :footer-props="{'items-per-page-options': [10, 50, 100]}"
            :items-per-page="10"
            @click:row="userOpen"
            :headers="headers"
            :items="users"
            :options.sync="usersTableOptions"
            :server-items-length="totalResponseUsers"
            :loading="usersLoading"
        >
          <template v-slot:[`item.profile.isBoy`]="{ item }">
            {{ item.profile.isBoy ? 'man' : 'woman' }}
          </template>
<!--          <template v-slot:[`item.balance.amount`]="{ item }">-->
<!--            {{ $getBalance(item.balance) }}-->
<!--          </template>-->
          <template v-slot:[`item.isAgency`]="{ item }">
            <v-chip
                v-if="item.isAgency"
                :color="getColorAgency(item.isAgency)"
                dark
                class="status"
            >
              {{ 'yes' }}
            </v-chip>
          </template>

          <template v-slot:[`item.isDeleted`]="{ item }">
            <v-chip
                v-if="item.isDeleted"
                color="red"
                dark
                class="status"
            >
              {{ 'yes' }}
            </v-chip>
          </template>

<!--          <template v-slot:[`item.status`]="{ item }">-->
<!--            <v-chip-->
<!--                :color="getColor(item.status)"-->
<!--                dark-->
<!--                class="status"-->
<!--            >-->
<!--              {{ item.status === 0 ? 'REVIEW' : item.status === 1 ? 'ACTIVE' : 'BLOCKED' }}-->
<!--            </v-chip>-->
<!--          </template>-->

          <template v-slot:[`item.profile.countryCode`]="{ item }">
            {{ $getCountyByCode(item.profile.countryCode).name }}
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>
          <template v-slot:[`item.profile.name`]="{ item }">
            <div class="user-img">
              <img :src="$getAvatarImage(item.profile.avatar)" alt="img">
              <span class="user-title"  @click.stop="openUserNewPage(item.id)">{{ item.profile.name }}</span>
              <div class="online" v-if="getterUserOnline(item.id)"></div>
              <v-icon v-if="item.isAgency" class="ml-3" color="primary">mdi-face-agent</v-icon>
              <v-icon
                  v-if="item.id !== me.id && $auth.isAdmin"
                  class="ml-3"
                  color="primary"
                  @click.stop="$switchProfileUser(item.id)"
              >
                mdi-account-arrow-left
              </v-icon>
            </div>
          </template>

        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {getUserFlirt, getUserLike} from "@/api/usersRequest";
import router from "@/routes/router";
import {api} from "@/providers/api";
import {mapGetters} from "vuex";
import MyBreadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "EmailManagerUsersList",
  components: {MyBreadcrumbs},
  data: () => ({
    headers: [
      {text: 'ID', value: 'id', sortable: true},
      {text: 'Name', value: 'profile.name', sortable: true},
      {text: 'Surname', value: 'profile.surname', sortable: true},
      {text: 'Is Agency', value: 'isAgency', sortable: true},
      {text: 'Is Deleted', value: 'isDeleted', sortable: false},
      {text: 'Tariff', value: 'membership.membershipTariff.name', sortable: true},
      {text: 'Gender', value: 'profile.isBoy', sortable: true},
      {text: 'Email', value: 'email', sortable: true},
      {text: 'Agency', value: 'agency.agency.name', sortable: false, width: 120},
      {text: 'Age', value: 'profile.age', sortable: true},
      {text: 'Country', value: 'profile.countryCode', sortable: true, width: 200},
      {text: 'Registered At', value: 'createdAt', sortable: true},
    ],
    users: [],
    search: '',
    usersLoading: false,
    totalResponseUsers: 0,
    usersTableOptions: {},
    item: {}
  }),
  watch: {
    usersTableOptions: {
      handler(changed) {
        console.log('usersTableOptions:change', changed);
        this.syncUsersFromApi();
      },
      deep: true
    },
    search(val) {
      console.log('val', val)
      // this.syncUsersFromApi();
    }
  },
  async created() {
    this.headers = [
      {text: 'ID', value: 'id', sortable: true},
      {text: this.translationsEmailManagerUsersList.headers.name, value: 'profile.name', sortable: true},
      {text: this.translationsEmailManagerUsersList.headers.surname, value: 'profile.surname', sortable: true},
      {text: this.translationsEmailManagerUsersList.headers.is_agency, value: 'isAgency', sortable: true},
      {text: this.translationsEmailManagerUsersList.headers.is_delete, value: 'isDeleted', sortable: false},
      {text: this.translationsEmailManagerUsersList.headers.tariff, value: 'membership.membershipTariff.name', sortable: true},
      {text: this.translationsEmailManagerUsersList.headers.gender, value: 'profile.isBoy', sortable: true},
      {text: this.translationsEmailManagerUsersList.headers.email, value: 'email', sortable: true},
      {text: this.translationsEmailManagerUsersList.headers.agency, value: 'agency.agency.name', sortable: false, width: 120},
      {text: this.translationsEmailManagerUsersList.headers.age, value: 'profile.age', sortable: true},
      {text: this.translationsEmailManagerUsersList.headers.country, value: 'profile.countryCode', sortable: true, width: 200},
      {text: this.translationsEmailManagerUsersList.headers.registered_at, value: 'createdAt', sortable: true},
    ]
    this.item = await api.get(`/admin/email/${this.$route.params.id}`)

  },
  computed: {
    ...mapGetters('User', ['me']),
    translations() {
      return this.$t('translation')
    },
    translationsEmailManagerUsersList() {
      return this.translations.EMAIL_MANAGER.table_users_list
    },
    breadcrumbs() {
      return [
        { text: this.translations.NAVBAR.EMAIL_MANAGER.title, href: '/email-manager' },
        { text: `${this.item.name} #${this.$route.params.id}`, href: `/email-manager/${this.item.id}`},
        { text: this.translations.EMAIL_MANAGER.table_users_list.title, disabled: true,}
      ]
    },
  },
  methods: {
    getColorAgency(status) {
      if (status) return 'green'
    },
    getColor(status) {
      if (status === 0) return '#9E9E9E'
      else if (status === 1) return 'green'
      else return 'orange'
    },
    openUserNewPage(id) {
      const url = this.$router.resolve(`/users/${id}`).href;
      window.open(url, '_blank');
    },
    async syncUsersFromApi() {
      this.usersLoading = true;

        try {
          const {sortBy, sortDesc, page, itemsPerPage: limit} = this.usersTableOptions;

          const response = await api
              .get(`/admin/email/${this.$route.params.id}/list`, {
                params: {
                  ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : [],
                  limit: limit === -1 ? 100 : limit,
                  page
                }
              });


          const {data: items, meta} = response;

          console.log('total', response)
          this.users = this.usersCheck(items)
          this.totalResponseUsers = meta.total;


        } catch (e) {
          console.log('error', e);

        } finally {
          this.usersLoading = false;
        }

    },
    usersCheck(users) {
      Object.filter = (obj, predicate) =>
          Object.keys(obj)
              .filter(key => predicate(obj[key]))
              .reduce((res, key) => (
                  res[key] = obj[key], res), {});


      const newUsers = []

      Object.filter(users, score => {
        if (score.profile !== null) {
          newUsers.push(score)
        }
      })
      return newUsers
    },

    async userOpen(user) {
      const dataLike = await getUserLike(user.id);
      const dataFlirt = await getUserFlirt(user.id);
      const obj = {
        like: {
          dataLike
        },
        flirt: {
          dataFlirt
        }
      };
      router.push({name: 'user-log', params: {id: user.id, targetUser: user, obj: obj}});
    },
  }
}
</script>

<style scoped lang="scss">
.agent-icon {
  position: absolute;
  margin-left: -35px
}

.users-list {
  .card, .card-man, .card-woman {
    max-width: 330px;
    background: #3da03d;
    color: white;

    .v-card__title {
      text-transform: uppercase;
    }

    .v-icon {
      color: #f4f6f9;
    }
  }

  .card-man {
    background: #5080f2;
  }

  .card-woman {
    background: #f37080;
  }

  .table-header {
    background: #2f55b4;
    color: white;

    .search-input {
      color: white;

      ::v-deep label {
        color: white;
      }

      ::v-deep .v-icon.v-icon {
        color: white;
      }

      ::v-deep .v-input__slot::before {
        border-color: white;
      }

      ::v-deep input {
        color: white;
      }
    }


  }
}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.status {
  font-size: 9px;
}

.v-chip {
  padding: 0 6px !important;
  height: 21px;
}

.online {
  background: #4fa526;
  width: 13px;
  height: 13px;
  position: absolute;
  border-radius: 100px;
  margin-left: 20px;
  border: 2px solid #fff;
  margin-top: 20px;
}

.user-img {
  display: flex;
  align-items: center;
}
.user-title{
  cursor: pointer;
  transition: 0.3s;

  &:hover{
    color: #2f55b4;
  }
}
.alert{
  position: absolute;
}
</style>
