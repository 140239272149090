<template>
  <div>
    <PreloaderPage class="preloader" v-if="loading"/>

    <v-container v-else class="my-5">
      <v-row v-if="videos.length > 0" class="photo-content video">
        <h4 class="text--darken-3">Videos</h4>
        <v-col
            cols="12"
            md="12"
        >
          <div class="photo-list">
            <div class="container-photo" v-for="video in videos" :key="video.id">
              <video controls :src="video.converted.s3Url"/>

            </div>
          </div>

        </v-col>
      </v-row>
      <v-row v-if="passportAccept.length > 0" class="photo-content passport">
        <h4 class="text--darken-3">{{ translations.passport }}</h4>
        <v-col
            cols="12"
            md="12"
        >
          <div class="photo-list">
            <div class="container-photo" v-for="photo in passportAccept" :key="photo.id">
              <img :src="$getAvatarImage(photo.file)" alt=""/>
            </div>
          </div>

        </v-col>
      </v-row>

      <v-row v-if="passportPending.length > 0" class="photo-content ">
        <h4 class="text--darken-3">{{ translations.passport_moderation }}</h4>
        <v-col
            cols="12"
            md="12"
        >
          <div class="photo-list">
            <div class="container-photo" v-for="photo in passportPending" :key="photo.id">
              <img :src="$getAvatarImage(photo.file)" alt=""/>

            </div>
          </div>

        </v-col>
        <v-divider class="ma-5"></v-divider>
      </v-row>

      <v-row v-if="albumAccept.length > 0" class="photo-content accept">
        <h4 class="text--darken-3">{{ translations.album }}</h4>
        <v-col
            cols="12"
            md="12"
        >
          <div class="photo-list">
            <div class="container-photo" v-for="photo in albumAccept" :key="photo.id">
              <img :src="$getAvatarImage(photo.url)" alt=""/>
              <v-icon v-if="photo.paid" color="red" class="icon-lock">
                mdi-lock
              </v-icon>

            </div>
          </div>

        </v-col>
      </v-row>

      <v-row v-if="albumPending.length > 0" class="photo-content">
        <h4 class="text--darken-3">Photo and video on moderation</h4>
        <v-col
            cols="12"
            md="12"

        >
          <div class="photo-list">
            <div class="container-photo" v-if="(photo.image && !photo.image.video) || photo.video"  v-for="photo in albumPending" :key="photo.image ? photo.image.id : photo.video.id">
              <img v-if="photo.destination !== 3" :src="$getAvatarImage(photo.image.url)" alt=""/>
              <video controls v-if="photo.destination === 3 && !photo.image && photo.video.isReady" :src="photo.video.converted.s3Url"/>
              <v-icon v-if="photo.image && photo.image.paid && photo.destination !== 3 && !photo.image.video" color="red" class="icon-lock">
                mdi-lock
              </v-icon>
            </div>
          </div>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PreloaderPage from "@/components/PreloaderPage.vue";
import {api} from "@/providers/api";

export default {
  name: "UserLogPhoto",
  components: {PreloaderPage},
  data: () => ({
    currentTab: 8,
    loading: false,
    passportAccept: [],
    passportPending: [],
    albumPending: [],
    albumAccept: [],
    videos: [],
    accessToken: '',
  }),
  computed: {
    translations() {
      return this.$t('translation.USERS.USER_LOG.tabs_items.photos_info')
    }
  },
  async created() {
    await this.initToken()

    await this.fetchData()

  },
  methods: {
    async fetchPassportPhoto() {
      const request = await api.get(`/admin/users/passport/${this.$route.params.id}`)
      this.passportPending = request.filter(r => r.documentStatus === 'PENDING')
      this.passportAccept = request.filter(r => r.documentStatus === 'ACCEPTED')
    },
    async initToken() {
      this.accessToken = await api.get(`/admin/users/${this.$route.params.id}/login`).then(r => r.access_token)
    },

    async fetchPendingPhoto() {
      this.albumPending = await api.get('/account/pending-photos', {
        headers: {
          Authorization: `Bearer ${this.accessToken}`
        }
      })
    },
    async fetchAcceptedPhoto() {
      this.albumAccept = await api.get('/account/album', {
        headers: {
          Authorization: `Bearer ${this.accessToken}`
        }
      })
      console.log('this.albumAccept', this.albumAccept)
    },

    async fetchVideos() {
      this.videos = await api.get(`admin/account/${this.$route.params.id}/videos`)
    },
    async fetchData() {
      this.loading = true

      try {
        await this.fetchPassportPhoto()
        await this.fetchAcceptedPhoto()
        await this.fetchPendingPhoto()
        await this.fetchVideos()

      } finally {
        this.loading = false

      }
    },
  }


}
</script>

<style scoped lang="scss">
.icon-lock {
  position: absolute;
  right: 0;
  bottom: 3px;
}

.container {
  height: unset !important;
}

.img-pending {
  width: 175px;
  height: 200px;
  object-fit: cover;

}

.photo-content {
  margin: 10px;
  //border-left: 3px solid #FF9800;
  padding: 10px;

  h4 {
    color: #FF9800;
    border: 2px solid #FF9800;
    border-radius: 20px;
    padding: 5px 16px;

  }

  &.video{
    h4 {
      color: #ef329c;
      border: 2px solid #ef329c;
      border-radius: 20px;
      padding: 5px 16px;
    }
  }

  &.passport {
    h4 {
      color: #a93ad5;
      border: 2px solid #a93ad5;
      border-radius: 20px;
      padding: 5px 16px;
    }
  }

  &.add {
    //border-left: 3px solid #4caf50;

    h4 {
      color: #4caf50;
      border: 2px solid #4caf50;
      border-radius: 20px;
      padding: 5px 16px;
    }
  }

  &.accept {
    //border-left: 3px solid #03A9F4;

    h4 {
      color: #03A9F4;
      border: 2px solid #03A9F4;
      border-radius: 20px;
      padding: 5px 16px;
    }
  }
}

.photo-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .container-photo {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin: 10px 10px 10px 0px;
  }

  video{
    width: 250px;
    height: 200px;
  }
  img {
    width: 175px;
    height: 200px;
    object-fit: cover;
  }
}
</style>