<template>
  <v-data-table
      :footer-props="{'items-per-page-options': [10, 50, 100]}"
      :items-per-page="10"
      :headers="headers"
      :items="items"
      :server-items-length="totalItems"
      :options.sync="options"
      :loading="loading"
      class="elevation-20"
      key="id"
  >

    <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>
    <template v-slot:[`item.adminViewed`]="{ item }">
      <CirclePulsating :pulsing="!item.adminViewed" />
    </template>
    <template v-slot:[`item.from`]="{ item }">{{ item.flirt.creator.name }}</template>
    <template v-slot:[`item.to`]="{ item }">{{ item.destinationUser.name }}</template>

    <template v-slot:[`item.viewed`]="{ item }">
      <v-icon :color="item.flirt.isNew ? 'red' : 'green'">{{ item.flirt.isNew ? 'mdi-eye-remove' : 'mdi-eye' }}</v-icon>
    </template>


  </v-data-table>
</template>

<script>

import {api} from "@/providers/api";
import {mapGetters} from "vuex";
import CirclePulsating from "@/components/CirclePulsating.vue";
import axios from "axios";

export default {
  name: "AgencyTableFlirt",
  components: {CirclePulsating},

  data: () => ({
    headers: [],
    options: {},
    totalItems: 0,
    loading: true,
    items: []
  }),
  watch: {

    options: {
      handler() {
        this.syncDataFromApi()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('User', ['me']),

  },
  methods: {
    async syncDataFromApi() {
      this.loading = true;
      try {
        if (this.cancelTokenSource) {
          this.cancelTokenSource.cancel('New request is being made');
        }

        this.cancelTokenSource = axios.CancelToken.source();

        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.options;

        const response = await api.get(`/agency-admin/notification/agency`, {
          params: {
            ...sortBy.length ? {
              order: sortBy[0] === "viewed" ? sortBy.map((s, i) => (sortDesc[i] ? 'ASC' : 'DESC'))[0] : sortBy.map((s, i) => (sortDesc[i] ? 'DESC' : 'ASC'))[0],
              sortField: sortBy[0]
            } : '',
            limit: limit === -1 ? 100 : limit,
            page,
            category: 'new_flirt_message',
            agency: this.me.agency.agencyId
          },
          cancelToken: this.cancelTokenSource.token,
        });


        if (!response) {
          return;
        }

        const {items, meta} = response;

        this.items = items;

        const itemsForView = items.filter(e => !e.adminViewed);

        if (itemsForView.length !== 0) {
          await api.post('/notifications/admin/view', {
            ids: itemsForView.map(e => e.id)
          });
        }

        this.totalItems = meta.totalItems;
        this.loading = false;
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Previous request canceled', error.message);
        } else {
          console.error('Error', error);
        }
      }
    },
  },
  created() {
    this.headers =  [
      {
        text: 'ID',
        value: 'id',
        sortable: false
      },
      {
        text: 'New',
        value: 'adminViewed',
        sortable: true,
        width: 100
      },
      {
        text: 'From',
        value: 'from',
        sortable: true
      },
      {
        text: 'To',
        value: 'to',
        sortable: true
      },
      {
        text: 'Created At',
        value: 'createdAt',
        sortable: true
      },
      {
        text: 'Read/Unread',
        value: 'viewed',
        sortable: true,
      },
    ]
  }
}
</script>

<style scoped lang="scss">
.user-img {
  display: flex;
  align-items: center;
}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.title {
  color: #f4f6f9;
}

.photo-img {
  margin-top: 3px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    object-fit: cover;
  }

}
</style>