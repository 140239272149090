import { socket } from "@/providers/socket";
import { app } from "@/main";
import store from "@/store";
import { getUserRealName } from "@/mixins/locations.mixin";

// socket.on("ChatMessage", (chatMessage) => {
//   console.log("ChatMessage", chatMessage);
//   store.dispatch("Chats/addIfNotExistsChat", {
//     chatId: chatMessage.chat.id,
//     chatMessage,
//   });
//   store.dispatch("Chats/addMessageComplex", { chatMessage });
//   if (chatMessage.sender.id !== store.state.User.me.id)
//     app.$bvToast.toast(`${chatMessage.text}`, {
//       title: `From: ${getUserRealName(chatMessage.sender)}`,
//       toaster: "b-toaster-bottom-right",
//       solid: true,
//       appendToast: true,
//       to: "/chat?chat_id=" + chatMessage.chat.id,
//     });
// });


function processEventData(data) {
  let newData = data;

  switch (data.type) {
    case "new_chat_message":
      newData.message = `${data.receiver.profile.name} received a message in chat from ${data.message.sender.profile.name}`
      break;
    case "introduce_message":
      newData.message = `${data.introduce.to.profile.name} received a letter from ${data.introduce.from.profile.name} `
      break;
    case "new_flirt_message":
      newData.message = `${data.flirt.creator.profile.name} sent a flirt to  ${data.flirt.receiver.profile.name} `
      break;
    case "chat_date_message":
      newData.message = `${data.chatDate.to.profile.name} received Date Invite from ${data.chatDate.from.profile.name} `
      break;
    case "new_like_message":
      newData.message = `${data.like.from.profile.name} liked ${data.like.to.profile.name} photo`
      break;
  }

  return newData;
}

socket.on('AgencyAdminNotification', (data) => {
  console.log('dataFromSocket', data)
  const processedData = processEventData(data);

  store.dispatch('addToSnackbarQueue', processedData);

})