<template>
  <div class="dashboard">
    <PreloaderPage v-if="preloader"/>
    <v-container v-else class="my-5">

      <v-row>
        <v-col cols="12" md="12" lg="12">
          <v-data-table
              :headers="headers"
              :items="allNotification"
              :footer-props="{'items-per-page-options': [10, 50, 100]}"
              :items-per-page="10"
              :server-items-length="totalNotificationItems"
              :options.sync="notificationOptions"
              :loading="notificationLoading"
          >
            <template v-slot:top>

              <v-toolbar flat color="primary" dark>
                <v-toolbar-title class="title">All notification</v-toolbar-title>
                <v-spacer></v-spacer>


                <v-btn :disabled="notificationLoading" icon @click="refreshNotification">
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              <div class="notification-info-text">
                <span v-html="getInfo(item)"></span>
              </div>

            </template>

          </v-data-table>
        </v-col>



        <v-col class="col-content" cols="12" md="6" lg="4">
          <v-card class="card">
            <v-card-title>{{ translations.count_all }}</v-card-title>
            <v-card-actions>
              <v-icon large>mdi-account-box-multiple</v-icon>
              <v-card-title>{{ users }}/ <span class="green--text">{{ onlineUsers.total ? onlineUsers.total : 0  }}</span></v-card-title>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="col-content" cols="12" md="6" lg="4">
          <v-card class="card">
            <v-card-title>{{ translations.count_men }}</v-card-title>
            <v-card-actions>
              <v-icon large>mdi-face-man-shimmer-outline</v-icon>
              <v-card-title>{{ manCounter }}/ <span class="green--text">{{ onlineUsers.boys ? onlineUsers.boys : 0  }}</span></v-card-title>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="col-content" cols="12" md="6" lg="4">
          <v-card class="card">
            <v-card-title>{{ translations.count_women }}</v-card-title>
            <v-card-actions>
              <v-icon large>mdi-face-woman-shimmer-outline</v-icon>
              <v-card-title>{{ womanCounter }}/ <span class="green--text">{{ onlineUsers.girls ? onlineUsers.girls : 0  }}</span></v-card-title>
            </v-card-actions>
          </v-card>
        </v-col>



        <v-col class="col-content" cols="12" md="6" lg="4">
          <v-card height="max-content" class="card">
            <v-card-title>{{ translations.count_agent }}</v-card-title>
            <v-card-actions>
              <v-icon large>mdi-face-agent</v-icon>
              <v-card-title>{{ agentCounter }}</v-card-title>
            </v-card-actions>
          </v-card>
        </v-col>


        <v-col class="col-content" cols="12" md="6" lg="4">
          <v-card height="max-content" class="card">
            <v-card-title>{{ translations.system_balance }}</v-card-title>
            <v-card-actions>
              <v-icon large>mdi-cash-check</v-icon>
              <v-card-title>{{ systemBalance }}</v-card-title>
            </v-card-actions>
          </v-card>
        </v-col>


        <v-col cols="12" md="6" lg="4">
          <v-card
              max-width="500"
              :loading="loading"

          >
            <template slot="progress">
              <v-progress-linear color="cyan" indeterminate></v-progress-linear>
            </template>

            <v-toolbar
                color="primary"
                dark
            >

              <v-toolbar-title>Top 5 countries by number of registrations</v-toolbar-title>

              <v-spacer></v-spacer>


              <v-btn icon @click="fetchUsersByCountry">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </v-toolbar>

            <v-list two-line disabled >
              <v-list-item-group
              >
                <template v-for="(item, index) in items">
                  <v-list-item :key="item.title">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>

                      <v-list-item-subtitle
                          class="text--primary"
                          v-text="item.count"
                      ></v-list-item-subtitle>

                    </v-list-item-content>

                  </v-list-item>

                  <v-divider
                      v-if="index < items.length - 1"
                      :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>


        <!--        <v-col class="col-content" cols="12" md="6" lg="4">-->
        <!--          <v-card class="card">-->
        <!--            <v-card-title>total earned by the system</v-card-title>-->
        <!--            <v-card-actions>-->
        <!--              <v-icon large>mdi-cash-multiple</v-icon>-->
        <!--              <v-card-title>1112</v-card-title>-->
        <!--            </v-card-actions>-->
        <!--          </v-card>-->
        <!--        </v-col>-->
        <!--        <v-col class="col-content" cols="12" md="6" lg="4">-->
        <!--          <v-card class="card">-->
        <!--            <v-card-title>Name card</v-card-title>-->
        <!--            <v-card-actions>-->
        <!--              <v-icon large>mdi-star-face</v-icon>-->
        <!--              <v-card-title>123</v-card-title>-->
        <!--            </v-card-actions>-->
        <!--          </v-card>-->
        <!--        </v-col>-->
        <!--        <v-col class="col-content" cols="12" md="6" lg="4">-->
        <!--          <v-card class="card">-->
        <!--            <v-card-title>Name card</v-card-title>-->
        <!--            <v-card-actions>-->
        <!--              <v-icon large>mdi-star-face</v-icon>-->
        <!--              <v-card-title>321</v-card-title>-->
        <!--            </v-card-actions>-->
        <!--          </v-card>-->
        <!--        </v-col>-->




        <v-col cols="12" md="12" lg="6">

          <v-card class="chart">

            <PieChart :data-pie="pie"/>
            <v-card-title class="chart-content">
              <div>
                <h6>{{ translations.label_pie_chart_like }}</h6>
                <p>132</p>
              </div>
              <div>
                <h6>{{ translations.label_pie_chart_chat }}</h6>
                <p>42</p>
              </div>
              <div>
                <h6>{{ translations.label_pie_chart_video_chat }}</h6>
                <p>503</p>
              </div>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" md="12" lg="6">
          <v-card class="chart">

            <LineChart :data-line="line"/>
            <v-card-title class="chart-content">
              <div class="chart-content-text-line">
                <h6>{{ translations.content_line_chart_today }}</h6>
                <p>132</p>
              </div>
            </v-card-title>
          </v-card>
        </v-col>


      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import PieChart from "@/components/PieChart";
import LineChart from "@/components/LineChart";
import {getMembers} from "@/api/membershipRequest";
import {getSystemBalance, getUsers} from "@/api/usersRequest";
import PreloaderPage from "@/components/PreloaderPage.vue";
import {api} from "@/providers/api";
import {getLikes} from "@/api/likeRequest";
import {format, subYears} from "date-fns";
import axios from "axios";


export default {
  name: 'MyDashboard',
  components: {PreloaderPage, LineChart, PieChart},
  options: {
    plugins: {
      legend: {
        display: false
      }
    }
  },
  data: () => ({
    notificationOptions: {},
    totalNotificationItems: 0,
    notificationLoading: true,
    headers: [
      {text: 'ID', value: 'id', sortable: false, width: '100'},
      {text: 'Title', value: 'title', sortable: false, width: '200'},
      {text: 'Info', value: 'createdAt', sortable: true},
    ],
    allNotification: [],
    items: [],
    loading: false,
    preloader: true,
    pie: {
      labels: [],
      data: [124, 534, 765],
    },
    line: {
      label: [],
      labels: ['01/07/22', '02/07/22', '03/07/22', '04/07/22', '05/07/22', '06/07/22', '07/07/22', '08/07/22'],
      data: [124, 534, 765, 412, 152, 435, 353, 153],
    },
    manCounter: 0,
    womanCounter: 0,
    agentCounter: 0,
    users: 0,
    onlineUsers: {},

    editedItem: {
      name: '',
      balance: '',
      membership: {
        membershipTariff: {
          name: ''
        }
      },
      profile: {
        isBoy: ''
      }
    },
    defaultItem: {
      name: '',
      isBoy: '',
    },
    memberships: [],
    systemBalance: 0,
    cancelTokenSource: null,
    intervalId: null,
    intervalUsersOnline: null
  }),

  async created() {

    this.line.label = [this.translations.label_line_chart]
    this.pie.labels = [
      this.translations.label_pie_chart_like,
      this.translations.label_pie_chart_chat,
      this.translations.label_pie_chart_video_chat]
    if (!this.$auth.isAdmin) {
      this.$router.push({name: 'agencies'})
    }
    const response = await this.fetchUsers()
    this.users = response.total

    // const a = await api.get('/notifications/agency/all?page=1&limit=2&sortField=createdAt&order=DESC').then(r => r.items)
    // const b = await api.get('/notifications/agency/all?page=1&limit=2&sortField=createdAt&order=ASC').then(r => r.items)
    //
    // console.log({a, b})

    this.manCounter = await this.fetchUsers('profile.isBoy').then(r => r.total)
    this.womanCounter = await this.fetchUsers('profile.isBoy', false).then(r => r.total)
    this.agentCounter = await this.fetchUsers('isAgency').then(r => r.total)

    // const usersList = await getUsers().then(r => r.data);
    // this.systemBalance = Math.floor((await getSystemBalance()).balance.amount * 100) / 100

    // this.genderCheck(usersList)
    this.fetchMembership()
    this.fetchSystemBalance()

    await this.fetchUsersByCountry()

    this.preloader = false
  },
  mounted() {
    this.notificationOptions.sortBy = ['createdAt'];
    this.notificationOptions.sortDesc = [true];
    this.intervalId = setInterval(this.syncNotificationFromApi, 5000);
    this.intervalUsersOnline = setInterval(this.getUsersOnline, 60000);

  },
  beforeDestroy() {
    clearInterval(this.intervalId);
    clearInterval(this.intervalUsersOnline);
  },
  watch: {
    notificationOptions: {
      handler(change) {
        console.log('notifications payments', change)

        this.syncNotificationFromApi()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('User', ['me']),

    translations() {
      return this.$t('translation.DASHBOARD')
    }

  },
  methods: {
    async getUsersOnline() {
      this.onlineUsers = await api.get('/admin/users/online-count')
    },
    getTextWithoutTags(htmlString) {
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = htmlString
      return tempDiv.innerText
    },

    async refreshNotification() {
      clearInterval(this.intervalId);

      await this.syncNotificationFromApi()
      this.intervalId = setInterval(this.syncNotificationFromApi, 5000);

    },
    getInfo(info) {
      const formattedDate = format(new Date(info.createdAt), "dd.MM.yyyy HH:mm");
      switch (info.source) {
        case 'new_chat_message':
          return `${formattedDate}: ${this.getUserName(info.message.sender)} sent a chat message to ${this.getUserName(info.destinationUser)}
          -> ${info.message.photos.length > 0 ? this.getMessageImage(info.message.photos[0].file.s3Url) : info.message.emoji ? this.getMessageImage(info.message.emoji.file.s3Url) : info.message.text}`
        case 'new_agency_request':
          return `${formattedDate}: ${info.description}`
        case 'new_flirt_message':
          return `${formattedDate}: ${this.getUserName(info.flirt.creator)} sent a flirt to ${this.getUserName(info.destinationUser)}`
        case 'agency_request_status_change':
          const parts = info.description.split('"');
          if (parts.length >= 2) {
            const agencyName = parts[1];
            return `${formattedDate}: agency "${agencyName}" received the status "${info.agencyRequest.status}"`
          }
          else {
            return `${formattedDate}: ${info.description}`
          }
        case 'chat_date_message':
          return `${formattedDate}: ${this.getUserName(info.chatDate.from)} sent a Date Invite to ${this.getUserName(info.destinationUser)}`
        case 'new_like_message':
          return `${formattedDate}: ${this.getUserName(info.like.from)} sent a like to ${this.getUserName(info.destinationUser)}`
        case 'introduce_message':
          return `${formattedDate}: ${this.getUserName(info.introduction.from)} sent a introduction to ${this.getUserName(info.destinationUser)} -> ${this.getTextWithoutTags(info.introduction.message.text)}`
        case 'agency_created_message':
          const sentences = info.description.split('. ');
          const message = sentences[sentences.length - 1];
          return `${formattedDate}: ${message}`
        case 'chat_date_message_status':
          const words = info.description.split(" ");
          const status = words[words.length - 1]
          return `${formattedDate}: ${this.getUserName(info.chatDate.to)} ${status} a Date Invite from ${this.getUserName(info.chatDate.from)}`
        case 'chat_date_message_reschedule':
          return `${formattedDate}: ${this.getUserName(info.chatDate.from)} reschedule a Date Invite from ${this.getUserName(info.destinationUser)}`
        case 'new_favorite':
          return `${formattedDate}: ${this.getUserName(info.favorite.user)} added ${this.getUserName(info.destinationUser)} to favorites`
      }

      // return formattedDate

    },
    getUserName(user) {
      return `<a class="user" target="_blank" href="/users/${user.id}" >${user.name}<img class="user-avatar" src="${this.$getAvatarImage(user.profile.avatar)}" alt=""></a>`;
    },
    getMessageImage(photo) {
      return `<img style="object-fit: cover;" width="50" height="50" src="${photo}"/>`;
    },
    async syncNotificationFromApi() {
      this.notificationLoading = true
      try {
        if (this.cancelTokenSource) {
          this.cancelTokenSource.cancel('New request is being made');
        }
        this.cancelTokenSource = axios.CancelToken.source();
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.notificationOptions

        const response = await api.get('/admin/notifications/agency/all', {
          params: {
            ...sortBy.length ? {sortField: 'createdAt'} : [],
            ...sortBy.length ? {order: (sortDesc[0] ? 'DESC' : 'ASC')} : [],
            limit: limit === -1 ? 100 : limit,
            page
          },
          cancelToken: this.cancelTokenSource.token,


        })
        if (!response) {
          return;
        }
        const {items, meta} = response

        this.allNotification = items;
        this.totalNotificationItems = meta.totalItems
        this.notificationLoading = false
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Previous request canceled', error.message);
        } else {
          console.error('Error', error);
        }
      }
    },
    async fetchUsersByCountry() {
      this.loading = true;
      const req = await api.get('/admin/users/byCountry');
      const countriesData = req.slice(0, 5);

      const countriesWithCount = this.$getCountries().map(reqCountry => {
        const foundCountry = countriesData.find(country => country.country === reqCountry.code);
        return foundCountry ? { ...reqCountry, count: foundCountry.count } : null;
      }).filter(country => country !== null);

      countriesWithCount.sort((a, b) => b.count - a.count);

      this.items = countriesWithCount;
      this.loading = false;
    },

    calculateDate(yearsToSubtract) {
      if (yearsToSubtract) {
        const currentDate = new Date();
        const newDate = subYears(currentDate, yearsToSubtract);
        return  newDate
      }
      return null;

    },

    async fetchUsers(searchItem = undefined, state = true) {
      const params = {
        "$and": [
          {"balance.amount": {"between": [0, 10000]}},
          {"isDeleted": false},
          {"$or": [{"profile.birthday": {"$isnull": true}}, {"profile.birthday": {"between": [this.calculateDate(99), this.calculateDate(18)]}}]},
        ]
      }

      if (searchItem !== undefined) {
        params["$and"].push({[searchItem]: state});
      }

      return await api
          .get('/admin/users', {
            params: {
              s: params
            }
          });
    },
    async fetchSystemBalance() {
      const systemBalance = await getSystemBalance()
      if (systemBalance) {
        this.systemBalance = Math.floor((systemBalance).balance.amount * 100) / 100
      }
    },
    async fetchMembership() {
      this.memberships = await getMembers();
      return this.memberships
    },
    genderCheck(users) {
      Object.filter = (obj, predicate) =>
          Object.keys(obj)
              .filter(key => predicate(obj[key]))
              .reduce((res, key) => (
                  res[key] = obj[key], res), {});


      this.users = Object.keys(Object.filter(users, score => score.profile !== null));
      this.manCounter = Object.keys(Object.filter(users, score => score.profile?.isBoy === true));
      this.agentCounter = Object.keys(Object.filter(users, score => score.isAgency === true));
      this.womanCounter = Object.keys(Object.filter(users, score => score.profile?.isBoy === false));
    }
  }
};
</script>

<style scoped lang="scss">

::v-deep{
  .user{
    color: #2f55b4;
    text-decoration: none;
    display:inline-block;
    &:after{
      display:block;
      content: '';
      border-bottom: solid 2px #2f55b4;
      transform: scaleX(0);
      transition: transform 150ms ease-in-out;
    }
    &:hover:after {
      transform: scaleX(1);
    }

    .user-avatar{
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
      margin: 0px 5px;
    }
  }
}

.notification-info-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 1200px !important;

  @media (max-width: 1440px) {
    max-width: 680px !important;
  }

}
.chart {
  background: white;
  border: 2px solid #2f55b4 !important;
  padding: 10px;

  .chart-content {
    background: white;
    color: white;
    border-radius: 5px;
    flex-direction: row;
    flex-wrap: nowrap;
    //justify-content: space-around;
    padding: unset;

    div {
      width: 100%;
      text-align: center;
      margin-right: 10px;

      h6 {
        color: #c4cae3;
        text-transform: uppercase;
      }

      p {
        margin: 0 0 5px 0;
      }


      &:nth-child(1) {
        border-top: 7px solid #a262ef;
        background: #2f55b4;
      }

      &:nth-child(2) {
        border-top: 7px solid #e24295;
        background: #2f55b4;
      }

      &:nth-child(3) {
        border-top: 7px solid #ff6358;
        background: #2f55b4;
        margin-right: 0;
      }

    }


    .chart-content-text-line {

      width: 100%;
      text-align: center;
      margin-right: 0;

      h6 {
        color: #c4cae3;
        text-transform: uppercase;
      }

      p {
        margin: 0 0 5px 0;
      }

      &:nth-child(1) {
        border-top: 7px solid #6aad2e;
        background: #2f55b4;
      }


    }
  }
}

.col-content {
  display: flex;
  justify-content: center;
}

.dashboard {
  .card, .card-another, .card-man, .card-woman {
    width: 350px;
    background: white;
    color: #2f55b4;
    margin: 15px;
    border: 2px solid #2f55b4;

    .v-card__title {
      text-transform: uppercase;
    }

    .v-icon {
      color: #2f55b4;
    }
  }

  .card-another {

    background: white;
    color: #2f55b4;

    .v-card__title {
      text-transform: uppercase;
    }

    .v-icon {
      color: #2f55b4;
    }
  }

  /*.card-man {*/
  /*  background: #5080f2;*/
  /*}*/

  /*.card-woman {*/
  /*  background: #f37080;*/
  /*}*/

  .table-header {
    background: #2f55b4;
    color: white;

    .search-input {
      color: white;

      ::v-deep label {
        color: white;
      }

      ::v-deep .v-icon.v-icon {
        color: white;
      }

      ::v-deep .v-input__slot::before {
        border-color: white;
      }

      ::v-deep input {
        color: white;
      }
    }


  }
}

</style>
