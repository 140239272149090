<template>
  <v-data-table
      :footer-props="{'items-per-page-options': [10, 50, 100]}"
      :items-per-page="10"
      :headers="headersLike"
      :items="items"
      :server-items-length="totalItems"
      :options.sync="options"
      :loading="loading"
      class="elevation-1"
      key="id"

  >
    <template v-slot:[`item.from`]="{ item }">
      <div class="user-img" @click="userPush(item.from)">
        <img :src="$getAvatarImage(item.from.profile.avatar)">
        {{ item.from.name }}

        <v-icon
            v-if="item.from.id !== me.id && !$auth.isAdmin"
            class="ml-3"
            color="primary"
            @click.stop="$switchProfileUser(item.from.id)"
        >
          mdi-account-arrow-left
        </v-icon>
      </div>
    </template>

    <template v-slot:[`item.to`]="{ item }">
      <div class="user-img" @click="userPush(item.to)">
        <img :src="$getAvatarImage(item.to.profile.avatar)">
        {{ item.to.name }}

        <v-icon
            v-if="item.to.id !== me.id && !$auth.isAdmin"
            class="ml-3"
            color="primary"
            @click.stop="$switchProfileUser(item.to.id)"
        >
          mdi-account-arrow-left
        </v-icon>
      </div>

    </template>

    <template v-slot:[`item.file`]="{ item }">

      <v-img :src="item.file.url" max-height="100" max-width="100"></v-img>

    </template>
    <template v-slot:[`item.file.createdAt`]="{ item }">{{ new Date(item.file.createdAt) | date('Pp') }}
    </template>
  </v-data-table>
</template>

<script>
import {mapGetters} from "vuex";
import {api} from "@/providers/api";
import router from "@/routes/router";

export default {
  name: "UserLogLikeTable",
  props: {
    refresh: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    headersLike: [],
    options: {},
    totalItems: 0,
    loading: true,
    items: []
  }),
  computed: {
    ...mapGetters('User', ['me']),
    translations() {
      return this.$t('translation.USERS.USER_LOG.tabs_items.like_table.headers')
    }
  },
  watch: {
    refresh(status) {
      this.loading = status
      if (status) {
        this.options.page = 1
        this.syncDataFromApi()
      }
    },
    options: {
      handler() {
        this.syncDataFromApi()
      },
      deep: true
    }
  },
  methods: {
    userPush(user) {
      if (Number(this.$route.params.id) !== user.id) {
        if (this.$auth.isAdmin) {
          router.push({name: 'user-log', params: {id: user.id}})
          this.$emit('reloadPage')
        }
        else {
          if (user.agency && Number(user.agency.agencyId) === Number(this.$route.params.id)) {
            router.push({name: 'user-log', params: {id: user.id}})
            this.$emit('reloadPage')
          }
        }
      }

    },


    async syncDataFromApi() {
      this.loading = true
      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.options
        const response = await api.get(`/admin/likes?filter=to.id||$eq||${this.$route.params.id}`, {
          params: {
            ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : [],
            limit: limit === -1 ? 100 : limit,
            page,

          },

        })

        const {data: items, total} = response
        this.items = items;
        this.totalItems = total
      } catch (e) {
        console.log('Error', e)
      } finally {
        this.loading = false
        this.$emit('refreshStop', 'like')
      }
    },

  },
  created() {
    this.headersLike = [
      {
        text: this.translations.from,
        value: 'from',
      },
      {
        text: this.translations.to,
        value: 'to',
      },
      {
        text: this.translations.photo_like,
        value: 'file',
      },
      {
        text: this.translations.created,
        value: 'file.createdAt',
      },

    ]
  }
}
</script>

<style scoped lang="scss">
.user-img {
  display: flex;
  align-items: center;
}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.title {
  color: #f4f6f9;
}

.photo-img {
  margin-top: 3px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    object-fit: cover;
  }

}

</style>