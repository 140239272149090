<template>
  <div>
    <v-container class="my-5">
      <v-tabs>

        <v-tab>PENDING</v-tab>
        <v-tab-item class="background">
          <UsersContractTable :props-items-agency="itemsPending" />
        </v-tab-item>


        <v-tab>APPROVED</v-tab>
        <v-tab-item class="background">
          <UsersContractTable :props-items-agency="itemsApproved" @call-fetch-agency="updateDataAgency"/>
        </v-tab-item>

        <v-tab>REJECTED</v-tab>
        <v-tab-item class="background">
          <UsersContractTable :props-items-agency="itemsRejected" @call-fetch-agency="updateDataAgency"/>
        </v-tab-item>

        <v-tab>DENIED</v-tab>
        <v-tab-item  class="background">
          <UsersContractTable :props-items-agency="itemsRework" @call-fetch-agency="updateDataAgency"/>
        </v-tab-item>

      </v-tabs>
    </v-container>
  </div>
</template>

<script>



  import {mapGetters} from "vuex";
  import UsersContractTable from "@/views/Agency/UsersContractTable";
  export default {
    name: "UsersContract",
    components: {UsersContractTable},
    data: () => ({

      itemsPending: [],
      itemsProcessing: [],
      itemsApproved: [],
      itemsRejected: [],
      itemsRework: [],
    }),
    created() {
      this.fetchAgencyItems()
    },
    computed:{
      ...mapGetters('User', ['me']),

    },
    methods: {

      updateDataAgency() {
        this.fetchAgencyItems()
      },
      async fetchAgencyItems() {
        this.itemsPending = [
          {id: 1, name: 'test', websiteUrl: 'http://test.com', creator: 'Oleg', status: 'PENDING'}
        ]
      }
    }
  }
</script>

<style scoped lang="scss">
  .background {
    background: #f4f6f9;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 20px;
  }
  .user-img {
    display: flex;
    align-items: center;
  }
  .select {
    max-width: 300px;
    margin-right: 100px;
  }

  .text-status {
    color: orange;
  }

  .img {
    width: 300px !important;
    cursor: pointer;
  }

  .icon-btn {
    border: 1px solid white;
    position: absolute;
    margin-top: -25px;
    margin-left: 280px;
    cursor: pointer;
  }
</style>