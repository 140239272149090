<template>
  <div>
    <v-alert class="alert" v-model="alertNotification" type="success" max-width="max-content">
      {{ textAlert }}
    </v-alert>
    <MyBreadcrumbs style="height: fit-content" v-if="this.$auth.isAdmin" :breadcrumbs="breadcrumbs"/>
    <v-container class="my-5">
      <v-data-table
          :loading="isLoading"
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :items-per-page="10"
          :headers="headers"
          :items="itemsMap"
          :options.sync="campaignsTableOptions"
          :server-items-length="totalResponseCampaigns"
          item-key="id"
          class="elevation-1"

      >
        <template v-slot:top>

          <v-toolbar dark flat color="primary">
            <v-toolbar-title>Campaigns</v-toolbar-title>
            <v-spacer/>
            <v-dialog
                persistent
                v-model="dialogCampaign"
                max-width="800px"
                scrollable
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    outlined
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="openCreateModal"
                >
                  Create new campaign
                </v-btn>
              </template>

              <v-form @submit.prevent ref="form">
              <v-card style="height: 700px;">
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-text-field
                            :rules="rulesRegistered"
                            dense
                            hide-details
                            outlined
                            v-model="editedItem.name"
                            label="Name"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-text-field
                            :rules="rulesRegistered"
                            dense
                            hide-details
                            outlined
                            v-model="editedItem.title"
                            label="Title"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-textarea
                            :rules="rulesRegistered"
                            dense
                            hide-details
                            outlined
                            v-model="editedItem.text"
                            label="Description"
                        ></v-textarea>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12">
                        <v-select
                            outlined
                            hide-details
                            v-model="targetPage"
                            :items="activeRoutes"
                            label="Target Page"
                        ></v-select>
                        <v-text-field
                            style="margin-top: 6px"
                            v-if="targetPage === 'custom'"
                            dense
                            hide-details
                            outlined
                            v-model="editedItem.targetPage"
                            label="Custom Target Page"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12">
                        <v-text-field
                            style="margin-top: 6px"
                            dense
                            hide-details
                            outlined
                            v-model="editedItem.imageUrl"
                            label="URL image"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <CampaignsFilter @users="value => users = value"
                                     :users-static="users"
                                     :data="filter"/>
                    <v-row>
                      <v-col cols="6">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                clearable
                                v-model="date"
                                label="Picker without buttons"
                                prepend-icon="mdi-calendar"
                                outlined
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="date"
                              @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6">
                        <v-menu
                            ref="menu"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="time"
                                clearable
                                label="Picker in menu"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                              v-if="menu2"
                              v-model="time"
                              full-width
                              @click:minute="$refs.menu.save(time)"
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6" v-if="isCreate">
                        <v-checkbox
                            v-model="executeNow"
                            label="Execute now?"
                        ></v-checkbox>
                      </v-col>
<!--                      <v-col cols="6" v-if="isCreate">-->
<!--                        <v-switch-->
<!--                            v-model="statusActive"-->
<!--                            :label="`Status: ${statusActive ? 'on' : 'off'}`"-->
<!--                        ></v-switch>-->
<!--                      </v-col>-->
                      <v-col cols="12" >
                      <v-divider class="my-6"/>
                      </v-col>
                      <v-col cols="12" >
                        <v-btn dark color="green" @click="AddOrEditCampaign">{{ isCreate ? 'Add' : 'Edit' }}</v-btn>
                        <v-btn style="margin-left: 6px" @click="dialogCampaign = false"> Cancel </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

              </v-card>
              </v-form>
            </v-dialog>
          </v-toolbar>

          <DeleteModal :dialog="dialogDelete" @close-options="closeDelete" @save-options="deleteConfirm"/>

        </template>

        <template v-slot:[`item.enabled`]="{ item }">
            <v-tooltip bottom v-if="!isStatusStall(item)">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs"
                     v-on="on">
                  <v-switch
                      dense
                      :disabled="true"
                      v-model="item.enabled"
                      color="green"
                  ></v-switch>
                </div>

              </template>
              <span>Notifications In Process</span>
            </v-tooltip>
          <v-switch
              v-else
              dense
              color="green"
              @change="changeState(item)"
              v-model="item.enabled"
          ></v-switch>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <router-link :to="'/campaigns/' + item.id" class="name-title">{{ item.name }}</router-link>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip small dark :color="isColor(item)"> {{ item.status }}</v-chip>
        </template>

        <template v-slot:[`item.targetTime`]="{ item }">
          <v-chip small v-if="item.targetTime !== ''"> {{ item.targetTime }} </v-chip>
        </template>

        <template v-slot:[`item.lastExecutedAt`]="{ item }">
          <v-chip small v-if="item.lastExecutedAt !== ''"> {{ item.lastExecutedAt }} </v-chip>
        </template>

        <template v-slot:[`item.control`]="{ item }">
          <div class="d-flex w-100">
            <v-icon
                v-if="isStatusStall(item)"
                class="ml-auto mr-auto"
                @click.stop="changeStatus(item)"
                :color="isStatusStall(item) ? 'green' : 'red'" >
              {{ isStatusStall(item) ? 'mdi-play-circle-outline' : 'mdi-stop-circle-outline' }}
            </v-icon>
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div style="position: relative">
              <v-tooltip bottom v-if="!isStatusStall(item)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      style="opacity: .6; cursor: default; position:relative;"
                      v-bind="attrs" v-on="on"
                      small
                      class="mr-3"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Notifications In Process</span>
              </v-tooltip>
            <v-icon
                v-else
                small
                @click.stop="editItem(item)"
                class="mr-3"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click.stop="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </div>

        </template>

      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import DeleteModal from "@/components/DeleteModal.vue";
import CampaignsFilter from "@/views/Campaigns/CampaignsFilter.vue";
import {api} from "@/providers/api";
import Vue from "vue";
import MyBreadcrumbs from "@/components/Breadcrumbs.vue";
import router from "@/routes/router";
import {routes} from "@/views/Campaigns/routes";

export default {
  name: "CampaignsPage",
  components: {MyBreadcrumbs, CampaignsFilter, DeleteModal},

  data: () => ({
    targetPage: null,
    actionsTitle: [
      {
        title: 'Edit'
      },
      {
        title: 'Delete'
      }
    ],
    dateOption: {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    },
    show: false,
    totalResponseCampaigns: 0,
    isCreate: false,
    selectedElement: null,
    dialogDelete: false,
    users: [],
    alertNotification: false,
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'NAME',
        value: 'name',
        sortable: false,
      },
      {
        text: 'LAUNCH DATE',
        value: 'targetTime',
        sortable: false,
      },
      {
        text: 'LAST RUN DATE',
        value: 'lastExecutedAt',
        sortable: false,
      },
      {
        text: 'ENABLED',
        value: 'enabled',
        sortable: false,
      },
      {
        text: 'STATUS',
        value: 'status',
        sortable: false,
      },
      {
        text: 'STATUS CONTROL',
        align: 'center',
        value: 'control',
        sortable: false,
      },
      {
        text: 'ACTION',
        align: 'center',
        value: 'actions',
        sortable: false,
      },
    ],
    rulesRegistered: [
      v => v && v.length >= 1 || 'Must be filled'
    ],
    timer: null,
    campaignsTableOptions: {},
    isLoading: false,
    items: [],
    dialogCampaign: false,
    editedItem: {},
    filter: {
      selectedIsAgency: '',
      selectedIsBoy: '',
      selectedTariff: '',
      selectedCountry: '',
      selectedAgency: '',
      balanceValue: [0, 10000],
      ageValue: [0, 99],
    },
    date: null,
    menu: false,
    time: null,
    menu2: false,
    statusActive: true,
    executeNow: true,
    textAlert: 'Notification sent!'
  }),
  watch: {
    campaignsTableOptions: {
      handler(changed) {
        this.fetchDate();
      },
      deep: true
    },
    date() {
      this.isDate();
      if(this.time === null && this.date !== null) {
        this.time = '12:00';
      }
    },
    time() {
      this.isDate();
      if(this.time !== null && this.date === null) {
        this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      }
    }
  },
  computed: {
    activeRoutes() {
        return routes;
    },
    itemsMap() {
      return this.items.map(item => {
        let isLastTargetTime = 'STALL';
        if(item.targetTime === null) {
          isLastTargetTime = item?.lastNotification?.status ? item?.lastNotification?.status === 'RUNNING' ? 'IN PROCESS' : 'STALL' : 'STALL'
        } else {
          let isLastNotificationRunning = item?.lastNotification?.status === 'RUNNING'
          isLastTargetTime = isLastNotificationRunning ? 'IN PROCESS' : item.lastExecutedAt !== null ? new Date(item.lastExecutedAt).getTime() <= new Date(item.targetTime).getTime() ? 'WAITING' : 'COMPLETED' : 'WAITING'
        }
        return {
          ...item,
          status: isLastTargetTime,
          lastExecutedAt: item.lastExecutedAt !== null ? new Date(item.lastExecutedAt).toLocaleString('en-US', this.dateOption) : '',
          targetTime: item.targetTime !== null ? new Date(item.targetTime).toLocaleString('en-US', this.dateOption) : ''
        }
      })
    },
    targetTime() {
      return new Date(`${this.date}T${this.time}`).getTime()
    },
    formTitle() {
      return this.isCreate ? 'Create new campaigns' : 'Edit';
    },
    isStatusStall() {
      return item => item.status !== 'IN PROCESS'
    },
    isColor() {
      return item => {
        switch (item.status) {
          case 'STALL':
            return 'orange'
          case 'WAITING':
            return 'deep-orange'
          case 'COMPLETED':
            return 'green'
          case 'IN PROCESS':
            return 'blue'
        }
      }
    },
    translations() {
      return this.$t('translation')
    },
    breadcrumbs() {
      return [
        {text: this.translations.NAVBAR.DASHBOARD.title, href: '/'},
        {text: 'Campaigns', disabled: true}
      ];
    },
  },
  methods: {
    isDate() {
      if(this.date === null && this.time === null) {
        this.executeNow = true;
      } else {
        this.executeNow = false;
      }
    },
    campaignOpen(item) {
      router.push(`/campaigns/${item.id}`);
    },
    openCreateModal() {
      this.isCreate = true;
      this.editedItem = {};
      this.$refs.form.resetValidation()
      this.filter = {
        selectedIsAgency: '',
        selectedIsBoy: '',
        selectedTariff: '',
        selectedCountry: '',
        selectedAgency: '',
        balanceValue: [0, 10000],
        ageValue: [0, 99],
      }
      this.users = [];
      this.date = null;
      this.time = null;
      this.targetPage = null;
    },
    async fetchDate() {
      this.isLoading = true;
      try {
        const {page, itemsPerPage: limit} = this.campaignsTableOptions
        const params = {
          limit: limit === -1 ? 100 : limit,
          page
        }
        const { items, meta } = await api.get('/admin/notifications-campaigns', {
          params: params,
        })
        this.items = items;
        this.totalResponseCampaigns = meta.totalItems;
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false;
      }
    },
    async AddOrEditCampaign() {
      if(this.$refs.form.validate()) {
        let isNaNTime = isNaN(this.targetTime)
        let isCustom = this.targetPage === 'custom'
        let targetPage = isCustom ? this.editedItem?.targetPage ? this.editedItem.targetPage : 'catalog/all' : this.targetPage !== null ? this.targetPage : 'catalog/all'
        const data = {
          name: this.editedItem.name,
          title: this.editedItem.title,
          text: this.editedItem.text,
          ...this.editedItem?.imageUrl ? {imageUrl: this.editedItem.imageUrl} : {},
          ...this.users.length > 0 ? {whitelist: this.users.map(item => item.id)} : {},
          ...!isNaNTime ? {targetTime: this.targetTime} : this.isCreate ? {} :{targetTime: null},
          targetPage: targetPage,
          isCustomUrl: isCustom,
          type: !isNaNTime ? 'ONE_TIME' : 'TRIGGERED'
        }
        const filter = {
          ...this.filter.selectedIsAgency !== '' ? {isAgency: this.filter.selectedIsAgency} : {},
          ...this.filter.selectedAgency !== '' ? {agencyId: this.filter.selectedAgency} : {},
          ...this.filter.selectedIsBoy !== '' ? {isBoy: this.filter.selectedIsBoy} : {},
          ...this.filter.selectedTariff !== '' ? {tariffId: this.filter.selectedTariff} : {},
          ...this.filter.selectedCountry !== '' ? {country: this.filter.selectedCountry} : {},
          balanceMin: this.filter.balanceValue[0],
          balanceMax: this.filter.balanceValue[1],
          ageMin: this.filter.ageValue[0],
          ageMax: this.filter.ageValue[1],
        }
        if (this.isCreate) {
          await api.post('/admin/notifications-campaigns', {
            ...data,
            targetAudience: {
              ...filter
            }
          }).then(async r => {
            if(this.executeNow) {
              await this.changeStatus(r)
            }
            await this.fetchDate()
          }).catch(e => e).finally(() => {
            this.dialogCampaign = false
          })
        }
        else {
          await api.patch(`/admin/notifications-campaigns/${this.editedItem.id}`, {
            ...data,
            targetAudience: {
              ...filter
            }
          }).then(async r => {
            await this.fetchDate()
          }).catch(e => e).finally(() => this.dialogCampaign = false)
        }
      }

    },
    async changeStatus(item) {
      // item.status = item.status === 'stall' ? 'in progress' : 'stall'
      await api.patch(`/admin/notifications-campaigns/${item.id}/run-process`).then(r => {
        this.textAlert = 'Notification sent!'
        this.alertNotification = true
      }).finally(() => {
        if(this.timer) {
          clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => {
          this.alertNotification = false
        },3000)
      }).catch(e => {
        this.textAlert = 'Error!'
        this.alertNotification = true
      })
    },
    async changeState(item) {
      try {
        if(!item.enabled) {
          await api.patch(`/admin/notifications-campaigns/${item.id}/stop`)
        } else {
          await api.patch(`/admin/notifications-campaigns/${item.id}/start`)
        }
        await this.fetchDate();
      } catch (e) {
        console.log(e)
      }
    },
    async editItem(item) {
      this.date = null;
      this.time = null;
      this.targetPage = null;
      this.editedItem = Object.assign({}, item)
      let url = this.editedItem.targetPage;;
      if(url !== null) {
        if(url.includes(process.env.VUE_APP_BASE_SITE_URL)) {
          let parts = url.split(process.env.VUE_APP_BASE_SITE_URL + '/app/');
          if (parts.length > 1) {
            this.targetPage = parts[1]
            this.editedItem.targetPage = parts[1];
          }
        } else {
          this.targetPage = 'custom'
          this.editedItem.targetPage = url;
        }
      }
      const target = this.editedItem.targetAudience;
      this.filter = {
        selectedIsAgency: target?.isAgency !== undefined ? target?.isAgency : '',
        selectedIsBoy: target?.isBoy !== undefined ? target?.isBoy : '',
        selectedTariff: target?.tariffId !== undefined ? target?.tariffId : '',
        selectedCountry: target?.country !== undefined ? target?.country : '',
        selectedAgency: target?.agencyId !== undefined ? target?.agencyId : '',
        balanceValue: [target?.balanceMin ? target?.balanceMin : 0, target?.balanceMax ? target?.balanceMax : 10000],
        ageValue: [target?.ageMin ? target?.ageMin : 0, target?.ageMax ? target?.ageMax : 99],
      }
      if(this.editedItem.targetTime) {
        let targetTime = new Date(this.editedItem.targetTime);
        this.date = targetTime.toISOString().split('T')[0];
        this.time = targetTime.toTimeString().split(' ')[0];
      }
      this.users = await api.get(`/admin/notifications-campaigns/${item.id}/whitelist`).then(r => r.items.map(item => item.user))
      this.isCreate = false
      this.dialogCampaign = true
    },
    async deleteConfirm() {
      await api.delete(`/admin/notifications-campaigns/${this.selectedElement.id}`)
      await this.fetchDate();
      this.closeDelete()
    },
    deleteItem(item) {
      this.selectedElement = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.dialogDelete = false
    },
  },

}
</script>

<style scoped lang="scss">
.name-title {
  text-decoration: navajowhite;
  color: unset;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #2f55b4;
  }
}

.alert {
  width: fit-content;
  white-space: nowrap;
  position: fixed;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);
  top: 70px;
  z-index: 100;
}

.container {
  height: fit-content;
  margin-top: 0 !important;
}
.v-divider {
  margin-top: 0 !important;
  margin-bottom: 12px !important;
}
</style>