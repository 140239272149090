<template>
  <div class="upload-avatar">
    <label :for="forKey">
      <div
          v-if="previewUrl || url"
          class="upload-icon"
      >
        <img
            :src="previewUrl || url"
            class="image-preview"
        >
      </div>
      <div
          v-else
          class="upload-icon"
      >
        <img
            class="icon"
            src="@/assets/images/icons/frame-landscape.svg"
        >
        <div class="caption">Upload avatar</div>
      </div>
    </label>
    <input
        :id="forKey"
        ref="input"
        class="upload-input"
        :accept="acceptFiles"
        name="photo"
        type="file"
        @change="changeHandler"
    >
    <CropImageModal />
  </div>
</template>

<script>
import CropImageModal from '@/components/CropImageModal.vue';

export default {
  name: 'AppImageAvatar',

  components: {
    CropImageModal
  },

  props: {
    url: {
      type: String,
      default: null
    },
    removeUrl: {
      type: Object,
      default: undefined,
      required: false
    }
  },

  watch: {
    removeUrl(val) {
      if (val === undefined) {
        this.previewUrl = null
      }
    }
  },

  data() {
    return {
      forKey: Math.random().toString(),
      files: null,
      accept: [
        'image/*'
      ],
      previewUrl: null
    };
  },

  computed: {
    acceptFiles() {
      return this.accept.join(',');
    },

    salt() {
      return Math.random()
          .toString(18)
          .split('.')
          .pop();
    }
  },
  methods: {
    changeHandler(event) {
      if (this.previewUrl) {
        URL.revokeObjectURL(this.previewUrl);
        this.previewUrl = null;
      }
      // console.log(event.target.files);

      if (event.target.files.length) {
        const file = event.target.files[0];
        // console.log(file); // image/gif
        if (file.type.includes('image')) {
          this.$modal.show('CropImageModal', {
            file,
            onComplete: ({ blob }) => {
              // console.log('Completed cropping', blob)
              this.$emit('input', { blob, file });
              this.previewUrl = URL.createObjectURL(blob);
            }
          });
        }
        // this.$emit('input', event.target.files[0]);
        this.$refs.input.value = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>


.upload-avatar {
  margin-right: 28px;
  width: 160px;

  label {
    cursor: pointer;
    /* Style as you please, it will become the visible UI component. */
  }

  .upload-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 0.1px;
  }

  .upload-icon {
    width: 160px;
    height: 180px;
    background: #FFFFFF;
    box-shadow: 0 5px 15px rgba(117, 0, 255, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 10px;

    .icon {
      width: 48px;
      height: 48px;
      margin-bottom: 10px;
    }

    .caption {
      font-family: RF Dewi Extended, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      /* or 129% */

      text-align: center;
      color: grey;
      width: 70%;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }

    &:after {
      opacity: 0;
      transition: all ease-in 100ms;
      content: '';
      background-color: rgba(0, 0, 0, 0.2);
      height: inherit;
      width: inherit;
      position: absolute;
      border-radius: inherit;
      background-image: url("~@/assets/images/icons/upload-avatar-ico.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 48px;
    }

    .image-preview {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>
