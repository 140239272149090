<template>
  <div>
    <PreloaderPage class="preloader-page" v-if="preloader"/>
    <v-container v-else class="my-5">

      <v-card>
        <v-row>
          <v-col cols="12" md="12" lg="12" class="agency-info">
            <v-row align="start">
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>ID</v-list-item-title>
                    <v-list-item-subtitle>{{ currentAgency.id ? currentAgency.id : 'no data' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ translationsAgencyLog.name_label }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                        currentAgency.name ? currentAgency.name : 'no data'
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ translationsAgencyLog.balance_label }}</v-list-item-title>
                    <v-list-item-subtitle v-if="currentAgency.balance">{{
                        $getBalance(currentAgency.balance.amount)
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>no data</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row align="start">
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ translationsAgencyLog.owner_label }}</v-list-item-title>
                    <v-list-item-subtitle class="owner-name" v-if="currentAgency.owner"
                                          @click="openUserNewPage(currentAgency.owner.id)">
                      {{
                        currentAgency.owner.name + ' ' + (currentAgency.owner.surname ? currentAgency.owner.surname : '')
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      no data
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ translationsAgencyLog.number_staff_label }}</v-list-item-title>
                    <v-list-item-subtitle>{{ currentAgency.totalMembers }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ translationsAgencyLog.created_label }}</v-list-item-title>
                    <v-list-item-subtitle v-if="currentAgency.createdAt">{{
                        new Date(currentAgency.createdAt) | date('Pp')
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>no data</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row align="start">
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ translationsAgencyLog.contract_label }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <a class="contract-link" v-if="contractCheck" @click="showContract(currentAgency)"
                         target="_blank">
                        View
                      </a>
                      <span v-else>Unsent</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-text-field hide-details readonly :value="agencyInviteUrl"
                              :label="translationsAgencyLog.woman_url_label"
                              prepend-icon="mdi-clipboard-text"
                              v-clipboard="() => agencyInviteUrl" filled></v-text-field>
              </v-col>
              <v-col/>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <v-card width="max-content">
        <v-card-title>Telegram Bot</v-card-title>
        <v-card-actions>
          <v-btn
              color="primary"
              @click="openTelegramBot"
              outlined
              :loading="loading"
          >
            Open
          </v-btn>
        </v-card-actions>
        <v-card-subtitle class="pb-0">Notification settings</v-card-subtitle>
        <v-card-actions class="flex-column align-start">
          <v-switch
              label="Ladies Activities"
              hide-details
              @change="changeLadiesActivities"
              :disabled="switchLoading.ladies_activities"
              :input-value="findSwitch(TYPES.ADMIN_NOTIFICATIONS_LADIES_ACTIVITIES)"
          ></v-switch>
          <v-switch
              label="Contact with Ladies"
              hide-details
              @change="changeContactWithLadies"
              :disabled="switchLoading.contact_with_ladies"
              :input-value="findSwitch(TYPES.ADMIN_NOTIFICATIONS_CONTACT_WITH_LADIES)"
          ></v-switch>
          <v-switch
              label="New Men Registration"
              hide-details
              @change="changeNewManRegistration"
              :disabled="switchLoading.new_man_registration"
              :input-value="findSwitch(TYPES.ADMIN_NOTIFICATIONS_NEW_MAN_REGISTRATION)"
          ></v-switch>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>


import {getRoles} from '@/api/usersRequest';
import {
  addUsersAgency,
  getAgencyBalanceId,
  getAgencyById,
} from '@/api/agencyRequest';
import {mapGetters} from 'vuex';
import {api} from '@/providers/api';
import PreloaderPage from "@/components/PreloaderPage.vue";
import {userOnlineCheck} from "@/mixins/user-online.mixin";

export default {
  name: 'AgencyLogSInfo',
  components: {
    PreloaderPage,

  },

  data: () => ({
    statusItems: ['REVIEW', 'ACTIVE', 'BLOCKED'],
    select: '',
    autoCompleteInput: {},
    autoCompleteUserRole: null,
    search: null,
    searchUser: null,
    agency: [],
    users: [],
    isCreateDialog: false,
    agencyName: '',
    balance: null,

    userRoles: [],
    agencyInfo: [],
    preloader: true,
    balanceLoading: false,
    agencyLoading: false,
    totalResponseUsers: 0,
    usersTableOptions: {},
    agencyUserProps: [],
    alertPayOut: false,
    loading: false,
    settings: {},
    switchLoading: {
      ladies_activities: false,
      contact_with_ladies: false,
      new_man_registration: false,
    },
    TYPES: {
      ADMIN_NOTIFICATIONS_LADIES_ACTIVITIES: 'ADMIN_NOTIFICATIONS_LADIES_ACTIVITIES',
      ADMIN_NOTIFICATIONS_CONTACT_WITH_LADIES: 'ADMIN_NOTIFICATIONS_CONTACT_WITH_LADIES',
      ADMIN_NOTIFICATIONS_NEW_MAN_REGISTRATION: 'ADMIN_NOTIFICATIONS_NEW_MAN_REGISTRATION',
    }
  }),
  async created() {
    await this.fetchInfoAgency();
  },

  computed: {
    findSwitch() {
      return type => this.settings.find(e => e.type === type)?.isEnabled
    },
    translations() {
      return this.$t('translation')
    },
    translationsAgencyLog() {
      return this.translations.AGENCY.AGENCY_LOG
    },
    userOnlineCheck() {
      return userOnlineCheck
    },
    ...mapGetters('Agencies', [
      'getterAgencyById',
    ]),
    ...mapGetters('User', ['me']),

    currentAgency() {
      return this.getterAgencyById(+this.agency.id)
    },

    adminCheck() {
      return this.$auth.isAdmin
    },
    contractCheck() {
      return this.agencyInfo?.agencyRequest?.contract
    },

    agencyInviteUrl() {
      const u = new URL(process.env.VUE_APP_BASE_SITE_URL);
      u.pathname = '/register/agency';
      u.searchParams.append('agency_id', String(this.agency.id));
      return u.toString();
    }
  },

  methods: {
    async changeLadiesActivities(value) {
      this.switchLoading.ladies_activities = true
      await this.checkTypeInSettings(this.TYPES.ADMIN_NOTIFICATIONS_LADIES_ACTIVITIES, value)
      this.switchLoading.ladies_activities = false
    },
    async changeContactWithLadies(value) {
      this.switchLoading.contact_with_ladies = true
      await this.checkTypeInSettings(this.TYPES.ADMIN_NOTIFICATIONS_CONTACT_WITH_LADIES, value)
      this.switchLoading.contact_with_ladies = false
    },
    async changeNewManRegistration(value) {
      this.switchLoading.new_man_registration = true
      await this.checkTypeInSettings(this.TYPES.ADMIN_NOTIFICATIONS_NEW_MAN_REGISTRATION, value)
      this.switchLoading.new_man_registration = false
    },

    async checkTypeInSettings(typeToCheck, isEnabled) {
      const found = this.settings.some(setting => setting.type === typeToCheck);
      if (found) {
        const { id } = this.settings.find(setting => setting.type === typeToCheck);
        await this.editTelegramSetting(id, isEnabled)
      } else {
        await this.createTelegramSetting(typeToCheck)
        await this.getUserSettings()
      }
    },

    async createTelegramSetting(type) {
      await api.post('/users-notifications/settings', {
        "isEnabled": true,
        "type": type,
        "channel": "TELEGRAM"
      })
    },
    async editTelegramSetting(id, isEnabled) {
      await api.patch(`/users-notifications/settings/${id}`, {
        isEnabled
      })
    },

    async openTelegramBot() {
      this.loading = true
      const {link} = await api.get('/telegram-bot/link')
      const url = `${link}?start=${this.me.id}`;
      window.open(url, '_blank');
      this.loading = false
    },
    getItemText(item) {
      return `${item.name} (${item.email})`;
    },

    showContract(agencyInfo) {
      console.log(agencyInfo)
      const url = agencyInfo?.agencyRequest?.contract?.url;
      if (url)
        window.open(url, '_blank');
    },


    async fetchInfoAgency() {
      this.preloader = true

      await this.getUserSettings()

      const response = await api.get('/admin/agencies')
      const {data: items} = response

      if (items.length === 1 && !this.$auth.isAdmin) {
        this.agency = items[0]
        console.log('agency', this.agency)
      }
      await this.$store.dispatch("Agencies/fetchAgencyById", {id: this.agency.id})

      await this.fetchBalance()

      this.preloader = false
    },


    async getUserSettings() {
      this.settings = await api.get(`/users/${this.me.id}/notification-settings`)
    },

    openUserNewPage(id) {
      const url = this.$router.resolve(`/users/${id}`).href;
      window.open(url, '_blank');
    },


    filterData(item, queryText) {
      return (
          item.email.toLowerCase().includes(queryText.toLowerCase()) ||
          item.name.toLowerCase().includes(queryText.toLowerCase())
      );
    },
    filterDataUserRole(item, queryText) {
      return (
          item.name.toLowerCase().includes(queryText.toLowerCase())
      );
    },


    close() {
      this.isCreateDialog = false;
      this.autoCompleteInput = '';
      this.autoCompleteUserRole = '';
    },
    async save() {
      this.isCreateDialog = false;
      await addUsersAgency(this.autoCompleteInput.id, this.id, this.autoCompleteUserRole);
      this.autoCompleteInput = '';
      await this.fetchAllData();
      await this.syncUsersFromApi()
      // this.users = await getUsers().then(r => r.data);
    },

    async fetchAllData() {
      this.fetchBalance();
    },

    async fetchBalance() {
      this.balanceLoading = true
      this.balance = await getAgencyBalanceId(this.agency.id).then(r => r.balance);
      this.balanceLoading = false
      return this.balance;
    },


  }
};
</script>

<style scoped lang="scss">
.col-info {
  padding: 0px;
}

.online {
  position: absolute;
  width: 10px;
  margin-top: -20px;
  border: 2px solid white;
  margin-left: 20px;
  height: 10px;
}

.card, .card-man, .card-woman {
  max-width: 330px;
  background: #2f55b4;
  color: white;
  margin-bottom: 10px;

  .v-card__title {
    text-transform: uppercase;
  }

  .v-icon {
    color: #f4f6f9;
  }
}

.table-header {
  background: #2f55b4;
  color: white;
  margin-bottom: 5px;

  .button-add {
    border: 1px solid #fff !important;
  }

  .search-input {
    margin-right: 50px;
    color: white;

    ::v-deep label {
      color: white;
    }

    ::v-deep .v-icon.v-icon {
      color: white;
    }

    ::v-deep .v-input__slot::before {
      border-color: white;
    }

    ::v-deep input {
      color: white;
    }
  }


}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.user-img {
  display: flex;
  align-items: center;
}

.row {
  margin-bottom: 20px;
}

.select-time-chart {
  width: 300px;
}

.input {
  width: max-content;
  margin-left: 10px;
}

.agency-main {
  .v-card {
    color: #343a40;
    border: none;

    .v-card__text {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.agency-info {
  .row {
    margin: 3px;
    border-bottom: 1px solid #e8e8e8;

    &:last-child {
      border-bottom: none;
    }
  }
}

.agency {
  display: flex;
  flex-direction: column;
  align-items: center;

  .text-h6 {
    width: 100%;
    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
  }

  .btn {
    width: 100%;
    max-width: fit-content;
    font-size: 13px;
  }
}

.alert {
  position: fixed;
  z-index: 100;
  right: 15px;
  top: 0px;
}

.user-title {
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #2f55b4;
  }
}

.amount-slider {
  width: 100%;
  max-width: 400px;

  @media (max-width: 1440px) {
    max-width: 200px;
  }
}

.v-tab-item-setting {
  background: #f3f3f3;
}

.preloader {
  height: 300px;
  padding-bottom: 15px;
  width: 100%;
  display: flex;
  background: #f3f3f3;
  justify-content: center;
  padding-top: 15px;
}

.alertSuccess {
  position: fixed;
  z-index: 10;
  right: 15px;
}

.agency-link {
  text-decoration: unset;
  color: #7b7b7b;

  &.router-link-active {
    color: #2f55b4;
  }
}

.preloader-page {
  position: absolute;
  top: 0;
}

.owner-name {
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #2f55b4 !important;
  }
}

.contract-link {
  text-decoration: underline;
}
</style>
