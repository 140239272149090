<template>
  <div class="notifications-campaign-log">
    <v-container class="my-5">
      <v-row>
        <v-col cols="12" md="12" lg="12">
          <v-card>
            <v-card-title class="table-header">
              {{ mainTitle }}
            </v-card-title>
            <v-data-table
                :footer-props="{'items-per-page-options': [10, 50, 100]}"
                :items-per-page="10"
                :headers="headers"
                :items="mailingMap"
                :options.sync="mailingTableOptions"
                :server-items-length="totalResponseMailing"
                :loading="mailingLoading"
            >
              <template v-slot:top>
                <stop-notifications-campaign-modal :dialog="statusModal"
                                    @close-options="closeModal"
                                    @save-options="stopChat"/>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip v-if="!item.state" small dark color="green"> ok </v-chip>
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                <v-chip small> {{ new Date(item.createdAt) | date('Pp') }} </v-chip>
              </template>
              <template v-slot:[`item.completeAt`]="{ item }">
                <v-chip v-if="item.completeAt !== ''" small> {{ item.completeAt }} </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex w-100">
                  <v-icon
                      class="ml-auto mr-auto"
                      @click="openModal(item)"
                      :color="item.endDate === '' ? 'green' : 'red'" >
                    {{ item.endDate === '' ? 'mdi-play-circle-outline' : 'mdi-stop-circle-outline' }}
                  </v-icon>
                </div>
              </template>
<!--              <template v-slot:[`item.actions`]="{ item }">-->
<!--                <v-btn-->
<!--                    v-if="item.state"-->
<!--                    @click="openModal(item)"-->
<!--                    color="error"-->
<!--                    dark-->
<!--                >-->
<!--                  {{ stopBtn }}-->
<!--                </v-btn>-->
<!--              </template>-->
            </v-data-table>
          </v-card>
        </v-col>

      </v-row>
    </v-container>
  </div>
</template>

<script>
import StopNotificationsCampaignModal from "@/components/StopNotificationsCampaignModal.vue";
import {api} from "@/providers/api";

export default {
  name: "NotificationsCampaignLog",
  components: {StopNotificationsCampaignModal},
  props: {
    activeId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      targetsUserBtn: 'Targets',
      stopBtn: 'Stop',
      activeItem: null,
      statusModal: false,
      usersModal: false,
      mailingTableOptions: {},
      mailingLoading: false,
      totalResponseMailing: 0,
      mainTitle: 'Notifications history',
      mailing: [],
      headers: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'START DATE', value: 'createdAt', sortable: false},
        {text: 'END DATE', value: 'completeAt', sortable: false},
        {text: 'COUNTER OF TARGETS USERS', value: 'targetUsersCount', sortable: false},
        {text: 'COUNTER OF SUCCESSFUL USERS', value: 'counterUsersSuccessful', sortable: false},
        {text: 'COUNTER OF UNSUCCESSFUL USERS', value: 'counterUsersUnsuccessful', sortable: false},
        {text: 'COUNTER OF PUSH', value: 'counterPush', sortable: false},
        {text: 'STATUS', value: 'status', sortable: false},
        // {text: 'STATUS CONTROL', value: 'actions', sortable: false,
        //   align: 'center'},
      ],
    }
  },
  watch: {
    mailingTableOptions: {
      handler(changed) {
        console.log('usersTableOptions:change', changed);
        this.syncMailingFromApi();
      },
      deep: true
    },
  },
  computed: {

    mailingMap() {
      return this.mailing.map(item => ({
        ...item,
        completeAt: item.completeAt !== null ? new Date(item.completeAt).toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' }) : ''
      }))
    },
  },
  methods: {
    openModal(item) {
      this.statusModal = true;
      this.activeItem = item;
    },
    closeModal() {
      this.statusModal = false;
    },
    stopChat() {
      console.log('STOP', this.activeItem)
      this.closeModal();
    },
    async syncMailingFromApi() {
      this.mailingLoading = true;
      try {
        const {page, itemsPerPage: limit} = this.mailingTableOptions
        const params = {
          page,
          limit: limit === -1 ? 100 : limit,
          status: 'COMPLETED'
        }
        const { items, meta } = await api.get(`/admin/notifications-campaigns/${ this.activeId }/processes`, {
          params: params,
        })
        this.mailing = items;
        this.totalResponseMailing = meta.totalItems;
      } catch (e) {
        console.log('error', e);
      } finally {
        this.mailingLoading = false;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.container {
  height: 100% !important;
}
.table-header {
  background: #2f55b4;
  color: white;

  .search-input {
    color: white;

    ::v-deep label {
      color: white;
    }

    ::v-deep .v-icon.v-icon {
      color: white;
    }

    ::v-deep .v-input__slot::before {
      border-color: white;
    }

    ::v-deep input {
      color: white;
    }
  }


}
.targets-btn {
  margin-left: 6px;
}
</style>