import {differenceInYears, parse} from "date-fns";

export const UserAgeMixin = {
  methods: {
    $calculateAge(birthdate) {
      if (birthdate) {
        const currentDate = new Date();
        const parsedBirthdate = parse(birthdate, 'yyyy-MM-dd', new Date());

        return differenceInYears(currentDate, parsedBirthdate);
      }

    }
  },
};
