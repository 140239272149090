import { api } from '@/providers/api';
import Vue from 'vue';
import {getAuthMe} from "@/api/authRequest";

const state = {
  me: null,
  favoriteUsers: [],
  usersOnline: [],
  users: []
};

const getters = {
  me: (state) => state.me,
  isFavoriteUser: (state) => (userId) =>
    state.favoriteUsers.findIndex((u) => u.id === userId) !== -1,
  getterUserById: state => userId => state.users.find(u => u.id === userId)
};

const mutations = {
  SET_USER_ME(state, user) {
    state.me = user;
  },
  SET_FAVORITE_USERS(state, list) {
    state.favoriteUsers = list;
  },
  SET_USER_ONLINE(state, { id, online }) {
    const s = state.usersOnline.find(s => s.id === id);
    Vue.set(state.usersOnline, state.usersOnline.indexOf(s), { ...s, value: online });
  },
  ADD_USER_ONLINE(state, { id, isOnline }) {
    state.usersOnline.push({ id, value: isOnline });
  },

  SET_USERS(state, list) {
    state.users = list;
  },
  ADD_UPDATE_USERS(state, list) {
    for (const item of list) {
      const index = state.users.findIndex(u => u.id === item.id);
      if (index === -1) {
        state.users.push(item);
      } else {
        Vue.set(state.users, index, item);
      }
    }
  },
  REMOVE_USERS(state, listIds) {
    state.users = state.users.filter(u => !listIds.includes(u.id));
  }
};

const actions = {
  async fetchFavoriteUsers({ commit }) {
    const items = await api.get('/users/catalog/favorites');
    commit('SET_FAVORITE_USERS', items);
    return items;
  },

  async markFavorite({ commit, getters, state }, userId) {
    const result = await api.request({
      url: '/users/catalog/favorites',
      method: getters.isFavoriteUser(userId) ? 'DELETE' : 'POST',
      data: {},
      params: {
        user_id: userId,
      },
    });
    commit(
      'SET_FAVORITE_USERS',
      getters.isFavoriteUser(userId)
        ? state.favoriteUsers.filter((e) => e.id !== userId)
        : state.favoriteUsers.concat([result.favorite])
    );
  },

  async fetch({ commit }) {
    const user = await getAuthMe();
    commit('SET_USER_ME', user);
  },

  async getUserById({commit}, { userId }) {
    const user = await api.get(`/users/${userId}`);

    commit('ADD_UPDATE_USERS', [user]);

    return user;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
