import {api} from "@/providers/api";

export async function getBlogs() {
  return api.get('/blog')
}


export async function postBlogs(id, data) {
  return api.post('/blog', data, {
    params: {
      categoryId: id,
    }
  })
}

export async function getBlogId(id) {
  return api.get(`/blog/${id}`)
}

export async function deleteBlog(id) {
  return api.delete(`/blog/${id}`)
}

export async function editBlog(id, categoryId, data) {
  return api.patch(`/blog/${id}`, data, {
    params: {
      categoryId: categoryId
    }
  })
}


export async function getBlogsCategory() {
  return api.get('/blog-category')
}

export async function postBlogsCategory(data) {
  return api.post('/blog-category', data)
}

export async function deleteBlogCategory(id) {
  return api.delete(`/blog-category/${id}`)
}

export async function editBlogCategory(id, data) {
  return api.patch(`/blog-category/${id}`, data)
}
export async function uploadFile(data) {
  return api.post('/files', data)
}

