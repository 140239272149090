<template>
  <div class="faq-container">
    <v-container class="my-5">
      <v-data-table
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :items-per-page="10"
              :headers="headers"
              :items="faq"
              :single-expand="true"
              :expanded.sync="expanded"
              :loading="faqLoading"
              item-key="name"
              show-expand
              class="elevation-1"
              @item-expanded="loadDetails">

        >
        <template v-slot:top>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>{{ translationsFAQ.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog
                    v-model="dialog"
                    max-width="500px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                        color="white"
                        outlined
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                >
                  {{ translationsFAQ.btn_new_faq_group }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">FAQ Group: {{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                              cols="12"
                              sm="6"
                              md="4"
                      >
                        <v-text-field
                                v-model="editedItem.name"
                                :label="translationsFAQ.modal_label"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                          color="blue darken-1"
                          text
                          @click="close"
                  >
                    {{ translations.GLOBAL_VALUE.cancel }}
                  </v-btn>
                  <v-btn
                          color="blue darken-1"
                          text
                          @click="save"
                  >
                    {{ translations.GLOBAL_VALUE.save }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <DeleteModal :dialog="dialogDelete" @close-options="closeDelete" @save-options="deleteItemConfirm"/>

          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
                  small
                  @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>


        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">

            <div>
              <v-data-table
                  :footer-props="{'items-per-page-options': [10, 50, 100]}"
                  :items-per-page="10"
                      :headers="headersQuestion"
                      :items="item.info"
                      sort-by="calories"
                      class="elevation-1 table"

              >
                <template v-slot:top>
                  <v-toolbar
                          flat
                          color="#FFF8FAFF"
                  >
                    <v-toolbar-title>{{ translationsFAQ.expanded_table.title }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-dialog
                            v-model="dialogQuestion"
                            max-width="500px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                color="primary"
                                outlined
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                        >
                          {{ translationsFAQ.expanded_table.btn_new_faq }}
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">{{ formTitleQuestion }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col
                                      cols="12"
                                      sm="12"
                                      md="12"
                              >
                                <v-text-field
                                        v-model="editedItemQuestion.title"
                                        label="Question"
                                ></v-text-field>
                                <v-text-field
                                        v-model="editedItemQuestion.description"
                                        label="Answer"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeQuestion"
                          >
                            {{ translations.GLOBAL_VALUE.cancel }}
                          </v-btn>
                          <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="saveQuestion"
                          >
                            {{ translations.GLOBAL_VALUE.save }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <DeleteModal :dialog="dialogDeleteQuestion" @close-options="closeDeleteQuestion" @save-options="deleteItemConfirmQuestion"/>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                          small
                          class="mr-2"
                          @click="editItemQuestion(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                          small
                          @click="deleteItemQuestion(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>

              </v-data-table>
            </div>

          </td>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
  import {
    addQuestion,
    deleteFAQById,
    deleteQuestion,
    editQuestion,
    getFAQ,
    patchEditFAQ,
    postFAQ
  } from "@/api/faqRequest";
  import DeleteModal from "@/components/DeleteModal";

  export default {
    name: 'FAQ',
    components: {DeleteModal},
    data() {
      return {
        expanded: [],
        faqId: null,
        dialog: false,
        dialogDelete: false,
        dialogQuestion: false,
        dialogDeleteQuestion: false,
        editedIndex: -1,
        editedIndexQuestion: -1,
        editedItem: {
          name: '',
        },

        editedItemQuestion: {
          title: '',
          description: ''
        },

        defaultItem: {
          name: '',
        },

        headers: [
          {text: '', value: 'data-table-expand'},
          {
            text: 'Title',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          {text: 'Actions', value: 'actions', sortable: false}

        ],
        headersQuestion: [
          {text: 'Title question', value: 'title', sortable: false},
          {text: 'Answer', value: 'description', sortable: false},
          {text: 'Actions', value: 'actions', sortable: false}
        ],
        faq: [],
        faqLoading: false
      };
    },
    computed: {
      formTitle() {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
      },
      formTitleQuestion() {
        return this.editedIndexQuestion === -1 ? 'New Item' : 'Edit Item';
      },
      translations() {
        return this.$t('translation')
      },
      translationsFAQ() {
        return this.translations.FAQ.table
      }
    },

    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },
      dialogQuestion(val) {
        val || this.close();
      },
      dialogDeleteQuestion(val) {
        val || this.closeDelete();
      },
    },

    created() {
      this.headers = [
        {text: '', value: 'data-table-expand'},
        {
          text: this.translationsFAQ.headers.title,
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {text:  this.translationsFAQ.headers.actions, value: 'actions', sortable: false}

      ]
      this.headersQuestion = [
        {text: this.translationsFAQ.expanded_table.headers.title, value: 'title', sortable: false},
        {text: this.translationsFAQ.expanded_table.headers.answer, value: 'description', sortable: false},
        {text: this.translationsFAQ.expanded_table.headers.actions, value: 'actions', sortable: false}
      ]
      this.fetchFAQ();
    },

    methods: {
      async fetchFAQ() {
        this.faqLoading = true
        this.faq = await getFAQ().then(r => r.map(e => ({
          id: e.id,
          name: e.title,
          info: e.items.map(i => ({id: i.id, title: i.question, description: i.answer}))
        })));
        this.faqLoading = false
      },

      loadDetails({item}) {
        this.faqId = this.faq.indexOf(item);
      },
      editItem(item) {
        this.editedIndex = this.faq.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },

      deleteItem(item) {
        this.editedIndex = this.faq.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
        console.log(this.editedItem.id)
      },

      async deleteItemConfirm() {
        await deleteFAQById(this.editedItem.id);
        this.faq.splice(this.editedIndex, 1);
        this.closeDelete();
      },

      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },

      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },

      async save() {
        if (this.editedIndex > -1) {
          await patchEditFAQ(this.faq[this.editedIndex]?.id, this.editedItem.name);
          Object.assign(this.faq[this.editedIndex], this.editedItem);

        } else {
          const {id} = await postFAQ(this.editedItem.name);
          this.faq.push({id, name: this.editedItem.name, info: []});
        }
        this.close();
      },

      editItemQuestion(item) {
        this.editedIndexQuestion = this.faq[this.faqId].info.indexOf(item);
        this.editedItemQuestion = Object.assign({}, item);
        this.dialogQuestion = true;
      },

      deleteItemQuestion(item) {
        this.editedIndexQuestion = this.faq[this.faqId].info.indexOf(item);
        this.editedItemQuestion = Object.assign({}, item);
        this.dialogDeleteQuestion = true;
      },

      async deleteItemConfirmQuestion() {
        await deleteQuestion(this.editedItemQuestion.id);
        (this.faq[this.faqId].info.splice(this.editedIndexQuestion, 1));
        this.closeDeleteQuestion();
      },

      closeQuestion() {
        this.dialogQuestion = false;
        this.$nextTick(() => {
          this.editedItemQuestion = Object.assign({}, this.defaultItem);
          this.editedIndexQuestion = -1;
        });
      },

      closeDeleteQuestion() {
        this.dialogDeleteQuestion = false;
        this.$nextTick(() => {
          this.editedItemQuestion = Object.assign({}, this.defaultItem);
          this.editedIndexQuestion = -1;
        });
      },

      async saveQuestion() {
        if (this.editedIndexQuestion > -1) {
          await editQuestion(this.faq[this.faqId]?.id,
            this.faq[this.faqId].info[this.editedIndexQuestion]?.id,
            this.editedItemQuestion.title,
            this.editedItemQuestion.description);

          Object.assign(this.faq[this.faqId].info[this.editedIndexQuestion], this.editedItemQuestion);
        } else {
          const {id} = await addQuestion(this.faq[this.faqId]?.id, this.editedItemQuestion.title, this.editedItemQuestion.description);
          this.faq[this.faqId].info.push({...this.editedItemQuestion, id});
        }
        this.closeQuestion();
      },
    }
  };
</script>

<style scoped>
  td {
    padding: 0 !important;
  }

  .table {
    background: #FFF8FAFF;
  }
</style>
