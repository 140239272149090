import {api} from "@/providers/api";

export async function authUser(forms) {
  return await api.post('/auth/login', {
    ...forms
  });
}

export async function postAuthRegister(data) {
  return await api.post('/auth/register-agency-member', data)
}

export async function getAuthMe() {
  return await api.get('v2/auth/me')
}