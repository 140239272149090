<template>
  <v-content class="ma-5">
    <v-container fluid fill-height>
      <v-layout justify-center>
        <v-flex xs12 sm8 md4>

          <v-alert
                  border="top"
                  color="red lighten-2"
                  dark
                  v-model="alertModel"
          >
            {{ translation.error_auth }}
          </v-alert>

          <v-card class="elevation-10">
            <v-toolbar dark color="primary">
              <v-toolbar-title> {{ translation.title }}</v-toolbar-title>
            </v-toolbar>
            <v-form ref="form" @submit.prevent="login">
            <v-card-text>

                <v-text-field
                        prepend-icon="mdi-account"
                        name="login"
                        :label="translation.login_label"
                        type="text"
                        color="primary"
                        v-model="forms.signIn.email"
                ></v-text-field>
                <v-text-field
                        id="password"
                        prepend-icon="mdi-lock"
                        name="password"
                        :label="translation.password_label"
                        type="password"
                        color="primary"
                        v-model="forms.signIn.password"
                ></v-text-field>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" class="white--text">{{ translation.btn_sign_in }}</v-btn>
            </v-card-actions>
            </v-form>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

  </v-content>
</template>

<script>
  import { setUserAuth } from '@/providers/auth';
  import {authUser} from "@/api/authRequest";
  export default {
    name: "MyAuth",
    data: () => ({
      alertModel: false,
      forms: {
        signIn: {
          email: null,
          password: null,
        },
      },

      showAlertSuccess: false,
      showAlertError: false,
      alertErrors: null,
    }),
    computed: {
      translation() {
        return this.$t('translation.AUTH')
      }
    },
    methods: {

      async login() {
        console.log('qwe')
        this.showAlertError = false;
        this.showAlertSuccess = false;
        this.alertErrors = null;
        try {
          const { access_token, user } = await authUser(this.forms.signIn)
          if(!user.roles.map(e => e.name).includes("admin") && user.agency.role.name !== 'admin'){
            throw new Error()
          }

          setUserAuth(access_token, user);
          this.$router.replace({name: 'dashboard'})
        } catch (e) {
          this.alertModel =  true
        }
      }
    }
  }
</script>

<style scoped>

</style>