<template>
  <div class="pay-out">
    <v-alert class="alertSuccess" dark v-model="alertPayOut" type="success">
      {{ translationsAgencyLogPayOut.alert_message }}
    </v-alert>
    <v-container :class="{'disabled': loadingActivity}">
      <MyBreadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-tabs v-model="currentTab" class="mb-3" background-color="#f4f6f9" color="primary">
        <v-tab to="staff">
          Staff
        </v-tab>
        <v-tab to="transactions">
          {{ translationsAgencyLog.breadcrumbs.transactions }}
        </v-tab>
        <v-tab to="chats">
          {{ 'Chats' }}
        </v-tab>
        <v-tab to="activities">
          {{ translationsAgencyLog.breadcrumbs.activities }}

        </v-tab>
        <v-tab v-if="!this.$auth.isAdmin" to="withdrawal">
          {{ translationsAgencyLog.breadcrumbs.withdrawal }}
        </v-tab>
        <v-tab to="stats">
          {{ translationsAgencyLog.breadcrumbs.stats }}
        </v-tab>
        <v-tab to="settings">
          {{ translationsAgencyLog.breadcrumbs.settings }}
        </v-tab>
        <v-tab to="pay-out">
          {{ translationsAgencyLog.breadcrumbs.pay_out }}
        </v-tab>
      </v-tabs>
    </v-container>
    <PreloaderPage class="preloader"  v-if="loading || loadingActivity"/>
    <v-container v-else class="my-5">
      <v-card-text class="pl-0">{{ translationsAgencyLogPayOut.note }}</v-card-text>
      <v-row class="ma-0">
        <v-autocomplete
            class="mt-3"
            color="primary"
            background-color="white"
            :error-messages="errorUser ? 'Must be filled' : ''"
            v-model="value"
            :items="items"
            :label="translationsAgencyLogPayOut.select_users_label"
            item-text="name"
            item-value="email"
            return-object
            :filter="userFilter"
            outlined
            hide-details
            multiple
        >
          <template v-slot:selection="data">
            <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="remove(data.item.id)"
                color="primary">
              <v-avatar left>
                <v-img :src="$getAvatarImage(data.item.profile.avatar)"></v-img>
              </v-avatar>
              {{ data.item.name }} ({{ data.item.email }})
            </v-chip>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-avatar>
              <img :src="$getAvatarImage(item.profile.avatar)">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="item.name"></v-list-item-title>
              <v-list-item-subtitle v-html="item.email"></v-list-item-subtitle>
            </v-list-item-content>

          </template>
        </v-autocomplete>
      </v-row>
      <v-row class="mt-3 ma-0">
        <v-btn width="200" @click="allSelected" color="primary">{{ translationsAgencyLogPayOut.btn_select_all_users }}</v-btn>
      </v-row>
      <v-row class="mt-3 ma-0">
        <v-btn width="200" @click="removeSelected" dark color="red">{{ translationsAgencyLogPayOut.btn_remove_all_users }}</v-btn>
      </v-row>

      <v-form @submit.prevent ref="form">
        <v-row class="ma-0">
          <v-col class="mt-3 pa-0" cols="12">
            <v-card outlined>
              <v-row class="ma-0 align-center ">

                <v-col cols="2">
                  <v-row class="ma-0 pa-0 justify-center">
                    <v-icon
                        color="primary"
                        size="150"
                    >
                      mdi-face-agent
                    </v-icon>
                  </v-row>

                </v-col>
                <v-col cols="8">

                  <v-row class="ma-3">
                    <v-col class="pa-0 pr-2"><span class="text-body-1 font-weight-medium ">{{ translationsAgencyLogPayOut.agency_label }} </span> <span
                        class="font-weight-medium">{{ agency.name }}</span></v-col>
                  </v-row>
                  <v-row class="ma-3">
                    <v-col class="pa-0 pr-2"><span class="text-body-1 font-weight-medium">{{ translationsAgencyLogPayOut.balance_label }} </span> <span
                        class="font-weight-medium ">{{ $getBalance(balance) }} $</span></v-col>
                  </v-row>

                  <v-row class="ma-3 align-center">
                    <div class="mr-5"><span class="text-body-1 font-weight-medium">{{ translationsAgencyLogPayOut.withdrawal_amount }} </span></div>


                    <div>
                      <v-text-field
                          type="number"
                          v-model="agencyBalance"
                          outlined
                          hide-details
                          dense
                          :rules="[withdrawalAmountAgencyRule(agencyBalance)]"
                      ></v-text-field>
                    </div>
                  </v-row>
                </v-col>
                <v-col cols="1"/>
                <v-divider vertical></v-divider>
                <v-col cols="1">
                  <v-row class="justify-center">
                    <v-checkbox
                        v-model="selected"
                        color="success"
                        hide-details
                    ></v-checkbox>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

        </v-row>

        <v-row v-if="value.length > 0" class="ma-0">
          <v-col class="mt-3 pl-0 pr-3" cols="6" v-for="user in value" :key="user.id">

            <v-card outlined>
              <v-row class="ma-0 align-center ">

                <v-col class="pa-0" cols="3">
                  <v-row class="ma-0 pa-0 justify-center">
                    <v-avatar size="100">
                      <img :src="$getAvatarImage(user.profile.avatar)">
                    </v-avatar>
                  </v-row>

                </v-col>
                <v-col cols="8">

                  <v-row class="ma-3">
                    <v-col class="pa-0 pr-2"><span class="font-weight-medium">{{ translationsAgencyLogPayOut.user_name_label }} {{ user.name }}</span></v-col>
                  </v-row>
                  <v-row class="ma-3">
                    <v-col class="pa-0 pr-2"><span class="font-weight-medium ">{{ translationsAgencyLogPayOut.user_balance_label }} {{
                        $getBalance(user.balance)
                      }} $</span>
                    </v-col>
                  </v-row>
                  <v-row class="ma-3 align-center">
                    <div class="mr-5 "><span class="text-body-1 font-weight-medium">{{ translationsAgencyLogPayOut.user_withdrawal_amount }} </span></div>

                    <v-sheet max-width="100">
                      <v-text-field :rules="[withdrawalAmountRule(user)]" type="number" v-model="user.withdrawalAmount"
                                    outlined hide-details
                                    dense></v-text-field>
                    </v-sheet>
                  </v-row>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="1">
                  <v-row class="justify-center">
                    <v-icon @click="remove(user.id)" large color="primary">
                      mdi-delete
                    </v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

        </v-row>


        <v-row class="ma-0 mt-3 ">
          <v-col cols="12" class="pa-0">
            <v-alert v-model="alertErr" type="error">{{ alertText }}</v-alert>
          </v-col>
          <v-col cols="12" class="mb-5 pa-0">
            <div class="d-flex align-center justify-end">
              <v-btn class="mr-3" color="success" @click="sendWithdrawal">{{ translationsAgencyLogPayOut.btn_withdrawal }}</v-btn>
              <span> {{ translationsAgencyLogPayOut.total_label }}: {{ totalAmount }} $</span>
            </div>
          </v-col>


        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import {api} from "@/providers/api";
import {getAgencyById} from "@/api/agencyRequest";
import PreloaderPage from "@/components/PreloaderPage.vue";
import MyBreadcrumbs from "@/components/Breadcrumbs.vue";
import {mapGetters} from "vuex";

export default {
  name: "AgencyPayOut",
  components: {MyBreadcrumbs, PreloaderPage},
  // props: {
  //   items: {
  //     type: Array,
  //     default: () => [],
  //   },
  //   agency: {
  //     type: Object,
  //     default: () => {
  //     },
  //   },
  //   balance: {
  //     type: String,
  //     required: true
  //   }
  // },
  data() {
    return {
      loadingActivity: false,
      currentTab: 7,
      value: [],
      items: [],
      agency: [],
      loading: false,
      balance: '',
      agencyBalance: null,
      errorUser: false,
      alertPayOut: false,
      selected: false,
      alertErr: false,
      alertText: '',
      agencyName: '',

    }
  },
  computed: {
    ...mapGetters('Agencies', [
      'getterDataById', 'getterAgencyById'
    ]),
    translations() {
      return this.$t('translation')
    },
    translationsAgencyLog() {
      return this.translations.AGENCY.AGENCY_LOG
    },
    translationsAgencyLogPayOut() {
      return this.translationsAgencyLog.pay_out_page
    },
    currentAgency() {
      return this.getterAgencyById(+this.$route.params.id)
    },

    breadcrumbs() {
      return this.$auth.isAdmin ?  [
        { text: this.translations.NAVBAR.DASHBOARD.title, href: '/' },
        { text: this.translations.AGENCY.AGENCIES.title, href: '/agencies' },
        { text: `${this.currentAgency?.name || '-'} #${this.$route.params.id}`, href: 'staff' },
        { text: this.translationsAgencyLogPayOut.title, disabled: true,}
      ] : [
        {text: `${this.currentAgency?.name || '-'} #${this.$route.params.id}`, href: 'staff'},
        {text: this.translationsAgencyLogPayOut.title, disabled: true,}
      ];
    },
    totalAmount() {
      const totalAmount = this.value.reduce((total, item) => {
        const amount = parseFloat(item.withdrawalAmount || 0);
        return total + amount;
      }, 0);

      if (this.selected) {
        let allAmount = totalAmount + (this.agencyBalance === null ? 0 : parseFloat(this.agencyBalance))
        return parseFloat(allAmount.toFixed(2))
      }

      return totalAmount
    }
  },
  async created() {
    if (this.currentAgency?.name === undefined) {
      this.loadingActivity = true;
    }
    this.loading = true
    try {
      this.agency = await this.$store.dispatch("Agencies/fetchAgencyById", {id: this.$route.params.id})
      this.items = await api
          .get(`/admin/agencies/${this.$route.params.id}/users`, {
            params: {
              limit: 100,
              page: 1,
            }
          })
          .then(r => r.data);
      this.balance = this.$getBalance(this.agency.balance.amount)
    } catch (e) {

    } finally {
      this.loading = false
      this.loadingActivity = false
    }
  },
  mounted() {
    this.value.forEach(user => {
      this.$set(user, 'withdrawalAmount', 0);
    });
  },
  watch: {
    // value(val) {
    //    if(Object.keys(val).length > 0) {
    //      this.errorUser = false
    //    }
    // }
  },
  methods: {
    withdrawalAmountRule(user) {
      console.log('user', user)
      return (value) => {
        console.log('value', value)
        if (value > user.balance) {
          return `Withdrawal amount can't be greater than balance (${user.balance}$)`;
        }
        return true;
      };
    },
    withdrawalAmountAgencyRule() {
      return (user) => {
        if (+user > +this.$getBalance(this.balance)) {
          console.log('user', user, this.$getBalance(this.balance))
          return `Withdrawal amount can't be greater than balance (${this.$getBalance(this.balance)}$)`;
        }
        return true;
      };
    },
    allSelected() {
      this.value = this.items
    },
    removeSelected() {
      this.value = []
    },

    remove(id) {
      console.log(id, this.value)
      const index = this.value.findIndex(item => item.id === id);
      if (index !== -1) {
        this.value.splice(index, 1);
      }
    },


    async sendWithdrawal() {
      if (this.$refs.form.validate()) {
        const users = this.value
            .map(r => {
              if (r.withdrawalAmount !== undefined && +r.withdrawalAmount !== 0 && r.withdrawalAmount !== '') {
                console.log('user: ', r)
                return {
                  userId: r.id,
                  amount: parseFloat(r.withdrawalAmount)
                }
              }
            })
            .filter(r => r !== undefined && !isNaN(r.amount) && r.amount > 0)
        // console.log(this.agencyBalance === null ? 0 : parseFloat(this.agencyBalance), users)
        console.log('users: ', users)
        if (this.totalAmount < 200) {
          this.alertErr = true
          this.alertText = 'Minimum withdrawal amount - 200$'
          setTimeout(() => {
            this.alertErr = false
          }, 3000)
        } else {
          try {
            this.loading = true
            await api.post(`/admin/agencies/${this.$route.params.id}/withdrawal`, {
              agencyAmount: this.selected ? this.agencyBalance === null ? 0 : parseFloat(this.agencyBalance) : 0,
              payoutPairs: users
            })
            this.value.map(r => r.withdrawalAmount = 0)
            this.agency = await getAgencyById(parseInt(this.$route.params.id))
            this.removeSelected()
            this.items = await api
                .get(`/admin/agencies/${this.$route.params.id}/users`, {
                  params: {
                    limit: 100,
                    page: 1,
                  }
                })
                .then(r => r.data);
            this.balance = this.$getBalance(this.agency.balance.amount)
            this.agencyBalance = null

          } catch (e) {
            this.alertErr = true
            this.alertText = 'Withdrawal error'
            setTimeout(() => {
              this.alertErr = false
            }, 3000)
          } finally {
            this.loading = false
            this.alertPayOut = true
            setTimeout(() => {
              this.alertPayOut = false
            }, 3000)
          }

        }
      } else {
        this.alertErr = true
        this.alertText = 'Balance exceeded'
        setTimeout(() => {
          this.alertErr = false
        }, 3000)
      }

    },
    userFilter(item, queryText) {
      // console.log('item', item)
      const hasName = item.name.toLowerCase().includes(queryText.toLowerCase())
      const hasEmail = item.email.toLowerCase().includes(queryText.toLowerCase())
      return hasName || hasEmail
    }
  }
}
</script>

<style scoped lang="scss">
.amount-slider {
  width: 100%;
  max-width: 400px;

  @media (max-width: 1440px) {
    max-width: 200px;
  }
}

//.pay-out {
//  background: #f3f3f3;
//}
.alertSuccess {
  position: fixed;
  z-index: 10;
  right: 15px;
}
.preloader {
  position: absolute;
  top: 0;
}

.disabled {
  opacity: .6;
  pointer-events: none;
}
</style>