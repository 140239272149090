<template>
  <div class="all-snackbars">
    <v-snackbar
        v-for="(snackbar, index) in snackbarQueue"
        :key="index"
        :timeout="3000"
        v-model="snackbar.show"
        @input="handleSnackbarClose(index)"
        color="blue-grey"
        elevation="24"
    >
      <v-list-item
      >

        <v-list-item-content>
          <v-list-item-title>{{ snackbar.message }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon @click="hideSnackbar(index)">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-snackbar>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';


export default {
  name: "SnackbarsQueue",
  computed: {
    ...mapState({
      snackbarQueue: state => state.snackbarQueue,
    }),
  },
  methods: {
    ...mapActions(['removeFromSnackbarQueue']),


    handleSnackbarClose(index) {
      this.removeFromSnackbarQueue(index);
    },
    hideSnackbar(index) {
      this.snackbarQueue[index].show = false;
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep{
  .v-snack{
    justify-content: flex-end !important;
    height: unset !important;
    position: unset !important;

    .v-snack__content{
      padding: 0px !important;
    }
  }


}
.all-snackbars{
  bottom: 0;
  background: inherit;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  justify-content: flex-end;
}
</style>