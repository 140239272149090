<template>
  <div>
    <v-container class="my-5">

      <v-data-table
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :items-per-page="10"
          :headers="headers"
          :items="contact"
          item-key="id"
          show-expand
          class="elevation-1"
          :expanded.sync="expanded"
          :single-expand="singleExpand"
          @click:row="userOpen"
          :loading="contactLoading"
          :options.sync="contactOptions"
          :server-items-length="contactTotalItems"
      >
        <template v-slot:top>

          <DeleteModal :dialog="dialogDelete" @close-options="closeDelete" @save-options="deleteItemConfirm"/>

          <v-toolbar flat color="primary">
            <v-toolbar-title class="title">{{ translationsContactLog.title }}</v-toolbar-title>
          </v-toolbar>

        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div class="row">
              <v-col>


                <p class="text-h6">{{ translationsContactLog.expanded_item.message }}</p>
                <p>
                  {{ item.message }}
                </p>

              </v-col>
            </div>
            <div class="row" v-if="item.answered">
              <v-col>


                <p class="text-h6">{{ translationsContactLog.expanded_item.answer  }}</p>
                <p>
                  {{ item.answer }}
                </p>

              </v-col>
            </div>
            <div class="row" v-else>
              <v-col>


                <p class="text-h6">{{ translationsContactLog.expanded_item.answer }}</p>
                <v-textarea v-model="item.answer" filled placeholder="Answer message"/>
                <v-btn dark color="green"
                       @click="sendAnswer(item)">
                  Send
                </v-btn>
              </v-col>
            </div>

          </td>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <div class="user-img ">
            <!--            <img :src="$getAvatarImage(item.owner.profile.avatar)">-->
            {{ item.owner ? item.owner.name : '' }}
          </div>
        </template>


        <template v-slot:[`item.priority`]="{ item }">
          <div>
            <v-chip v-if="item.priority !== ''"
                    :color="getColor(item.priority)"
                    dark
            >
              {{ item.priority }}
            </v-chip>
            <div v-else>
              -
            </div>
          </div>


        </template>

        <template v-slot:[`item.answered`]="{ item }">
          <div>
            <v-chip
                :color="item.answered ? 'green' : 'orange'"
                dark
            >
              {{ item.answered ? 'yes' : 'no' }}
            </v-chip>

          </div>


        </template>

        <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon
              small
              @click.stop="deleteDialog(item)"
          >
            mdi-delete
          </v-icon>
        </template>

      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import {getUserFlirt, getUserId, getUserLike} from "@/api/usersRequest";

import router from "@/routes/router";
import {deleteContact, getContact, sendAnswer} from "@/api/feedbackRequest";
import DeleteModal from "@/components/DeleteModal";


export default {
  name: "ContactLog",
  components: {DeleteModal},
  data() {
    return {
      contactLoading: true,
      contactOptions: {},
      contactTotalItems: 0,
      expanded: [],
      singleExpand: true,
      contact: [],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',

        },

        {
          text: 'User',
          value: 'owner.name',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Email',
          value: 'email',
        },

        {
          text: 'Subject',
          value: 'subject',
        },

        {
          text: 'Created',
          value: 'createdAt',
        },
        {
          text: 'Priority',
          value: 'priority',
        },
        {
          text: 'Answered',
          value: 'answered',
        },
        {
          text: 'Action',
          value: 'action',
        },
      ],

      deleteItem: '',
      dialogDelete: false
    };
  },
  computed: {
    translations() {
      return this.$t('translation')
    },
    translationsContactLog() {
      return this.translations.CONTACT_LOG.table
    }
  },
  created() {
    this.headers = [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',

      },

      {
        text: this.translationsContactLog.headers.user,
        value: 'owner.name',
      },
      {
        text: this.translationsContactLog.headers.name,
        value: 'name',
      },
      {
        text: this.translationsContactLog.headers.email,
        value: 'email',
      },

      {
        text: this.translationsContactLog.headers.subject,
        value: 'subject',
      },

      {
        text: this.translationsContactLog.headers.created_at,
        value: 'createdAt',
      },
      {
        text: this.translationsContactLog.headers.priority,
        value: 'priority',
      },
      {
        text: this.translationsContactLog.headers.answered,
        value: 'answered',
      },
      {
        text: this.translationsContactLog.headers.action,
        value: 'action',
      },
    ]
    this.fetchContact()
  },
  watch: {
    contactOptions: {
      handler(change) {
        console.log('contact payments', change)
        this.syncContactFromApi()
      },
      deep: true
    }
  },
  methods: {
    async syncContactFromApi() {
      this.contactLoading = true
      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.contactOptions
        const response = await getContact({
          params: {
            ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : [],
            limit: limit === -1 ? 100 : limit,
            page
          }
        })
        const {data: items, total} = response

        this.contact = items;
        this.contactTotalItems = total
      } catch (e) {
        console.log('error contact', e)
      } finally {
        this.contactLoading = false
      }
    },
    async sendAnswer(item) {
      const r = await sendAnswer({
        id: item.id,
        response: item.answer
      })
      this.syncContactFromApi()
    },


    getColor(status) {
      if (status === 'yes') {
        return 'green'
      } else {
        return 'orange'
      }
    },
    async fetchContact() {
      const {data} = await getContact()
      this.contact = data
    },

    async deleteItemConfirm() {
      await deleteContact(this.deleteItem.id)
      this.fetchContact()
      this.dialogDelete = false
    },
    deleteDialog(item) {
      this.dialogDelete = true
      this.deleteItem = item
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async userOpen(user) {
      const dataUser = await getUserId(user.owner.id)
      const dataLike = await getUserLike(user.owner.id);
      const dataFlirt = await getUserFlirt(user.owner.id);
      const obj = {
        like: {
          dataLike
        },
        flirt: {
          dataFlirt
        }
      };
      router.push({name: 'user-log', params: {id: user.owner.id, targetUser: dataUser, obj: obj}})
    },

  },
}
</script>

<style scoped lang="scss">
.processing {
  color: white;
}

.row {
  display: flex;
  flex-wrap: nowrap;
  margin: 5px;

  .user-img {
    margin-right: 30px;
  }

  .v-btn {
    margin-right: 5px;
    margin-bottom: 15px;
  }
}

.title {
  color: #f4f6f9;
}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.user-img {
  display: flex;
  align-items: center;
}
</style>
