<template>
  <v-container class="my-5">
    <v-row>
      <v-col  cols="6">
        <div class="d-flex align-center">
          <v-file-input
              accept="image/gif"
              label="Emoji input"
              v-model="file"
              @change="changeInput"
          ></v-file-input>
          <v-btn @click="saveEmoji" v-if="file" class="ml-2" outlined color="success">Save</v-btn>
        </div>
      </v-col>
    </v-row>

    <v-data-table
        :footer-props="{'items-per-page-options': [10, 50, 100]}"
        :items-per-page="50"
        :loading="loading"
        :headers="headers"
        :items="items"
        class="elevation-1"
        key="id"

    >
      <template v-slot:top>
        <v-toolbar
            flat
            color="primary"
            dark
        >
          <v-toolbar-title>Emoji</v-toolbar-title>

          <DeleteModal :dialog="dialogDelete" @close-options="closeDelete" @save-options="deleteItemConfirm"/>
        </v-toolbar>
      </template>

      <template v-slot:[`item.file.s3Url`]="{ item }">
        <v-img width="50" height="50" :src="item.file.s3Url" />
      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-icon
            @click.stop="showDeleteModal(item)"
        >
          mdi-delete
        </v-icon>
      </template>

    </v-data-table>
  </v-container>
</template>

<script>
import {api} from "@/providers/api";
import DeleteModal from "@/components/DeleteModal.vue";

export default {
  name: "EmojiPage",
  components: {DeleteModal},
  data: () => ({
    items: [],
    headers: [
      {
        text: 'Id',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      { text: 'Emoji', sortable: false, value: 'file.s3Url' },
      { text: 'Actions', sortable: false, value: 'actions'}
    ],
    loading: false,
    file: null,
    fileUrl: '',
    selectedFileId: null,
    dialogDelete: false,
  }),
  async created() {
    await this.fetchEmoji()
  },
  methods: {
    async deleteItemConfirm() {
      await api.delete(`/emoji/${this.selectedFileId}`)
      this.dialogDelete = false
      await this.fetchEmoji()
    },
    showDeleteModal(item) {
      this.selectedFileId = item.id
      this.dialogDelete = true
    },
    closeDelete() {
      this.dialogDelete = false
    },
    async fetchEmoji() {
      this.loading = true
      this.items = await api.get('/emoji')
      this.loading = false
    },
    changeInput() {
      // this.displaySelectedImage()
    },

    async saveEmoji() {
      this.loading = true
      let formData = new FormData();
      formData.append('image', this.file);
      await api.post('/emoji', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      this.loading = false
      await this.fetchEmoji()
      this.file = null
    }
    // displaySelectedImage() {
    //   const reader = new FileReader();
    //
    //   reader.onload = (event) => {
    //     this.fileUrl = event.target.result;
    //   };
    //
    //   reader.readAsDataURL(this.file);
    // },
  }
}
</script>

<style scoped lang="scss">

</style>