import {api} from "@/providers/api";


export async function getFAQ() {
  return await api.get('/faq')
}

export async function deleteFAQById(id) {
  return await api.delete(`/faq/${id}`);
}

export async function patchEditFAQ(id, title) {
  return await api.patch(`/faq/${id}`, {title: title});
}

export async function postFAQ(title) {
  return await api.post('/faq', {title: title});
}

export async function deleteQuestion(id) {
  return await api.delete(`/faq/0/${id}`);
}

export async function editQuestion(id, itemId, question, answer) {
  return await api.patch(`/faq/${id}/${itemId}`, {
    question: question,
    answer: answer
  });
}
export async function addQuestion(id, question, answer) {
  return await api.post(`/faq/${id}`, {
    question: question,
    answer: answer
  });
}