var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{key:"id",staticClass:"elevation-1",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalItems,"options":_vm.options,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.adminViewed",fn:function(ref){
var item = ref.item;
return [_c('CirclePulsating',{attrs:{"pulsing":!item.adminViewed}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.createdAt),'Pp')))]}},{key:"item.chatDate.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":_vm.getColor(item.chatDate.status),"small":""}},[_vm._v(_vm._s(item.chatDate.status))])]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.chatDate.from.name))]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.destinationUser.name))]}},{key:"item.viewed",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":!item.chatDate.viewed ? 'red' : 'green'}},[_vm._v(_vm._s(!item.chatDate.viewed ? 'mdi-eye-remove' : 'mdi-eye'))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }