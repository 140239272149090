<template>
  <div>
    <v-data-table
        :footer-props="{'items-per-page-options': [10, 50, 100]}"
        :items-per-page="10"
        :headers="headers"
        :items="items"
        :loading="loading"
        class="elevation-1"
        key="id"
    >
      <template v-slot:[`item.startDate`]="{ item }">{{ new Date(item.startDate) | date('Pp') }}</template>
      <template v-slot:[`item.endDate`]="{ item }">{{ new Date(item.endDate) | date('Pp') }}</template>

      <template v-slot:[`item.nextPaymentAt`]="{ item }">
        <span v-if="item.nextPaymentAt">{{ new Date(item.nextPaymentAt) | date('Pp') }}</span>
      </template>

      <template v-slot:[`item.firstPaymentAt`]="{ item }">
        <span v-if="item.firstPaymentAt">{{ new Date(item.firstPaymentAt) | date('Pp') }}</span>
      </template>
      <template v-slot:[`item.category`]="{ item }">
        <v-chip color="orange" dark small>
          {{
            item.isCanceled
                ? 'Canceled'
                : !item.hasFirstPayment
                    ? 'Free Trial'
                    : (new Date(item.endDate) > new Date() ? 'Ongoing' : 'Subscription Expired')
          }}
        </v-chip>
      </template>

    </v-data-table>
  </div>

</template>

<script>
import {api} from "@/providers/api";

export default {
  name: "UserLogSubscription",
  props: {
    refresh: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    headers: [
      {value: 'id', text: 'Id', sortable: false},
      {value: 'membershipTariff.name', text: 'Tariff', sortable: false},
      {value: 'category', text: 'Category', sortable: false},
      {value: 'startDate', text: 'Start', sortable: false},
      {value: 'endDate', text: 'End', sortable: false},
      {value: 'firstPaymentAt', text: 'First Payment', sortable: false},
      {value: 'nextPaymentAt', text: 'Next Payment', sortable: false},
    ],
    loading: true,
    items: []
  }),
  watch: {
    refresh(status) {
      if (status) {
        this.fetchSub()
      }
    },
  },
  async created() {
    await this.fetchSub()
  },
  methods: {
    async fetchSub() {
      this.loading = true
      this.items = await api.get(`/admin/users/${this.$route.params.id}/membership`)
      this.$emit('refreshStop', 'sub')
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>