import Vue from "vue";
import { api } from "@/providers/api";
import {getAgencyById, getTotalUsersInAgencyById} from "@/api/agencyRequest";

const state = {
  agencies: [],
  agenciesData: {},
  allAgencies: []
};

const getters = {
  getterAgencyById: (state) => (id) => state.agencies.find((e) => e.id === id),
  getterDataById: (state) => (key, id) => {
    if (state.agenciesData[key] && state.agenciesData[key][id]) {
      return state.agenciesData[key][id]
    } else {
      return []
    }
  }
};
const actions = {
  async fetchAllAgencies({ commit }) {
    let res;
    let page = 1
    let pageCount;
    let arr = []
    do {
      res = await api.get('admin/agencies', {params: { limit: 100, page: page}});
      pageCount = res.pageCount
      page++
      arr.push(...res.data)
    } while (pageCount >= page);
    commit('SET_ALL_AGENCIES', arr);

  },
  addData({ commit }, { key, id, value }) {
    commit('ADD_DATA', { key, id, value })
  },
  async fetchAgencyById({ commit, getters }, { id }) {
    if (!getters.getterAgencyById(+id)) {
      const response = await getAgencyById(Number(id));
      const totalMembers = await getTotalUsersInAgencyById(Number(id));
      commit('ADD_AGENCY', {...response, totalMembers});
      return response;
    }
    else {
      const response = await getAgencyById(Number(id));
      const totalMembers = await getTotalUsersInAgencyById(Number(id));
      commit('UPDATE_AGENCY', {...response, totalMembers});
      return response;
    }
  }
};
const mutations = {
  SET_ALL_AGENCIES(state, payload) {
    state.allAgencies = payload;
  },
  ADD_DATA(state, { key, id, value }) {
    if (!state.agenciesData.hasOwnProperty(key)) {
      state.agenciesData[key] = {}
    }

    if (state.agenciesData[key].hasOwnProperty(id)) {
      state.agenciesData[key][id] = value
    } else {
      state.agenciesData[key][id] = value
    }
    console.log('state.agenciesData Finish', state.agenciesData)
  },

  ADD_AGENCY(state, agency) {
    state.agencies.push(agency);
  },
  UPDATE_AGENCY(state, agency) {
    console.log('agency', agency)
    const index = state.agencies.findIndex((e) => e.id === agency.id);
    if (index === -1) return;
    state.agencies[index] = agency;
  },
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
