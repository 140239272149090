<template>
  <v-col class="pt-5">
    <v-data-table
        :footer-props="{'items-per-page-options': [10, 50, 100]}"
        :items-per-page="10"
        :headers="botHeader"
        :server-items-length="totalItems"
        :options.sync="options"
        :loading="loading"
        :items="botItems"
    >
      <template v-slot:top>

        <v-dialog v-model="dialogAdd">
          <v-card>
            <v-card-title>{{ formTitle }}</v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                        placeholder="Input messages"
                        v-model="editBot.message">
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
              >
                {{ translations.GLOBAL_VALUE.cancel }}
              </v-btn>
              <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
              >
                {{ translations.GLOBAL_VALUE.save }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <DeleteModal :dialog="dialogDelete" @close-options="closeDelete" @save-options="deleteItemConfirm" />


        <v-toolbar flat color="primary" dark>
          <v-toolbar-title class="title">{{ translationsBotTemplate.title }}</v-toolbar-title>
<!--          <v-spacer></v-spacer>-->
<!--          <v-btn outlined @click="dialogAdd = true">{{ translationsBotTemplate.btn_new_bot_text }}</v-btn>-->
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-icon @click="editItem(item)" small class="mr-2">
          mdi-pencil
        </v-icon>


        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>

    </v-data-table>
  </v-col>
</template>

<script>
  import {
    createChatBotMessageTemplate, deleteChatBotMessageTemplate,
    updateChatBotMessageTemplate
  } from "@/api/chat-bot.api";
  import {api} from "@/providers/api";
  import DeleteModal from "@/components/DeleteModal.vue";

  export default {
    name: "BotTableTemplates",
    components: {DeleteModal},
    data: () => ({
      dialogAdd: false,
      dialogDelete: false,
      editIndex: -1,
      deleteIndex: null,
      editBot: {
        templateContent: ''
      },
      defaultItem: {
        templateContent: ''
      },


      botHeader: [
        {
          text: 'ID',
          value: 'id',
          sortable: false,
          align: 'start',
        },
        {
          text: 'Text messages',
          value: 'message',
          sortable: false,

        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ],
      botItems: [],
      options: {},
      totalItems: 0,
      loading: false,
    }),
    watch: {
      options: {
        handler() {
          this.syncDataFromApi()
        },
        deep: true
      },
      dialogAdd(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },
    },
    computed: {
      translations() {
        return this.$t('translation')
      },
      translationsBotTemplate() {
        return this.translations.BOT.table_template
      },
      formTitle() {
        return this.editIndex === -1 ? 'New bot' : 'Edit bot';
      },
    },
    created() {
      this.botHeader = [
        {
          text: 'ID',
          value: 'id',
          sortable: false,
          align: 'start',
        },
        {
          text: this.translationsBotTemplate.headers.text,
          value: 'message',
          sortable: false,

        },

        {
          text: 'Actions',
          value: 'actions',
          sortable: false,

        },

      ]

    },

    methods: {
      async syncDataFromApi() {
        this.loading = true
        try {
          const {sortBy, sortDesc, page, itemsPerPage: limit} = this.options
          const response = await api.get(`/chats-bot/static-templates`, {
            params: {
              ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : [],
              limit: limit === -1 ? 100 : limit,
              page,

            },

          })

          console.log('response', response)
          this.botItems = response.items;
          console.log('botItems', this.botItems)
          this.totalItems = response.meta.totalItems
        } catch (e) {
          console.log('Error', e)
        } finally {
          this.loading = false
        }
      },
      close() {
        this.dialogAdd = false;
        this.$nextTick(() => {
          this.editBot = Object.assign({}, this.defaultItem);
        });
      },
      async save() {
        console.log('edit index save = ', this.editIndex)
        // if (this.editIndex > -1) {
          await updateChatBotMessageTemplate(+this.botItems[this.editIndex]?.id, this.editBot.message)
          Object.assign(this.botItems[this.editIndex], this.editBot);
        // } else {
        //   console.log('created')
        //   this.botItems.push(await createChatBotMessageTemplate(this.editBot));
        // }
        this.close();
      },
      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editBot = Object.assign({}, this.defaultItem);
          this.editIndex = -1;
        });
      },
      async deleteItemConfirm() {
        await deleteChatBotMessageTemplate(this.deleteIndex)
        this.botItems = this.botItems.filter(e => e.id !== this.deleteIndex);
        this.closeDelete();
      },
      editItem(item) {
        this.dialogAdd = true;
        this.editIndex = this.botItems.indexOf(item);
        this.editBot = Object.assign({}, item);

      },
      deleteItem(item) {
        this.dialogDelete = true;
        this.deleteIndex = item.id;
      },

    }
  }
</script>

<style scoped>

</style>