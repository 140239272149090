<template>
  <div>
    <v-container :class="{'disabled' : activityLoading}">
      <MyBreadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-tabs v-model="currentTab" class="mb-3" background-color="#f4f6f9" color="primary">
        <v-tab to="staff">
          Staff
        </v-tab>
        <v-tab to="transactions">
          {{ translationsAgencyLog.breadcrumbs.transactions }}
        </v-tab>
        <v-tab to="chats">
          Chats
        </v-tab>
        <v-tab to="activities">
          {{ translationsAgencyLog.breadcrumbs.activities }}

        </v-tab>
        <v-tab v-if="!this.$auth.isAdmin" to="withdrawal">
          {{ translationsAgencyLog.breadcrumbs.withdrawal }}
        </v-tab>
        <v-tab to="stats">
          {{ translationsAgencyLog.breadcrumbs.stats }}
        </v-tab>
        <v-tab to="settings">
          {{ translationsAgencyLog.breadcrumbs.settings }}
        </v-tab>
        <v-tab to="pay-out">
          {{ translationsAgencyLog.breadcrumbs.pay_out }}
        </v-tab>
      </v-tabs>
    </v-container>
    <PreloaderPage class="preloader"  v-if="loading || activityLoading"/>
    <v-container v-if="getterDataById('withdrawal', this.$route.params.id).items ? getterDataById('withdrawal', this.$route.params.id).items.length >= 0 :  true" class="my-5">
      <v-data-table
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :items-per-page="10"
          :headers="headers"
          :items="getterDataById('withdrawal', this.$route.params.id).items ? getterDataById('withdrawal', this.$route.params.id).items : []"
          item-key="id"
          class="elevation-1"
          :options.sync="optionsWithdrawal"
          :server-items-length="getterDataById('withdrawal', this.$route.params.id).total"
          @update:page="tset"
      >

        <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>

        <template v-slot:[`item.status`]="{ item }">
          <div>
            <v-chip
                :color="getColor(item.status)"
                dark
                small
            >
              {{ item.status }}
            </v-chip>
          </div>
        </template>

        <template v-slot:[`item.summaryAmount`]="{ item }">
          <div>
            <v-chip
                color="green"
                dark
                small
            >
              {{ item.summaryAmount }}
            </v-chip>
          </div>
        </template>

        <!--        <template v-slot:[`item.membersAmount`]="{ item }">-->
        <!--          <div>-->
        <!--            <v-chip-->
        <!--                color="blue"-->
        <!--                dark-->
        <!--                small-->
        <!--            >-->
        <!--              {{ item.membersAmount }}-->
        <!--            </v-chip>-->
        <!--          </div>-->
        <!--        </template>-->
        <!--        <template v-slot:[`item.agencyAmount`]="{ item }">-->
        <!--          <div>-->
        <!--            <v-chip-->
        <!--                color="blue"-->
        <!--                dark-->
        <!--                small-->
        <!--            >-->
        <!--              {{ item.agencyAmount }}-->
        <!--            </v-chip>-->
        <!--          </div>-->
        <!--        </template>-->


      </v-data-table>

    </v-container>
  </div>
</template>

<script>
import {getWithdrawals} from '@/api/transactionsRequest';
import MyBreadcrumbs from "@/components/Breadcrumbs.vue";
import PreloaderPage from "@/components/PreloaderPage.vue";
import {getAgencyById} from "@/api/agencyRequest";
import {mapGetters} from "vuex";
import {api} from "@/providers/api";

export default {
  name: 'AgencyLogTableWithdrawer',
  components: {PreloaderPage, MyBreadcrumbs},
  data: () => ({
    currentTab: 4,
    showBtn: false,
    expanded: [],
    singleExpand: true,
    withdrawal: [],
    loading: false,
    activityLoading: false,
    agencyName: '',
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'Agency',
        sortable: false,
        value: 'agency.name'
      },
      {
        text: 'Agency amount',
        sortable: false,
        value: 'agencyAmount',
      },
      {
        text: 'Members amount',
        sortable: false,
        value: 'membersAmount',
      },
      {
        text: 'Summary amount',
        sortable: false,
        value: 'summaryAmount',
      },
      {
        text: 'Created',
        value: 'createdAt',
      },
      {
        text: 'Status',
        sortable: false,
        value: 'status',
      },
    ],
    totalWithdrawalItem: 0,
    loadingWithdrawal: false,
    optionsWithdrawal: {},
  }),
  async created() {
    this.headers = [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: this.translationsAgencyLogWithdrawal.headers.agency,
        sortable: false,
        value: 'agency.name'
      },
      {
        text: this.translationsAgencyLogWithdrawal.headers.agency_amount,
        sortable: false,
        value: 'agencyAmount',
      },
      {
        text: this.translationsAgencyLogWithdrawal.headers.members_amount,
        sortable: false,
        value: 'membersAmount',
      },
      {
        text: this.translationsAgencyLogWithdrawal.headers.summary_amount,
        sortable: false,
        value: 'summaryAmount',
      },
      {
        text: this.translationsAgencyLogWithdrawal.headers.created_at,
        value: 'createdAt',
      },
      {
        text: this.translationsAgencyLogWithdrawal.headers.status,
        sortable: false,
        value: 'status',
      },
    ]
    if (this.currentAgency?.name === undefined) {
      this.activityLoading = true;
      await this.$store.dispatch("Agencies/fetchAgencyById", {id: this.$route.params.id})
      this.activityLoading = false;
    }
    // await this.fetchAgencyWithdrawal();
  },
  mounted() {
  },
  computed: {
    ...mapGetters('Agencies', [
      'getterDataById', 'getterAgencyById'
    ]),
    translations() {
      return this.$t('translation')
    },
    translationsAgencyLog() {
      return this.translations.AGENCY.AGENCY_LOG
    },
    translationsAgencyLogWithdrawal() {
      return this.translationsAgencyLog.table_withdrawal
    },
    currentAgency() {
      return this.getterAgencyById(+this.$route.params.id)
    },

    breadcrumbs() {
      return this.$auth.isAdmin ?  [
        { text: this.translations.NAVBAR.DASHBOARD.title, href: '/' },
        { text: this.translations.AGENCY.AGENCIES.title, href: '/agencies' },
        { text: `${this.currentAgency?.name || '-'} #${this.$route.params.id}`, href: 'staff' },
        { text: this.translationsAgencyLogWithdrawal.title, disabled: true,}
      ] : [
        {text: `${this.currentAgency?.name || '-'} #${this.$route.params.id}`, href: 'staff'},
        {text: this.translationsAgencyLogWithdrawal.title, disabled: true,}
      ];
    },
  },
  watch: {
    optionsWithdrawal: {
      handler(change) {
        console.log('change withdrawal', change)
          this.fetchAgencyWithdrawal()

      },
      deep: true
    }
  },
  methods: {
    async tset(val) {
      console.log('startTest', val)
    },
    async fetchAgencyWithdrawal() {
      console.log('startFetch')
      try {
        this.loading = true
            const { sortBy, sortDesc, page, itemsPerPage: limit} = this.optionsWithdrawal

        const response = await api.get('/admin/agencies/withdrawal', {
              params: {
                ...sortBy.length ? { sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC'))) } : [],
                limit: limit === -1 ? 100: limit,
              page
              }
            });
            console.log('resposne', response)

            const { data: items, total} = response

        this.withdrawal = items;
        this.totalWithdrawalItem = total
        const newPage = response.page
        await this.$store.dispatch("Agencies/addData", { key: 'withdrawal', id: this.$route.params.id,
          value: {items: this.withdrawal,  total: this.totalWithdrawalItem, page: newPage}  })


      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
      console.log('fetchAgencyWithdrawal');
    },
    // async syncWithdrawalFromApi() {
    //   this.loadingWithdrawal = true
    //   try {
    //     const { sortBy, sortDesc, page, itemsPerPage: limit} = this.optionsWithdrawal
    //     const response = await api.get('/admin/agencies/withdrawal', {
    //       params: {
    //         ...sortBy.length ? { sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC'))) } : [],
    //         limit: limit === -1 ? 100: limit,
    //         page
    //       }
    //     });
    //     console.log('resposne', response)
    //
    //     const { data: items, total } = response
    //
    //     this.withdrawal = items;
    //     this.totalWithdrawalItem = total
    //     await this.$store.dispatch("Agencies/addData", { key: 'withdrawal', id: this.$route.params.id, value: this.withdrawal  })
    //
    //   }
    //   catch (e) {
    //     console.log('error' + JSON.stringify(e))
    //   }
    //   finally
    //   {
    //     this.loadingWithdrawal = false
    //   }
    // },

    getColor(status) {
      if (status === 'PENDING') {
        return "orange";
      } else if (status === 'PROCESSING') return 'blue';
      else if (status === 'APPROVED') return 'green';
      else if (status === 'REJECTED') return 'red';
      else return 'purple';
    },

  }
};
</script>

<style scoped lang="scss">
.row {
  max-width: 350px;
  display: flex;
  flex-wrap: nowrap;
  margin: 5px;

  .user-img {
    margin-right: 30px;
  }

  .v-btn {
    margin-right: 5px;
    margin-bottom: 15px;
  }
}
.preloader {
  position: absolute;
  top: 0;
}
.disabled {
  opacity: .6;
  pointer-events: none;
}
</style>
