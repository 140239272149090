var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.withdrawalLoading)?_c('PreloaderPage'):_vm._e(),_c('v-container',{staticClass:"my-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"headers":_vm.headers,"page":_vm.currentDataWithdrawal().page,"items":_vm.currentDataWithdrawal().items,"item-key":"id","show-expand":"","expanded":_vm.expanded,"single-expand":_vm.singleExpand,"options":_vm.optionsWithdrawal,"server-items-length":_vm.currentDataWithdrawal().total},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.optionsWithdrawal=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.translationsWithdrawal.title))])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"row"},[_c('v-col',[_c('v-select',{attrs:{"outlined":"","items":_vm.items},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}}),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.successStatus(item.id, item.status)}}},[_vm._v(_vm._s(_vm.translations.GLOBAL_VALUE.save))])],1)],1)])]}},{key:"item.agency.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"agency-name",on:{"click":function($event){$event.stopPropagation();return _vm.openAgencyPage(item.agency.id)}}},[_vm._v(_vm._s(item.agency.name))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"item.summaryAmount",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-chip',{attrs:{"color":"green","dark":"","small":""}},[_vm._v(" "+_vm._s(item.summaryAmount)+" ")])],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.createdAt),'Pp')))]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }