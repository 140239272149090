<template>
  <div>
    <v-container class="my-5">
      <v-data-table
          :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 50, 100]
      }"
          :items-per-page.sync="itemsPerPage"
          :headers="headers"
          :items="users"
          class="elevation-1"
          :server-items-length="totalItems"
          :options.sync="tableOptions"
          :loading="tableLoading"
          show-expand
          item-key="id"
          :expanded.sync="expanded"
          :single-expand="singleExpand"
          :page.sync="page"

      >
        <template v-slot:top>
          <v-toolbar flat color="primary">
            <v-toolbar-title class="title">Letter Log</v-toolbar-title>
            <v-spacer/>
            <v-btn dark icon @click="toggleSearchFields">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-toolbar>

          <TableSearchFields
              v-if="showSearchFields"
              :searchFields="searchFields"
              @add-field="addSearchField"
              @remove-field="removeSearchField"
              @update-fields="updateSearchCriteria"
              @clear-fields="clearFields"
          />

        </template>
        <template v-slot:[`item.from`]="{ item }">
          <div class="user-img" @click="userOpen(item.from)">
            <img :src="$getAvatarImage(item.from.profile.avatar)">
            {{ $getUserName(item.from) }}

            <v-icon
                v-if="item.from.id !== me.id && !$auth.isAdmin"
                class="ml-3"
                color="primary"
                @click.stop="$switchProfileUser(item.from.id)"
            >
              mdi-account-arrow-left
            </v-icon>
          </div>
        </template>

        <template v-slot:[`item.to`]="{ item }">
          <div class="user-img" @click="userOpen(item.to)">
            <img :src="$getAvatarImage(item.to.profile.avatar)">
            {{ $getUserName(item.to) }}
            <v-icon
                v-if="item.to.id !== me.id && !$auth.isAdmin"
                class="ml-3"
                color="primary"
                @click.stop="$switchProfileUser(item.to.id)"
            >
              mdi-account-arrow-left
            </v-icon>
          </div>

        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div class="ma-5">
              <p v-html="item.message.text"></p>


              <div v-if="item.message.photos.filter( e => e.file !== null ).length > 0">
                <v-divider class="mt-2 mb-2"></v-divider>
                <img v-for="img in item.message.photos" :key="img.id" :src="img.file.s3Url" v-if="img.file" alt=""
                     class="introductions-photos"/>
              </div>
            </div>


          </td>
        </template>

        <template v-slot:[`item.message`]="{ item }">
          <div class="introductions-text">
            {{ getTextWithoutTags(item.message.text) }}
          </div>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>
        <template v-slot:[`item.photo`]="{ item }">
          <v-chip small dark
                  :color="item.message.photos.filter( e => e.file !== null ).length > 0 ? 'green' : 'orange'">
            {{ item.message.photos.filter(e => e.file !== null).length > 0 ? 'yes' : 'no' }}
          </v-chip>
        </template>
        <template v-slot:footer>
          <div class="input-page">
            <v-row align="center" justify="start" align-content="center" class="mx-2">
              <v-col cols="12" md="3">
                <v-text-field
                    single-line
                    hide-details
                    label="Go to page"
                    v-model="pageInput"
                    @change="updatePage"
                    type="number"
                    min="1"
                    :max="pageCount"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  Pages: {{ page }} / {{ pageCount }}
                </span>
              </v-col>
            </v-row>
          </div>

        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>


import {mapGetters} from 'vuex';
import {api} from "@/providers/api";
import TableSearchFields from "@/components/TableSearchFields.vue";

export default {
  name: 'LikeLog',
  components: {TableSearchFields},
  data() {
    return {
      expanded: [],
      singleExpand: true,
      tableOptions: {},
      totalItems: 0,
      tableLoading: true,
      dialogDelete: false,
      deleteId: '',
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',

        },

        {
          text: 'FROM',
          value: 'from',
          sortable: true,
        },
        {
          text: 'TO',
          value: 'to',
          sortable: true,
        },
        {
          text: 'PHOTO',
          value: 'photo',
          sortable: false,
        },
        {
          text: 'MESSAGE',
          value: 'message',
          sortable: false,
          width: '250'
        },
        {
          text: 'CREATED',
          value: 'createdAt',
        },

      ],
      users: [],
      page: 1,
      pageInput: 1,
      itemsPerPage: 10,

      showSearchFields: false,
      searchFields: {
        id: [{value: '', multiple: true, name: 'id'}],
        from: [{value: '', multiple: true, name: 'from'}],
        to: [{value: '', multiple: true, name: 'to'}],
        message: [{value: '', multiple: false, name: 'message'}],
        created: [{value: '', multiple: false, name: 'created'}]
      },
      searchCriteria: []
    };
  },

  created() {

  },
  watch: {
    tableOptions: {
      handler(change) {
        console.log('change payments', change)
        this.syncIntroductionsFromApi()
      },
      deep: true
    },
    page(newVal) {
      this.pageInput = newVal;
    },
  },
  computed: {
    ...mapGetters('User', ['me']),
    pageCount() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },

  methods: {
    clearFields() {
      this.searchFields = {
        id: [{value: '', multiple: true, name: 'id'}],
        from: [{value: '', multiple: true, name: 'from'}],
        to: [{value: '', multiple: true, name: 'to'}],
        message: [{value: '', multiple: false, name: 'message'}],
        created: [{value: '', multiple: false, name: 'created'}]
      }
    },
    toggleSearchFields() {
      this.showSearchFields = !this.showSearchFields;
    },
    addSearchField(data) {
      const {searchFields, category} = data
      this.searchFields = searchFields
      this.searchFields[category].push({value: ''});
    },
    removeSearchField(data) {
      const {category, index} = data
      this.searchFields[category].splice(index, 1);
    },
    async updateSearchCriteria(data) {
      const {searchFields, searchCriteria} = data
      this.searchFields = searchFields
      this.searchCriteria = searchCriteria
      if (this.page !== 1) {
        this.page = 1
      } else {
        await this.syncIntroductionsFromApi();
      }
    },
    updatePage() {
      let page = parseInt(this.pageInput, 10);
      if (page > 0 && page <= this.totalItems) {
        this.page = page;
      } else {
        this.pageInput = this.page;
      }
    },
    async userOpen(user) {
      this.$router.push({name: 'user-log', params: {id: user.id}})
    },

    getTextWithoutTags(htmlString) {
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = htmlString
      return tempDiv.innerText
    },
    async syncIntroductionsFromApi() {
      this.tableLoading = true
      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.tableOptions
        const response = await api.get('/admin/introductions', {
          params: {
            ...sortBy.length ? {
              createdAtSort: sortBy.map((s, i) => (sortDesc[i] ? 'DESC' : 'ASC'))[0],
              sortField: sortBy[0]
            } : '',
            limit: limit === -1 ? 100 : limit,
            page,
            ...this.searchCriteria
          },

        })
        console.log(response)

        const {data: items, total} = response

        this.users = items;
        this.totalItems = total
      } catch (e) {
        console.log('introductions error', e)
      } finally {
        this.tableLoading = false
      }
    },


    closeDelete() {
      this.dialogDelete = false;
    },

  }
};
</script>

<style scoped lang="scss">
.introductions-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 200px !important;
}

.introductions-photos {
  height: 150px !important;
  width: 250px !important;
  object-fit: cover;
  margin: 0px 15px 15px 0px;
}


img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.user-img {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.title {
  color: #f4f6f9;
}

.photo-img {
  margin-top: 3px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    object-fit: cover;
  }

}
</style>
