<template>
  <v-dialog v-model="_dialog" max-width="500px">
    <v-card>
      <v-card-title class="text-h5"> Stop mailing? </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeModal"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="saveClick"> OK </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "StopNotificationsCampaignModal",
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    _dialog: {
      get: function () {
        return this.dialog;
      },
      set: function () {
        this.$emit('close-options');
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-options')
    },
    saveClick() {
      this.$emit('save-options')
    }
  }
}
</script>

<style scoped>

</style>