<template>
  <v-app>
    <v-main>
      <MyNavbar v-if="!$route.meta.hideNavbar">
      </MyNavbar>
      <router-view></router-view>
      <SnackbarsQueue/>
    </v-main>
  </v-app>
</template>

<script>

import MyNavbar from "@/components/Navbar";
import {api} from "@/providers/api";
import SnackbarsQueue from "@/components/SnackbarsQueue.vue";
import {getAuthMe} from "@/api/authRequest";
export default {
  name: 'App',

  components: {
    SnackbarsQueue,
    MyNavbar
  },

  data: () => ({
    //
  }),

  async beforeCreate() {
    await this.$store.dispatch('fetchTranslation')
    const translationCode = this.$store.state.translation.code
    this.$i18n.setLocaleMessage(translationCode, this.$store.state.translation)
    this.$i18n.locale = translationCode
  },

  async created() {
    await this.checkToken()
  },
  methods: {
    async checkToken() {
      const token = localStorage.getItem("access_token");
      if (token) {
        try {
          await getAuthMe()
        }
        catch (e) {
          console.log('error message', e.data.message)
          if(e.data.message === 'session have no sessionId' || e.data.message ===  'session expired') {
            localStorage.removeItem("access_token");
            window.location.reload();
          }
        }
      }
    }
  },
};
</script>
<style>
  .v-main{
    background: #f4f6f9;
  }


</style>
