<template>
  <v-container class="my-5">
    <v-data-table
        :footer-props="{'items-per-page-options': [10, 50, 100]}"
        :items-per-page="10"
        :headers="headers"
        :items="items"
        :server-items-length="totalItems"
        :options.sync="options"
        :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title>Subscription Cancel</v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:[`item.user`]="{ item }">
        <div class="user-img">
          <img :src="$getAvatarImage(item.user.profile.avatar)" alt="img">
          <span class="user-title" @click.stop="openUserNewPage(item.user.id)">{{ item.user.name }}</span>
          <div class="online" v-if="getterUserOnline(item.user.id)"></div>
          <v-icon v-if="item.user.isAgency" class="ml-3" color="primary">mdi-face-agent</v-icon>
          <v-icon
              v-if="item.user.id !== me.id && $auth.isAdmin"
              class="ml-3"
              color="primary"
              @click.stop="$switchProfileUser(item.user.id)"
          >
            mdi-account-arrow-left
          </v-icon>
        </div>
      </template>
      <template v-slot:[`item.reason`]="{ item }">
        <v-chip small dark>
          {{ item.reason }}
        </v-chip>
      </template>

    </v-data-table>
  </v-container>
</template>

<script>
import {api} from "@/providers/api";
import {mapGetters} from "vuex";

export default {
  name: "SubscriptionCancel",
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'User',
        sortable: false,
        value: 'user'
      },
      {
        text: 'Reason',
        sortable: false,
        value: 'reason'
      },
      {
        text: 'Text',
        sortable: false,
        value: 'description'
      },
    ],
    items: [],
    options: {},
    totalItems: 0,

  }),
  computed:{
    ...mapGetters('User', ['me'])
  },
  watch: {
    options: {
      handler() {
        this.syncDataFromApi()
      },
      deep: true
    }
  },
  methods: {
    openUserNewPage(id) {
      const url = this.$router.resolve(`/users/${id}`).href;
      window.open(url, '_blank');
    },
    async syncDataFromApi() {
      this.loading = true
      try {
        const {page, itemsPerPage: limit} = this.options
        const response = await api.get(`/admin-membership-tariffs/statistic`, {
          params: {
            limit: limit === -1 ? 100 : limit,
            page,
          },
        })
        const {items, meta} = response
        this.items = items
        console.log('meta.totalItems', meta.totalItems)
        this.totalItems = meta.totalItems
      } catch (e) {
        console.log('Error', e)
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style scoped lang="scss">
img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}
.online {
  background: #4fa526;
  width: 13px;
  height: 13px;
  position: absolute;
  border-radius: 100px;
  margin-left: 20px;
  border: 2px solid #fff;
  margin-top: 20px;
}

.user-img {
  display: flex;
  align-items: center;
}

.user-title {
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #2f55b4;
  }
}
</style>