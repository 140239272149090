<template>
  <div>
    <v-data-table
        :footer-props="{'items-per-page-options': [10, 50, 100]}"
        :items-per-page="10"
        :headers="headersTrans"
        :items="items"
        :server-items-length="totalItems"
        :options.sync="options"
        :loading="loading"
        class="elevation-1"
        key="id"

    >

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ new Date(item.createdAt) | date('Pp') }}
      </template>
      <template v-slot:[`item.category`]="{ item }">
        {{ categoryFormat(item.parentTransaction) }}
      </template>
      <template v-slot:[`item.from`]="{ item }">
        <v-list-item two-line dense class="pa-0">
          <v-list-item-content>
            <v-list-item-subtitle class="">{{ item.parentTransaction ? 'ID: ' +  item.parentTransaction.fromBalance.owner.id : '' }}</v-list-item-subtitle>
            <v-list-item-title>{{ item.parentTransaction ? item.parentTransaction.fromBalance.owner.email : '' }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-slot:[`item.completedAt`]="{ item }">
        <div v-if="item.completedAt !== null ">
          <v-chip
              :color="getColor(item.completedAt)"
              dark
          >
            {{ new Date(item.completedAt) | date('Pp') }}
          </v-chip>
        </div>
        <div v-else>
          <v-chip
              :color="getColor(item.completedAt)"
              dark
          >
            -
          </v-chip>
        </div>
        <!--          {{ item.membership !== null ? ( new Date(item.membership.startDate )) : 'null' }}-->
      </template>

    </v-data-table>
  </div>
</template>

<script>

  import {api} from "@/providers/api";
  import {mapGetters} from "vuex";

  export default {
    name: "UserWomanLogCreditHistoryTable",
    props: {
      refresh: {
        type: Boolean,
        default: false
      },
      user: {
        type: Object,
        default: () => ({})
      }
    },
    data: () => ({
      headersTrans: [],
      options: {},
      totalItems: 0,
      loading: true,
      items: []
    }),
    computed: {
      translations() {
        return this.$t('translation.USERS.USER_LOG.tabs_items.transaction_table.headers')
      }
    },
    created() {
      this.headersTrans = [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',

        },

        {
          text: this.translations.created_at,
          value: 'createdAt',
        },
        {
          text: this.translations.completed_at,
          value: 'completedAt',
        },

        {
          text: this.translations.amount,
          value: 'amount',
        },
        {
          text: this.translations.category,
          value: 'category',
        },
        {
          text: 'From',
          value: 'from',
        },
      ]

    },
    watch: {
      refresh(status) {
        this.loading = status
        if (status) {
          this.options.page = 1
          this.syncDataFromApi()
        }
      },
      options: {
        handler() {
          this.syncDataFromApi()
        },
        deep: true
      }
    },

    methods: {
      categoryFormat(item) {
        if (item === null) {
          return '';
        }
        if (item.chatId) {
          return 'Chat';
        }
        if (item.tariffId) {
          return 'Tariff';
        }
        if (item.flirtId) {
          return 'Flirt';
        }
        if (item.introductionId) {
          return 'Introduce';
        }
        if (item.videoCallId) {
          return 'Video Chat';
        }

        return item.category;
      },

      async syncDataFromApi() {
        this.loading = true
        try {
          const {sortBy, sortDesc, page, itemsPerPage: limit} = this.options
          const response = await api.get('/admin/transactions/user', {
            params: {
              ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : [],
              limit: limit === -1 ? 100 : limit,
              page,
              user_id: this.$route.params.id

            },

          })

          this.items = response.items;
          this.totalItems = response.meta.totalItems
        } catch (e) {
          console.log('Error', e)
        } finally {
          this.loading = false
          this.$emit('refreshStop', 'credit')
        }
      },
      getColor(status) {
        if (status === null)
          return 'orange'
        else return 'green'
      },
    }
  }
</script>

<style scoped>

</style>