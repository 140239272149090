 import { getCountry, getCountries, getStates } from "country-state-picker/lib/index";
 import { api } from '@/providers/api';

export function getUserRealName(me) {
  return me.profile.name && me.profile.surname
    ? me.profile.name + " " + me.profile.surname
    : me.profile.name
    ? me.profile.name
    : me.name;
}

export const LocationsMixin = {
  methods: {
    $getCountyByCode(code) {
      return getCountry(code);
    },
    $getCountries() {
      return getCountries();
    },
    $getCountryStates(code) {
      return getStates(code);
    },
    async $switchProfileUser(userId) {
      const url = new URL(`${process.env.VUE_APP_BASE_SITE_URL}/app`)
      const response = await api.get(`/admin/users/${userId}/login`)
      url.searchParams.append('admin_access_token', response.access_token)
      return window.open(url.toString());
    },
    $openUser(user) {
      const url = this.$router.resolve(`/users/${user.id}`).href;
      window.open(url, '_blank');
    },
  },
  computed: {

    $userProfileUrl() {
      return `/id${this.$store.getters["User/me"]?.id}`;
    },

    $getUserName() {
      return (me) => {
        return getUserRealName(me);
      };
    },
  },
};
