var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notifications-campaign-log"},[_c('v-container',{staticClass:"my-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"table-header"},[_vm._v(" "+_vm._s(_vm.mainTitle)+" ")]),_c('v-data-table',{attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headers,"items":_vm.mailingMap,"options":_vm.mailingTableOptions,"server-items-length":_vm.totalResponseMailing,"loading":_vm.mailingLoading},on:{"update:options":function($event){_vm.mailingTableOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('stop-notifications-campaign-modal',{attrs:{"dialog":_vm.statusModal},on:{"close-options":_vm.closeModal,"save-options":_vm.stopChat}})]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(!item.state)?_c('v-chip',{attrs:{"small":"","dark":"","color":"green"}},[_vm._v(" ok ")]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm._f("date")(new Date(item.createdAt),'Pp'))+" ")])]}},{key:"item.completeAt",fn:function(ref){
var item = ref.item;
return [(item.completeAt !== '')?_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.completeAt)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex w-100"},[_c('v-icon',{staticClass:"ml-auto mr-auto",attrs:{"color":item.endDate === '' ? 'green' : 'red'},on:{"click":function($event){return _vm.openModal(item)}}},[_vm._v(" "+_vm._s(item.endDate === '' ? 'mdi-play-circle-outline' : 'mdi-stop-circle-outline')+" ")])],1)]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }