import { api } from "@/providers/api";

const state = {
    data: []
};

const getters = {
    getterData: (state) => (key) => {
        if (state.data[key]) {
            return state.data[key]
        } else {
            return []
        }
    }
};
const actions = {
    addData({ commit }, { key, value }) {
        commit('ADD_DATA', { key, value })
    },
};
const mutations = {
    ADD_DATA(state, { key, value }) {
        if (!state.data.hasOwnProperty(key)) {
            state.data[key] = {}
        }
            state.data[key] = value

        console.log('ADMIN state.data:', state.data)
    },

};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
