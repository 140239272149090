<template>
  <v-col>
    <v-alert class="alertSuccess" dark v-model="alertSuccess" type="success">
      {{ translationsBotSetting.alert_text }}
    </v-alert>
    <v-select
        v-model="select"
        :items="items"
        item-text="value"
        item-value="value"
        :label="translationsBotSetting.select_messages_label"
        return-object
        class="select my-5"
        outlined
        disabled
    ></v-select>
    <v-text-field @blur="percentageCountBot"
                  class="select my-5"
                  v-model="countBot"
                  :label="translationsBotSetting.percent_bots_label"
                  type="number"
                  outlined></v-text-field>
    <v-switch
        v-model="switchModel"
        color="success"
        class="ml-5"
        :label="`Bots: ${this.switchModel ? 'On' : 'Off'}`"
    ></v-switch>
  </v-col>
</template>

<script>
import {getActiveState, setActiveState} from "@/api/chat-bot.api";
import {api} from "@/providers/api";


export default {
  name: "BotSettings",
  data: () => ({
    switchModel: true,
    alertSuccess: false,
    countBot: null,
    countBotDefault: null,
    items: [
      {value: '1 min'},
      {value: '5 min'},
      {value: '30 min'},
      {value: '1 hour'},
      {value: '2 hours'},
      {value: '6 hours'},
      {value: '12 hours'},
      {value: '24 hours'},
    ],
  }),
  watch: {
    switchModel(v) {
      setActiveState(v);
    }
  },
  computed: {
    select() {
      console.log(this.items.slice(0, 1));
      return this.items[0].value;
    },
    translations() {
      return this.$t('translation')
    },
    translationsBotSetting() {
      return this.translations.BOT.settings
    }
  },
  async created() {
    this.countBot = await api.get('/chats-bot/percent').then(r => r.percent)
    this.countBotDefault = this.countBot
  },

  mounted() {
    this.fetchSwitch()
  },
  methods: {
    async fetchSwitch() {
      this.switchModel = await getActiveState();
    },
    async percentageCountBot() {
      if (this.countBotDefault !== this.countBot) {
        await api.post(`/chats-bot/percent/${this.countBot}`)
        this.alertSuccess = true;
        setTimeout(() => {
          this.alertSuccess = false;
        }, 3000);
        this.countBotDefault = this.countBot;
      }
    }
  }


}
</script>

<style scoped lang="scss">
.alertSuccess {
  position: fixed;
  z-index: 10;
  right: 15px;
}

.select {
  max-width: 400px;
  margin-left: 20px;
}
</style>