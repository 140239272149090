<template>
  <div>
    <v-container class="my-5">


      <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
              color="primary"
              dark
          >
            <v-toolbar-title>{{ translationsConfiguration.title }}</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-dialog
                v-model="dialog"
                max-width="500px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="white"
                    outlined
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  {{ translationsConfiguration.btn_new_configuration }}
                </v-btn>
              </template>
              <v-card height="400">
                <v-card-title>
                  <span class="text-h5 primary--text">{{ translationsConfiguration.add_modal_title }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-text-field
                            outlined
                            hide-details
                            :label="translationsConfiguration.modal_title_label"
                            v-model="title"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-text-field
                            outlined
                            hide-details
                            :label="translationsConfiguration.modal_email_label"
                            v-model="email"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-select
                            v-model="selectedType"
                            outlined
                            chips
                            :label="translationsConfiguration.modal_type_label"
                            :items="types"
                            multiple
                            hide-details
                        >
                          <template v-slot:selection="{ item }">
                            <v-chip dark color="teal">
                              {{ item }}
                            </v-chip>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-btn
                            right
                            color="success"
                            outlined
                            @click="saveNewItem"
                        >
                          {{ translations.GLOBAL_VALUE.save }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>

                </v-card-text>

              </v-card>
            </v-dialog>
            <v-dialog v-model="editModal" max-width="500px">
              <v-card height="400">
                <v-card-title>
                  <span class="text-h5 primary--text">{{ translationsConfiguration.edit_modal_title }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-text-field
                            outlined
                            hide-details
                            :label="translationsConfiguration.modal_title_label"
                            v-model="editItem.title"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-text-field
                            outlined
                            v-model="editItem.email"
                            hide-details
                            :label="translationsConfiguration.modal_email_label"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-select
                            outlined
                            chips
                            :label="translationsConfiguration.modal_type_label"
                            :items="types"
                            multiple
                            v-model="editItem.mailTypes"
                            hide-details
                        >
                          <template v-slot:selection="{ item }">
                            <v-chip dark color="teal">
                              {{ item }}
                            </v-chip>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-btn
                            right
                            color="success"
                            outlined
                            @click="saveEditItem"
                        >
                          {{ translations.GLOBAL_VALUE.save }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>

                </v-card-text>

              </v-card>
            </v-dialog>
            <v-dialog v-model="deleteModal" max-width="500px">
              <v-card height="170">
                <v-card-title>
                  <span class="text-h5 primary--text">Delete item</span>
                </v-card-title>

                <v-card-text>
                  {{ translations.GLOBAL_VALUE.delete_modal.title }}
                </v-card-text>
                <v-row class="ma-3">
                  <v-btn color="primary" @click="confirmDelete">{{ translations.GLOBAL_VALUE.delete_modal.ok_btn }}</v-btn>
                </v-row>

              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
              small
              @click.stop="openEditModal(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click.stop="openDeleteModal(item)"
          >
            mdi-delete
          </v-icon>
        </template>

        <template v-slot:[`item.type`]="{ item }">
          <v-chip class="mr-1" dark v-for="(elem, index) in item.mailTypes" :key="index" color="teal">
            {{ elem.settingType }}
          </v-chip>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import {api} from "@/providers/api";

export default {
  name: "ConfigurationPage",
  data: () => ({
    dialog: false,
    deleteModal: false,
    colors: [
      "indigo",
      "green",
      "orange",
      "teal",
      "purple",
      "red",
      "blue"
    ],
    types: ['support', 'withdrawal', 'asdsad', 'aaa1', 'bbb2', 'ccc3'],
    headers: [
      {text: 'ID', align: 'start', value: 'id', sortable: false},
      {text: 'Title', value: 'title', sortable: false},
      {text: 'Email', value: 'email', sortable: false},
      {text: 'Type', value: 'type', sortable: false},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    editItem: {
      mailTypes: [],
      email: '',
      title: '',
      id: null,
    },
    deleteId: null,
    editModal: false,
    items: [],
    title: '',
    email: '',
    selectedType: []
  }),
  methods: {
    openDeleteModal(item) {
      this.deleteModal = true
      this.deleteId = item.id
    },
    async confirmDelete() {
      this.deleteModal = false
      await api.delete(`/admin/mailer-settings/${this.deleteId}`)
      await this.fetchMailer()
    },
    openEditModal(item) {
      this.editModal = true
      this.editItem = {
        mailTypes: item.mailTypes.map(r => r.settingType),
        title: item.title,
        email: item.email,
        id: item.id
      }
      console.log(this.editItem)
    },
    async saveEditItem() {
      await api.patch(`/admin/mailer-settings/${this.editItem.id}`, {
        title: this.editItem.title,
        email: this.editItem.email,
        mailTypes: this.editItem.mailTypes
      })
      this.editModal = false
      await this.fetchMailer()
    },
    async saveNewItem() {
      await api.post('/admin/mailer-settings', {
        title: this.title,
        email: this.email,
        mailTypes: this.selectedType,
      })
      this.title = ''
      this.email = ''
      this.selectedType= []
      this.dialog = false
      await this.fetchMailer()

    },
    async fetchTypes() {
     this.types = await api.get('/admin/mailer-settings/available-types')
    },
    async fetchMailer() {
      this.items = await api.get('/admin/mailer-settings')
    }
  },
  computed: {
    translations() {
      return this.$t('translation')
    },
    translationsConfiguration() {
      return this.translations.CONFIGURATION.table
    }
  },
  async created() {
    this.headers = [
      {text: 'ID', align: 'start', value: 'id', sortable: false},
      {text: this.translationsConfiguration.headers.title, value: 'title', sortable: false},
      {text: this.translationsConfiguration.headers.email, value: 'email', sortable: false},
      {text: this.translationsConfiguration.headers.type, value: 'type', sortable: false},
      {text: this.translationsConfiguration.headers.actions, value: 'actions', sortable: false},
    ]
   await this.fetchMailer()
   await this.fetchTypes()
  }
}
</script>

<style scoped>

</style>