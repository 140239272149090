<template>
  <div>
    <PreloaderPage v-if="activityLoading" class="preloader-line"/>
    <v-container :class="{'disabled' : activityLoading}">
      <MyBreadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-tabs background-color="#f4f6f9" color="primary">
        <v-tab to="staff">
          Staff
        </v-tab>

        <v-tab to="transactions">
          {{ translationsAgencyLog.breadcrumbs.transactions }}
        </v-tab>

        <v-tab to="chats">
          Chats
        </v-tab>
        <v-tab to="activities">
          {{ translationsAgencyLog.breadcrumbs.activities }}

        </v-tab>
        <v-tab v-if="!this.$auth.isAdmin" to="withdrawal">
          {{ translationsAgencyLog.breadcrumbs.withdrawal }}
        </v-tab>
        <v-tab to="stats">
          {{ translationsAgencyLog.breadcrumbs.stats }}
        </v-tab>
        <v-tab to="settings">
          {{ translationsAgencyLog.breadcrumbs.settings }}
        </v-tab>
        <v-tab to="pay-out">
          {{ translationsAgencyLog.breadcrumbs.pay_out }}
        </v-tab>
      </v-tabs>
    </v-container>
    <v-container class="mb-5">
      <v-alert class="alert-error" type="error" v-model="alertShow">
        Error: Value out of range. The percentage must be between 0 and 100.
      </v-alert>

      <v-col class="col-info" cols="12" md="12" lg="12">


        <v-card>
          <v-card-title class="table-header">
            {{ translationsAgencyLog.table_staff.title }}
            <v-text-field
                class="ml-6"
                v-model="search"
                append-icon="mdi-magnify"
                :label="translations.GLOBAL_VALUE.search"
                dark

            ></v-text-field>
            <v-spacer></v-spacer>
            <v-text-field
                style="max-width: 200px"
                class="mr-3"
                v-model="girlPercent"
                label="Girl Percent"
                dark
                @change="girlPercentChange"

            ></v-text-field>
            <v-btn
                elevation="0"
                color="primary"
                dark
                class="button-add mb-2 mr-2"
                @click="pullOnline"
            >
              {{ translationsAgencyLog.table_staff.btn_put_online }}
            </v-btn>
            <v-btn @click="addWomanPage" elevation="0"
                   color="primary"
                   dark
                   class="button-add mb-2 mr-2">
              {{ translationsAgencyLog.table_staff.btn_create_user }}
            </v-btn>
          </v-card-title>

          <v-data-table
              :footer-props="{'items-per-page-options': [10, 50, 100]}"
              :items-per-page="10"
              :headers="headers"
              :items="agency"
              :search="search"
              :loading="agencyLoading"
              @click:row="pushUser"
              :options.sync="usersTableOptions"
              :server-items-length="totalResponseUsers"
          >
            <template v-slot:top>
              <DeleteModal :dialog="dialogDelete" @close-options="closeDelete" @save-options="deleteItemConfirm"/>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <v-select
                  v-if="adminCheck"
                  @click.stop
                  :items="statusItems"
                  :value=" item.status === 0 ? 'REVIEW' : item.status === 1 ? 'ACTIVE' : 'BLOCKED' "
                  label="Status"
                  dense
                  height="20"
                  outlined
                  @change="changeStatusUser($event, item.id)"
                  hide-details
              ></v-select>
              <span v-else>
                {{ item.status === 0 ? 'REVIEW' : item.status === 1 ? 'ACTIVE' : 'BLOCKED' }}
              </span>

            </template>


            <template v-slot:[`item.name`]="{ item }">
              <div class="user-img">
                <img :src="$getAvatarImage(item.profile.avatar)">
                <span class="user-title" @click.stop="openUserNewPage(item.id)">{{ item.name }}</span>
                <v-icon
                    v-if="item.id !== me.id && ($auth.isAdmin || me.isAgency)"
                    class="ml-3"
                    color="primary"
                    @click.stop="$switchProfileUser(item.id)"
                >
                  mdi-account-arrow-left
                </v-icon>
                <img v-if="item.isOnline" src="@/components/img/online.svg" alt="" class="online">
              </div>
            </template>
            <template v-slot:[`item.balance`]="{ item}">
              <span>{{ $getBalance(item.balance) }}</span>
            </template>
            <template v-slot:[`item.autoMessage`]="{ item }">
              <v-checkbox dense v-model="item.disableChatBot" @click.stop="changeChatBot(item) "/>
            </template>
            <template v-slot:[`item.messagesCount`]="{ item }">
              <v-chip dark small color="green" v-if="item.messagesCount > 0">
                {{ item.messagesCount }}
              </v-chip>
            </template>

            <template v-slot:[`item.isOnline`]="{ item }">
              <div>
                <v-icon color="orange " v-if="item.status === 0">
                  mdi-account-cancel
                </v-icon>
                <v-icon color="green" @click.stop="changeUserOnlineStatus(item)" v-else-if="item.isOnline">
                  mdi-account-badge
                </v-icon>
                <v-icon color="grey" @click.stop="changeUserOnlineStatus(item)" v-else-if="!item.isOnline">
                  mdi-account-badge
                </v-icon>
              </div>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-icon
                  small
                  class="mr-2"
                  @click.stop="openUserEdit(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                  small
                  @click.stop="deleteAgency(item)"
              >
                mdi-delete
              </v-icon>
            </template>

          </v-data-table>
        </v-card>

      </v-col>

    </v-container>
  </div>
</template>

<script>


import router from '@/routes/router';
import { getRoles} from '@/api/usersRequest';
import {
  addUsersAgency,
  deleteUsersAgency,
  getAgencyBalanceId,
  getAgencyById,
} from '@/api/agencyRequest';
import {mapGetters} from 'vuex';
import DeleteModal from '@/components/DeleteModal';

import {api} from '@/providers/api';
import MyBreadcrumbs from "@/components/Breadcrumbs.vue";
import {userOnlineCheck} from "@/mixins/user-online.mixin";
import PreloaderPage from "@/components/PreloaderPage.vue";

export default {
  name: 'AgencyLogStaff',
  components: {
    PreloaderPage,
    MyBreadcrumbs,
    DeleteModal,

  },
  props: {
    targetAgency: {
      type: Object,
      default: null
    },
    id: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    activityLoading: false,
    girlPercent: null,
    statusItems: ['REVIEW', 'ACTIVE', 'BLOCKED'],
    select: '',
    deleteItem: null,
    dialogDelete: false,
    autoCompleteInput: {},
    autoCompleteUserRole: null,
    search: null,
    searchUser: null,
    agency: [],
    users: [],
    isCreateDialog: false,
    agencyName: '',
    balance: null,
    headers: [
      {text: 'ID', align: 'start', value: 'id', sortable: true},
      {text: 'Put Online', value: 'isOnline', sortable: false,},
      {text: 'Name', value: 'name', sortable: true,},
      {text: 'Email', value: 'email', sortable: true,},
      {text: 'Role', value: 'agency.role.name', sortable: true,},
      {text: 'Balance ($)', value: 'balance', sortable: true},
      {text: 'Status', value: 'status', sortable: false, width: 250},
      {text: 'Action', value: 'action', sortable: false},
    ],
    userRoles: [],
    agencyInfo: [],
    preloader: true,
    balanceLoading: false,
    agencyLoading: false,
    totalResponseUsers: 0,
    usersTableOptions: {},
    agencyUserProps: [],
    alertShow: false,
  }),
  async created() {
    this.headers = [
      {text: 'ID', align: 'start', value: 'id', sortable: true},
      {text: 'Put Online', value: 'isOnline', sortable: false,},
      {text: this.translationsAgencyLog.table_staff.headers.name, value: 'name', sortable: true,},
      {text: this.translationsAgencyLog.table_staff.headers.email, value: 'email', sortable: true,},
      {text: 'Disable  Auto Messages', value: 'autoMessage', sortable: false,},
      {text: 'Number of chat messages', value: 'messagesCount', sortable: false,},
      {text: this.translationsAgencyLog.table_staff.headers.role, value: 'agency.role.name', sortable: true,},
      {text: this.translationsAgencyLog.table_staff.headers.balance + ' ($)', value: 'balance', sortable: true},
      {text: this.translationsAgencyLog.table_staff.headers.status, value: 'status', sortable: false, width: 250},
      {text: this.translationsAgencyLog.table_staff.headers.actions, value: 'action', sortable: false},
    ]
    if (this.currentAgency?.name === undefined) {
      this.activityLoading = true;
    } else {
      this.setGirlPercent();
    }
    await this.$store.dispatch("Agencies/fetchAgencyById", {id: this.$route.params.id})
    this.activityLoading = false;
    this.setGirlPercent();
    await this.checkOrCreatAgency();
  },
  async mounted() {

  },


  computed: {
    translations() {
      return this.$t('translation')
    },
    translationsAgencyLog() {
      return this.translations.AGENCY.AGENCY_LOG
    },
    userOnlineCheck() {
      return userOnlineCheck
    },
    ...mapGetters('Agencies', [
      'getterAgencyById',
    ]),
    ...mapGetters('User', ['me']),

    currentAgency() {
      return this.getterAgencyById(+this.$route.params.id)
    },

    breadcrumbs() {
      return this.$auth.isAdmin ?  [
        {text: this.translations.NAVBAR.DASHBOARD.title, href: '/'},
        {text: this.translations.AGENCY.AGENCIES.title, href: '/agencies'},
        {text: `${this.currentAgency?.name || '-'} #${this.$route.params.id || '-'}`, disabled: true,}
      ] : [
        {text: `${this.currentAgency?.name || '-'} #${this.$route.params.id || '-'}`, disabled: true,},
      ];
    },
    adminCheck() {
      return this.$auth.isAdmin
    },
    contractCheck() {
      return this.agencyInfo?.agencyRequest?.contract
    },
    statusSelect() {
      if (this.agency.status === 0) return 'REVIEW';
      else if (this.agency.status === 1) return 'ACTIVE';
      else return 'BLOCKED';
    },
    agencyInviteUrl() {
      const u = new URL(process.env.VUE_APP_BASE_SITE_URL);
      u.pathname = '/register/agency';
      u.searchParams.append('agency_id', String(this.id));
      return u.toString();
    }
  },

  watch: {
    searchUser: {
      handler() {
        this.syncSearchUsersFromApi()
      },
      deep: true
    },
    usersTableOptions: {
      handler(changed) {
        console.log('usersTableOptions:change', changed);
        this.syncUsersFromApi();
      },
      deep: true
    },
  },

  methods: {
    setGirlPercent() {
      this.girlPercent = this.currentAgency.girlPercent ? (Number(this.currentAgency.girlPercent) * 100) : null
    },
    async girlPercentChange(val) {
      const percentage = val / 100;
      console.log('val', val);

      if (percentage > 1 || percentage < 0) {
        this.alertShow = true
      } else {
        this.alertShow = false
        await api.post(`/admin/agencies/${Number(this.$route.params.id)}/set-girl-percent`, {}, {
          params: {
            value: percentage
          }
        })
      }
    },
    async changeChatBot(item) {
      await api.post(`/admin/agencies/chat-bot/${item.id}/${item.disableChatBot}`)
    },
    async changeUserOnlineStatus(user) {
      if (user.isOnline) {
        await api.post(`/admin/users/${user.id}/online-state`, {},{
          params: {
            state: false
          }
        })
      }
      else {
        await api.post(`/admin/users/${user.id}/online-state`, {},{
          params: {
            state: true
          }
        })
      }
      await this.syncUsersFromApi();
    },
    getItemText(item) {
      return `${item.name} (${item.email})`;
    },

    addWomanPage() {
      this.$router.push({path: `/add-woman/${this.$route.params.id}`, params: {id: this.$route.params.id}})
    },


    async pullOnline() {
          await api.post(`/admin/agencies/${this.$route.params.id}/online-state`, {}, {
            params: {
              state: true
            }
          })
          await this.syncUsersFromApi();
    },

    async changeStatusUser(event, userId) {

      const USER_STATUSES = [
        'REVIEW',
        'ACTIVE',
        'BLOCKED'
      ];
      await api.post(`/admin/users/${userId}/status`, {}, {
        params: {
          status: USER_STATUSES.indexOf(event)
        }
      });
      await this.syncUsersFromApi()

    },

    showContract(agencyInfo) {
      console.log(agencyInfo)
      const url = agencyInfo?.agencyRequest?.contract?.url;
      if (url)
        window.open(url, '_blank');
    },


    async checkOrCreatAgency() {
      this.preloader = true

      await this.syncUsersFromApi()
      // this.users = await getUsers().then(r => r.data)
      await this.fetchBalance()
      this.userRoles = await getRoles()
      // await this.fetchTargetAgency()

      this.preloader = false
    },
    async fetchTargetAgency() {
      const agencyById = await getAgencyById(parseInt(this.id))
      this.agencyName = agencyById.name
      this.balance = agencyById.balance.amount;
      this.agencyInfo = agencyById;
    },

    usersSearchCheck(users) {
      Object.filter = (obj, predicate) =>
          Object.keys(obj)
              .filter(key => predicate(obj[key]))
              .reduce((res, key) => (
                  res[key] = obj[key], res), {});


      const newUsers = []

      Object.filter(users, score => {
        if (score.profile !== null) {
          newUsers.push(score)
        }
      })
      return newUsers
    },
    async syncSearchUsersFromApi() {
      if (this.searchUser.trim().length <= 0) {
        this.users = []
      } else {
        try {
          const response = await api.get('/admin/users', {
            params: {
              s: {"$or": [{"name": this.searchUser.trim()}, {"email": this.searchUser.trim()}]}
            }
          });
          this.users = response.data
          console.log('this.users', this.users)

        } catch (e) {
          console.log('error', e);

        } finally {

        }
      }

    },


    async syncUsersFromApi() {
      this.agencyLoading = true;
      console.log('YES', this.$route.params.id)
      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.usersTableOptions;
        const response = await api
            .get(`/admin/agencies/${this.$route.params.id}/users`, {
              params: {
                ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : {},
                limit: limit === -1 ? 100 : limit,
                page,
              }
            });


        const {data: items, total} = response;

        this.agency = items.map(item => ({
          ...item,
          balance: this.$getBalanceAgency(item?.balance)
        }))
        this.totalResponseUsers = total;

      } catch (e) {
        console.log('error', e);

      } finally {
        this.agencyLoading = false;
      }


    },

    openUserNewPage(id) {
      const url = this.$router.resolve(`/users/${id}`).href;
      window.open(url, '_blank');
    },


    async deleteItemConfirm() {
      await deleteUsersAgency(this.deleteItem.id, this.id);
      await this.syncUsersFromApi()
      this.fetchAllData();
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.autoCompleteInput = '';
      this.autoCompleteUserRole = '';
    },

    filterData(item, queryText) {
      return (
          item.email.toLowerCase().includes(queryText.toLowerCase()) ||
          item.name.toLowerCase().includes(queryText.toLowerCase())
      );
    },
    filterDataUserRole(item, queryText) {
      return (
          item.name.toLowerCase().includes(queryText.toLowerCase())
      );
    },
    openUserEdit(item) {
      const url = this.$router.resolve(`/users/${item.id}/edit`).href;
      window.open(url, '_blank');
    },
    deleteAgency(item) {
      this.dialogDelete = true;
      this.deleteItem = item;
    },
    close() {
      this.isCreateDialog = false;
      this.autoCompleteInput = '';
      this.autoCompleteUserRole = '';
    },
    async save() {
      this.isCreateDialog = false;
      await addUsersAgency(this.autoCompleteInput.id, this.id, this.autoCompleteUserRole);
      this.autoCompleteInput = '';
      await this.fetchAllData();
      await this.syncUsersFromApi()
      // this.users = await getUsers().then(r => r.data);
    },

    async fetchAllData() {
      this.fetchBalance();
    },

    async fetchBalance() {
      this.balanceLoading = true
      this.balance = await getAgencyBalanceId(this.id).then(r => r.balance);
      this.balanceLoading = false
      return this.balance;
    },


    pushUser(item) {
      router.push({name: 'user-log', params: {id: item.id}});
    },


  }
};
</script>

<style scoped lang="scss">
.col-info {
  padding: 0px;
}

.online {
  position: absolute;
  width: 10px;
  margin-top: -20px;
  border: 2px solid white;
  margin-left: 20px;
  height: 10px;
}

.card, .card-man, .card-woman {
  max-width: 330px;
  background: #2f55b4;
  color: white;
  margin-bottom: 10px;

  .v-card__title {
    text-transform: uppercase;
  }

  .v-icon {
    color: #f4f6f9;
  }
}

.table-header {
  background: #2f55b4;
  color: white;
  margin-bottom: 5px;

  .button-add {
    border: 1px solid #fff !important;
  }

  .search-input {
    margin-right: 50px;
    color: white;

    ::v-deep label {
      color: white;
    }

    ::v-deep .v-icon.v-icon {
      color: white;
    }

    ::v-deep .v-input__slot::before {
      border-color: white;
    }

    ::v-deep input {
      color: white;
    }
  }


}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.user-img {
  display: flex;
  align-items: center;
}

.row {
  margin-bottom: 20px;
}

.select-time-chart {
  width: 300px;
}

.input {
  width: max-content;
  margin-left: 10px;
}

.agency-main {
  .v-card {
    color: #343a40;
    border: none;

    .v-card__text {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.agency-info {
  .row {
    margin: 3px;
    border-bottom: 1px solid #e8e8e8;

    &:last-child {
      border-bottom: none;
    }
  }
}

.agency {
  display: flex;
  flex-direction: column;
  align-items: center;

  .text-h6 {
    width: 100%;
    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
  }

  .btn {
    width: 100%;
    max-width: fit-content;
    font-size: 13px;
  }
}

.alert {
  position: fixed;
  z-index: 100;
  right: 15px;
  top: 0px;
}

.user-title {
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #2f55b4;
  }
}

.amount-slider {
  width: 100%;
  max-width: 400px;

  @media (max-width: 1440px) {
    max-width: 200px;
  }
}

.v-tab-item-setting {
  background: #f3f3f3;
}

.preloader {
  height: 300px;
  padding-bottom: 15px;
  width: 100%;
  display: flex;
  background: #f3f3f3;
  justify-content: center;
  padding-top: 15px;
}

.alertSuccess {
  position: fixed;
  z-index: 10;
  right: 15px;
}

.agency-link {
  text-decoration: unset;
  color: #7b7b7b;

  &.router-link-active {
    color: #2f55b4;
  }
}

.preloader-page {
  position: absolute;
  top: 0;
}

.owner-name {
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #2f55b4 !important;
  }
}

.contract-link {
  text-decoration: underline;
}
.preloader-line {
  position: absolute;
  top: 0;
  height: 2px;
}

.disabled {
  opacity: .6;
  pointer-events: none;
}
</style>
