import {api} from "@/providers/api";

export async function editMember(id, data) {
  return api.patch(`/admin/membership-tariffs/${id}`, data)
    .then(r => r.data);
}
export async function addMember(data) {
  return api.post('/admin/membership-tariffs', data)
}
export async function deleteMembership(id) {
  return api.delete(`/admin/membership-tariffs/${id}`)
}

export async function getMembers() {
  return api.get('/membership-tariffs')
}
export async function getMembershipUsers(params) {
  return api.get('/admin/membership-tariffs', {
    params
  })
}