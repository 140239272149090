<template>
  <v-container class="my-5">
    <v-data-table
        :footer-props="{'items-per-page-options': [10, 50, 100]}"
        :items-per-page="10"
            :headers="categoryHeaders"
            :items="category"
            item-key="name"
            class="elevation-1 mb-6"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ translationsBlogLog.table_blog_category.title }}</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-dialog
                  v-model="dialogCategory"
                  max-width="800px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
              >
                {{ translationsBlogLog.table_blog_category.btn_new_category }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                            cols="12"
                            sm="12"
                            md="12"
                    >
                      <v-text-field
                              v-model="editedCategory.title"
                              :label="translationsBlogLog.table_blog_category.modal_category_title_label"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="blue darken-1"
                        text
                        @click="closeCategory"
                >
                  {{ translations.GLOBAL_VALUE.cancel }}
                </v-btn>
                <v-btn
                        color="blue darken-1"
                        text
                        @click="saveCategory"
                >
                  {{ translations.GLOBAL_VALUE.save }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <DeleteModal :dialog="dialogDeleteCategory" @close-options="closeDeleteCategory" @save-options="deleteCategoryConfirm"/>


        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
                small
                @click.stop="editCategory(item)"
                class="mr-2"
        >
          mdi-pencil
        </v-icon>
        <v-icon
                small
                @click.stop="deleteCategory(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>


    <v-data-table
        :footer-props="{'items-per-page-options': [10, 50, 100]}"
        :items-per-page="10"
            :headers="blogHeaders"
            :items="blogs"
            item-key="name"
            class="elevation-1"

    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ translationsBlogLog.table_blog_pages.title }}</v-toolbar-title>
          <v-spacer></v-spacer>

              <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      @click="addBlog"
              >
                {{ translationsBlogLog.table_blog_pages.btn_new_page }}
              </v-btn>

          <DeleteModal :dialog="dialogDeletePage" @close-options="closeDeletePage" @save-options="deleteConfirmPage"/>

        </v-toolbar>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <div v-if="item.createdAt !== null ">
          {{ new Date(item.createdAt) | date('Pp') }}
        </div>
        <div v-else>
          {{ item = '' }}
        </div>

      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
                small
                @click.stop="editPage(item)"
                class="mr-2"
        >
          mdi-pencil
        </v-icon>
        <v-icon
                small
                @click.stop="deletePage(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import router from "@/routes/router";
  import {
    deleteBlog,
    deleteBlogCategory,
    editBlogCategory,
    getBlogs,
    getBlogsCategory,
    postBlogsCategory
  } from "@/api/blogs";
  import DeleteModal from "@/components/DeleteModal";


  export default {
    name: "BlogLog",
    components: {DeleteModal},
    data: () => ({
      loadingBlogs: true,
      optionsBlogs: {},
      totalBlogs: 0,
      categoryHeaders: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Title', value: 'title', sortable: false},
        {text: 'Actions', value: 'actions', sortable: false},
      ],

      blogHeaders: [
        {text: 'ID', value: 'id', align: 'start'},
        {
          text: 'Title',
          sortable: false,
          value: 'title',
        },
        {
          text: 'Category',
          sortable: false,
          value: 'category.title',
        },
        {text: 'Created', value: 'createdAt', sortable: false},
        {text: "Action", sortable: false, value: 'actions'}

      ],
      blogs: [],


      category: [],

      editedCategoryIndex: -1,
      editedCategory: {
        title: ''
      },
      defaultCategory: {
        title: ''
      },

      editedItem: {
      },
      defaultItem: {
      },
      dialogDeletePage: false,
      editedPageIndex: -1,

      dialogDeleteCategory: false,
      dialogCategory: false,


    }),
    watch: {
      dialogCategory(val) {
        val || this.closeCategory();
      },
      dialogDeleteCategory(val) {
        val || this.closeDeleteCategory();
      },

      // optionsBlogs: {
      //   handler(change) {
      //     console.log('change blogs', change)
      //     this.syncBlogsFromApi()
      //   },
      //   deep: true
      // }
    },

    created() {
      this.blogHeaders = [
        {text: 'ID', value: 'id', align: 'start'},
        {
          text: this.translationsBlogLog.table_blog_pages.headers.title,
          sortable: false,
          value: 'title',
        },
        {
          text: this.translationsBlogLog.table_blog_pages.headers.category,
          sortable: false,
          value: 'category.title',
        },
        {text: this.translationsBlogLog.table_blog_pages.headers.created_at, value: 'createdAt', sortable: false},
        {text: this.translationsBlogLog.table_blog_pages.headers.actions, sortable: false, value: 'actions'}

      ]
      this.categoryHeaders = [
        {text: 'ID', value: 'id', sortable: false},
        {text: this.translationsBlogLog.table_blog_category.headers.title, value: 'title', sortable: false},
        {text: this.translationsBlogLog.table_blog_category.headers.actions, value: 'actions', sortable: false},
      ]
      this.fetchBlog()
      this.fetchCategory()
    },

    computed: {
      translations() {
        return this.$t('translation')
      },
      translationsBlogLog() {
        return this.translations.BLOG_LOG
      },
      formTitle() {
        return this.editedCategoryIndex === -1 ? 'New Category' : 'Edit Category';
      }
    },

    methods: {


      // async syncBlogsFromApi() {
      //   this.loadingBlogs = true
      //   try {
      //     const { sortBy, sortDesc, page, itemsPerPage: limit} = this.optionsBlogs
      //     const response = await api.get('/blog',{
      //       params: {
      //         ...sortBy.length ? { sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC'))) } : [],
      //         limit: limit === -1 ? 100: limit,
      //         page
      //       },
      //
      //     })
      //
      //     const { data: items, total } = response
      //
      //     this.blogs = items;
      //     this.totalBlogs = total
      //   }
      //   catch (e) {
      //     console.log('likes error', e)
      //   }
      //   finally {
      //     this.loadingBlogs = false
      //   }
      // },

      editPage(post){
        router.push({ name: 'edit-blog', params: { id: post.id, targetPost: post} });
      },

      async fetchBlog() {
        this.blogs = await getBlogs().then(i => i.items)
        return this.blogs
      },
      async fetchCategory() {
        this.category = await getBlogsCategory()
        return this.category
      },

      addBlog() {
        this.$router.push({name: 'add-blog'})
      },

      deletePage(item) {
        this.editedPageIndex = this.blogs.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDeletePage = true;
      },

      async deleteConfirmPage() {
        await deleteBlog(this.editedItem.id)
        this.blogs.splice(this.editedPageIndex, 1);
        this.closeDeletePage();
      },


      closeDeletePage() {
        this.dialogDeletePage = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedPageIndex = -1;
        });
      },


      editCategory(item) {
        this.editedCategoryIndex = this.category.indexOf(item);

        this.editedCategory = Object.assign({}, item);
        this.dialogCategory = true;
      },

      deleteCategory(item) {
        this.editedCategoryIndex = this.category.indexOf(item);
        this.editedCategory = Object.assign({}, item);
        this.dialogDeleteCategory = true;
      },

      async deleteCategoryConfirm() {
        await deleteBlogCategory(this.editedCategory.id);
        this.category.splice(this.editedCategoryIndex, 1);
        this.closeDeleteCategory();
      },

      closeCategory() {
        this.dialogCategory = false;
        this.$nextTick(() => {
          this.editedCategory = Object.assign({}, this.defaultCategory);
          this.editedCategoryIndex = -1;
        });
      },

      closeDeleteCategory() {
        this.dialogDeleteCategory = false;
        this.$nextTick(() => {
          this.editedCategory = Object.assign({}, this.defaultCategory);
          this.editedCategoryIndex = -1;
        });
      },

      async saveCategory() {
        if (this.editedCategoryIndex > -1) {
          await editBlogCategory(this.category[this.editedCategoryIndex]?.id, {title: this.editedCategory.title})
          Object.assign(this.category[this.editedCategoryIndex], this.editedCategory);
          this.fetchBlog()

        } else {
          const { id } = await postBlogsCategory({ title: this.editedCategory.title });
          this.category.push({ id, title: this.editedCategory.title });
        }
        this.closeCategory();
      },


    }
  }
</script>

<style scoped>


</style>