var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{key:"id",staticClass:"elevation-1",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.startDate),'Pp')))]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.endDate),'Pp')))]}},{key:"item.nextPaymentAt",fn:function(ref){
var item = ref.item;
return [(item.nextPaymentAt)?_c('span',[_vm._v(_vm._s(_vm._f("date")(new Date(item.nextPaymentAt),'Pp')))]):_vm._e()]}},{key:"item.firstPaymentAt",fn:function(ref){
var item = ref.item;
return [(item.firstPaymentAt)?_c('span',[_vm._v(_vm._s(_vm._f("date")(new Date(item.firstPaymentAt),'Pp')))]):_vm._e()]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"orange","dark":"","small":""}},[_vm._v(" "+_vm._s(item.isCanceled ? 'Canceled' : !item.hasFirstPayment ? 'Free Trial' : (new Date(item.endDate) > new Date() ? 'Ongoing' : 'Subscription Expired'))+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }