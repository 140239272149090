import axios from "axios";
import { API_URL } from "@/providers/config";
import { removeUserAuth } from "@/providers/auth";

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          removeUserAuth();
          break;
      }

      return Promise.reject(error.response);
    }
  }
);

export const api = axiosInstance;
