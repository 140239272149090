<template>
  <v-data-table
      :footer-props="{'items-per-page-options': [10, 50, 100]}"
      :items-per-page="10"
      :headers="headers"
      :items="videos"
      item-key="id"
      show-expand
      class="elevation-1"
      :expanded.sync="expanded"
      :single-expand="singleExpand"
      :options.sync="optionsVideo"
      :server-items-length="videosTotalItems"
      :loading="videosLoading"
  >
    <template v-slot:top>
      <v-toolbar flat color="primary">
        <v-toolbar-title class="title">Video log</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-select
              label="Select status video"
              hide-details
              dark
              dense
              outlined
              :items="passportsStatus"
              class="mt-3"
              v-model="status"
              @change="changeStatusPassport"/>
        </v-toolbar-items>
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-row class="ma-0">
          <v-col cols="5">
            <video :src="item.video.converted.s3Url" width="350" controls />
            <p>{{ item.video.converted.userName }}</p>
          </v-col>

          <v-col>
            <v-btn width="100" class="mb-5 d-block" v-if="item.status === 0 || item.status === 2" color="success" @click="accept(item)">Accept</v-btn>
            <v-btn width="100" class="mb-10 d-block" v-if="item.status === 0 " color="error" @click="reject(item)">Reject</v-btn>
          </v-col>
        </v-row>

      </td>
    </template>

    <template v-slot:[`item.user.profile.name`]="{ item }">
      <router-link class="user-img" :to="{name: 'user-log', params: {id: item.user.id}}">
        <img :src="$getAvatarImage(item.user.profile.avatar)">
        {{ item.user.profile.name }}

        <v-icon
            v-if="item.user.id !== me.id && !$auth.isAdmin"
            class="ml-3"
            color="primary"
            @click.stop="$switchProfileUser(item.user.id)"
        >
          mdi-account-arrow-left
        </v-icon>
      </router-link>
    </template>
    <template v-slot:[`item.createdAt`]="{ item }">{{ new Date(item.createdAt) | date('Pp') }}</template>
    <template v-slot:[`item.status`]="{ item }">
      <v-chip
          :color="getColor(item.status)"
          dark
      >
        <span v-if="item.status === 0">Waiting</span>
        <span v-if="item.status === 1">Accepted</span>
        <span v-if="item.status === 2">Rejected</span>
      </v-chip>
    </template>

  </v-data-table>
</template>

<script>
import {
  approveChangeImageRequest,
  changeDocumentStatus,
  getChangeVideoRequests,

} from "@/api/usersRequest";
import router from "@/routes/router";
import {mapGetters} from "vuex";
import {api} from "@/providers/api";

export default {
  name: "UsersVideosTable",

  data: () => ({
    videosTotalItems: 0,
    optionsVideo: {},
    videosLoading: true,
    expanded: [],
    singleExpand: true,
    passportsStatus: ['Waiting', 'Accepted', 'Rejected'],
    status: 'Waiting',
    selectedStatus: null,
    headers: [

    ],
    videos: [

    ],
  }),
  watch: {

    optionsVideo: {
      handler(change) {
        console.log('change videos', change);
        this.syncVideoFromApi();
      },
      deep: true
    },
    selectedStatus() {
      this.syncVideoFromApi();
    }
  },
  computed: {
    ...mapGetters('User', ['me']),

    changeStatus() {
      if (this.status === 'Waiting' ){
        return 0
      }
      else if(this.status === 'Accepted') {
        return 1
      }
      else {
        return 2
      }
    },
  },
  created() {
    this.headers = [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
        sortable: false
      },

      {
        text: 'Name',
        value: 'user.profile.name',
        sortable: false

      },
      {
        text: 'Created',
        value: 'createdAt',
        sortable: false

      },
      {
        text: 'Status',
        value: 'status',
        sortable: true

      },

    ]
    // this.fetchPassports()
  },
  methods: {
    changeStatusPassport() {
      this.syncVideoFromApi()
    },
    async syncVideoFromApi() {
      this.videosLoading = true;

      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.optionsVideo;
        const response = await getChangeVideoRequests({
          params: {
            status: this.changeStatus,
            ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : [],
            limit: limit === -1 ? 100 : limit,
            page
          }
        });

        const {data, total} = response;
        console.log('totalItems', response)
        this.videos = data.filter(e => e.video && e.video.isReady);
        this.videosTotalItems = total;
      } catch (e) {
        console.log('error contact', e);
      } finally {
        this.videosLoading = false;
      }


    },

    // async fetchPassports() {
    //   this.passports = await getPassportsRequests({
    //     params: {
    //       status: this.status
    //     }
    //   })
    // },

    async accept(item) {
      console.log(item)
      await approveChangeImageRequest(item.id, true);
      await this.syncVideoFromApi();
    },

    async reject(item) {
      await approveChangeImageRequest(item.id, false);
      await this.syncVideoFromApi();
    },

    getColor(status) {
      if (status === 1)
        return 'green';
      else if (status === 2)
        return 'red';
      else return 'orange';
    },

    userOpen(user) {
      this.$router.push({name: 'user-log', params: {id: user.user.id}});
    },

  },

}
</script>

<style scoped lang="scss">




.row-text {
  width: unset;
}

.title {
  color: #f4f6f9;
}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.text-container {
}

.user-img {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;

}
</style>
