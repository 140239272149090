var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{key:"id",staticClass:"elevation-1",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headersTrans,"items":_vm.items,"server-items-length":_vm.totalItems,"options":_vm.options,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(new Date(item.createdAt),'Pp'))+" ")]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.categoryFormat(item))+" ")]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"pa-0",attrs:{"two-line":"","dense":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{},[_vm._v(_vm._s(item.destinationUser ? 'ID: '+item.destinationUser.id : ''))]),_c('v-list-item-title',[_vm._v(_vm._s(item.destinationUser ? item.destinationUser.email : ''))])],1)],1)]}},{key:"item.completedAt",fn:function(ref){
var item = ref.item;
return [(item.completedAt !== null )?_c('div',[_c('v-chip',{attrs:{"color":_vm.getColor(item.completedAt),"dark":""}},[_vm._v(" "+_vm._s(_vm._f("date")(new Date(item.completedAt),'Pp'))+" ")])],1):_c('div',[_c('v-chip',{attrs:{"color":_vm.getColor(item.completedAt),"dark":""}},[_vm._v(" - ")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }