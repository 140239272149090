import {api} from '@/providers/api';

export async function getUsers() {
  return api.get('/admin/users');
}

export async function getUserId(id) {
  return api.get(`/admin/users/${id}`);
}

export async function getUserIp(id) {
  return api.get(`/admin/users/${id}/ip`)
}

export async function getAvatarRequests(data) {
  return api.get('/admin/users/avatars', data);
}

export async function getChangeImageRequests(data) {
  return api.get('/admin/users/change-image-requests', data);
}
export async function getAttachmentsRequests(data) {
  return api.get('/admin/users/change-attachment-requests', data);
}
export async function getChangeVideoRequests(data) {
  return api.get('/admin/users/change-video-requests', data);
}

export async function getClosingAccounts(data) {
  return api.get('/admin/users/change-account-requests', data);
}


export async function getRoles() {
  return api.get('/admin/users/roles');
}

export async function deleteRoles(name) {
  return api.delete('/admin/users/roles/', {
    params: {
      name: name
    }
  });
}

export async function approveChangeImageRequest(id, approved, rejectMessage) {
  return api.post(`/admin/users/change-image-requests/${id}/${approved}`, {
    rejectMessage
  });
}

export async function changeDocumentStatus(id, status) {
  return api.post(`/admin/users/passport/${id}/${status}`)
}

export async function addRoles(data) {
  return api.post('/admin/users/roles', {}, {
    params: {
      name: data.name
    }
  });
}

export async function getUserLike(id) {
  return api.get(`/admin/likes?filter=to.id||$eq||${id}`);
}

export async function getUserFlirt(id) {
  return api.get(`/admin/flirts`, {
    params: {
      receiver_id: id
    }
  });
}

export async function getUserMessage(id) {
  return api.get('/admin/messages/', {
    params: {
      chat_id: id
    }
  });
}

export async function getUserTransactions(id) {
  return api.get('/admin/transactions/user', {
    params: {
      user_id: id
    }
  });
}

export async function getUserChat(id) {
  return api.get('/admin/chats/', {
    params: {
      user_id: id
    }
  });
}

export async function getAgencyId(id) {
  return api.get(`/admin/agencies/${id}/users`);
}

export async function getTransactions() {
  return api.get('/admin/transactions/user');
}

export async function getNewTransactions(data) {
  return api.get('/admin/transactions', data);
}

export async function addUserRole(userId, roleId) {
  return await api.post(`/admin/users/${userId}/role`, {}, {
    params: {
      role_id: roleId
    }
  });
}

export async function removeUserRole(userId, roleId) {
  return await api.delete(`/admin/users/${userId}/role`, {
    params: {
      role_id: roleId
    }
  });
}

export async function getAlbum(data) {
  return api.get('/admin/users/album', data);
}

export async function approveAlbum(id, approved) {
  return api.post(`/admin/users/album/${id}/${approved}`);
}

export async function removeUser(id) {
  return await api.delete(`/admin/users/${id}`);
}

export async function getUsersCustom(id) {
  return api.get(`/admin/users/${id}/custom`);
}

export async function changeRating(id, rating) {
  return await api.post(`/admin/users/${id}/profile`, {rating});
}

export async function getSystemBalance() {
  return await api.get('/admin/balances/system')
}

export async function getPassportsRequests(data) {
  return api.get('/admin/users/passports',  data);
}