import Vue from "vue";
import { api } from "@/providers/api";

const state = {
  chats: [],
  chatMessages: [],
};

const getters = {
  getChatById: (state) => (id) => state.chats.find((e) => e.chat.id === id),
  getChatMembers: (state, getters) => (id) =>
    getters.getChatById(id)?.chat?.members || [],
  getChatMember: (state, getters) => (id, memberId) =>
    getters.getChatById(id)?.chat?.members?.find((u) => u.id === memberId),
  getChatMessages: (state) => (id) =>
    state.chatMessages.find((e) => e.chatId === id)?.messages || [],
};

const createChatMessagePool = (state, chatMessage) => {
  const pool = {
    chatId: chatMessage.chat.id,
    messages: [],
  };
  state.chatMessages.push(pool);
  return pool;
};

const mutations = {
  ADD_CHATS(state, list) {
    state.chats.push(...list);
  },
  SET_CHATS(state, list) {
    state.chats = list;
  },
  REMOVE_CHAT(state, chatId) {
    state.chats = state.chats.filter((e) => e.id === chatId);
  },
  UPDATE_CHAT(state, chat) {
    const currentChat = state.chats.find((e) => e.id === chat.id);
    if (!currentChat) return;
    Vue.set(state.chats, state.chats.indexOf(chat), {
      ...currentChat,
      ...chat,
    });
  },
  UPDATE_CHAT_MESSAGE(state, { chatMessage }) {
    console.log("UPDATE_CHAT_MESSAGE", chatMessage, state.chats);
    const currentChat = state.chats.find(
      (e) => e.chat.id === chatMessage.chat.id
    );
    if (!currentChat) return;
    Vue.set(state.chats, state.chats.indexOf(currentChat), {
      ...currentChat,
      message: chatMessage,
    });
  },

  ADD_MESSAGE(state, { chatMessage }) {
    const chatMessagesItem =
      state.chatMessages.find((e) => e.chatId === chatMessage.chat.id) ||
      createChatMessagePool(state, chatMessage);
    const mIndex = chatMessagesItem.messages.findIndex(
      (m) => m.id === chatMessage.id
    );
    if (mIndex === -1) {
      chatMessagesItem.messages.push({
        id: chatMessage.id,
        sender: {
          id: chatMessage.sender.id,
        },
        text: chatMessage.text,
        createdAt: chatMessage.createdAt,
        updatedAt: chatMessage.updatedAt,
      });
    }
    chatMessagesItem.messages.sort((a, b) => a.id - b.id);
  },
};

const actions = {
  async addMessageComplex({ commit }, { chatMessage }) {
    commit("ADD_MESSAGE", { chatMessage });
    commit("UPDATE_CHAT_MESSAGE", { chatMessage });
  },

  async addIfNotExistsChat({ commit, getters }, { chatId, chatMessage }) {
    if (!getters.getChatById(chatId)) {
      const chat = await api.get(`/chats/${chatId}`);
      commit("ADD_CHATS", [{ chat, message: chatMessage }]);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
