<template>
  <Pie :chart-data="chartData" :chart-options="chartOptions" :data-pie="dataPie" width="500" height="500"/>
</template>

<script>
  import {Pie} from 'vue-chartjs/legacy'

  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
  } from 'chart.js'

  ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

  export default {
    name: 'PieChart',
    components: {Pie},
    props: {
      dataPie: {
          type: Object,
          required: true
      }
      // chartData: {
      //   type: Object,
      //   required: true
      // },
      // chartOptions: {
      //   type: Object,
      //   default: () => {
      //
      //   }
      // }
    },
    data() {
      return {
        chartData: {
          labels: this.dataPie.labels,
          datasets: [
            {
              backgroundColor:['#a262ef', '#e24295', '#ff6358'],
              data: this.dataPie.data
            }
          ]
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },


  }
</script>