<template>
  <div>
    <v-container :class="{'disabled' : loadingActivity}">
      <MyBreadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-tabs v-model="currentTab" class="mb-3" background-color="#f4f6f9" color="primary">
        <v-tab to="staff">
          Staff
        </v-tab>
        <v-tab to="transactions">
          {{ translationsAgencyLog.breadcrumbs.transactions }}
        </v-tab>
        <v-tab to="chats">
          Chats
        </v-tab>
        <v-tab to="activities">
          {{ translationsAgencyLog.breadcrumbs.activities }}

        </v-tab>
        <v-tab v-if="!this.$auth.isAdmin" to="withdrawal">
          {{ translationsAgencyLog.breadcrumbs.withdrawal }}
        </v-tab>
        <v-tab to="stats">
          {{ translationsAgencyLog.breadcrumbs.stats }}
        </v-tab>
        <v-tab to="settings">
          {{ translationsAgencyLog.breadcrumbs.settings }}
        </v-tab>
        <v-tab to="pay-out">
          {{ translationsAgencyLog.breadcrumbs.pay_out }}
        </v-tab>
      </v-tabs>
    </v-container>
    <PreloaderPage class="preloader" v-if="loading || loadingActivity"/>

    <v-container v-else class="my-5">
      <v-row>
        <v-col cols="9">
          <v-card class="mt-3 pa-2" outlined>
            <v-card-title class="pa-1">{{ translationsAgencyLogSettings.auto_message_card_title }}</v-card-title>
            <v-divider class="mb-3"/>
            <v-data-table
                :headers="headers"
                :items="autoMessageDefault"
                sort-by="id"
                class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar
                    flat
                >
                  <v-dialog
                      v-model="dialog"
                      max-width="500px"
                      persistent
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                      >
                        {{ translationsAgencyLogSettings.table_template.btn_new_item }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                            >
                              <v-textarea
                                  trim
                                  outlined
                                  v-model.trim="editedItem.templateContent"
                                  :label="translationsAgencyLogSettings.table_template.text_label"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            :disabled="loadingModal"
                            text
                            @click="closeModal"
                        >
                          {{ translations.GLOBAL_VALUE.cancel }}
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            :loading="loadingModal"
                            text
                            @click="saveModal"
                        >
                          {{ translations.GLOBAL_VALUE.save }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">{{ translations.GLOBAL_VALUE.delete_modal.title }}</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">{{ translations.GLOBAL_VALUE.delete_modal.cancel_btn }}</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{ translations.GLOBAL_VALUE.delete_modal.ok_btn }}</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click.stop="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>

            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card height="250" class="mt-3 pa-2" outlined>
            <v-card-title class="pa-1">{{ translationsAgencyLogSettings.commission_card_title }}</v-card-title>
            <v-divider class="mb-3"/>
            <v-text-field outlined label="Agency Percent" v-model="percentageDivision"
                          @blur="percentageAgencyBlur"></v-text-field>

            <v-text-field outlined label="Girl Percent" v-model="percentageDivisionGirl"
                          @blur="percentageGirlBlur"></v-text-field>
          </v-card>
        </v-col>
        <!--        <v-col cols="8">-->
        <!--          <v-card height="400" class="mt-3 pa-2" outlined>-->
        <!--            <v-card-title class="pa-1">Auto message template</v-card-title>-->
        <!--            <v-divider class="mb-3"/>-->

        <!--            <v-textarea height="300"-->
        <!--                        @blur="autoMessageBlur"-->
        <!--                        v-model="autoMessage"-->
        <!--                        auto-grow outlined-->
        <!--                        :messages="`Each new line is considered a separate message (${messageArray.length}/10).-->
        <!--                        If the field is empty, the default message will be used`"-->
        <!--            >-->
        <!--            </v-textarea>-->
        <!--          </v-card>-->
        <!--        </v-col>-->
        <v-col cols="3"></v-col>
        <v-col cols="3"></v-col>
      </v-row>
      <v-alert type="warning" :value="alertDanger" max-width="400px" border="top" class="alert">
        {{ alertDangerMessage }}
      </v-alert>
      <v-alert type="success" :value="alert" max-width="400px" border="top" class="alert">
        {{ alertMessage }}
      </v-alert>
    </v-container>
  </div>
</template>

<script>
import {getAgencyById, setAgencyPercent, setGirlPercent} from "@/api/agencyRequest";
import {api} from "@/providers/api";
import MyBreadcrumbs from "@/components/Breadcrumbs.vue";
import PreloaderPage from "@/components/PreloaderPage.vue";
import {mapGetters} from "vuex";

export default {
  name: "AgencyLogSettings",
  components: {PreloaderPage, MyBreadcrumbs},
  data: () => ({
    loadingActivity: false,
    currentTab: 6,
    percentageDivision: '50',
    percentageDivisionGirl: '15',
    percentageDefault: '',
    percentageDefaultGirl: '',
    autoMessageDefault: [],
    alert: false,
    alertDanger: false,
    loading: false,
    alertMessage: '',
    alertDangerMessage: '',
    dialog: false,
    dialogDelete: false,
    activityLoading: false,
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'Message',
        align: 'start',
        sortable: false,
        value: 'templateContent',
      },
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    deleteIndex: null,
    loadingModal: false,
  }),
  computed: {
    ...mapGetters('Agencies', [
      'getterDataById', 'getterAgencyById'
    ]),
    translations() {
      return this.$t('translation')
    },
    translationsAgencyLog() {
      return this.translations.AGENCY.AGENCY_LOG
    },
    translationsAgencyLogSettings() {
      return this.translationsAgencyLog.settings_page
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },

    currentAgency() {
      return this.getterAgencyById(+this.$route.params.id)
    },

    breadcrumbs() {
      return this.$auth.isAdmin ?  [
        { text: this.translations.NAVBAR.DASHBOARD.title, href: '/' },
        { text: this.translations.AGENCY.AGENCIES.title, href: '/agencies' },
        { text: `${this.currentAgency?.name || '-'} #${this.$route.params.id}`, href: 'staff' },
        { text: this.translationsAgencyLogSettings.title, disabled: true,}
      ] : [
        {text: `${this.currentAgency?.name || '-'} #${this.$route.params.id}`, href: 'staff'},
        {text: this.translationsAgencyLogSettings.title, disabled: true,}
      ];
    },
  },
  watch: {
    dialog(val) {
      val || this.closeModal()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  async created() {
    this.headers = [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: this.translationsAgencyLogSettings.table_template.headers.message,
        align: 'start',
        sortable: false,
        value: 'templateContent',
      },
      {text: this.translationsAgencyLogSettings.table_template.headers.actions, value: 'actions', sortable: false},
    ]

    if (this.currentAgency?.name === undefined) {
      this.loadingActivity = true;
    }
    await this.fetchData()
    this.loadingActivity = false;
  },
  methods: {
    async fetchData() {
      this.loading = true

      try {
        await this.fetchAgency()

      } finally {
        this.loading = false

      }
    },
    async fetchAgency() {
      const agencyById = await this.$store.dispatch("Agencies/fetchAgencyById", {id: this.$route.params.id})
      this.autoMessageDefault = agencyById.messageTemplates;
      this.percentageDefault = agencyById.agencyPercent * 100;
      this.percentageDefaultGirl = agencyById.girlPercent * 100;
      this.percentageDivision = this.percentageDefault;
      this.percentageDivisionGirl = this.percentageDefaultGirl
    },
    editItem(item) {
      console.log('item', item)
      this.editedIndex = this.autoMessageDefault.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.autoMessageDefault.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
      this.deleteIndex = item.id
      console.log(this.deleteIndex)
    },

    async deleteItemConfirm() {
      await api.delete(`/admin/agencies/template/${this.deleteIndex}`)
      await this.fetchAgency()
      this.closeDelete()
    },

    closeModal() {
      this.loadingModal = false
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async saveModal() {
      this.loadingModal = true
      if (this.editedIndex > -1) {
        await api.patch(`/admin/agencies/template/${this.autoMessageDefault[this.editedIndex].id}`, {
          template: this.editedItem.templateContent
        })
        await this.fetchAgency()

        // Object.assign(this.autoMessageDefault[this.editedIndex], this.editedItem)
        console.log('edit', this.editedItem.templateContent)
      } else {
        await api.post(`/admin/agencies/${this.$route.params.id}/template`, {
          template: this.editedItem.templateContent

        })
        await this.fetchAgency()
      }
      this.closeModal()
    },
    async percentageAgencyBlur() {
      if (this.percentageDefault !== this.percentageDivision) {
        console.log('percentage !=');
        const r = await setAgencyPercent(this.$route.params.id, this.percentageDivision / 100)
        if (r.success) {
          this.alert = true;
          this.alertMessage = this.translationsAgencyLogSettings.alert_message
          setTimeout(() => {
            this.alert = false;
          }, 2000);
          this.percentageDefault = this.percentageDivision;
        }

      } else console.log('percentage ===');
    },
    async percentageGirlBlur() {
      if (this.percentageDefaultGirl !== this.percentageDivisionGirl) {
        console.log('percentage !=');
        const r = await setGirlPercent(this.$route.params.id, this.percentageDivisionGirl / 100)
        console.log(r)
        if (r.success) {
          this.alert = true;
          this.alertMessage = this.translationsAgencyLogSettings.alert_message
          setTimeout(() => {
            this.alert = false;
          }, 2000);
          this.percentageDefaultGirl = this.percentageDivisionGirl;
        }

      } else console.log('percentage ===');
    },

  }
}
</script>

<style scoped lang="scss">
.preloader {
  position: absolute;
  top: 0;
}
.alert{
  position: fixed;
  top: 70px;
  right: 10px;
}
.warning-text{
  &.active{
    color: orangered;
  }
}

.disabled {
  opacity: .6;
  pointer-events: none;
}
</style>