<template>
  <Bar :chart-data="chartData" :chart-options="chartOptions" :height="200" />
</template>

<script>
  import { Bar } from 'vue-chartjs/legacy'
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
  } from 'chart.js'

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


  export default {
    name: 'BarChart',
    components: { Bar },
    props: {
      // dataBar: {
      //   type: Object,
      //   required: true
      // },
      chartData: {
        type: Object,
        required: true
      },
      chartOptions: {
        type: Object,
        default: () => {

        }
      }
    },
    data() {
      return {
        // chartData: {
        //   labels: this.dataBar.labels,
        //   datasets: [
        //     {
        //       label: this.dataBar.label,
        //       backgroundColor: this.dataBar.backgroundColor,
        //       data: this.dataBar.data
        //     }
        //   ]
        // },
        // chartOptions: {
        //   responsive: true,
        //   maintainAspectRatio: false
        // }
      }
    },
  }
</script>