<template>
  <div>
    <v-container class="my-5">
      <v-data-table
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :items-per-page="10"
          :sort-by="'name'"
          :sort-desc="false"
          :headers="headersPrice"
          :items="prices"
          item-key="name"
          class="elevation-1"
          :loading="pricesLoading"

      >
        <template v-slot:foot>
          <div class="actions-container">
            <v-card-title> {{ creditsRateTitle }} {{ getActiveCurrencyRate }} </v-card-title>
            <v-btn v-if="getActiveCreditRate" @click.native="editItemCreditRate(getActiveCreditRate)"> Edit </v-btn>
          </div>
        </template>
        <template v-slot:top>
          <v-toolbar flat color="primary">
            <v-toolbar-title class="title">{{ translationsCommission.table_price.title }}</v-toolbar-title>
          </v-toolbar>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Edit</v-card-title>
              <v-card-text>
                <p>{{ name }}</p>
                <v-spacer></v-spacer>
                <v-text-field
                    type="number"
                    v-model="value"
                    label="Value"
                    required
                ></v-text-field>
                <v-text-field
                    v-model="agencyPercent"
                    label="Agency Percent"
                    type="number"
                    required
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-card-text>
              <v-card-actions>

                <v-btn color="blue darken-1" text @click="editItemConfirmPrices"
                >
                  {{ translations.GLOBAL_VALUE.save }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialog3" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Edit Credit Rate</v-card-title>
              <v-card-text>
                <p> {{ name }}</p>
                <v-spacer></v-spacer>
                <v-text-field
                    type="number"
                    v-model="value"
                    label="Value"
                    required
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-card-text>
              <v-card-actions>

                <v-btn color="blue darken-1" text @click="editItemConfirmCreditsRate"
                >
                  {{ translations.GLOBAL_VALUE.save }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
              small
              @click="editItemPrices(item)"
          >
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>

      <v-data-table
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :items-per-page="10"
          :headers="headersTax"
          :loading="taxLoading"
          :items="tax"
          item-key="name"
          class="elevation-1"

      >
        <template v-slot:top>
          <v-toolbar flat color="primary">
            <v-toolbar-title class="title">{{ translationsCommission.table_tax.title }}</v-toolbar-title>
          </v-toolbar>
          <v-dialog v-model="dialog2" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Edit</v-card-title>
              <v-card-text>
                <p>{{ name }}</p>
                <v-spacer></v-spacer>
                <v-text-field
                    type="number"
                    v-model="value"
                    label="Value"
                    required
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-card-text>
              <v-card-actions>

                <v-btn color="blue darken-1" text @click="editItemConfirmTax"
                >
                  {{ translations.GLOBAL_VALUE.save }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
              small
              @click="editItemTax(item)"
          >
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import {
  changeCommissionPrices,
  changeCommissionTax, changeCreditsRate,
  getCommissionPrices,
  getCommissionTax
} from '@/api/commissionRequest';
import BigNumber from "bignumber.js";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: 'CommissionPrice',
  data: () => ({
    headers: [
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Value',
        value: 'value'
      },
      {
        text: 'Agency Percent (%)',
        value: 'agencyPercent'
      },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    id: -1,
    creditsRateTitle: 'Credit Rate ($): ',
    headersPrice: [],
    headersCreditsRate: [],
    headersTax: [],
    prices: [],
    tax: [],
    creditsRateLoading: false,
    dialog: false,
    dialog2: false,
    dialog3: false,
    name: '',
    value: '',
    agencyPercent: '',
    pricesLoading: false,
    taxLoading: false,
  }),
  computed: {
    ...mapState(['creditsRate']),
    ...mapGetters(['getActiveCurrencyRate', 'getActiveCreditRate']),
    translations() {
      return this.$t('translation')
    },
    translationsCommission() {
      return this.translations.COMMISSION_PRICE
    },
    activeCreditsRate() {
      return this.getActiveCurrencyRate;
    }
  },
  created() {
    this.headersPrice = [
      {
        text: this.translationsCommission.table_price.headers.name,
        value: 'name'
      },
      {
        text: 'Agency Percent (%)',
        value: 'agencyPercent'
      },
      {
        text: 'Agency Revenue ($)',
        value: 'agencyRevenue'
      },
      {
        text: this.translationsCommission.table_price.headers.value,
        value: 'value'
      },
      { text: this.translationsCommission.table_price.headers.actions, value: 'actions', sortable: false },
    ]

    this.headersCreditsRate = [
      {
        text: this.translationsCommission.table_price.headers.name,
        value: 'name'
      },
      {
        text: 'Credit',
        value: 'credit'
      },
      {
        text: 'Rate',
        value: 'rate'
      },
      { text: this.translationsCommission.table_price.headers.actions, value: 'actions', sortable: false },
    ]
    this.headersTax = [
      {
        text: this.translationsCommission.table_price.headers.name,
        value: 'name'
      },
      {
        text: this.translationsCommission.table_price.headers.value + ' (%)',
        value: 'value'
      },
      { text: this.translationsCommission.table_price.headers.actions, value: 'actions', sortable: false },
    ]
    this.fetchPrices();
    this.fetchTax();
    this.fetchCreditsRate();
  },

  methods: {
    ...mapActions(['fetchCurrentRate']),
    async fetchPrices() {
      this.pricesLoading = true;

      const prices = await getCommissionPrices();

      this.prices = prices.map(priceObj => {
        let agencyRevenue = (this.activeCreditsRate * priceObj.value) * priceObj.agencyPercent;
        const balance = new BigNumber(agencyRevenue)
        priceObj.agencyRevenue = balance.toFixed(2, 4)
        priceObj.agencyPercent = (priceObj.agencyPercent * 100).toFixed(2, 3)
        if (priceObj.name === "PHOTO_DEFAULT_PRICE") {
          return {
            ...priceObj,
            name: "PRIVATE_PHOTO_PRICE"
          };
        }
        return priceObj;
      });


      this.pricesLoading = false;
      return this.prices;
    },
    async fetchCreditsRate() {
      this.creditsRateLoading = true
      await this.fetchCurrentRate();
      this.creditsRateLoading = false
    },
    async fetchTax() {
      this.taxLoading = true
      const tax = await getCommissionTax();
      this.tax = tax.map(item => {
        item.value = (item.value * 100).toFixed(2, 3)
        return item
      })
      this.taxLoading = false
      this.tax = [this.tax.find(item => item.name === 'DEFAULT_GIRL_PERCENT')]
      return this.tax;
    },
    editItemPrices(item) {
      this.dialog = true;
      this.value = item.value;
      this.agencyPercent = item.agencyPercent;
      this.name = item.name === 'PRIVATE_PHOTO_PRICE' ? 'PHOTO_DEFAULT_PRICE' : item.name;
    },
    editItemTax(item) {
      this.dialog2 = true;
      this.value = item.value;
      this.name = item.name;
    },
    editItemCreditRate(item) {
      this.dialog3 = true;
      this.value = item.rate
      this.id = item.id
      this.name = item.name
    },
    convertPercent(value) {
      return +value * 100
    },
    async editItemConfirmTax() {
      let convertedNumber = (Number(this.value) / 100).toFixed(4);
      await changeCommissionTax(convertedNumber, this.name);
      this.dialog2 = false;
      await this.fetchTax();
    },
    async editItemConfirmCreditsRate() {
      const convertedNumber = Number(this.value);
      await changeCreditsRate(this.id, convertedNumber);
      this.dialog3 = false;
      await this.fetchCurrentRate();
      await this.fetchPrices();
    },
    async editItemConfirmPrices() {
      let percent = (Number(this.agencyPercent) / 100).toFixed(4);
      await changeCommissionPrices(+this.value, this.name, percent);
      this.dialog = false;
      await this.fetchPrices();
    }
  }
};
</script>

<style scoped lang="scss">
.actions-container {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
}
.v-data-table {
  margin-bottom: 30px;
}

img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.user-img {
  display: flex;
  align-items: center;
}

.title {
  color: #f4f6f9;
}

.photo-img {
  margin-top: 3px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    object-fit: cover;
  }

}
</style>
