<template>
  <div>
    <v-container :class="{'disabled' : activityLoading}">
      <MyBreadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-tabs v-model="currentTab" class="mb-3" background-color="#f4f6f9" color="primary">
        <v-tab to="staff">
          Staff
        </v-tab>

        <v-tab to="transactions">
          Transactions
        </v-tab>

        <v-tab to="chats">
          Chats
        </v-tab>
        <v-tab to="activities">
          Activities
        </v-tab>
        <v-tab v-if="!this.$auth.isAdmin" to="withdrawal">
          Withdrawal
        </v-tab>
        <v-tab to="stats">
          Stats
        </v-tab>
        <v-tab to="settings">
          Settings
        </v-tab>
        <v-tab to="pay-out">
          Pay Out
        </v-tab>
      </v-tabs>
    </v-container>
    <PreloaderPage class="preloader" v-if="loadingStats || activityLoading"/>
    <v-container :class="{'disabled' : loadingStats || activityLoading}" class="my-5">
      <v-row class="ma-5">
        <v-col class="col-content" cols="12" md="12" lg="12">
          <v-select v-model="select"
                    @change="changeTimeChart"
                    :items="items"
                    class="select-time-chart"
                    outlined
          >
          </v-select>
        </v-col>
        <v-col v-for="(item, index) in data" :key="index" class=" col-content" cols="12" md="6" lg="6">
          <v-card class="pa-1" color="#f4f6f9">
            <BarChart :chart-data="item"/>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PreloaderPage from "@/components/PreloaderPage.vue";
import MyBreadcrumbs from "@/components/Breadcrumbs.vue";
import BarChart from "@/components/BarChart.vue";
import {mapGetters} from "vuex";
import {api} from "@/providers/api";
import {format, startOfWeek} from "date-fns";

export default {
  name: "AgencyLogStats",
  components: {
    BarChart,
    MyBreadcrumbs,
    PreloaderPage},
  data: () => ({
    activityLoading: false,
    currentTab: 5,
    select: '',
    items: ['1 day', '7 days', '30 days', 'all days'],
    data: [
      {
        labels: [null],
        labelStatic: 'Hours online',
        title: 'minutesOnline',
        datasets: [
          {
            label: 'Hours online - 1 day',
            backgroundColor: '#ff6961',
            borderColor: '#ff6961',
            tension: 0.1,
            data: [],
          }
        ]
      },

      {
        labels: [null],
        labelStatic: 'Messages sent',
        title: 'messageSent',
        datasets: [
          {
            label: 'Messages sent - 1 day',
            backgroundColor: '#ffb480',
            borderColor: '#ffb480',
            tension: 0.1,
            data: [],
          }
        ]
      },

      {
        labels: [null],
        labelStatic: 'Messages received',
        title: 'messageReceived',
        datasets: [
          {
            label: 'Messages received - 1 day',
            backgroundColor: '#ff80fd',
            borderColor: '#ff80fd',
            tension: 0.1,
            data: [],
          }
        ]
      },

      {
        labels: [null],
        labelStatic: 'Flirt sent',
        title: 'flirtsSent',
        datasets: [
          {
            label: 'Flirt sent - 1 day',
            backgroundColor: '#f8f38d',
            borderColor: '#f8f38d',
            tension: 0.1,
            data: [],
          }
        ]
      },

      {
        labels: [null],
        labelStatic: 'Flirt received',
        title: 'flirtsReceived',
        datasets: [
          {
            label: 'Flirt received - 1 day',
            backgroundColor: '#42d6a4',
            borderColor: '#42d6a4',
            tension: 0.1,
            data: [],
          }
        ]
      },

      {
        labels: [null],
        labelStatic: 'Photo likes received',
        title: 'likesReceived',
        datasets: [
          {
            label: 'Photo likes received - 1 day',
            backgroundColor: '#bc75ff',
            borderColor: '#bc75ff',
            tension: 0.1,
            data: [],
          }
        ]
      },
      {
        labels: [null],
        labelStatic: 'Time spent in chat (hour(s))',
        title: 'timeSpentInChat',
        datasets: [
          {
            label: 'Time spent in chat - 1 day',
            backgroundColor: '#08cad1',
            borderColor: '#08cad1',
            tension: 0.1,
            data: [],
          }
        ]
      },

      {
        labels: [null],
        labelStatic: 'Time spent CamShare chat (hour(s))',
        title: 'timeSpentInVideoChat',
        datasets: [
          {
            label: 'Time spent CamShare chat - 1 day',
            backgroundColor: '#59adf7',
            borderColor: '#59adf7',
            tension: 0.1,
            data: [],
          }
        ]
      },

      {
        labels: [null],
        labelStatic: 'Unique men',
        title: 'uniqueMan',
        datasets: [
          {
            label: 'Unique men - 1 day',
            backgroundColor: '#9d94ff',
            borderColor: '#9d94ff',
            tension: 0.1,
            data: [],
          }
        ]
      },

      {
        labels: [null],
        title: 'agencyProfit',
        labelStatic: 'Agency Profit (usd)',
        datasets: [
          {
            label: 'Agency Profit (usd) - 1 day',
            backgroundColor: '#98ff62',
            borderColor: '#98ff62',
            tension: 0.1,
            data: [],
          }
        ]
      },
    ],
    loadingStats: false,
    agencyName: ''
  }),
  computed: {
    ...mapGetters('Agencies', [
      'getterDataById', 'getterAgencyById'
    ]),

    currentAgency() {
      return this.getterAgencyById(+this.$route.params.id)
    },

    breadcrumbs() {
      return this.$auth.isAdmin ?  [
        { text: 'Dashboard', href: '/' },
        { text: 'Agencies', href: '/agencies' },
        { text: `${this.currentAgency?.name || '-'} #${this.$route.params.id}`, href: 'staff' },
        { text: 'Stats', disabled: true,}
      ] : [
        {text: `${this.currentAgency?.name || '-'} #${this.$route.params.id}`, href: 'staff'},
        {text: 'Stats', disabled: true,}
      ];
    },
  },
  async created() {
    if (this.currentAgency?.name === undefined) {
      this.activityLoading = true;
      await this.$store.dispatch("Agencies/fetchAgencyById", {id: this.$route.params.id})
      this.activityLoading = false;
    }
    this.select = this.items[0];
    await this.fetchAllData(1);
  },
  methods: {
    async fetchAllData(day = '') {
      let isActiveAllDay = day === ''
      let title = isActiveAllDay ? `/v2/admin/agencies/stats/all` : `/v2/admin/agencies/stats/all?days=${day}`
      this.loadingStats = true
      await api.get(title).then(r => {
          this.data = this.data.map(item => {
            let result = r?.[item.title] ? r[item.title]: [];
            let keys = Object.keys(result);
            let itemTime = result;
            if(isActiveAllDay) {
              let month = new Set(keys.map(date => format(new Date(date), 'yyyy-MM')))
              if(month.size > 2 && month.size <= 12) {
                const getWeekYearKey = date => format(startOfWeek(new Date(date), { weekStartsOn: 1 }), 'yyyy-MM-dd')
                itemTime = this.getTime(result, getWeekYearKey)
              } else if(month.size > 2) {
                const getMonthYearKey = date => format(new Date(date), 'yyyy-MM')
                itemTime = this.getTime(result, getMonthYearKey)
              }
            }
            let resultTime = []
            for (const key in itemTime) {
              if (itemTime.hasOwnProperty(key)) {
                resultTime.push({
                  key: key,
                  value: itemTime[key]
                })
              }
            }
            resultTime.sort((a, b) => new Date(a.key) - new Date(b.key))
            keys = resultTime.map(item => item.key)
            let values = resultTime.map(item => item.value)
            item.labels = [...keys]
            if(item.title === 'minutesOnline' || item.title === 'timeSpentInChat' || item.title === 'timeSpentInVideoChat') {
              values = values.map(item => {
                return (item / 60).toFixed(2);
              })
            }
            item.datasets[0].data = [...values];
            item.datasets[0].label = day !== '' ? `${item.labelStatic} - ${day} day(s)` : `${item.labelStatic} - all days`;
            return item
          })
      }).finally(() => this.loadingStats = false).catch(e => e)
    },

    getTime(time, func) {
      let item = {};
      for (const [date, value] of Object.entries(time)) {
        const monthYear = func(date)
        if (!item[monthYear]) {
          item[monthYear] = 0
        }
        item[monthYear] += value
      }
      return item;
    },

    changeTimeChart(select) {
      if (select === '1 day') {
        this.fetchAllData(1)
      } else if (select === '7 days') {
        this.fetchAllData(7)
      } else if (select === '30 days') {
        this.fetchAllData(30)
      } else if (select === 'all days') {
        this.fetchAllData()
      }
    },
  }
}
</script>

<style scoped>
.select-time-chart{
  max-width: 150px;
}
.preloader {
  position: absolute;
  top: 0;
}
.disabled {
  opacity: .6;
  pointer-events: none;
}
</style>