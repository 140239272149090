<template>
  <div>
    <v-container class="my-5">


      <v-btn
          @click="back"
          color="primary"
          class="mb-2"
          outlined
      >
        <v-icon left>
          mdi-arrow-left-thin
        </v-icon>
        Back
      </v-btn>

      <v-data-table
          :footer-props="{'items-per-page-options': [10, 50, 100]}"
          :items-per-page="10"
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="options"
          :loading="loading"
          class="elevation-1"
          key="id"

      >
        <template v-slot:top>
          <v-toolbar dark flat color="primary">
            <v-toolbar-title> Chat Messages #{{ $route.params.id }}</v-toolbar-title>
          </v-toolbar>

          <!--          <DeleteModal :dialog="dialogDelete" @close-options="closeDelete" @save-options="deleteProps"/>-->

        </template>

        <template v-slot:[`item.from`]="{ item }">
          <v-list-item
              class="pa-0"
              dense
          >
            <v-list-item-avatar size="35" class="mr-3">
              <v-list-item-avatar class="mr-0">
                <v-img :src="$getAvatarImage(getSender(members, item.sender.id).profile.avatar)"></v-img>
              </v-list-item-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ getSender(members, item.sender.id).name }}</v-list-item-title>
            </v-list-item-content>

          </v-list-item>

        </template>

        <template v-slot:[`item.to`]="{ item }">
          <v-list-item
              class="pa-0"
              dense
          >
            <v-list-item-avatar size="35" class="mr-3">
              <v-list-item-avatar class="mr-0">
                <v-img :src="$getAvatarImage(getSender(members, item.sender.id, false).profile.avatar)"></v-img>
              </v-list-item-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ getSender(members, item.sender.id, false).name }}</v-list-item-title>
            </v-list-item-content>

          </v-list-item>

        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          {{ new Date(item.createdAt) | date('Pp') }}
        </template>
        <!--      <template v-slot:[`item.category`]="{ item }">-->
        <!--        {{ item.category }}-->
        <!--      </template>-->

      </v-data-table>
    </v-container>
  </div>
</template>

<script>

import {api} from "@/providers/api";

export default {
  name: "AgencyLogMessages",

  data: () => ({
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'From',
        sortable: false,
        value: 'from',
      },
      {
        text: 'To',
        sortable: false,
        value: 'to',
      },
      {
        text: 'Message',
        sortable: false,
        value: 'text',
      },
      {
        text: 'CreatedAt',
        sortable: false,
        value: 'createdAt',
      },
    ],
    options: {},
    totalItems: 0,
    loading: true,
    items: [],
    members: []
  }),
  watch: {
    options: {
      handler() {
        this.syncDataFromApi()
      },
      deep: true
    }
  },

  methods: {
    getSender(users, senderId, from = true) {
      if (from) {
        return users.find(e => e.id === senderId)
      }
      else {
        return users.find(e => e.id !== senderId)

      }



    },
    back() {
      const str = this.$route.fullPath;
      const match = str.match(/\/agencies\/(\d+)\/messages/);

      if (match) {
        const number = parseInt(match[1], 10);
        this.$router.push({path: `/agencies/${number}/chats`})
      }
    },

    async syncDataFromApi() {
      this.loading = true
      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.options
        const response = await api.get(`/admin/agencies/chat/${this.$route.params.id}`, {
          params: {
            ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : [],
            limit: limit === -1 ? 100 : limit,
            page,

          },

        })

        this.items = response.items;
        this.members = response.members;
        this.totalItems = response.meta.totalItems
      } catch (e) {
        console.log('Error', e)
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style scoped>

</style>