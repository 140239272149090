import {api} from "@/providers/api";

export async function getGifts() {
  return api.get('/flirts/gifts')
}

export async function deleteGift(id) {
  return api.delete(`/flirts/gifts/${id}`)
}

export async function postEditGift(id, price) {
  return await api.patch(`/flirts/gifts/${id}`, {}, {
    params: {
      price: price

    }
  });
}

export async function postAddGift(price, formData) {
  return await api.post(`/flirts/gifts?price=${price}`, formData);
}