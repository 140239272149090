<template >
  <modal
      :name="$options.name"
      :width="860" height="90%"
      @before-open="beforeOpen"
      @opened="opened"
      @before-close="beforeClose"
  >
    <div
        class="wrap"
    >
      <img
          ref="cropper"
          :src="image"
      /></div>
    <button
        class="apply-btn"
        @click="generateImage"
    >
      Apply
    </button>
  </modal>
</template>

<script>
import 'cropperjs/dist/cropper.min.css';
import Cropper from 'cropperjs';

export default {
  name: 'CropImageModal',

  props: {
    aspectRatio: { default: 1, type: Number }
  },

  data() {
    return {
      onComplete: () => null,
      targetFile: null,
      image: null,
      cropper: null,
      // cropperOptions: {
      //
      // }
    };
  },

  computed: {
    cropperOptions() {
      return {
        aspectRatio: this.aspectRatio,
        background: false,
        viewMode: 1
      };
    }
  },

  methods: {
    reset() {
      this.image = null;
      this.targetFile = null;
      this.cropper = null;
      this.onComplete = () => null;
    },

    beforeOpen({ params }) {
      this.reset();
      this.setInitialParams(params);
      this.setInitialImageSrc();
    },

    setInitialParams(params) {
      this.targetFile = params.file;
      this.onComplete = params.onComplete;
    },

    setInitialImageSrc() {
      this.image = URL.createObjectURL(this.targetFile);
    },

    beforeClose() {
    },

    generateImage() {
      const canvas = this.cropper.getCroppedCanvas();
      if (canvas) {
        canvas.toBlob(blob => {
          this.onComplete({ blob });
          this.$modal.hide(this.$options.name);
        }, 'image/jpeg');
      }
    },
    initCropper() {
      this.cropper = new Cropper(this.$refs.cropper, {
        ...this.cropperOptions
      });
    },
    opened() {
      this.initCropper();
    }
  }
};
</script>

<style lang="scss" scoped>

.cropper-object {
  height: 100%;
  width: 100%;
  border: solid 1px #EEE;
}

.wrap {
  position: relative;
  height: 100%;

  img {
    height: 100%;
  }
}

.apply-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
