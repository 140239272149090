<template>
  <div>
    <div v-for="(fields, category) in localSearchFields" :key="category" class="my-2">
      <div class="d-flex align-center">
        <p class="ml-3 mr-2 mb-0">{{ category.toUpperCase() }}</p>
        <v-btn
            v-if="fields[0].multiple"
            icon
            color="green"
            @click="addSearchField(category)"
        >
          <v-icon>mdi-plus-circle</v-icon>
        </v-btn>
      </div>
      <div v-for="(field, index) in fields" :key="index" class="d-flex align-center">
        <v-menu
            v-if="isDatepicker(category)"
            v-model="field.datePickerModel"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="field.value"
                hide-details
                single-line
                outlined
                dense
                clearable
                class="mb-2 mx-3"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="field.value"
              @input="field.datePickerModel = false"
          ></v-date-picker>
        </v-menu>

        <v-autocomplete
            v-else-if="isUsersSearch(category)"
            class="mb-2 mx-3"
            hide-details
            single-line
            outlined
            dense
            clearable
            v-model="field.value"
            :items="field.items"
            item-text="email"
            item-value="email"
            @update:search-input="val  => debouncedChangeAuto(category, index, val)"
        >
          <template v-slot:item="{ item }">
            <v-list-item-avatar>
              <img :src="$getAvatarImage(item.profile.avatar)">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="item.name"></v-list-item-title>
              <v-list-item-subtitle v-html="item.email"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-select
            v-else-if="field.isSelect"
            v-model="field.value"
            :items="field.items"
            hide-details
            single-line
            outlined
            dense
            clearable
            class="mb-2 mx-3"

        ></v-select>

        <v-text-field
            v-else
            v-model="field.value"
            hide-details
            single-line
            outlined
            clearable
            dense
            :type="isNumberType(category) ? 'number' : 'string'"
            class="mb-2 mx-3"
        ></v-text-field>
        <v-btn
            v-if="fields.length > 1 && index > 0"
            class="mr-1"
            icon
            color="red"
            @click="removeSearchField(category, index)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>
    <v-btn
        color="green"
        class="ml-3"
        dark
        @click="updateFields"
    >
      Search
    </v-btn>
    <v-btn
        :disabled="isClearDisabled"
        color="blue"
        class="ml-3"
        :dark="!isClearDisabled"
        @click="$emit('clear-fields')"
    >
      Clear
    </v-btn>
  </div>
</template>

<script>
import { api } from "@/providers/api";
import { debounce } from "lodash";
import Vue from "vue";

export default {
  name: "TableSearchFields",
  props: {
    searchFields: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      menu: false,
      debouncedChangeAuto: null,
      localSearchFields: JSON.parse(JSON.stringify(this.searchFields))
    };
  },
  watch: {
    searchFields: {
      handler(newVal) {
        this.localSearchFields = JSON.parse(JSON.stringify(newVal));
        this.initializeDatePickerModels();
      },
      deep: true
    },
  },
  computed: {
    isClearDisabled() {
      return !Object.values(this.localSearchFields).some(fields =>
          fields.some(field => field.value)
      );
    },
    isNumberType() {
      const arr = ['id', 'chatId'];
      return type => arr.includes(type);
    },
    isUsersSearch() {
      const arr = ['from', 'to'];
      return type => arr.includes(type);
    },
    isDatepicker() {
      const arr = ['created', 'time_Start', 'time_End', 'sent'];
      return type => arr.includes(type);
    },
  },
  created() {
    this.debouncedChangeAuto = debounce(this.changeAuto, 400);
  },
  mounted() {
    this.initializeDatePickerModels();
  },
  methods: {
    updateFields() {
      this.applySearchCriteria();
    },
    applySearchCriteria() {
      const searchCriteria = {};
      const arrayFields = ['id', 'from', 'to'];

      const isDateString = (value) => {
        return /^\d{4}-\d{2}-\d{2}$/.test(value);
      };

      for (const [category, fields] of Object.entries(this.localSearchFields)) {
        const values = fields.map(field => field.value).filter(value => value);

        const formattedValues = values.map(value => {
          if (isDateString(value)) {
            return Date.parse(value);
          }
          return value;
        });

        const key = fields[0].name;
        if (formattedValues.length > 1) {
          searchCriteria[key] = formattedValues;
        } else if (formattedValues.length === 1) {
          if (arrayFields.includes(key)) {
            searchCriteria[key] = [formattedValues[0]];
          } else {
            searchCriteria[key] = formattedValues[0];
          }
        }
      }
      this.$emit('update-fields', { searchFields: this.localSearchFields, searchCriteria: searchCriteria });
    },
    async changeAuto(category, index, val) {
      const res = await api.get('/admin/users', {
        params: {
          s: {
            "$and": [
              {"$or": [{"name": val.trim()}, {"$or": [{"email": {"$cont": val.trim()}}, {"deletedEmail": {"$cont": val.trim()}}]}]}
            ]
          }
        }
      }).then(r => r.data);
      Vue.set(this.localSearchFields[category][index], 'items', res);
    },
    addSearchField(category) {
      this.$emit('add-field', { searchFields: this.localSearchFields, category });
    },
    removeSearchField(category, index) {
      this.$emit('remove-field', { category, index });
    },
    initializeDatePickerModels() {
      for (const [category, fields] of Object.entries(this.localSearchFields)) {
        if (this.isDatepicker(category)) {
          fields.forEach(field => {
            if (!field.hasOwnProperty('datePickerModel')) {
              Vue.set(field, 'datePickerModel', null);
            }
          });
        }
      }
    }
  },
};
</script>
