var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.activityLoading)?_c('PreloaderPage',{staticClass:"preloader-line"}):_vm._e(),_c('v-container',{class:{'disabled' : _vm.activityLoading}},[_c('MyBreadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('v-tabs',{staticClass:"mb-3",attrs:{"background-color":"#f4f6f9","color":"primary"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab',{attrs:{"to":"staff"}},[_vm._v(" Staff ")]),_c('v-tab',{attrs:{"to":"transactions"}},[_vm._v(" "+_vm._s(_vm.translationsAgencyLog.breadcrumbs.transactions)+" ")]),_c('v-tab',{attrs:{"to":"chats"}},[_vm._v(" Chats ")]),_c('v-tab',{attrs:{"to":"activities"}},[_vm._v(" "+_vm._s(_vm.translationsAgencyLog.breadcrumbs.activities)+" ")]),(!this.$auth.isAdmin)?_c('v-tab',{attrs:{"to":"withdrawal"}},[_vm._v(" "+_vm._s(_vm.translationsAgencyLog.breadcrumbs.withdrawal)+" ")]):_vm._e(),_c('v-tab',{attrs:{"to":"stats"}},[_vm._v(" "+_vm._s(_vm.translationsAgencyLog.breadcrumbs.stats)+" ")]),_c('v-tab',{attrs:{"to":"settings"}},[_vm._v(" "+_vm._s(_vm.translationsAgencyLog.breadcrumbs.settings)+" ")]),_c('v-tab',{attrs:{"to":"pay-out"}},[_vm._v(" "+_vm._s(_vm.translationsAgencyLog.breadcrumbs.pay_out)+" ")])],1)],1),_c('v-container',{staticClass:"my-5"},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headersTransaction,"items":_vm.transactionsFilter,"server-items-length":_vm.totalItems,"options":_vm.options,"loading":_vm.transactionsLoading,"item-key":"name"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.createdAt),'Pp')))]}},{key:"item.parentTransaction",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.categoryFormat(item.parentTransaction))+" ")]}},{key:"item.parentTransaction.fromBalance.owner.email",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"pa-0",attrs:{"dense":""},on:{"click":function($event){return _vm.userPush(item.parentTransaction.fromBalance.owner)}}},[_c('v-list-item-avatar',{staticClass:"mr-3",attrs:{"size":"35"}},[_c('v-list-item-avatar',{staticClass:"mr-0"},[_c('v-img',{attrs:{"src":_vm.$getAvatarImage(item.parentTransaction.fromBalance.owner.profile.avatar)}})],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.parentTransaction.fromBalance.owner.name))]),(_vm.$auth.isAdmin)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.parentTransaction.fromBalance.owner.email))]):_vm._e()],1)],1)]}},{key:"item.destinationUser.email",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"pa-0",attrs:{"dense":""},on:{"click":function($event){return _vm.userPush(item.destinationUser)}}},[_c('v-list-item-avatar',{staticClass:"mr-3",attrs:{"size":"35"}},[_c('v-list-item-avatar',{staticClass:"mr-0"},[_c('v-img',{attrs:{"src":_vm.$getAvatarImage(item.parentTransaction.destinationUser.profile.avatar)}})],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.parentTransaction.destinationUser.name))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.parentTransaction.destinationUser.email))])],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }