<template>
  <div>
    <v-container class="my-5">
      <v-tabs background-color="#f4f6f9">

        <v-tab  @click="refreshAgency">{{ translationsUsersAgency.pending }}</v-tab>
        <v-tab-item class="background">
          <UsersAgencyTable :refresh-agency="refreshAgencyStatus" :props-items-agency="itemsPending" @call-fetch-agency="updateDataAgency"/>
        </v-tab-item>

        <v-tab @click="refreshAgency">{{ translationsUsersAgency.processing }}</v-tab>
        <v-tab-item class="background">
          <UsersAgencyTable :refresh-agency="refreshAgencyStatus" :props-items-agency="itemsProcessing" @call-fetch-agency="updateDataAgency"/>
        </v-tab-item>

        <v-tab @click="refreshAgency">{{ translationsUsersAgency.approved }}</v-tab>
        <v-tab-item class="background">
          <UsersAgencyTable :refresh-agency="refreshAgencyStatus" :props-items-agency="itemsApproved" status-request="APPROVED" @call-fetch-agency="updateDataAgency"/>
        </v-tab-item>

        <v-tab @click="refreshAgency">{{ translationsUsersAgency.rejected }}</v-tab>
        <v-tab-item class="background">
          <UsersAgencyTable :refresh-agency="refreshAgencyStatus" :props-items-agency="itemsRejected" @call-fetch-agency="updateDataAgency"/>
        </v-tab-item>

        <v-tab @click="refreshAgency">{{ translationsUsersAgency.rework }}</v-tab>
        <v-tab-item  class="background">
          <UsersAgencyTable :refresh-agency="refreshAgencyStatus" :props-items-agency="itemsRework" @call-fetch-agency="updateDataAgency"/>
        </v-tab-item>

        <v-tab @click="refreshAgency">{{ translationsUsersAgency.contract_awaiting }}</v-tab>
        <v-tab-item  class="background">
          <UsersAgencyTable :refresh-agency="refreshAgencyStatus" :props-items-agency="itemsContractProc" @call-fetch-agency="updateDataAgency"/>
        </v-tab-item>

        <v-tab @click="refreshAgency">{{ translationsUsersAgency.contract_moderation }}</v-tab>
        <v-tab-item  class="background">
          <UsersAgencyTable :refresh-agency="refreshAgencyStatus" :props-items-agency="itemsContractReview" @call-fetch-agency="updateDataAgency"/>
        </v-tab-item>

      </v-tabs>
    </v-container>
  </div>
</template>

<script>


import {getBecomeAgency, getBecomeAgencyByStatus} from "@/api/agencyRequest";
  import {mapGetters} from "vuex";
  import UsersAgencyTable from "@/views/Agency/UsersAgencyTable";
  export default {
    name: "UsersAgency",
    components: { UsersAgencyTable },
    data: () => ({

      itemsPending: '',
      itemsProcessing: '',
      itemsApproved: '',
      itemsRejected: '',
      itemsRework: '',
      itemsContractProc: '',
      itemsContractReview: '',
      refreshAgencyStatus: 1,
    }),
    created() {
      this.fetchAgencyItems()
    },
    computed:{
      ...mapGetters('User', ['me']),
      translations() {
        return this.$t('translation')
      },
      translationsUsersAgency() {
        return this.translations.AGENCY.AGENCY_CREATION_REQUEST_LOG.tabs
      },
    },
    methods: {
      updateDataAgency() {
        this.fetchAgencyItems()
      },
      refreshAgency() {
        this.refreshAgencyStatus ++
      },
      async fetchAgencyItems() {
        this.itemsPending = 'PENDING'
        this.itemsProcessing = 'PROCESSING'
        this.itemsApproved = 'APPROVED'
        this.itemsRejected = 'REJECTED'
        this.itemsRework = 'REWORK'
        this.itemsContractProc = 'PROCESSING_CONTRACT'
        this.itemsContractReview = 'CONTRACT_REVIEW'
      }
    }
  }
</script>

<style scoped lang="scss">
  .background {
    background: #f4f6f9;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 20px;
  }
  .user-img {
    display: flex;
    align-items: center;
  }
  .select {
    max-width: 300px;
    margin-right: 100px;
  }

  .text-status {
    color: orange;
  }

  .img {
    width: 300px !important;
    cursor: pointer;
  }

  .icon-btn {
    border: 1px solid white;
    position: absolute;
    margin-top: -25px;
    margin-left: 280px;
    cursor: pointer;
  }
</style>
