<template>
  <div>
    <v-container class="my-5">

      <v-tabs background-color="#f4f6f9">

        <v-tab>{{ translationsBot.tabs.history }}</v-tab>
        <v-tab-item class="background">
          <BotTableHistory/>
        </v-tab-item>

        <v-tab>{{ translationsBot.tabs.template }}</v-tab>
        <v-tab-item class="background">
          <BotTableTemplates/>
        </v-tab-item>

        <v-tab>{{ translationsBot.tabs.setting }}</v-tab>
        <v-tab-item>
          <BotSettings/>
        </v-tab-item>

      </v-tabs>
    </v-container>
  </div>
</template>

<script>
import BotTableTemplates from "@/views/Bot/BotTableTemplates";
import BotTableHistory from "@/views/Bot/BotTableHistory";
import BotSettings from "@/views/Bot/BotSettings";

export default {
  name: 'BotPage',
  components: {BotSettings, BotTableHistory, BotTableTemplates},
  data: () => ({
    historyItems: [],
    botItems: [],
    loadingBot: false
  }),
  computed: {
    translations() {
      return this.$t('translation')
    },
    translationsBot() {
      return this.translations.BOT
    },
  },
};
</script>

<style scoped>
.background {
  background: #f4f6f9;
}


</style>
