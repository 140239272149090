import { render, staticRenderFns } from "./UserWomanLogTransactionTable.vue?vue&type=template&id=3317e39d&scoped=true"
import script from "./UserWomanLogTransactionTable.vue?vue&type=script&lang=js"
export * from "./UserWomanLogTransactionTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3317e39d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VChip,VDataTable})
