<template>
  <div>
    <v-container class="my-5">
      <template>

        <v-file-input
            :label="translationsAgencyContract.contract_file_label"
            outlined
            v-model="file"
            truncate-length="50"
            class="input"
            dense
        ></v-file-input>

        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>{{ translationsAgencyContract.title }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ translationsAgencyContract.name_label }}: {{ this.name ? this.name : 'no data' }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ translationsAgencyContract.created_label }}:
              <span v-if="this.createdAt">
                {{ new Date(this.createdAt) | date('Pp') }}
              </span>
              <span v-else>
                no data
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-btn color="success" @click="saveContact">{{ translationsAgencyContract.btn_save_contract }}</v-btn>
        <v-btn class="ml-6" color="info" @click="showContract">{{ translationsAgencyContract.btn_watch_contract }}</v-btn>


        <v-alert
            class="mt-5"
            v-if="success"
            type="success"
            max-width="400"
        >Contract save!
        </v-alert>
        <v-alert
            class="mt-5"
            v-if="error"
            type="error"
            max-width="400"
        >{{ errMessage }}
        </v-alert>


        <v-alert
            class="mt-5"
            v-if="alertErr"
            type="error"
            max-width="400"
        >
          Contract not added yet
        </v-alert>

      </template>
    </v-container>
  </div>
</template>

<script>
import {api} from "@/providers/api";

export default {
  name: "AgencyAddContractFile",
  data: () => ({
    file: '',
    success: false,
    error: false,
    errMessage: '',
    alertErr: false,
    name: '',
    createdAt: '',
  }),
  computed: {
    translations() {
      return this.$t('translation')
    },
    translationsAgencyContract() {
      return this.translations.AGENCY.ADD_CONTRACT_FILE
    },
  },
  created() {
    this.fetchContract()
  },
  methods: {

    async fetchContract() {
      const contract = await api.get('/admin/agencies/agency-template-contract').then(r => r?.contract);
      if (contract) {
        this.name = contract.name
        this.createdAt = contract.createdAt
      }
    },

    async showContract() {
      const url = await api.get('/admin/agencies/agency-template-contract').then(r => r?.contract.url);
      if (url)
        window.open(url, '_blank');
    },
    async saveContact() {

      try {
        console.log(this.file)
        let formData = new FormData();
        formData.append("file", this.file, this.file.name);
        await api.post('/agencies/agency-template-contract', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        this.success = true
        this.error = false
        this.fetchContract()
      } catch (e) {
        console.log("EROOR", e)
        this.errMessage = e.data.message
        this.error = true
        this.success = false
      }

    }
  }
}
</script>

<style scoped lang="scss">
.input {
  max-width: 500px;
}

.v-icon {
  margin-left: 20px;
  margin-top: -20px;
  margin-right: 20px;
}

</style>
