import Vuex from "vuex";
import Vue from "vue";
// import createPersistedState from "vuex-persist-indexeddb";
import User from "./modules/User";
import Chats from "@/store/modules/Chats";
import Admin from "@/store/modules/Admin";
import Agencies from "@/store/modules/Agencies";
import {api} from "@/providers/api";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    DEFAULT_ACTIVE_RATE: 'DOLLAR',
    creditsRate: [],
    isHiddenChat: true,
    translation: {},
    translations: [],
    snackbarQueue: []
  },
  getters: {
    getActiveCreditRate: state => { return state.creditsRate.find(item => item.name === state.DEFAULT_ACTIVE_RATE) },
    getActiveCurrencyRate: (state, getters) => { return getters.getActiveCreditRate?.rate || 0.39 }
  },
  mutations: {
    ADD_TO_SNACKBAR_QUEUE(state, snackbarData) {
      state.snackbarQueue.push(snackbarData);
    },
    REMOVE_FROM_SNACKBAR_QUEUE(state, index) {
      state.snackbarQueue.splice(index, 1);
    },

    SET_TRANSLATION(state, value) {
      console.log('TRANSLATION', value)
      state.translation = value
    },
    SET_TRANSLATIONS(state, value) {
      console.log('TRANSLATIONS', value)
      state.translations = value
    },
    SET_CURRENT_RATE(state, value) {
      state.creditsRate = value
    },
    showChat(state, value) {
      state.isHiddenChat = value;
    },
  },
  actions: {
    async addToSnackbarQueue({ commit }, snackbarData) {
      commit('ADD_TO_SNACKBAR_QUEUE', {...snackbarData, show: true});
      await api.post('/notifications/admin/view', {
        ids: [snackbarData.notificationId]
      })
    },
    removeFromSnackbarQueue({ commit }, index) {
      commit('REMOVE_FROM_SNACKBAR_QUEUE', index);
    },

    async onAuthInit({ dispatch }) {
      dispatch("User/fetchFavoriteUsers");
    },
    async fetchCurrentRate({ commit }) {
      const item = await api.get('/currency-exchange-rates');
      commit('SET_CURRENT_RATE', item);
    },
    async fetchTranslation({ commit }) {
      const item = await api.get('/language-translates/default');
      commit('SET_TRANSLATION', item);
    },
    async fetchTranslations({ commit }) {
      const items = await api.get('/admin/language-translates');
      commit('SET_TRANSLATIONS', items);
    }
  },

  modules: {
    User,
    Chats,
    Agencies,
    Admin
  },
  plugins: [
    // createPersistedState({
    //   key: "milana_chats_db",
    //   paths: "Chats",
    //   overwrite: true,
    //   // fetchBeforeUse: true,
    // }),
  ],
});
