<template>
  <div>
    ManageTagManager
  </div>
</template>

<script>
  export default {
    name: "ManageTagManager"
  }
</script>

<style scoped lang="scss">

</style>