var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"my-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.translationsConfiguration.title))]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"white","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.translationsConfiguration.btn_new_configuration)+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"height":"400"}},[_c('v-card-title',[_c('span',{staticClass:"text-h5 primary--text"},[_vm._v(_vm._s(_vm.translationsConfiguration.add_modal_title))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","label":_vm.translationsConfiguration.modal_title_label},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","label":_vm.translationsConfiguration.modal_email_label},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"outlined":"","chips":"","label":_vm.translationsConfiguration.modal_type_label,"items":_vm.types,"multiple":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":"teal"}},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-btn',{attrs:{"right":"","color":"success","outlined":""},on:{"click":_vm.saveNewItem}},[_vm._v(" "+_vm._s(_vm.translations.GLOBAL_VALUE.save)+" ")])],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.editModal),callback:function ($$v) {_vm.editModal=$$v},expression:"editModal"}},[_c('v-card',{attrs:{"height":"400"}},[_c('v-card-title',[_c('span',{staticClass:"text-h5 primary--text"},[_vm._v(_vm._s(_vm.translationsConfiguration.edit_modal_title))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","label":_vm.translationsConfiguration.modal_title_label},model:{value:(_vm.editItem.title),callback:function ($$v) {_vm.$set(_vm.editItem, "title", $$v)},expression:"editItem.title"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","label":_vm.translationsConfiguration.modal_email_label},model:{value:(_vm.editItem.email),callback:function ($$v) {_vm.$set(_vm.editItem, "email", $$v)},expression:"editItem.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"outlined":"","chips":"","label":_vm.translationsConfiguration.modal_type_label,"items":_vm.types,"multiple":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":"teal"}},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.editItem.mailTypes),callback:function ($$v) {_vm.$set(_vm.editItem, "mailTypes", $$v)},expression:"editItem.mailTypes"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-btn',{attrs:{"right":"","color":"success","outlined":""},on:{"click":_vm.saveEditItem}},[_vm._v(" "+_vm._s(_vm.translations.GLOBAL_VALUE.save)+" ")])],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}},[_c('v-card',{attrs:{"height":"170"}},[_c('v-card-title',[_c('span',{staticClass:"text-h5 primary--text"},[_vm._v("Delete item")])]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.translations.GLOBAL_VALUE.delete_modal.title)+" ")]),_c('v-row',{staticClass:"ma-3"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.confirmDelete}},[_vm._v(_vm._s(_vm.translations.GLOBAL_VALUE.delete_modal.ok_btn))])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openEditModal(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDeleteModal(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return _vm._l((item.mailTypes),function(elem,index){return _c('v-chip',{key:index,staticClass:"mr-1",attrs:{"dark":"","color":"teal"}},[_vm._v(" "+_vm._s(elem.settingType)+" ")])})}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }