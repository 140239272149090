import { api } from '@/providers/api';

export async function getWithdrawals(agencyId = undefined) {
  return api.get('/admin/transactions/withdrawal', {
    params: {
      agency_id: agencyId
    }
  });

}

export async function getUserWithdrawals(agencyId) {
  return api.get('/admin/transactions/withdrawal-user', {
    params: {
      agency_id: agencyId
    }
  });
}

export async function changeStatusWithdrawal(id, status) {
  return api.post(`/admin/transactions/withdrawal/${id}/status`, {}, {
    params: {
      value: status
    }
  });
}
