var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"faq-container"},[_c('v-container',{staticClass:"my-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headers,"items":_vm.faq,"single-expand":true,"expanded":_vm.expanded,"loading":_vm.faqLoading,"item-key":"name","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.loadDetails},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.translationsFAQ.title))]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"white","outlined":"","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.translationsFAQ.btn_new_faq_group)+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("FAQ Group: "+_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.translationsFAQ.modal_label},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.translations.GLOBAL_VALUE.cancel)+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.translations.GLOBAL_VALUE.save)+" ")])],1)],1)],1),_c('DeleteModal',{attrs:{"dialog":_vm.dialogDelete},on:{"close-options":_vm.closeDelete,"save-options":_vm.deleteItemConfirm}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',[_c('v-data-table',{staticClass:"elevation-1 table",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headersQuestion,"items":item.info,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"#FFF8FAFF"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.translationsFAQ.expanded_table.title))]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","outlined":"","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.translationsFAQ.expanded_table.btn_new_faq)+" ")])]}}],null,true),model:{value:(_vm.dialogQuestion),callback:function ($$v) {_vm.dialogQuestion=$$v},expression:"dialogQuestion"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitleQuestion))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Question"},model:{value:(_vm.editedItemQuestion.title),callback:function ($$v) {_vm.$set(_vm.editedItemQuestion, "title", $$v)},expression:"editedItemQuestion.title"}}),_c('v-text-field',{attrs:{"label":"Answer"},model:{value:(_vm.editedItemQuestion.description),callback:function ($$v) {_vm.$set(_vm.editedItemQuestion, "description", $$v)},expression:"editedItemQuestion.description"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeQuestion}},[_vm._v(" "+_vm._s(_vm.translations.GLOBAL_VALUE.cancel)+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveQuestion}},[_vm._v(" "+_vm._s(_vm.translations.GLOBAL_VALUE.save)+" ")])],1)],1)],1),_c('DeleteModal',{attrs:{"dialog":_vm.dialogDeleteQuestion},on:{"close-options":_vm.closeDeleteQuestion,"save-options":_vm.deleteItemConfirmQuestion}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItemQuestion(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItemQuestion(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)])]}}],null,true)},[_vm._v(" > ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }