<template>
  <div class="">
    <v-breadcrumbs large :items="breadcrumbs" >
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
            :disabled="item.disabled"
        >
          <v-list class="list-container">
            <v-list-item :to="item.href" link>
              <v-list-item-title> {{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: "MyBreadcrumbs",
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep{

  .v-breadcrumbs{
    .v-breadcrumbs__item{
      color: #2f55b4;
    }
    .v-breadcrumbs__divider, .v-breadcrumbs__item--disabled{
      color: rgba(0,0,0,0.6) !important;
      .v-list-item{
        color: rgba(0,0,0,0.6) !important;
      }
    }

    .list-container{
      background: #f4f6f9;
      padding: 0;
      .v-list-item,{
        color: #2f55b4;
        padding: 0;
        &:hover{
          &:before{
            opacity: 0;
          }
        }
      }
      .v-list-item--active:before{
        opacity: 0;
      }
    }
  }
}
</style>