<template>
  <div class="pa-4">
    <v-data-table
        :footer-props="{'items-per-page-options': [10, 50, 100]}"
        :headers="agencyHeader"
        :items="usersAgency"
        show-expand
        class="elevation-1"
        :expanded.sync="expanded"
        :single-expand="singleExpand"
        :loading="loadingUsersAgency"
        :server-items-length="totalItemsUsersAgency"
        :options.sync="optionsUsersAgency"
        :items-per-page="-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title class="title">{{ translationsAgencyTable.title }}</v-toolbar-title>
        </v-toolbar>
      </template>

      <template v-slot:[`item.license`]="{ item }">

        <a v-if="item.businessLicenseNumberFile" :href="item.businessLicenseNumberFile.url" target="_blank">
          {{ translationsAgencyTable.link_view }}
        </a>
        <div v-else>Unsent</div>
      </template>

      <template v-slot:[`item.contract`]="{ item }">

        <a v-if="item.contract" :href="item.contract.url" target="_blank">
          View
        </a>
        <div v-else>Unsent</div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="ma-5">
            <v-col cols="12" lg="8" md="8">
              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.email }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.email }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.name_agency }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.name }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.phone }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.phone }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.building_number }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.buildingNumber }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.street }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.street }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.office_number }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.officeNumber }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.city }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.city }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.country }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.country }}</v-col>
              </v-row>


              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.date_registration }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.registerDate }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.first_name_agency_director }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.directorFirstName }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.last_name_agency_director }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.directorLastName }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.number_female_clients }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.activeFemaleClientsCount }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.web_cameras_count }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.webCamerasCount }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.computers_count }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.computersCount }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.employees_count }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.employeesCount }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.girl_links_list }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.girlLinksList }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">
                  {{ translationsAgencyTable.info.partnership_programs_list }}
                </v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.partnershipProgramsList }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.about_us }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.aboutUs }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">
                  {{ translationsAgencyTable.info.is_applied_other_partnership_before }}
                </v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.isAppliedOtherPartnershipBefore }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">
                  {{ translationsAgencyTable.info.is_rejected_other_partnership_applications_before }}
                </v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.isRejectedOtherPartnershipApplicationsBefore }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">
                  {{ translationsAgencyTable.info.rejected_other_partnership_before_reason }}
                </v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.rejectedOtherPartnershipBeforeReason }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">
                  {{  translationsAgencyTable.info.is_lost_our_partnership }}
                </v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.isLostOurPartnership }}</v-col>
              </v-row>


              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">
                  {{ translationsAgencyTable.info.lost_our_partnership_reason }}
                </v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.lostOurPartnershipReason }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.is_website_exists }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.isWebsiteExists }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.website_url }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.websiteUrl }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.is_provide_material_compensation }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.isProvideMaterialCompensation }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">Business license number</v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.businessLicenseNumber }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">
                  {{ translationsAgencyTable.info.is_provide_material_compensation_etc }}
                </v-col>
                <v-col cols="12" lg="8" md="8" class="col">{{ item.isProvideMaterialCompensation }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.business_license }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">
                  <v-img width="300" :src="item.businessLicenseNumberFile.url" class="img"
                         @click="openImg(item.businessLicenseNumberFile.url)"></v-img>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4" md="4" class="col col-name">{{ translationsAgencyTable.info.director_passport }}</v-col>
                <v-col cols="12" lg="8" md="8" class="col">
                  <v-img width="300" :src="item.directorPassportFile.url" class="img"
                         @click="openImg(item.directorPassportFile.url)"></v-img>
                </v-col>
              </v-row>

            </v-col>
            <v-col cols="12" lg="4" md="4" >
              <v-row class="ml-4" align="start" >
                <v-col class="col-select">
                  <v-card
                      outlined
                      class="pa-3"
                  >
                    <v-card-title>{{ translationsAgencyTable.contract_file_label }}</v-card-title>
                    <v-card-text>
                      <a class="text-overline" v-if="item.contract" :href="item.contract.url" target="_blank">{{ translationsAgencyTable.link_show_contract }}</a>
                    </v-card-text>
                    <v-divider class="mb-5"></v-divider>
                    <div class="contract mt-3">
                      <v-file-input
                          :label="translationsAgencyTable.change_contract_label"
                          outlined
                          v-model="uploadContract"
                          truncate-length="50"
                          class="input"
                          dense
                      ></v-file-input>
                      <v-btn dark color="green" class="ml-3" @click="sendContract(item)">{{ translationsAgencyTable.btn_upload }}</v-btn>
                    </div>
                  </v-card>
<!--                  <v-list-item>-->
<!--                    <v-list-item-content>-->
<!--                      <v-list-item-title>Contract file</v-list-item-title>-->
<!--                      <v-list-item-subtitle>-->
<!--                        <a :href="item.contract.url" target="_blank">Show contract file</a>-->
<!--                      </v-list-item-subtitle>-->

<!--                      <div class="contract mt-3">-->
<!--                        <v-file-input-->
<!--                            label="Change contract"-->
<!--                            outlined-->
<!--                            v-model="uploadContract"-->
<!--                            truncate-length="50"-->
<!--                            class="input"-->
<!--                            dense-->
<!--                        ></v-file-input>-->
<!--                        <v-btn dark color="green" class="ml-3">Upload</v-btn>-->
<!--                      </div>-->



<!--                    </v-list-item-content>-->
<!--                  </v-list-item>-->

                </v-col>

              </v-row>


              <v-row class="ml-4" align="start">
                <v-col class="col-select">

                  <v-textarea v-model="item.reworkMessage" filled :label="translationsAgencyTable.rework_message_label"/>

                  <v-textarea v-model="item.rejectMessage" filled :label="translationsAgencyTable.reject_message_label"/>

                  <v-row align="start" >
                    <v-select
                        :items="selectItems"
                        :label="translationsAgencyTable.select_status_label"
                        dense
                        outlined
                        class="select"
                        v-model="item.status"
                    ></v-select>

                    <v-btn dark color="green"
                           @click="changeStatusAgency(item.id, item.status, item.reworkMessage, item.rejectMessage)">
                      {{ translations.GLOBAL_VALUE.save }}
                    </v-btn>

                  </v-row>
                  <v-row align="start" v-if="statusRequest === 'APPROVED'">
                    <v-btn class="d-block mt-2" dark color="blue"
                           @click="resendEmail(item.id)"
                    >
                      {{ translationsAgencyTable.btn_resend_welcome_mail }}
                    </v-btn>


                  </v-row>
                  <v-row align="start" v-if="resendEmailAlert">
                    <v-alert class="d-block mt-2" type="success">
                      {{ translationsAgencyTable.alert_message }}
                    </v-alert>
                  </v-row>

                </v-col>

              </v-row>
            </v-col>
          </v-row>


        </td>
      </template>

      <template v-slot:[`item.status`]="{item}">

        <v-chip
            :color="getColor(item.status)"
            dark
        >
          {{ item.status }}
        </v-chip>

      </template>


      <template v-slot:[`item.creator`]="{ item }">
        <div class="user-img cursor-pointer" @click="userPush(item.creator)">
          <img :src="$getAvatarImage(item.creator.profile.avatar)">
          {{ item.creator.profile.name }}
        </div>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import {getBecomeAgency, getBecomeAgencyByStatus, postStatusAgency} from "@/api/agencyRequest";
import {api} from "@/providers/api";
import router from "@/routes/router";


export default {
  name: "UsersAgencyTable.vue",
  props: {
    propsItemsAgency: {
      type: String,
      required: true
    },
    refreshAgency: {
      type: Number,
      required: true
    },
    statusRequest: {
      type: String,
      required: false,
    }
  },
  data: () => ({
    expanded: [],
    singleExpand: true,
    reworkMessage: '',
    rejectMessage: '',

    selectItems: [
      'PENDING',
      'PROCESSING',
      "APPROVED",
      "REJECTED",
      "REWORK",
      "PROCESSING_CONTRACT",
      "CONTRACT_REVIEW",
    ],
    agencyHeader: [
      {text: 'ID', align: 'start', value: 'id'},
      {text: 'Name of Agency', value: 'name'},
      {text: 'Email', value: 'email'},
      {text: 'Address', value: 'completeAddress'},
      {text: 'Site Address', value: 'websiteUrl'},
      {text: '# of Girls', value:'activeFemaleClientsCount'},
      {text: 'Creator', value: 'creator'},
      {text: 'License', value: 'license'},
      {text: 'Contract', value:'contract'},

      {text: 'Status', value: 'status'},

    ],
    usersAgency: [],
    loadingUsersAgency: true,
    optionsUsersAgency: {},
    totalItemsUsersAgency: 0,
    uploadContract: '',
    resendEmailAlert: false
  }),

  computed: {
    translations() {
      return this.$t('translation')
    },
    translationsAgencyTable() {
      return this.translations.AGENCY.AGENCY_CREATION_REQUEST_LOG.table
    },
  },

  created() {
    this.propsItemsAgency.map(e => {
      e.completeAddress = `${e.country}, ${e.city}, ${e.street}, ${e.buildingNumber}`
    })
  },

  watch: {
    optionsUsersAgency: {
      handler(change) {
        this.syncUsersAgencyFromApi()
      },
      deep: true
    },
    refreshAgency: {
      handler(val) {
        this.syncUsersAgencyFromApi()
      },
      deep: true
    }

    // search(val) {
    //   console.log('val', val)
    //   return true
    // }
  },

  methods: {
    userPush(user) {
      router.push({name: 'user-log', params: {id: user.id, targetUser: user}})

    },
    async resendEmail(id) {
      console.log(id)
      await api.post(`/admin/agencies/agency-requests/${id}/resend`)
      this.resendEmailAlert = true

    },
    async sendContract(item){
      const form = new FormData()
      form.append('file', this.uploadContract)
      await api.post(`/admin/agencies/agency-requests/${item.id}/contract`, form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      this.uploadContract = null
      this.$emit('call-fetch-agency', true)
      await this.syncUsersAgencyFromApi()
    },
    async syncUsersAgencyFromApi() {
      this.loadingUsersAgency = true
      try {
        const {sortBy, sortDesc, page, itemsPerPage: limit} = this.optionsUsersAgency
        const response = await getBecomeAgencyByStatus({
          params: {
            ...sortBy.length ? {sort: sortBy.map((s, i) => (s + ',' + (sortDesc[i] ? 'DESC' : 'ASC')))} : [],
            limit: limit === -1 ? 100: limit,
            page,
            status: this.propsItemsAgency
          }
        });
        console.log('this.optionsUsersAgency', this.optionsUsersAgency)

        // const { data: items, total } = response
        // console.log("RESPONSE" + this.propsItemsAgency, response)


        this.usersAgency = response.items;
        this.totalItemsUsersAgency = response.meta.totalItems
      } catch (e) {
        console.log('error' + JSON.stringify(e))
      } finally {
        this.loadingUsersAgency = false
      }
    },


    openImg(img) {
      window.open(img)
    },
    getColor(color) {
      switch (color) {
        case 'PENDING':
          return 'orange'
        case 'PROCESSING':
          return 'purple'
        case 'APPROVED':
          return 'green'
        case 'REJECT':
          return 'red'
        case 'PROCESSING_CONTRACT':
          return 'blue'
        case 'CONTRACT_REVIEW':
          return 'pink'
        default:
          return 'dark'
      }
    },

    async changeStatusAgency(id, status, rework, reject) {
      // console.log(id, status, rework, reject)
      if (status === 'REJECTED') {
        await postStatusAgency(id, status, rework, reject)
        this.$emit('call-fetch-agency', true)
        this.syncUsersAgencyFromApi()
      } else {
        await postStatusAgency(id, status, rework, '')
        this.$emit('call-fetch-agency', true)
        this.syncUsersAgencyFromApi()
      }
    },
  }
}
</script>

<style scoped lang="scss">
img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.user-img {
  display: flex;
  align-items: center;
}

.select {
  max-width: 300px;
  margin-right: 100px;
}

.text-status {
  color: orange;
}

.img {
  width: 300px !important;
  cursor: pointer;
}

.icon-btn {
  border: 1px solid white;
  position: absolute;
  margin-top: -25px;
  margin-left: 280px;
  cursor: pointer;
}

.col {
  border: 1px solid darkgray;
}

.col-name {
  border-right: none;
  background: #daebf3;
}
.col-select{
  border: none;
}
.contract{
  display: flex;
}
</style>
