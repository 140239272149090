var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headers,"items":_vm.passports,"item-key":"id","show-expand":"","expanded":_vm.expanded,"single-expand":_vm.singleExpand,"options":_vm.optionsPassport,"server-items-length":_vm.passportsTotalItems,"loading":_vm.passportsLoading},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.optionsPassport=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.translations.title))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-select',{staticClass:"mt-3",attrs:{"label":_vm.translations.status_label,"hide-details":"","dark":"","dense":"","outlined":"","items":_vm.passportsStatus},on:{"change":_vm.changeStatusPassport},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-img',{staticClass:"user-img",attrs:{"max-height":"200","max-width":"800","src":_vm.$getAvatarImage(item.file),"contain":""}})],1),_c('v-col',[(item.documentStatus === 'PENDING' || item.documentStatus === 'DECLINED')?_c('v-btn',{staticClass:"mb-5 d-block",attrs:{"width":"100","color":"success"},on:{"click":function($event){return _vm.accept(item)}}},[_vm._v("Accept")]):_vm._e(),(item.documentStatus === 'PENDING')?_c('v-btn',{staticClass:"mb-10 d-block",attrs:{"width":"100","color":"error"},on:{"click":function($event){return _vm.reject(item)}}},[_vm._v("Reject")]):_vm._e()],1)],1)],1)]}},{key:"item.user.profile.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"user-img",on:{"click":function($event){$event.preventDefault();return _vm.userOpen(item)}}},[_c('img',{attrs:{"src":_vm.$getAvatarImage(item.user.profile.avatar)}}),_vm._v(" "+_vm._s(item.user.profile.name)+" "),(item.user.id !== _vm.me.id && !_vm.$auth.isAdmin)?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$switchProfileUser(item.user.id)}}},[_vm._v(" mdi-account-arrow-left ")]):_vm._e()],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.createdAt),'Pp')))]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.documentStatus),"dark":""}},[(item.documentStatus === 'PENDING')?_c('span',[_vm._v("Waiting")]):_vm._e(),(item.documentStatus === 'ACCEPTED')?_c('span',[_vm._v("Accepted")]):_vm._e(),(item.documentStatus === 'DECLINED')?_c('span',[_vm._v("Rejected")]):_vm._e()])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }