import {api} from "@/providers/api";

export async function postSaveUser(id, user) {
  return await api.post(`/admin/users/${id}/user`, user);
}



export async function postSaveUserProfile(id, profile) {
  return await api.post(`/admin/users/${id}/profile`, profile);
}

export async function postSaveProfile(id, profile) {
  return await api.post(`/admin/users/${id}/user`, profile);
}

export async function postSaveAvatar(id, data) {
  return await api.post(`/admin/users/${id}/avatar`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function postSaveMembership(id, membershipId) {
  return api.post(`/admin/users/${id}/membership`, {}, {
    params: {
      membershipId: membershipId
    }
  });
}
export async function deleteMembership(id) {
  return await api.delete(`/admin/users/${id}/membership`);
}

export async function postSaveBalance(id, amount) {
  return await api.post('/admin/balances/' + id, {}, {params: {amount: amount}});
}