import { io } from "socket.io-client";
import { API_URL } from "@/providers/config";
import { getApiAuthToken } from "@/providers/auth";

export function createSocket() {
  return io(API_URL, {
    auth: {
      token: getApiAuthToken(),
    },
    forceNew: true,
    transports: ['websocket']
    // path: "/events",
  });
}

export let socket = createSocket();

export function setSocketAuth(token) {
  socket.disconnect();
  socket.auth = { token };
  if (token) {
    socket.connect();
  }
}

socket.on("connect", () => console.log("Socket connected!"));
socket.on("connect_error", (e) => console.log("Socket connect error!", e));
socket.on("disconnect", (e) => console.log("Socket disconnect!", e));
