<template>
  <v-col class="pt-5">
    <v-data-table
        :footer-props="{'items-per-page-options': [10, 50, 100]}"
        :items-per-page="10"
        :headers="headerHistory"
        :items="items"
        :server-items-length="totalItems"
        :options.sync="options"
        :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>{{ translationsBotHistory.title }}</v-toolbar-title>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
  import {api} from "@/providers/api";
  import {getBotMessagesActivity} from "@/api/chat-bot.api";

  export default {
    name: "BotTableHistory",
    data: () => ({
      options: {},
      totalItems: 0,
      loading: true,
      items: [],
      headerHistory: [
        {
          text: 'ID',
          value: 'id',
          sortable: false,
          align: 'start'
        },
        {
          text: 'From',
          value: 'user',
          sortable: false,
        }, {
          text: 'To',
          value: 'to',
          sortable: false,
        },
        {
          text: 'Text messages',
          value: 'text'
        },
      ],

    }),
    computed: {
      translations() {
        return this.$t('translation')
      },
      translationsBotHistory() {
        return this.translations.BOT.table_history
      }
    },
    watch: {
      options: {
        handler() {
          this.syncDataFromApi()
        },
        deep: true
      }
    },
    created() {
      this.headerHistory = [
        {
          text: 'ID',
          value: 'id',
          sortable: false,
          align: 'start'
        },
        {
          text: this.translationsBotHistory.headers.from,
          value: 'user',
          sortable: false,
        }, {
          text: this.translationsBotHistory.headers.to,
          value: 'to',
          sortable: false,
        },
        {
          text: this.translationsBotHistory.headers.text,
          value: 'text'
        },
      ]
    },
    methods: {
      itemsFilter(items) {
        return items.map((e) => {
              const toUser = e.chat.members.find(m => m.id !== e.sender.id)
              return {
                ...e,
                user: `${e.sender.profile.name} ${e.sender.profile.surname || ''} (${e.sender.email})`,
                to: `${toUser?.profile.name} (${toUser?.email})`
              };
            }
        );
      },
      async syncDataFromApi() {
        this.loading = true
        try {
          const {sortBy, sortDesc, page, itemsPerPage: limit} = this.options
          const response = await getBotMessagesActivity(page, limit)

          const {items, meta} = response
          console.log('response', response)
          this.items = this.itemsFilter(items);
          this.totalItems = meta.totalItems
        } catch (e) {
          console.log('Error', e)
        } finally {
          this.loading = false
        }
      },
    }
  }
</script>

<style scoped>

</style>