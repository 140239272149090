<template>
  <div>
    WireExport
  </div>
</template>

<script>
  export default {
    name: "WireExport"
  }
</script>

<style scoped lang="scss">

</style>