<template>
  <PreloaderPage v-if="loading"/>
  <div v-else>
    <v-alert class="alert" v-model="alertSetting.show" :type="alertSetting.type" max-width="max-content">
      {{ alertSetting.text }}
    </v-alert>
    <v-row>
      <v-col
          md="10"
          lg="10"
          offset-md="1"
      >
        <MyBreadcrumbs class="mb-3" :breadcrumbs="breadcrumbs"/>

        <h1 class="pb-2 pt-2">{{ item.name }}</h1>

        <v-card color="#f4f6f9" elevation="3" class="pa-5">
          <v-card-title><span class="title">{{ translationsEmailManagerEdit.title_info }}</span>
            <v-icon @click="openModal(item)" small class="ml-2">mdi-pencil</v-icon>
          </v-card-title>

          <v-dialog max-width="900" v-model="dialog">
            <v-card class="pa-3">
              <v-card-title>{{ translationsEmailManagerEdit.info_block.name }}</v-card-title>
              <v-text-field hide-details outlined v-model="editItem.name"></v-text-field>
              <v-card-title>{{ translationsEmailManagerEdit.info_block.title }}</v-card-title>
              <v-text-field hide-details outlined v-model="editItem.title"></v-text-field>
              <v-card-title
                  v-if="!item.disableFrequency"
              >
                {{ translationsEmailManagerEdit.info_block.timezone }}
              </v-card-title>
              <v-autocomplete
                  v-if="!item.disableFrequency"
                  outlined
                  hide-details
                  v-model="editItem.timezone"
                  :items="timeZone"
              ></v-autocomplete>
              <v-card-title
                  v-if="!item.disableFrequency"
              >
                {{ translationsEmailManagerEdit.info_block.frequency }}
              </v-card-title>
              <VueCronEditorBuefy
                  v-if="!item.disableFrequency"
                  v-model="editItem.frequency"
              />
              <v-card-actions>
                <v-spacer/>
                <v-btn color="success" @click="saveInfo">{{ translations.GLOBAL_VALUE.save }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-row align="start">
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>ID</v-list-item-title>
                  <v-list-item-subtitle>{{ item.id }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ translationsEmailManagerEdit.info_block.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ translationsEmailManagerEdit.info_block.template_path }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.template_path }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="start">
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ translationsEmailManagerEdit.info_block.class }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.class }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ translationsEmailManagerEdit.info_block.title }}</v-list-item-title>
                  <!--                  <span>{{ item.title }}</span>-->
                  <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ translationsEmailManagerEdit.info_block.status }}</v-list-item-title>
                  <!--                  <v-list-item-subtitle class="primary&#45;&#45;text">-->
                  <!--                    {{ item.active ? 'active' : 'inactive' }}-->
                  <!--                    &lt;!&ndash;                    <v-chip dark small :color="item.active ? 'green' : '#b7b7b7'">&ndash;&gt;-->
                  <!--                    &lt;!&ndash;                      {{ item.active ? 'active' : 'inactive' }}&ndash;&gt;-->
                  <!--                    &lt;!&ndash;                    </v-chip>&ndash;&gt;-->
                  <!--                  </v-list-item-subtitle>-->
                  <v-list-item-subtitle>
                    <v-switch @change="changeStatus(item.active)" class="ma-0 pa-0" color="success" hide-details
                              v-model="item.active" :label="item.active ? 'active' : 'inactive' "></v-switch>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="start">

            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ translationsEmailManagerEdit.info_block.last_start_time }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-if="item.lastStartTime === null">
                      {{ '' }}
                    </span>
                    <span v-else>
                      {{ new Date(item.lastStartTime) | date('Pp') }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ translationsEmailManagerEdit.info_block.last_end_time }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-if="item.lastEndTime === null">
                      {{ '' }}
                    </span>
                    <span v-else>
                      {{ new Date(item.lastEndTime) | date('Pp') }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col
                v-if="!item.disableFrequency"
            >
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ translationsEmailManagerEdit.info_block.frequency }}</v-list-item-title>
                  <v-list-item-subtitle>{{ frequency(item.frequency) }}</v-list-item-subtitle>


                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col v-else>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ translationsEmailManagerEdit.info_block.is_executing }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <span>{{ isExecuteFormat(item.lastStartTime, item.lastEndTime) }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-divider v-if="!item.disableFrequency"></v-divider>
          <v-row v-if="!item.disableFrequency" align="start">

            <v-col cols="4">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ translationsEmailManagerEdit.info_block.timezone }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <span>{{ item.timezone }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="4">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ translationsEmailManagerEdit.info_block.offset }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <span>{{ offsetTimeZone }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="4">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ translationsEmailManagerEdit.info_block.is_executing }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <span>{{ isExecuteFormat(item.lastStartTime, item.lastEndTime) }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>


          </v-row>

        </v-card>

        <v-card color="#f4f6f9" elevation="3" class="pa-5 mt-5">
          <v-card-title><span class="title">{{ translationsEmailManagerEdit.title_action }}</span>
          </v-card-title>
          <v-row align="start">

            <v-col cols="4">
              <v-list-item>
                <v-btn class="mb-0" color="primary" outlined dark @click="executeById">
                  {{ translationsEmailManagerEdit.action_block.btn_send_now }}
                  <v-icon>mdi-play</v-icon>
                </v-btn>
              </v-list-item>
            </v-col>


          </v-row>
        </v-card>

        <v-card color="#f4f6f9" elevation="3" class="pa-5 mt-5">
          <v-card-title><span class="title">{{ translationsEmailManagerEdit.title_filter }}</span></v-card-title>

          <v-row class="ma-0">
            <v-col cols="4">
              <v-select
                  outlined
                  hide-details
                  v-model="filter.selectedIsAgency"
                  :items="optionsIsAgency"
                  :label="translationsEmailManagerEdit.filter_block.is_agency_label"
              ></v-select>
            </v-col>
            <v-col v-if="item.class !== 'SendNewLadies'" cols="4">
              <v-select
                  outlined
                  hide-details
                  v-model="filter.selectedIsBoy"
                  :items="optionsIsBoy"
                  :label="translationsEmailManagerEdit.filter_block.gender_label"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                  outlined
                  hide-details
                  v-model="filter.selectedTariff"
                  :items="optionsTariff"
                  :label="translationsEmailManagerEdit.filter_block.tariff_label"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="4">
              <v-select
                  outlined
                  hide-details
                  v-model="filter.selectedStatus"
                  :items="optionsStatus"
                  :label="translationsEmailManagerEdit.filter_block.status_label"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                  outlined
                  hide-details
                  v-model="filter.selectedCountry"
                  :items="$getCountries()"
                  item-text="name"
                  item-value="code"
                  :label="translationsEmailManagerEdit.filter_block.country_label"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                  hide-details
                  outlined
                  :label="translationsEmailManagerEdit.filter_block.agency_label"
                  :items="allAgencies"
                  item-text="name"
                  item-value="id"
                  v-model="filter.selectedAgency"

              />
            </v-col>
          </v-row>
          <v-divider class="ma-2"/>
          <v-card-subtitle class="pa-0 pl-3">
            {{ translationsEmailManagerEdit.filter_block.balance_range_label }}
          </v-card-subtitle>
          <v-row class="ma-0">
            <v-col>
              <v-range-slider
                  v-model="filter.balanceValue"
                  max="10000"
                  min="0"
                  hide-details
                  class="align-center"
              >
                <template v-slot:prepend>
                  <v-text-field
                      :value="filter.balanceValue[0]"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 60px"
                  ></v-text-field>
                </template>
                <template v-slot:append>
                  <v-text-field
                      :value="filter.balanceValue[1]"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 60px"
                  ></v-text-field>
                </template>
              </v-range-slider>

            </v-col>
          </v-row>
          <v-divider class="ma-2"/>
          <v-card-subtitle class="pa-0 pl-3">
            {{ translationsEmailManagerEdit.filter_block.age_range_label }}
          </v-card-subtitle>
          <v-row class="ma-0">
            <v-col>
              <v-range-slider
                  v-model="filter.ageValue"
                  max="99"
                  min="0"
                  hide-details
                  class="align-center"
              >
                <template v-slot:prepend>
                  <v-text-field
                      :value="filter.ageValue[0]"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                  ></v-text-field>
                </template>
                <template v-slot:append>
                  <v-text-field
                      :value="filter.ageValue[1]"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 60px"
                  ></v-text-field>
                </template>
              </v-range-slider>

            </v-col>

          </v-row>
          <v-row class="ma-0">
            <v-col>
              <v-btn color="primary" outlined dark @click="openUsersList">
                {{ translationsEmailManagerEdit.filter_block.btn_users_list }}
              </v-btn>
              <!--              <h3 @click="openUsersList">Users list</h3>-->
            </v-col>

          </v-row>
          <v-divider class="ma-2"/>

          <v-row class="ma-0">
            <v-col>
              <v-autocomplete
                  :label="translationsEmailManagerEdit.filter_block.name_or_email_label"
                  v-model="autoCompleteInput"
                  :search-input.sync="searchUser"
                  :items="users"
                  :item-text="getItemText"
                  item-value="id"
                  color="primary"
                  multiple
                  return-object
                  outlined

              >
                <template v-slot:selection="data">
                  {{ data = '' }}
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-avatar>
                    <img :src="$getAvatarImage(item.profile.avatar)">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="item.name"></v-list-item-title>
                    <v-list-item-subtitle v-html="item.email"></v-list-item-subtitle>
                  </v-list-item-content>

                </template>
              </v-autocomplete>

            </v-col>

          </v-row>

          <v-row class="ma-0">
            <v-col>
              <v-chip
                  v-for="user in autoCompleteInput"
                  :key="user.id"
                  color="primary"
                  class="mr-2 mb-2"
                  close
                  @click:close="onChipClose(user.id)"

              >
                <v-avatar left>
                  <v-img :src="$getAvatarImage(user.profile.avatar)"></v-img>
                </v-avatar>
                {{ user.name }} ({{ user.email }})
              </v-chip>

            </v-col>

          </v-row>
          <v-divider class="ma-2"/>


          <v-card-actions>

            <v-spacer></v-spacer>
            <v-btn
                color="success"
                outlined
                @click="acceptFilters"
            >
              {{ translations.GLOBAL_VALUE.save }}
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-col>

    </v-row>

  </div>
</template>

<script>
import cronstrue from "cronstrue";
import {api} from "@/providers/api";
import PreloaderPage from "@/components/PreloaderPage.vue";
import VueCronEditorBuefy from 'vue-cron-editor-buefy';
import MyBreadcrumbs from "@/components/Breadcrumbs.vue";
import moment from 'moment-timezone';
import {isAfter, isBefore, parseISO} from "date-fns";
import {isEqual} from "lodash";

export default {
  name: "EmailManagerEdit",
  components: {PreloaderPage, VueCronEditorBuefy, MyBreadcrumbs},
  data: () => ({
    dialog: false,
    autoCompleteInput: [],
    searchUser: null,
    users: [],

    item: {},
    editItem: {
      name: '',
      title: '',
      frequency: '',
      timezone: ''
    },
    optionsIsAgency: [{text: '', value: ''}, {text: 'Yes', value: true}, {text: 'No', value: false}],
    optionsIsBoy: [{text: '', value: ''}, {text: 'Man', value: true}, {text: 'Woman', value: false}],
    optionsStatus: [{text: '', value: ''}, {text: 'Active', value: 1}, {text: 'Review', value: 0}, {
      text: 'Blocked',
      value: 2
    }],
    optionsTariff: [],
    offsetTimeZone: '',

    filter: {
      selectedIsAgency: '',
      selectedIsBoy: '',
      selectedTariff: '',
      selectedStatus: '',
      selectedCountry: '',
      selectedAgency: '',
      balanceValue: [0, 10000],
      ageValue: [0, 99],
    },
    loading: false,
    alertSetting: {
      type: 'success',
      text: '',
      show: false
    }
  }),
  watch: {
    searchUser: {
      handler() {
        this.syncSearchUsersFromApi()
      },
      deep: true
    },
  },
  async created() {

    const response = await api.get('/membership-tariffs')


    console.log(response)
    const oldTarrif = response.map(r => {
      return {
        text: r.name,
        value: r.id
      }
    });
    console.log(oldTarrif)
    oldTarrif.unshift({text: '', value: ''});
    this.optionsTariff = oldTarrif
    console.log(oldTarrif)
    console.log(this.optionsTariff)
    await this.fetchItem()
    await this.$store.dispatch('Agencies/fetchAllAgencies');
  },
  computed: {
    translations() {
      return this.$t('translation')
    },
    translationsEmailManagerEdit() {
      return this.translations.EMAIL_MANAGER.email_manager_edit_page
    },
    timeZone() {
      return moment.tz.names()
    },
    breadcrumbs() {
      return [
        {text: this.translations.NAVBAR.EMAIL_MANAGER.title, href: '/email-manager'},
        {text: `${this.item.name} #${this.$route.params.id}`, disabled: true,}
      ]
    },
    allAgencies() {
      return this.$store.state.Agencies.allAgencies
    },
  },
  methods: {
    isExecuteFormat(start, end) {
      const dateStart = parseISO(start);
      const dateEnd = parseISO(end);

      if (isAfter(dateStart, dateEnd)) {
        return 'Performed'
      } else {
        return 'Completed'
      }
    },
    async executeById() {
      try {
        await api.post(`/admin/email/${this.$route.params.id}/execute`)
        this.alertSetting.type = 'success'
        this.alertSetting.text = 'Execute success'
        this.alertSetting.show = true
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          this.alertSetting.show = false
        }, 3000)
        this.item = await api.get(`/admin/email/${this.$route.params.id}`)
        this.setOffset(this.item.timezone)

      }
    },
    openUsersList() {
      this.$router.push({path: `/email-manager/${this.$route.params.id}/users`})
    },
    onChipClose(userId) {
      this.autoCompleteInput = this.autoCompleteInput.filter(user => user.id !== userId);
    },
    getItemText(item) {
      return `${item.name} (${item.email})`;
    },
    async syncSearchUsersFromApi() {
      if (this.searchUser.trim().length <= 0) {
        this.users = []
      } else {
        try {
          const response = await api.get('/admin/users', {
            params: {
              s: {"$or": [{"name": {"$cont": this.searchUser.trim()}}, {"email": {"$cont": this.searchUser.trim()}}]}
            }
          });
          this.users = response.data
          console.log('this.users', this.users)

        } catch (e) {
          console.log('error', e);

        } finally {

        }
      }

    },
    async saveInfo() {
      await api.patch(`/admin/email/${this.$route.params.id}`, {...this.editItem})
      this.item = await api.get(`/admin/email/${this.$route.params.id}`)
      this.setOffset(this.item.timezone)

      this.dialog = false
      this.alertSetting.type = 'success'
      this.alertSetting.text = 'Info change'
      this.alertSetting.show = true
      setTimeout(() => {
        this.alertSetting.show = false
      }, 3000)

    },
    openModal(item) {
      this.editItem = Object.assign({}, item)
      this.dialog = true
    },

    setOffset(timezone) {
      const now = moment().tz(timezone);
      const offset = now.utcOffset() / 60;
      this.offsetTimeZone = offset >= 0 ? `+${offset}` : offset;
    },
    async fetchItem() {
      this.loading = true

      try {
        this.item = await api.get(`/admin/email/${this.$route.params.id}`)
        console.log('this.item.filter', this.item.filter)

        this.setOffset(this.item.timezone)


        if (this.item.filter) {

          if (this.item.whitelist.length > 0) {
            this.autoCompleteInput = this.item.whitelist.map(r => {
              return {
                ...r.user
              }
            })
          }


          this.filter.ageValue[0] = this.item.filter.ageMin

          this.filter.ageValue[1] = this.item.filter.ageMax
          this.filter.balanceValue[0] = this.item.filter.balanceMin
          this.filter.balanceValue[1] = this.item.filter.balanceMax

          const countries = this.$getCountries()
          const country = countries.find(r => r.code === this.item.filter.country)
          console.log(country)
          if (country) {
            this.filter.selectedCountry = country.code
          }


          if (this.item.filter.tariffId) {
            this.filter.selectedTariff = this.item.filter.tariffId
          }

          if (this.item.filter.agencyId) {
            this.filter.selectedAgency = this.item.filter.agencyId
          }

          if (this.item.filter.status !== null) {
            this.filter.selectedStatus = this.item.filter.status
          }

          if (this.item.filter.isAgency === null) {
            this.filter.selectedIsAgency = ''
          } else if (this.item.filter.isAgency) {
            this.filter.selectedIsAgency = true
          } else {
            this.filter.selectedIsAgency = false
          }


          if (this.item.filter.isBoy === null) {
            this.filter.selectedIsBoy = ''
          } else if (this.item.filter.isBoy) {
            this.filter.selectedIsBoy = true
          } else {
            this.filter.selectedIsBoy = false
          }


        }

      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }

    },
    async changeStatus(active) {
      if (active) {
        await api.get(`/admin/email/${this.$route.params.id}/enable`)
      } else {
        await api.get(`/admin/email/${this.$route.params.id}/disable`)
      }
      this.item = await api.get(`/admin/email/${this.$route.params.id}`)

    },
    async acceptFilters() {
      const data = {
        isAgency: this.filter.selectedIsAgency,
        isBoy: this.filter.selectedIsBoy,
        tariffId: this.filter.selectedTariff,
        status: this.filter.selectedStatus,
        country: this.filter.selectedCountry,
        agencyId: this.filter.selectedAgency,
        balanceMin: this.filter.balanceValue[0],
        balanceMax: this.filter.balanceValue[1],
        ageMin: this.filter.ageValue[0],
        ageMax: this.filter.ageValue[1],
      }
      const filteredObj = Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== "" && value !== null));
      try {
        await api.post(`/admin/email/${this.$route.params.id}/filter`, {...filteredObj})
        await api.post(`/admin/email/${this.$route.params.id}/whitelist`, {
          ids: this.autoCompleteInput.map(r => r.id)
        })

        this.alertSetting.type = 'success'
        this.alertSetting.text = 'Change success'
        this.alertSetting.show = true
      } catch (e) {
        console.log(e)
        this.alertSetting.type = 'error'
        this.alertSetting.text = 'Change error'
        this.alertSetting.show = true
      } finally {
        setTimeout(() => {
          this.alertSetting.show = false

        }, 3000)
      }

      console.log(filteredObj);
    },
    frequency(text) {
      return cronstrue.toString(text)
    },
  }
}
</script>

<style scoped lang="scss">
.v-list-item__subtitle {
  white-space: unset !important;
}

.alert {
  position: fixed;
  right: 10px;
  top: 70px;
  z-index: 100;
}

.title {
  border-bottom: 2px solid #2f55b4
}
</style>