import { render, staticRenderFns } from "./UserManLogCreditHistoryTable.vue?vue&type=template&id=503444be&scoped=true"
import script from "./UserManLogCreditHistoryTable.vue?vue&type=script&lang=js"
export * from "./UserManLogCreditHistoryTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "503444be",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VChip,VDataTable,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle})
