import { api } from '@/providers/api';

export async function getActiveState() {
  return await api.get('/chats-bot/status').then(r => r.status);
}

export async function setActiveState(value) {
  return await api.post('/chats-bot/status', {}, {
    params: {
      value
    }
  }).then(r => r.status);
}

export async function getChatBotMessageTemplates() {
  return await api.get('/chats-bot/static-templates');
}

export async function createChatBotMessageTemplate({ templateContent }) {
  return await api.post('/chats-bot/templates', { templateContent });
}

export async function updateChatBotMessageTemplate(id, message) {
  return await api.patch(`/chats-bot/${id}`, { message: message });
}

export async function deleteChatBotMessageTemplate(id) {
  return await api.delete(`/chats-bot/${id}`);
}

export async function getBotMessagesActivity(page = 1, limit = 100) {
  return await api.get('/chats-bot/messages', { params: { page, limit } });
}
