<template>
  <div>
    <v-container class="my-5">
      <v-tabs @change="changeTab" background-color="#f4f6f9" color="primary">
        <v-tab v-for="(tab, index) in tabs" :key="index">{{ tab.name }}</v-tab>
        <v-tab-item v-for="(tab, index) in tabs" :key="index">
          <component :is="tab.component" v-if="currentTab === index" />
        </v-tab-item>
      </v-tabs>
    </v-container>
  </div>
</template>

<script>
import AgencyTableIntroduce from "@/views/AgencyNotification/AgencyTableIntroduce.vue";
import AgencyTableChatDate from "@/views/AgencyNotification/AgencyTableChatDate.vue";
import AgencyTableFlirt from "@/views/AgencyNotification/AgencyTableFlirt.vue";
import AgencyTableLike from "@/views/AgencyNotification/AgencyTableLike.vue";
import AgencyTableChat from "@/views/AgencyNotification/AgencyTableChat.vue";

export default {
  name: "AgencyNotificationTable",
  components: {
    AgencyTableChat,
    AgencyTableLike,
    AgencyTableFlirt,
    AgencyTableChatDate,
    AgencyTableIntroduce
  },

  data() {
    return {
      currentTab: 0,
      tabs: [
        { name: "Chat Message", component: AgencyTableChat },
        { name: "Like", component: AgencyTableLike },
        { name: "Flirt", component: AgencyTableFlirt },
        { name: "Chat Date", component: AgencyTableChatDate },
        { name: "Introduce", component: AgencyTableIntroduce }
      ]
    };
  },

  methods: {
    changeTab(newTab) {
      this.currentTab = newTab;
    }
  }
};
</script>

<style scoped>
</style>
