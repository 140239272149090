var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{key:"id",staticClass:"elevation-20",attrs:{"footer-props":{'items-per-page-options': [10, 50, 100]},"items-per-page":10,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalItems,"options":_vm.options,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.adminViewed",fn:function(ref){
var item = ref.item;
return [_c('CirclePulsating',{attrs:{"pulsing":!item.adminViewed}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(new Date(item.createdAt),'Pp')))]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.message.sender.name))]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.destinationUser.name))]}},{key:"item.viewed",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":!item.message.viewed ? 'red' : 'green'}},[_vm._v(_vm._s(!item.message.viewed ? 'mdi-eye-remove' : 'mdi-eye'))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }