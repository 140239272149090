<template>
  <div>
    FreeEmail
  </div>
</template>

<script>
  export default {
    name: "FreeEmail"
  }
</script>

<style scoped lang="scss">

</style>