import BigNumber from "bignumber.js";
import {mapGetters, mapState} from "vuex";


export const BalanceMixin = {
  computed: {
    ...mapGetters(['getActiveCurrencyRate']),
  },
  methods: {
    $getBalance(balance) {
      const balanceFix = new BigNumber(balance)
      return balanceFix.toFixed(2, 3)
    },
    $getBalanceAgency(balance) {
      const balanceRate = balance * this.getActiveCurrencyRate;
      const balanceFix = new BigNumber(balanceRate)
      return balanceFix.toFixed(2, 3)
    }
  }
}