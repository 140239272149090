import { render, staticRenderFns } from "./AgencyBonus.vue?vue&type=template&id=4dab4686&scoped=true"
import script from "./AgencyBonus.vue?vue&type=script&lang=js"
export * from "./AgencyBonus.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dab4686",
  null
  
)

export default component.exports