export const routes = [
    {
        text: 'Custom route',
        value: 'custom'
    },
    {
        text: 'All men-ladies',
        value: 'catalog/all'
    },
    {
        text: 'New men-ladies',
        value: 'catalog/new'
    },
    {
        text: 'Online men-ladies',
        value: 'catalog/online'
    },
    {
        text: 'Chats',
        value: 'chat'
    },
    {
        text: 'Mailbox/Inbox',
        value: 'introductions/inbox'
    },
    {
        text: 'Favorites',
        value: 'favorites/all'
    },
    {
        text: 'Favorites/Admires',
        value: 'favorites/admires'
    },
    {
        text: 'Favorites/Matches',
        value: 'favorites/matches'
    },
    {
        text: 'Chat Dates/Pending',
        value: 'invitation/pending'
    },
    {
        text: 'Chat Dates/Accepted',
        value: 'invitation/accepted'
    },
    {
        text: 'Chat Dates/Declined',
        value: 'invitation/declined'
    },
    {
        text: 'Photo Likes',
        value: 'likes/received'
    },
    {
        text: 'Flirts/New',
        value: 'flirts/new'
    },
    {
        text: 'Flirts/Received',
        value: 'flirts/received'
    },
    {
        text: 'My Account/Actions',
        value: 'personal/actions'
    },
    {
        text: 'My Account/My Profile',
        value: 'personal/my-profile'
    },
    {
        text: 'My Account/Match Preferences',
        value: 'personal/match-preferences'
    },
    {
        text: 'My Account/Subscription',
        value: 'personal/subscription'
    },
    {
        text: 'My Account/Credits-purchase History',
        value: 'personal/credit-history'
    },
    {
        text: 'Buy Credits',
        value: 'buy-credits'
    },
    {
        text: 'Upgrade Membership',
        value: 'membership'
    },
]